<template>
  <v-layout fill-height>
    <v-main>
      <PageBar
          :title="$tr('menu', 'key_e9')"
          :drawer="true"
      />
      <IntergrationView  v-for="item in intergration.filter(info => info.route_name === 'Googleanalytics')" :key="item.id"  :item="item" />
      <Loader v-if="loading"/>
      <Container v-else>
        <Title :title="$tr('project', 'key_336')"/>
        <v-row class="mb-7">
          <v-col md="6" sm="12" cols="12">
            <v-text-field
                :label="$tr('project', 'key_337')"
                outlined
                color="primary"
                dense
                hide-details
                v-model="settings.analytics_key"
            ></v-text-field>
          </v-col>
        </v-row>
        
      </Container>
      <HelpArticle id="632817b9b78f9a796bdeacbf" :helpcentertopic="helpcentertopic" />
       
      <Footer
          v-if="!loading"
          :loading="btnLoad"
          ref="footer"
          @click="updatePushDetail"
      />
      
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Container from "@/components/blocks/Container";
import Title from "@/components/blocks/Title";
import Loader from "@/components/blocks/Loader";
import Footer from "@/components/blocks/Footer";
import IntergrationView from "../../../../../components/blocks/IntergrationView.vue";
import HelpArticle from "../../../../../components/blocks/HelpArticle.vue";

export default {
  name: 'Push',
  components: {
    PageBar,
    Container,
    Title,
    Loader,
    Footer,
    IntergrationView,
    HelpArticle
},
  data: () => ({
    loading: true,
    btnLoad: false,
    intergration: [],
    helpcentertopic: [
      {
        "name" : "Setup Google Analytics",
        "link" : "123",
      },
      {
        "name" : "Google Analytics Use Guide",
        "link" : "123",
      },
      {
        "name" : "How to get the API Key",
        "link" : "123",
      },
      {
        "name" : "Enable Google Analytics on your App",
        "link" : "123",
      }
    ],
    settings: {
      analytics_key:""
    },
    btn_load: false
  }),
  methods: {
    getPushSettings() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/push/detail/${this.$route.params.uid}`).
      then(
          response => {
            this.settings = response.data.detail;
            this.getIntergrations();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
      );
    },
    getIntergrations() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/intergrations/getintergrations/${this.$route.params.id}`).
        then(
          response => {
            this.intergration = response.data.intergration;
            this.loading = false;
          }
        ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
        );
    },
    updatePushDetail() {
      this.btnLoad = true;
      let params = new URLSearchParams();
      params.append('analytics_key', this.settings.analytics_key);
      this.$http.post(`${this.$serverApiLink}api/account/push/updateanalitics/${this.$route.params.uid}`, params).
      then(
          // eslint-disable-next-line no-unused-vars
          response => {
            this.btnLoad = false;
            this.$refs.footer.showSuccessAlert();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.btnLoad = false;
          }
      );
    },
  },
  mounted() {
    this.getPushSettings();
  }
}
</script>