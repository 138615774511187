<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 9.09577C12 9.19528 12.0371 9.29121 12.104 9.36484C12.2526 9.5283 12.5056 9.54035 12.6691 9.39175L16.9574 5.49329C16.973 5.47906 16.988 5.46409 17.0022 5.44844C17.2499 5.176 17.2298 4.75437 16.9574 4.5067L12.6691 0.608235C12.5954 0.5413 12.4995 0.504211 12.4 0.504211C12.1791 0.504211 12 0.683298 12 0.904211L12 3.99999C7.58172 3.99999 4 7.58171 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12C6 8.68628 8.68629 5.99999 12 5.99999L12 9.09577Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'RefreshMiniIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>