<template>
  <v-layout fill-height>
    <v-main>
      <PageBar :title="$tr('menu', 'key_e12')" :drawer="true" />
      <IntergrationView
        v-for="item in intergration.filter(
          (info) => info.route_name === 'Firebase'
        )"
        :key="item.id"
        :item="item" />
      <Loader v-if="loading" />
      <Container v-else>
        <Title title="Firebase configuration" />
        <v-row class="mb-7">
          <v-col md="4" sm="12" cols="12">
            <v-text-field
              :label="$tr('project', 'key_372')"
              outlined
              color="primary"
              dense
              hide-details
              v-model="settings.api_key"></v-text-field>
          </v-col>
          <v-col md="4" sm="12" cols="12">
            <v-text-field
              :label="$tr('project', 'key_373')"
              outlined
              color="primary"
              dense
              hide-details
              v-model="settings.firebase_senderID"></v-text-field>
          </v-col>
          <v-col md="4" sm="12" cols="12">
            <v-text-field
              :label="$tr('project', 'key_374')"
              outlined
              color="primary"
              dense
              hide-details
              v-model="settings.firebase_projectID"></v-text-field>
          </v-col>
          <v-col md="4" sm="12" cols="12">
            <v-text-field
              :label="$tr('project', 'key_375')"
              outlined
              color="primary"
              dense
              hide-details
              v-model="settings.firebase_iosappID"></v-text-field>
          </v-col>
          <v-col md="4" sm="12" cols="12">
            <v-text-field
              :label="$tr('project', 'key_376')"
              outlined
              color="primary"
              dense
              hide-details
              v-model="settings.firebase_androidappID"></v-text-field>
          </v-col>
          <!-- <v-col md="4" sm="12" cols="12">
            <v-text-field
              :label="$tr('project', 'key_381')"
              outlined
              color="primary"
              dense
              hide-details
              v-model="settings.fcm_server_key"></v-text-field>
          </v-col> -->
        </v-row>

        <v-row class="mb-0">
          <v-col md="12" style="margin-top: 0px" v-if="connectionloading">
            <v-alert dense text type="info">Connecting...</v-alert>
          </v-col>

          <v-col md="12" style="margin-top: 0px" v-if="!connectionloading">
            <v-alert v-if="appconnected" dense text type="success"
              >Connected Successfully</v-alert
            >

            <v-alert v-if="!appconnected" dense text type="warning"
              >Connection Problem. Check your API Key & Secret
              <v-btn small style="float: right" @click="checkConnectStatus">
                Check Conection Again
              </v-btn>
            </v-alert>
          </v-col>
        </v-row>

        <Title title="Upload google-service.json file" />
        <div class="row">
          <div class="col-md-6">
            <div class="permissions_block">
              <DropZone
                ref="dropZone"
                label="Upload google-service.json file"
                @update="updateFile" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="permissions_block">
              <DropZone
                ref="dropZone2"
                :label="$tr('project', 'key_448')"
                @update="updateServiceFile" />
            </div>
          </div>
        </div>
        <Title title="Upload firebase-adminsdk for setup push notifications" />
        <div class="row">
          <div class="col-md-6">
            <div class="permissions_block">
              <DropZone
                ref="dropZone3"
                label="Upload firebase-adminsdk.json file"
                @update="updateadminsdkFile" />
            </div>
          </div>
        </div>

        <Title :title="$tr('project', 'key_97')" style="padding-top: 20px" />
        <div class="row">
          <div class="col-md-6">
            <div class="permissions_block">
              <div class="d-flex justify-space-between align-center pa-4">
                <div class="d-flex justify-start align-center">
                  <v-avatar color="whitesmoke" :size="60" class="mr-4">
                    <div
                      class="android--text"
                      style="width: 32px; height: 32px">
                      <AndroidLogoIcon :size="32" />
                    </div>
                  </v-avatar>
                  <div>
                    <div class="body-1 font-weight-medium">
                      {{ $tr("project", "key_95") }}
                    </div>
                    <div class="body-2 smoke--text">
                      {{ $tr("project", "key_292") }}
                    </div>
                  </div>
                </div>
                <v-switch
                  :true-value="1"
                  :false-value="0"
                  v-model="settings.android_enabled"
                  inset></v-switch>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="permissions_block">
              <div class="d-flex justify-space-between align-center pa-4">
                <div class="d-flex justify-start align-center">
                  <v-avatar color="whitesmoke" :size="60" class="mr-4">
                    <div class="Apple--text" style="width: 32px; height: 32px">
                      <AppleLogoIcon :size="32" />
                    </div>
                  </v-avatar>
                  <div>
                    <div class="body-1 font-weight-medium">
                      {{ $tr("project", "key_382") }}
                    </div>
                    <div class="body-2 smoke--text">
                      {{ $tr("project", "key_383") }}
                    </div>
                  </div>
                </div>
                <v-switch
                  :true-value="1"
                  :false-value="0"
                  v-model="settings.apple_enabled"
                  inset></v-switch>
              </div>
            </div>
          </div>
          <v-col md="6" sm="12" cols="12">
            <v-text-field
              label="Deep Link"
              outlined
              color="primary"
              dense
              hide-details
              v-model="settings.deep_link"></v-text-field>
          </v-col>
          <v-col md="6" sm="12" cols="12">
            <v-text-field
              label="Deep Link URL"
              outlined
              color="primary"
              dense
              hide-details
              v-model="settings.dynamic_link"></v-text-field>
          </v-col>
        </div>
      </Container>
      <HelpArticle
        id="63184635d8fe9e425c2ba1e2"
        :helpcentertopic="helpcentertopic" />
      <Footer
        v-if="!loading"
        :loading="btnLoad"
        ref="footer"
        @click="updatePushDetail" />
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Container from "@/components/blocks/Container";
import Title from "@/components/blocks/Title";
import Loader from "@/components/blocks/Loader";
import Footer from "@/components/blocks/Footer";
import IntergrationView from "../../../../../components/blocks/IntergrationView.vue";
import HelpArticle from "../../../../../components/blocks/HelpArticle.vue";
import DropZone from "@/components/form/DropZone";
import AppleLogoIcon from "../../../../../components/icons/AppleLogoIcon.vue";
import AndroidLogoIcon from "../../../../../components/icons/AndroidLogoIcon.vue";
// import axios from "axios";

export default {
  name: "Push",
  components: {
    PageBar,
    Container,
    Title,
    Loader,
    DropZone,
    Footer,
    // RefreshMiniIcon,
    IntergrationView,
    HelpArticle,
    AppleLogoIcon,
    AndroidLogoIcon,
  },
  data: () => ({
    loading: true,
    btnLoad: false,
    connectionloading: true,
    intergration: [],
    settings: {
      api_key: "",
      app_id: "",
      ios: 0,
      android: 0,
      file: null,
      deep_link: "",
      dynamic_link: "",
      servicefile: null,
      sign_key: "",
      firebase_projectID: "",
      firebase_iosappID: "",
      firebase_androidappID: "",
      firebase_senderID: "",
      // fcm_server_key: "",
      isSelecting: false,
      firebase_configfile: 0,
      firebase_googleserviceInfoplist: 0,
      adminsdkfile: 0,
    },
    helpcentertopic: [
      {
        name: "Setup Google Analytics",
        link: "123",
      },
      {
        name: "Google Analytics Use Guide",
        link: "123",
      },
      {
        name: "How to get the API Key",
        link: "123",
      },
      {
        name: "Enable Google Analytics on your App",
        link: "123",
      },
    ],
    reissue: false,
    btn_load: false,
  }),
  methods: {
    async checkConnectStatus() {
      this.connectionloading = true;

      let params = new FormData();
      params.set("title", "Test");
      params.set("message", "Test notification description");
      params.set("led", "#2D9CDB");
      params.set("audience", "testall");

      params.set("action", "open product");
      params.set("page", "ShopPage");
      params.set(
        "arguments",
        JSON.stringify({
          query: { orderby: "price", order: "desc", category: "16" },
          data: { name: "Notofication" },
        })
      );
      params.set("sound", "Tri-tone");

      params.set("sheduled", "false");
      params.set("sheduled_date", "2024-09-30");
      params.set("sheduled_time", "12:00");
      params.set(
        "image",
        "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png"
      );

      this.$http
        .post(
          `${this.$serverApiLink}api/account/newsletter/testPush/${this.$route.params.uid}`,
          params,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((response) => {
          console.log(response);
          this.appconnected = true;
          // this.$store.commit("setSnackBar", {
          //   code: response.status,
          //   message: response.data.message,
          // });
          this.connectionloading = false;
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.connectionloading = false;
        });

      // const postparam = {
      //   to: "ebOmCuBmQ_W4v1ouwpBZyc:APA91bF8dDzVJKsD1hQQVIIYrod89aB0_ugkp89hD0yk8but62Q7lg7lyIArDQFr0o4Ax0NBBDX77WKXggjT4m3dFkSMmcgGxGOKYD_6p8chp5hiNy_w40BQ1oCdhV3zYckJMPIck79",
      //   notification: {
      //     title: "Test",
      //     body: "Test notification description",
      //     mutable_content: true,
      //     sound: "Tri-tone",
      //   },

      //   data: {
      //     type: "open product",
      //     route: "ShopPage",
      //     args: {
      //       query: {
      //         orderby: "price",
      //         order: "desc",
      //         category: "16",
      //       },
      //       data: { name: "Notofication" },
      //     },
      //   },
      // };
      // const headers = {
      //   "content-type": "application/json",
      //   Authorization:
      //     "key=AAAAol9PscU:APA91bG0C_CNASmIWPUWAB9EzMb7aE4cZ2I22TSIbMtm_QDPkVlhAY3x3YBZNBZ7GFY21ggOP9togrRPJE6VsMqPBnEISI2jMaiBbYHAox-3onpswVsDaMzvIa4PDrUySEqKgM118RJn",
      // };
      // axios
      //   .post(`https://fcm.googleapis.com/fcm/send`, postparam, { headers })
      //   .then((response) => {
      //     this.appconnected = true;
      //     this.connectionloading = false;
      //     console.log("success");
      //     console.log(axios);
      //     console.log(axios.headers["Content-Type"]);
      //     console.log(response);
      //     console.log("Store Connection Successful : " + response.status);
      //   })
      //   .catch((error) => {
      //     this.appconnected = false;
      //     this.connectionloading = false;
      //     console.log("failed");
      //     console.log(axios);
      //     console.error(axios.defaults.headers);
      //     console.error(axios.headers["Content-Type"]);
      //     console.error(error);
      //     console.log("Store Connection Problem : " + error);
      //   });
    },
    updateFile(file) {
      this.settings.file = file;
    },
    updateServiceFile(servicefile) {
      this.settings.servicefile = servicefile;
    },
    updateadminsdkFile(adminsdkfile) {
      this.settings.adminsdkfile = adminsdkfile;
    },
    getPushSettings() {
      this.loading = true;
      this.$http
        .get(
          `${this.$serverApiLink}api/account/push/detail/${this.$route.params.uid}`
        )
        .then((response) => {
          console.log("------------------------------- >>>> Firebase Console");
          console.log(response.data.detail.firebase_senderID);
          this.settings = response.data.detail;
          this.getIntergrations();
          this.checkConnectStatus();
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.loading = false;
          //this.checkConnectStatus();
        });
    },
    getIntergrations() {
      this.loading = true;
      this.$http
        .get(
          `${this.$serverApiLink}api/account/intergrations/getintergrations/${this.$route.params.id}`
        )
        .then((response) => {
          this.intergration = response.data.intergration;
          this.loading = false;
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.loading = false;
        });
    },
    updatePushDetail() {
      this.btnLoad = true;
      let params = new FormData();
      params.append("apple", this.settings.ios);
      params.append("android", this.settings.android);
      params.append("app_id", this.settings.app_id);
      params.append("api_key", this.settings.api_key);
      params.append("firebase_iosappID", this.settings.firebase_iosappID);
      params.append(
        "firebase_androidappID",
        this.settings.firebase_androidappID
      );
      params.append("firebase_senderID", this.settings.firebase_senderID);
      params.append("firebase_projectID", this.settings.firebase_projectID);
      params.append("deep_link", this.settings.deep_link);
      params.append("dynamic_link", this.settings.dynamic_link);
      params.append("firebase_configfile", this.settings.file);
      params.append(
        "firebase_googleserviceInfoplist",
        this.settings.servicefile
      );
      params.append("adminsdkfile", this.settings.adminsdkfile);
      // params.append("fcm_server_key", this.settings.fcm_server_key);
      params.append("android_enabled", this.settings.android_enabled);
      params.append("apple_enabled", this.settings.apple_enabled);
      this.$http
        .post(
          `${this.$serverApiLink}api/account/push/update/${this.$route.params.uid}`,
          params,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then(
          // eslint-disable-next-line no-unused-vars
          (response) => {
            this.btnLoad = false;
            this.$refs.dropZone.clean();
            this.$refs.footer.showSuccessAlert();
          }
        )
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          console.log("gehe");
          this.btnLoad = false;
        });
    },
  },
  mounted() {
    this.getPushSettings();
  },
};
</script>
