<template>
  <v-layout fill-height>
    <v-main>
      <PageBar
          :title="$tr('menu', 'key_e2')"
          
          :drawer="true"
          @onChange="updateTab"
      />
      <Loader v-if="loading"/>
      <template v-else>
        <template v-if="!tab">
          <Container>
            <div class="d-flex justify-space-between align-center">
              <Title :title="$tr('menu', 'key_e2')"/>
              <v-btn
                  small
                  depressed
                  color="primary"
                  outlined
                  class="mb-5"
                  @click="dialog.status = true"
              >
                {{ $tr('project', 'key_53') }}
              </v-btn>
            </div>
            <EmptyList v-if="!gallery.list.length"/>
            <v-list dense two-line class="pa-0 background_block" v-else>
              <div v-for="(item, index) in gallery.list" :key="'menu_item_app_'+index">
                <v-divider
                    v-if="index > 0"
                    :key="'menu_line_'+index"
                />
                <v-list-item
                    :key="'menu_select_item_'+index"
                    class="pl-0 pr-0 pt-0 pb-0"
                >
                  <v-list-item-avatar
                      size="40"
                      color="whitesmoke"
                      class="mt-0 mb-0"
                  >
                    <div
                        class="d-flex justify-center align-center black--text"
                        style="width: 40px; height: 40px; font-size: 18px"
                    >
                      <ion-icon
                          :name="item.icon"
                      ></ion-icon>
                    </div>
                  </v-list-item-avatar>

                  <v-list-item-content>

                    <v-list-item-title style="font-weight:bold; font-size: 0.9em;">
                      {{ item.name }}
                    </v-list-item-title>

                    <v-list-item-subtitle class="smoke--text">
                       <!-- {{ item.link }} -->
                    </v-list-item-subtitle>

                  </v-list-item-content>

                  <v-list-item-action>
                    <div>
                      <v-btn icon small color="orange" class="mr-3" @click="openAppUpdate(index)">
                        <EditMiniIcon :size="16"/>
                      </v-btn>
                      <v-btn icon small color="danger" @click="deleteAppItem(index)">
                        <DeleteMiniIcon :size="16"/>
                      </v-btn>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </v-list>
          </Container>
          <Footer
              ref="footer"
          />
        </template>
        
        
      </template>
      <v-dialog
          scrollable
          max-width="680"
          v-model="dialog.status"
      >
        <NewGallery
            :loading="dialog.loading"
            ref="newApp"
            @close="dialog.status = false"
            @save="createAppItem"
        />
      </v-dialog>
      <v-dialog
          scrollable
          max-width="680"
          v-model="edit.status"
      >
        <NewGallery
            v-if="edit.status"
            :item-name="gallery.list[edit.index].name"
            :item-link="gallery.list[edit.index].link"
            :loading="edit.loading"
            @close="edit.status = false"
            @save="updateAppItem"
        />
      </v-dialog>

    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Container from "@/components/blocks/Container";
import Title from "@/components/blocks/Title";
import Loader from "@/components/blocks/Loader";
import EmptyList from "@/views/account/apps/builder/components/EmptyList";
import NewGallery from "@/views/account/apps/builder/modals/NewGallery";
import DeleteMiniIcon from "@/components/icons/DeleteMiniIcon";
import EditMiniIcon from "@/components/icons/EditMiniIcon";
import Footer from "@/components/blocks/FooterWithoutButton";

export default {
  name: 'Slideshow',
  components: {
    PageBar,
    Container,
    Title,
    Loader,
    EmptyList,
    NewGallery,
    DeleteMiniIcon,
    EditMiniIcon,
    Footer
  },
  data: () => ({
    loading: true,
    tab: 0,
    gallery: {
      list: [],
      count: 0
    },
    dialog: {
      status: false,
      loading: false
    },
    edit: {
      status: false,
      loading: false,
      index: 0
    },
    dialogBar: {
      status: false,
      loading: false
    },
    editBar: {
      status: false,
      loading: false,
      index: 0
    },
    dialogModal: {
      status: false,
      loading: false
    },
    editModal: {
      status: false,
      loading: false,
      index: 0
    },
  }),
  computed: {
    tabs: function () {
      return [
        this.$tr('project', 'key_118'),
        this.$tr('project', 'key_45'),
        this.$tr('project', 'key_327')
      ];
    },
    actions: function () {
      return [
        {
          text: this.$tr('project', 'key_57'),
          value: 0
        },
        {
          text: this.$tr('project', 'key_58'),
          value: 1
        },
        {
          text: this.$tr('project', 'key_59'),
          value: 2
        },
        {
          text: this.$tr('project', 'key_60'),
          value: 3
        },
        {
          text: this.$tr('project', 'key_298'),
          value: 4
        },
        {
          text: this.$tr('project', 'key_328'),
          value: 5
        },
      ];
    },
  },
  methods: {
    updateTab(value) {
      this.tab = value;
    },
    openAppUpdate(index) {
      this.edit = {
        status: true,
        loading: false,
        index: index
      }
    },
    deleteAppItem(index) {
      this.$store.commit('setLoading', true);
      this.$http.get(`${this.$serverApiLink}api/account/gallery/remove/${this.gallery.list[index].id}`).
      then(
          // eslint-disable-next-line no-unused-vars
          response => {
            this.gallery.list.splice(index, 1);
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "app"
            });
            this.$refs.footer.showSuccessAlert();
            this.$store.commit('setLoading', false);
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.$store.commit('setLoading', false);
          }
      );
    },
    createAppItem(value) {
      this.dialog.loading = true;
      let params = new URLSearchParams();
      params.append('name', value.name);
      this.$http.post(`${this.$serverApiLink}api/account/gallery/create/${this.$route.params.uid}`, params).
      then(
          response => {
            this.gallery.list.push({
              name: value.name,
              link: '',
              type: '',
              icon: 'heart-outline',
              id: response.data.id
            });
            this.dialog = {
              loading: false,
              status: false
            };
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "app"
            });
            this.$refs.footer.showSuccessAlert();
            this.$refs.newApp.clean();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.dialog.loading = false;
          }
      );
    },
    updateAppItem(value) {
      this.edit.loading = true;
      let id = this.gallery.list[this.edit.index].id;
      let params = new URLSearchParams();
      params.append('link', value.link);
      params.append('name', value.name);
      this.$http.post(`${this.$serverApiLink}api/account/gallery/update/${id}`, params).
      then(
          () => {
            this.gallery.list[this.edit.index] = {
              name: value.name,
              link: value.link,
              id: this.gallery.list[this.edit.index].id
            };
            this.edit = {
              status: false,
              loading: false,
              index: 0
            };
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "app"
            });
            this.$refs.footer.showSuccessAlert();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.edit.loading = false;
          }
      );
    },
    getGalleryItems() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/gallery/list/${this.$route.params.uid}`).
      then(
          response => {
            this.gallery.list = response.data.list;
            this.gallery.count = response.data.count;
            this.loading = false;
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
          }
      );
    },
  },
  mounted() {
    this.getGalleryItems();
  }
}
</script>