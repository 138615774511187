<template>
  <v-layout fill-height>
    <v-main>
      <PageBar :title="$tr('menu', 'key_e12')" :drawer="true" />
      <IntergrationView
        v-for="item in intergration.filter(
          (info) => info.route_name === 'firebasepush'
        )"
        :key="item.id"
        :item="item" />
      <Loader v-if="loading" />
      <Container v-else>
        <Title :title="$tr('menu', 'key_e14')" />
        <v-row class="mb-7">
          <v-col md="4" sm="12" cols="12">
            <!-- <v-text-field
                  :label="$tr('project', 'key_381')"
                  outlined
                  color="primary"
                  dense
                  hide-details
                  v-model="settings.fcm_server_key"
              ></v-text-field> -->
          </v-col>
        </v-row>

        <Title :title="$tr('project', 'key_97')" />
        <div class="permissions_block">
          <div class="d-flex justify-space-between align-center pa-4">
            <div class="d-flex justify-start align-center">
              <v-avatar color="whitesmoke" :size="60" class="mr-4">
                <div class="android--text" style="width: 32px; height: 32px">
                  <AndroidLogoIcon :size="32" />
                </div>
              </v-avatar>
              <div>
                <div class="body-1 font-weight-medium">
                  {{ $tr("project", "key_95") }}
                </div>
                <div class="body-2 smoke--text">
                  {{ $tr("project", "key_292") }}
                </div>
              </div>
            </div>
            <v-switch
              :true-value="1"
              :false-value="0"
              v-model="settings.android_enabled"
              inset></v-switch>
          </div>
        </div>
        <div class="permissions_block" style="margin-top: 20px">
          <div class="d-flex justify-space-between align-center pa-4">
            <div class="d-flex justify-start align-center">
              <v-avatar color="whitesmoke" :size="60" class="mr-4">
                <div class="Apple--text" style="width: 32px; height: 32px">
                  <AppleLogoIcon :size="32" />
                </div>
              </v-avatar>
              <div>
                <div class="body-1 font-weight-medium">
                  {{ $tr("project", "key_382") }}
                </div>
                <div class="body-2 smoke--text">
                  {{ $tr("project", "key_383") }}
                </div>
              </div>
            </div>
            <v-switch
              :true-value="1"
              :false-value="0"
              v-model="settings.apple_enabled"
              inset></v-switch>
          </div>
        </div>
      </Container>
      <HelpArticle
        id="63184635d8fe9e425c2ba1e2"
        :helpcentertopic="helpcentertopic" />
      <Footer
        v-if="!loading"
        :loading="btnLoad"
        ref="footer"
        @click="updatePushDetail" />
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Container from "@/components/blocks/Container";
import Title from "@/components/blocks/Title";
import Loader from "@/components/blocks/Loader";
import AndroidLogoIcon from "@/components/icons/AndroidLogoIcon";
import AppleLogoIcon from "@/components/icons/AppleLogoIcon";
import Footer from "@/components/blocks/Footer";
import IntergrationView from "../../../../../components/blocks/IntergrationView.vue";
import HelpArticle from "../../../../../components/blocks/HelpArticle.vue";
export default {
  name: "Push",
  components: {
    PageBar,
    Container,
    Title,
    Loader,
    AndroidLogoIcon,
    AppleLogoIcon,
    Footer,
    IntergrationView,
    HelpArticle,
  },
  data: () => ({
    loading: true,
    btnLoad: false,
    intergration: [],
    settings: {
      // fcm_server_key : "",
      apple_enabled: 0,
      android_enabled: 0,
    },
    helpcentertopic: [
      {
        name: "Setup Google Analytics",
        link: "123",
      },
      {
        name: "Google Analytics Use Guide",
        link: "123",
      },
      {
        name: "How to get the API Key",
        link: "123",
      },
      {
        name: "Enable Google Analytics on your App",
        link: "123",
      },
    ],
    reissue: false,
    btn_load: false,
  }),
  methods: {
    getPushSettings() {
      this.loading = true;
      this.$http
        .get(
          `${this.$serverApiLink}api/account/push/detail/${this.$route.params.uid}`
        )
        .then((response) => {
          this.settings = response.data.detail;
          this.getIntergrations();
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.loading = false;
        });
    },
    getIntergrations() {
      this.loading = true;
      this.$http
        .get(
          `${this.$serverApiLink}api/account/intergrations/getintergrations/${this.$route.params.id}`
        )
        .then((response) => {
          this.intergration = response.data.intergration;
          this.loading = false;
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.loading = false;
        });
    },
    updatePushDetail() {
      this.btnLoad = true;
      let params = new URLSearchParams();
      // params.append('fcm_server_key', this.settings.fcm_server_key);
      params.append("android_enabled", this.settings.android_enabled);
      params.append("apple_enabled", this.settings.apple_enabled);
      // params.append('firebase_file', this.settings.file);
      this.$http
        .post(
          `${this.$serverApiLink}api/account/push/updatepush/${this.$route.params.uid}`,
          params
        )
        .then(
          // eslint-disable-next-line no-unused-vars
          (response) => {
            this.btnLoad = false;
            this.$refs.footer.showSuccessAlert();
          }
        )
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          console.log("gehe");
          this.btnLoad = false;
        });
    },
  },
  mounted() {
    this.getPushSettings();
  },
};
</script>
