<template>
  <div class="row">
    <div class="guidcard col ml-10">
      <h3 style="margin-bottom: 10px">Shopimint Documentation</h3>
      <p>
        This documentation guides you through the main features of the theme and
        guides you how to use and customize your mobile app.
      </p>
      <v-btn
        elevation="2"
        outlined
        rounded
        text
        href="https://help.shopimint.com/tag/app-builder-basic-design-guidelines/"
        target="_blank"
        style="
          margin-right: 10px;
          margin-top: 5px;
          background-color: aliceblue;
        ">
        See Documentaion
      </v-btn>
      <v-btn
        elevation="2"
        outlined
        rounded
        text
        href="https://help.shopimint.com/app-builder-basic-design-guidelines/"
        target="_blank"
        style="
          margin-right: 10px;
          margin-top: 5px;
          background-color: aliceblue;
        ">
        Watch 10min Video
      </v-btn>
    </div>
    <div class="guidcard col ml-10">
      <h3 style="margin-bottom: 10px">Promote Business Guide</h3>
      <p>
        We empowers you to sell anywhere, to anybody – over the internet and
        throughout the world.
      </p>
      <v-btn
        elevation="2"
        outlined
        rounded
        text
        href="https://help.shopimint.com/tag/promote-earn/"
        target="_blank"
        style="margin-right: 10px; background-color: aliceblue">
        Learn More
      </v-btn>
    </div>
    <div class="guidcard col ml-10 mr-10">
      <h3 style="margin-bottom: 10px">Partner & Developer Programme</h3>
      <p>
        Content creators, course educators, influencers, and review sites are
        helping their audiences launch successful businesses with Shopimint.
      </p>
      <v-btn
        elevation="2"
        outlined
        rounded
        text
        :to="{ name: 'Partner' }"
        style="
          margin-right: 10px;
          margin-top: 5px;
          background-color: aliceblue;
        ">
        Join
      </v-btn>
      <v-btn
        elevation="2"
        outlined
        rounded
        text
        :to="{ name: 'Partner' }"
        style="
          margin-right: 10px;
          margin-top: 5px;
          background-color: aliceblue;
        ">
        Get Started
      </v-btn>
    </div>
    <!-- <div class="guidcard">
      <h3 style="margin-bottom: 10px">Developer Programme</h3>
      <p>
        Shopimint developer programme support you to develop and distribute
        apps, app services, testing and build amazing features with shopimint
        app builder.
      </p>
      <v-btn
        elevation="2"
        outlined
        rounded
        text
        :to="{ name: 'Partner' }"
        style="margin-right: 10px; background-color: aliceblue">
        Get Started
      </v-btn>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "GuidArea",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    show: "",
  }),
};
</script>
