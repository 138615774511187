<template>
  <v-layout fill-height>
    <v-main>
      <PageBar
          :title="$tr('menu', 'key_e13')"
          :drawer="true"
      />
      <IntergrationView  v-for="item in intergration.filter(info => info.route_name === 'livechat')" :key="item.id"  :item="item" />
      <Loader v-if="loading"/>
      <Container v-else>
        <Title :title="$tr('project', 'key_378')"/>
          <v-row class="mb-7">
            <v-col md="12" sm="12" cols="12" class="pb-10">

              <v-item-group mandatory v-model="livechat.livechat_name" class="row">
                <div  v-for="(item, i) in appbartemplates"
                      :key="`${i}_app_template_layout_builder`"
                      class="col-md-3" >
                  <img
                      :src="item.image"
                      :class="livechat.livechat_name === i ? 'app_template active' : 'app_template'"
                      @click="livechat.livechat_name = i"
                      style="width:100%"
                  />
                  <div class="caption text-uppercase">
                    {{ item.name }}
                  </div>
                </div>

              </v-item-group>
            </v-col>
            <v-col md="12" sm="12" cols="12">
              <v-textarea
                :label="$tr('project', 'key_380')"
                  outlined
                  color="primary"
                  dense
                  hide-details
                  v-model="livechat.livechat_intergration_code"
              ></v-textarea>
            </v-col>
          </v-row>
          <div class="permissions_block">
            <div class="d-flex justify-space-between align-center pa-4">
              <div class="d-flex justify-start align-center">
                <!-- <v-avatar color="whitesmoke" :size="60" class="mr-4"> -->
                  <!-- <div class="android--text" style="width: 32px; height: 32px">
                    <AndroidLogoIcon :size="32"/>
                  </div> -->
                <!-- </v-avatar> -->
                <div>
                  <div class="body-1 font-weight-medium">
                    {{ $tr('project', 'key_398') }}
                  </div>
                </div>
              </div>
              <v-switch
                  :true-value="1"
                  :false-value="0"
                  v-model="livechat.livechat_enabled"
                  inset
              ></v-switch>
            </div>
          </div>
      </Container>
      <HelpArticle id="6328188cb78f9a796bdeacd7" :helpcentertopic="helpcentertopic" />
      <Footer
          v-if="!loading"
          :loading="btnLoad"
          ref="footer"
          @click="updatePushDetail"
      />
      
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Container from "@/components/blocks/Container";
import Title from "@/components/blocks/Title";
import Loader from "@/components/blocks/Loader";
// import AndroidLogoIcon from "@/components/icons/AndroidLogoIcon";
import Footer from "@/components/blocks/Footer";
// import RefreshMiniIcon from "@/components/icons/RefreshMiniIcon";
import IntergrationView from "../../../../../components/blocks/IntergrationView.vue";
import HelpArticle from "../../../../../components/blocks/HelpArticle.vue";
// import DropZone from "@/components/form/DropZone"

import livechat1 from "@/assets/images/livechat/livechat.png";
import livechat2 from "@/assets/images/livechat/tawkio.png";
import livechat3 from "@/assets/images/livechat/tidio.png";
import livechat4 from "@/assets/images/livechat/other.png";

export default {
  name: 'Push',
  components: {
    PageBar,
    Container,
    Title,
    Loader,
    // DropZone,
    // AndroidLogoIcon,
    Footer,
    // RefreshMiniIcon,
    IntergrationView,
    HelpArticle
},
  data: () => ({
    loading: true,
    btnLoad: false,
    intergration: [],
    livechat: {
      livechat_name: "",
      livechat_intergration_code: "",
      livechat_enabled: "",
    },
    helpcentertopic: [
      {
        "name" : "Live Chat",
        "link" : "123",
      },
      {
        "name" : "Add Live Chat Integration Code",
        "link" : "123",
      },
      {
        "name" : "Enable Livechat for your app",
        "link" : "123",
      },
      {
        "name" : "Tidio",
        "link" : "123",
      },
      {
        "name" : "Add Tidio Integration Code",
        "link" : "123",
      },
      {
        "name" : "Enable Tidio for your app",
        "link" : "123",
      },
      {
        "name" : "Tawk.To",
        "link" : "123",
      },
      {
        "name" : "Add Tawk.To Integration Code",
        "link" : "123",
      },
      {
        "name" : "Enable Tawk.To for your app",
        "link" : "123",
      },
    ],
    reissue: false,
    btn_load: false,
    appbartemplates: [
      {
        image: livechat1,
        name: "Live Chat"
      },
      {
        image: livechat2,
        name: "tawk to"
      },
      {
        image: livechat3,
        name: "Tidio"
      },
      {
        image: livechat4,
        name: "Other"
      },
    ],
  }),
  methods: {
    getIntergrations() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/intergrations/getintergrations/${this.$route.params.id}`).
        then(
          response => {
            this.intergration = response.data.intergration;
            this.getPushSettings();
            
          }
        ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
        );
    },
    getPushSettings() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/appintergration/livechat/${this.$route.params.uid}`).
      then(
          response => {
            this.livechat = response.data.detail;
            this.loading = false;
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
      );
    },
    updatePushDetail() {
      this.btnLoad = true;
      let params = new URLSearchParams();
      params.append('livechat_name', this.livechat.livechat_name);
      params.append('livechat_intergration_code', this.livechat.livechat_intergration_code);
      params.append('livechat_enabled', this.livechat.livechat_enabled);
      this.$http.post(`${this.$serverApiLink}api/account/appintergration/updatelivechat/${this.$route.params.uid}`, params ).
      then(
          // eslint-disable-next-line no-unused-vars
          response => {
            this.btnLoad = false;
            this.$refs.footer.showSuccessAlert();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
              });
            this.btnLoad = false;
          }
      );
    },
  },
  mounted() {
    this.getIntergrations();
  }
}
</script>