<template>
  <v-layout fill-height>
    <v-main>
      <PageBar :title="$tr('menu', 'key_e10')" :drawer="true" />
      <IntergrationView
        v-for="item in intergration.filter(
          (info) => info.route_name === 'Paymentgateways'
        )"
        :key="item.id"
        :item="item" />
      <Loader v-if="loading" />
      <Container v-else> </Container>
      <HelpArticle
        id="632818e9b78f9a796bdeacf0"
        :helpcentertopic="helpcentertopic" />
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Container from "@/components/blocks/Container";
import Loader from "@/components/blocks/Loader";
import IntergrationView from "../../../../../components/blocks/IntergrationView.vue";
import axios from "axios";
import HelpArticle from "../../../../../components/blocks/HelpArticle.vue";

export default {
  name: "Push",
  components: {
    PageBar,
    Container,
    Loader,
    IntergrationView,
    HelpArticle,
  },

  data: () => ({
    loading: true,
    btnLoad: false,
    intergration: [],
    settings: {
      api_key: "",
      app_id: "",
      ios: 0,
      android: 0,
      sign_key: "",
    },
    helpcentertopic: [
      {
        name: "Setup Google Analytics",
        link: "123",
      },
      {
        name: "Google Analytics Use Guide",
        link: "123",
      },
      {
        name: "How to get the API Key",
        link: "123",
      },
      {
        name: "Enable Google Analytics on your App",
        link: "123",
      },
    ],
    info: null,
    errored: false,
    reissue: false,
    btn_load: false,
  }),
  methods: {
    getPushSettings() {
      this.loading = true;
      this.$http
        .get(
          `${this.$serverApiLink}api/account/push/detail/${this.$route.params.uid}`
        )
        .then((response) => {
          this.settings = response.data.detail;
          this.getIntergrations();
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.loading = false;
        });
    },
    getIntergrations() {
      this.loading = true;
      this.$http
        .get(
          `${this.$serverApiLink}api/account/intergrations/getintergrations/${this.$route.params.id}`
        )
        .then((response) => {
          this.intergration = response.data.intergration;
          this.getarticle();
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.loading = false;
        });
    },
    getarticle() {
      axios
        .get(
          "https://blog.shopimint.com/ghost/api/content/posts/63007de272930117af643045/?key=171dec075a09e6c4521c3fc3dd&include=tags"
        )
        .then((response) => {
          this.posts = response.data.posts;
          console.log(
            "This area is for use by developers only. Do not copy/paste information here. "
          );
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          console.log("There is a error ");
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    reissueSign() {
      this.btn_load = true;
      this.$http
        .get(
          `${this.$serverApiLink}api/account/push/reissue_sign/${this.$route.params.uid}`
        )
        .then((response) => {
          this.settings.sign_key = response.data.sign_key;
          this.reissue = false;
          this.btn_load = false;
          this.$refs.footer.showSuccessAlert();
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.btn_load = false;
        });
    },
  },
  mounted() {
    this.getPushSettings();
  },
};
</script>
