<template>
    <v-card style="margin:5px; border-radius: 10px;">
        <v-img :src="item.preview_image"></v-img>
        <v-card-subtitle class="pt-1 pb-1 font-weight-bold">
        {{ item.name }}
        </v-card-subtitle>

        <v-dialog
          v-model="dialog"
          width="100%"
          max-width="1200px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-card-actions style="width:100%;">
              <v-btn
                color="black"
                small
                outlined
                v-bind="attrs"
                v-on="on"
              >
                Preview
              </v-btn>
            </v-card-actions>
            
          </template>

          <v-card style="padding: 0;">
            <div >
              <v-row style="margin:0;">
              <v-col md="7" sm="12" cols="12" class="pb-0 pt-0 pl-0">
                <!-- <div v-for="(slide,i) in JSON.parse(item.sliderimages)" :key="i">
                   {{ slide }}
                </div> -->
                <v-carousel 
                  cycle
                  hide-delimiter-background
                  height="auto"
                  interval="4000"
                  show-arrows-on-hover
                  vertical="true"
                  vertical-delimiters
                  >
                  <v-carousel-item
                    v-for="(slide,i) in JSON.parse(item.sliderimages)"
                    :key="i"
                    :src="slide"
                  ></v-carousel-item>
                </v-carousel>
              </v-col>
              <v-col md="5" sm="12" cols="12" class="pb-0">
                <v-card-title style="margin-top: 20px; padding: 0px;">
                  <h1>{{ item.name }}</h1>
                </v-card-title>
                <v-card-title style="margin-top: 10px; padding: 0px;">
                  <h5>{{item.subtitle}}</h5>
                  <br>
                  <p style="font-size: 15px;line-height: normal; margin-top:10px;">{{item.short_description}}</p>
                </v-card-title>

                <!-- <v-img :src="item.branding_image"></v-img> -->

                <div style="margin-top:0px;">
                  <span style="font-size:14px; padding-top:10px; color:grey; line-height: 16px;">Scan QR to preview on your mobile app</span><br/>
                  <img 
                      :src="`https://api.qrserver.com/v1/create-qr-code/?data=${item.app_deeplink}&amp;size=130x130`"
                      max-height="200"
                      max-width="200"
                  />
                </div>
                  

                  <!-- <span style="font-size:14px; padding-top:10px; color:grey; line-height: 16px;">Home Page , App Bar & Walkthrough Settings with images will be imported.</span> -->
                  <div>
                    <v-checkbox
                      v-model="checkbox"
                      :label="`Import Branding Color Settings & Fonts (This will overide your existing settings)`"
                    ></v-checkbox>
                  </div>
                
                  <v-btn 
                    color="black" 
                    outlined 
                    :loading="importbtn"
                    @click="importTheme(item.core_app_id)"
                    >
                      Apply this theme
                  </v-btn>

                  <v-alert
                    v-if="themeimported"
                    style="margin-top:10px;"
                    dense
                    text
                    type="success"
                  >
                    Congratulations. New Theme Imported Successfully.
                    <br />  <br /> 
                    <v-btn 
                      style="float:left"
                      :to="{name:'Pages'}"
                      color="black" 
                      outlined 
                      :loading="importbtn"
                      @click="importTheme(item.core_app_id)"
                      >
                        Customize My Theme
                    </v-btn>

                  </v-alert>

                  <div style="width:100%; height:30px;"></div>
                              
              </v-col>
            </v-row>
            </div>
            

            <!-- <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialog = false"
              >
                I accept
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-dialog>
        <!-- <v-card-actions style="width:100%;">
            <v-btn color="black" small outlined >Preview</v-btn> 
            
        </v-card-actions> -->
    </v-card>
</template>

<script>

export default {
  name: 'TemplateCard',
  props: {
    item: {
      type: Object,
      required: true
    },
  },
  
  data () {
      return {
        items: this.item.sliderimages,
        checkbox: true,
        dialog : false,
        importbtn: false,
        themeimported: false,
      }
  },
  methods: {

    importTheme(theme_uid){
      let params = new URLSearchParams();
      this.importbtn = true;
      this.themeimported = false;
      params.append('theme_uid', theme_uid);
      this.$http.post(`${this.$serverApiLink}api/account/ThemeBuilder/import/${this.$route.params.uid}`, params).
      then(
          response => {
            this.importbtn = false;
            this.themeimported = true;
            console.log(response);
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.importbtn = false;
          }
      );
    }

  }
}
</script>
