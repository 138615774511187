<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9445 9.50154C10.3931 9.53217 9.9709 10.004 10.0015 10.5555L10.5015 19.5555C10.5322 20.1069 11.004 20.5291 11.5555 20.4985C12.1069 20.4678 12.5291 19.996 12.4985 19.4445L11.9985 10.4445C11.9678 9.8931 11.496 9.4709 10.9445 9.50154Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0555 9.50154C17.6069 9.53217 18.0291 10.004 17.9985 10.5555L17.4985 19.5555C17.4678 20.1069 16.996 20.5291 16.4445 20.4985C15.8931 20.4678 15.4709 19.996 15.5015 19.4445L16.0015 10.4445C16.0322 9.8931 16.504 9.4709 17.0555 9.50154Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 5C2.94772 5 2.5 5.44772 2.5 6C2.5 6.55228 2.94772 7 3.5 7H4.59608L5.89361 19.8226C5.96744 20.5523 6.02861 21.157 6.11753 21.6489C6.21021 22.1618 6.345 22.629 6.60612 23.0628C7.01335 23.7394 7.61173 24.2803 8.32586 24.6173C8.78375 24.8334 9.2622 24.9205 9.78174 24.9611C10.2802 25 10.8879 25 11.6214 25H16.3786C17.1121 25 17.7198 25 18.2183 24.9611C18.7378 24.9205 19.2163 24.8334 19.6741 24.6173C20.3883 24.2803 20.9866 23.7394 21.3939 23.0628C21.655 22.629 21.7898 22.1618 21.8825 21.6489C21.9714 21.157 22.0326 20.5523 22.1064 19.8226L23.4039 7H24.5C25.0523 7 25.5 6.55228 25.5 6C25.5 5.44772 25.0523 5 24.5 5H18.3885C17.9338 2.99601 16.1416 1.5 14 1.5C11.8584 1.5 10.0662 2.99601 9.61151 5H3.5ZM7.87953 19.5826C7.95827 20.3607 8.01227 20.8873 8.08564 21.2932C8.15682 21.687 8.234 21.8891 8.31965 22.0314C8.52327 22.3697 8.82246 22.6401 9.17952 22.8087C9.32971 22.8796 9.53854 22.936 9.9375 22.9672C10.3488 22.9993 10.8781 23 11.6602 23H16.3398C17.1219 23 17.6512 22.9993 18.0625 22.9672C18.4615 22.936 18.6703 22.8796 18.8205 22.8087C19.1775 22.6401 19.4767 22.3697 19.6804 22.0314C19.766 21.8891 19.8432 21.687 19.9144 21.2932C19.9877 20.8873 20.0417 20.3607 20.1205 19.5826L21.3937 7H6.6063L7.87953 19.5826ZM14 3.5C12.9748 3.5 12.0938 4.11705 11.708 5H16.292C15.9062 4.11705 15.0252 3.5 14 3.5Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'DeleteIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>