<template>
  <div class="component-item card" style="width: 100%; cursor: grab;" v-if="selectedcategory == 'popular' || type.includes(selectedcategory) " >
    <div class="card-body justify-content-center" style="text-align: center;"  >
      <span style="font-size:15px;">{{ displayname }} </span>
      <img :src="require(`@/assets/images/builder/${image}`)" alt="" style="width:100%; border-radius: 10px; " class="mr-3"/>
    </div>
  </div>
</template>

<script>
// import CheckCircleIcon from "@/components/icons/CheckCircleIcon";
// import ClockIcon from "@/components/icons/ClockIcon";
import componeneticon from "@/assets/images/builder/slidericon.png";

export default {
  name: 'StatusBadge',
  props: {
    status: {
      type: Boolean,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    displayname : {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    selectedcategory: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  components: {
    // CheckCircleIcon,
    // ClockIcon
  },
  data: () => ({
    componeneticon: componeneticon
  }),
  methods: {

  }
}
</script>
