var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"fill-height":""}},[_c('v-main',[_c('PageBar',{attrs:{"title":"Theme Collection","tabs":_vm.tabs,"drawer":true},on:{"onChange":_vm.updateTab}}),(_vm.loading)?_c('Loader'):_vm._e(),[_c('v-row',{staticClass:"mb-6"},[_c('v-col',{attrs:{"md":"12","sm":"12","cols":"12"}},[_c('div',[_c('v-parallax',{attrs:{"dark":"","src":"https://static.vecteezy.com/system/resources/previews/004/805/308/original/flat-design-abstract-background-soft-liquid-shapes-template-with-modern-gradient-background-colors-cool-aesthetic-background-design-suitable-for-social-media-post-mobile-app-banner-web-ads-free-vector.jpg","height":"200"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h2',{staticClass:"text-h5 font-weight-medium mb-4",staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.$tr("project", "key_e22"))+" ")]),_c('h4',{staticClass:"paragraph",staticStyle:{"color":"grey","font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.$tr("project", "key_e23"))+" ")])])],1)],1)],1)])],1),(!_vm.tab)?_c('Container',[_c('Container',{staticClass:"row"},_vm._l((_vm.template),function(item,index){return _c('div',{key:'blog_' + index,staticClass:"col-lg-6 col-md-6 col-12"},[_c('TemplateCard',{attrs:{"item":item}})],1)}),0)],1):(_vm.tab === 1)?_c('Container',[_c('Container',{staticClass:"row"},_vm._l((_vm.template.filter(
              function (info) { return info.template_owner === 1; }
            )),function(item){return _c('div',{key:item.id,staticClass:"col-lg-6 col-md-6 col-12"},[_c('TemplateCard',{attrs:{"item":item}})],1)}),0)],1):(_vm.tab === 2)?_c('Container',[_c('Container',{staticClass:"row"},_vm._l((_vm.template.filter(
              function (info) { return info.category === 'electronics'; }
            )),function(item){return _c('div',{key:item.id,staticClass:"col-lg-6 col-md-6 col-12"},[_c('TemplateCard',{attrs:{"item":item}})],1)}),0)],1):(_vm.tab === 3)?_c('Container',[_c('Container',{staticClass:"row"},[_vm._l((_vm.template.filter(
                function (info) { return info.category === 'shopping'; }
              )),function(item){return _c('div',{key:item.id,staticClass:"col-lg-6 col-md-6 col-12"},[_c('TemplateCard',{attrs:{"item":item}})],1)})],2)],1):(_vm.tab === 4)?_c('Container',[_c('Container',{staticClass:"row"},_vm._l((_vm.template.filter(
              function (info) { return info.category === 'fashion'; }
            )),function(item){return _c('div',{key:item.id,staticClass:"col-lg-6 col-md-6 col-12"},[_c('TemplateCard',{attrs:{"item":item}})],1)}),0)],1):_vm._e()]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }