<template>
  <v-layout fill-height>
    <v-main>
      <PageBar :title="$tr('menu', 'key_e31')" :preview="false" drawer />
      <Loader v-if="loading" />
      <template v-else>
        <div>
          <v-container fluid>
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-4">
                    <v-card class="mx-auto" outlined>
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="">Earnings</div>
                          <v-list-item-title
                            class="text-h5 mb-1"
                            style="font-size: xx-large !important;font-weight:500;"
                          >
                          ${{earnings}}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            ><v-chip
                              style="background-color: black;color: white;"
                              >2.5%</v-chip
                            ></v-list-item-subtitle
                          >
                        </v-list-item-content>

                        <v-list-item-avatar tile width="90px">
                          <v-sparkline
                            :fill="fill"
                            :gradient="selectedGradient"
                            :line-width="width"
                            :padding="padding"
                            :smooth="radius || false"
                            :value="value"
                            auto-draw
                          ></v-sparkline>
                        </v-list-item-avatar>
                      </v-list-item>
                    </v-card>
                  </div>
                  <div class="col-md-4" >
                    <v-card class="mx-auto"  outlined>
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="">Balance</div>
                          <v-list-item-title
                            class="text-h5 mb-1"
                            style="font-size: xx-large !important;font-weight:500;"
                          >
                            ${{balance}}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <v-btn small style="border-radius: 5px; color:white; background-color:black;" @click="requestpayout = true">
                              Request Payout
                            </v-btn>
                          </v-list-item-subtitle >      
                        </v-list-item-content>

                        <v-list-item-avatar tile width="90px" >
                          <v-sparkline
                            :fill="fill"
                            :gradient="selectedGradient"
                            :line-width="width"
                            :padding="padding"
                            :smooth="radius || false"
                            :value="valuebalance"
                            auto-draw
                          ></v-sparkline>
                        </v-list-item-avatar>
                      </v-list-item>
                    </v-card>
                  </div>
                  <div class="col-md-4">
                    <v-card class="mx-auto" outlined>
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline"></div>
                          <v-list-item-title class="">
                            <p style="margin-bottom: 8px;">Share your partner link &nbsp; 
                              <v-btn
                                small
                                style="border-radius: 100px; color:white; background-color:black;"
                                @click="copy"
                              >
                                Copy
                              </v-btn>
                            </p>
                            <v-text-field
                              label="Regular"
                              single-line
                              color="primary"
                              v-on:focus="$event.target.select()" 
                              ref="clone" 
                              readonly
                              v-model="partner.affiliate_link"
                            ></v-text-field>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </div>

                  <div class="col-md-12">
                    <h2 class="mb-5">Transactions</h2>
                    <v-data-table
                      :headers="headers"
                      :items="desserts"
                      :items-per-page="5"
                      class="elevation-1"
                    ></v-data-table>
                  </div>
                  <div class="col-md-12 mt-5" >
                    <h2 class="mb-5">The value of the badge</h2>
                    <p style="font-size: 20px; line-height: normal;">
                      With the Shopimint Partner badge, you’re able to showcase your Shopimint expertise to clients and stand out in the industry.
                    </p>
                    <img
                      src="https://app.shopimint.com/backend/upload/partner/sopimint_partner.jpg"
                      width="350px"
                    />
                  </div>
                  <div class="col-md-12 mt-5">
                    <h2 class="mb-5">Your Referals</h2>
                    <v-simple-table height="300px">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Name
                            </th>
                            <th class="text-left">
                              Calories
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in referals"
                            :key="item.name"
                          >
                            <td>{{ item.name }}</td>
                            <td>{{ item.calories }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                  
                </div>
              </div>
              <div class="col-md-3">
                <v-card class="mx-auto mb-5" outlined>
                  <v-card-title >
                    Shopimint ToolKit
                  </v-card-title>
                  <v-card-text>
                    <h3 class="mb-2">
                      <v-icon color="black"
                        >mdi-arrow-right-bold-circle</v-icon
                      >&nbsp; Presentation
                    </h3>
                    <h3 class="mb-2">
                      <v-icon color="black"
                        >mdi-arrow-right-bold-circle</v-icon
                      >&nbsp; #Hashtags
                    </h3>
                    <h3 class="mb-2">
                      <v-icon color="black"
                        >mdi-arrow-right-bold-circle</v-icon
                      >&nbsp; Help Guide
                    </h3>
                    <h3 class="mb-2">
                      <v-icon color="black"
                        >mdi-arrow-right-bold-circle</v-icon
                      >&nbsp; Video Guide
                    </h3>
                  </v-card-text>
                </v-card>
                <v-card class="mx-auto mb-5" outlined>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title class="text-h5 mb-0">
                        Configure Payout Methods
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <v-btn
                        outlined
                        rounded
                        style="color: white; background-color: black;"
                        text
                        @click="dialog = true"
                      >
                      Configure Now 
                      </v-btn>
                    </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-avatar tile height="100px">
                      <v-icon large color="black"
                        >mdi-credit-card-outline</v-icon
                      >
                    </v-list-item-avatar>
                  </v-list-item>
                </v-card>
                <v-card class="mx-auto mb-5" outlined>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="">Promocodes</div>
                      <v-list-item-title
                        class="text-h5 mb-1"
                        style="font-size: xx-large !important;font-weight:500;"
                      >
                        {{promocode}}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        ><v-chip>Generate </v-chip></v-list-item-subtitle
                      >
                    </v-list-item-content>

                    <v-list-item-avatar tile height="100px">
                      <v-icon large color="black">mdi-wallet-giftcard</v-icon>
                    </v-list-item-avatar>
                  </v-list-item>
                </v-card>
              </div>
            </div>
          </v-container>
        </div>
      </template>
      <v-dialog v-model="dialog" max-width="520px" >
        <v-container >
          <v-row style=" align-items: center;"> 
              <v-col md="12"  class="pb-0">
                <h4>Configure Payout Methods</h4>
              </v-col>
              <v-col md="12" sm="12" cols="12">
                <v-select
                  :items="payoutmethods"
                  dense
                  hide-details
                  label="Payout Type"
                  outlined
                  v-model="partner.affiliate_payout_method"
                ></v-select>
              </v-col>
              <v-col md="12"  class="pb-0">
                <v-text-field
                  label="Your Payput Method email"
                  outlined
                  color="primary"
                  dense
                  v-model="partner.affiliate_payout_account_email"
                ></v-text-field>
              </v-col>

              <v-col md="12"  class="pb-5" >
                <v-btn
                    depressed
                    block
                    large
                    color="primary"
                    @click="updatePayment"
                >
                  Save Settings
                </v-btn>
              </v-col>
            
            <template v-if="success_alert">
              <v-icon
                  color="success"
                  class="mr-3"
              >
                mdi-check-circle
              </v-icon>
              <div class="body-2">
                Updated Successfully.
              </div>
            </template>
        </v-row>
        </v-container>
        
      </v-dialog>
      <v-dialog v-model="requestpayout" max-width="520px" >
        <v-container >
          <v-row style=" align-items: center;"> 
              <v-col md="12"  class="pb-0">
                <h4>Request Withdrawal</h4>
              </v-col>
              <v-col md="12"  class="pb-0">
                <v-text-field
                  label="Available Balance"
                  outlined
                  color="primary"
                  type="number"
                  filled
                  disabled
                  dense
                  v-model="balance"
                ></v-text-field>
              </v-col>
              <v-col md="12"  class="pb-0">
                <v-text-field
                  label="Enter Amount"
                  outlined
                  color="primary"
                  type="number"
                  dense
                  :rules="exampleRules"
                  v-model="withdrawamount"
                ></v-text-field>
              </v-col>

              <v-col md="12"  class="pb-5" >
                <v-btn
                  depressed
                  block
                  large
                  color="primary"
                  @click="requestWithdrawal"
                >
                  Request
                </v-btn>
              </v-col>
            
            <template v-if="success_alert">
              <v-icon color="success" class="mr-3" >mdi-check-circle</v-icon>
              <div class="body-2">
                Withdrawal Requested Succcessfully.
              </div>
            </template>
        </v-row>
        </v-container>
        
      </v-dialog>
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Loader from "@/components/blocks/Loader";
// import EmptyState from "@/components/blocks/EmptyState";
import homebackground from "@/assets/images/homebackground.png";

const gradients = [
    ['#ffffff'],
  ]

export default {
  name: 'Apps',
  components: {
    PageBar,
    Loader,
},
  data: () => ({
    homebackground: homebackground,
    sort: 0,
    success_alert: false,
    loading: true,
    list: [],
    offset: 0,
    dialog: false,
    requestpayout:false,
    commission:{
      app_id:'',
      transaction_id:'',
      payment_amount:'',
      commission_amount:'',
      affiliate_user_id:'',
      date:'',
      note:'',
    },
    earnings: 123456.99,
    balance: 15268.26,
    withdrawamount:0,
    exampleRules: [ 
        v => !!v || "This field is required",
        v => ( v && v >= 10 ) || "Withdraw amount should be above $10",
        v => ( v && v <= 150 ) || "Withdraw amount should not be $150",
    ],
    partner:{
      affiliate_link:'',
      affiliate_payout_method:'',
      affiliate_payout_account_email:''
    },
    count: 0,
    loadBtn: {
      status: true,
      loading: false
    },
    fill: false,
    selectedGradient: gradients[4],
    gradients,
    promocode:"RGDNIOP",
    padding: 8,
    radius: 10,
    value: [ 9 , 8 , 7, 6 ,1 , 10],
    valuebalance: [4500, 1000, 4000, 8000,6000, 5000, 5010, 5700],
    width: 2,
    headers: [
      { text: 'ID', value: 'name', },
      { text: 'App Name', value: 'calories' },
      { text: 'Plan', value: 'fat' },
      { text: 'Commission', value: 'carbs' },
    ],
    referals: [
      {
        name: 'Frozen Yogurt',
        calories: 159,
      },
      {
        name: 'Frozen Yogurt',
        calories: 159,
      },
      {
        name: 'Frozen Yogurt',
        calories: 159,
      },
      {
        name: 'Frozen Yogurt',
        calories: 159,
      },
      {
        name: 'Frozen Yogurt',
        calories: 159,
      },
      {
        name: 'Frozen Yogurt',
        calories: 159,
      },
      {
        name: 'Frozen Yogurt',
        calories: 159,
      },
      {
        name: 'Frozen Yogurt',
        calories: 159,
      },
    ],
    desserts: [
      {
        name: 'Frozen Yogurt',
        calories: 159,
        fat: 6.0,
        carbs: 24,
      },
      {
        name: 'Ice cream sandwich',
        calories: 237,
        fat: 9.0,
        carbs: 37,
      },
      {
        name: 'Eclair',
        calories: 262,
        fat: 16.0,
        carbs: 23,
      },
      {
        name: 'Cupcake',
        calories: 305,
        fat: 3.7,
        carbs: 67,
      },
      {
        name: 'Gingerbread',
        calories: 356,
        fat: 16.0,
        carbs: 49,
      },
      {
        name: 'Jelly bean',
        calories: 375,
        fat: 0.0,
        carbs: 94,
      },
      {
        name: 'Lollipop',
        calories: 392,
        fat: 0.2,
        carbs: 98,
      },
      {
        name: 'Honeycomb',
        calories: 408,
        fat: 3.2,
        carbs: 87,
      },
      {
        name: 'Donut',
        calories: 452,
        fat: 25.0,
        carbs: 51,
      },
      {
        name: 'KitKat',
        calories: 518,
        fat: 26.0,
        carbs: 65,
      },
    ],
  }),
  computed: {
    payoutmethods: function () {
      return [
        {
          text: 'Paypal',
          value: 'paypal'
        },
        {
          text: 'Payoneer',
          value: 'payoneer'
        },
      ];
    },
  },
  methods: {
    getDetail() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/users/affiliate_detail`).
      then(
          response => {
            this.partner = response.data.detail;
            this.getCommisionDetail();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
          }
      );
    },
    getCommisionDetail() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/commission/detail`).
      then(
          response => {
            this.commission = response.data.detail;
            this.loading = false;
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
          }
      );
    },
    copy() {
      this.$refs.clone.focus();
      document.execCommand('copy');
    },
    updatePayment() {
      this.updateLoading = true
      let params = new URLSearchParams();
      params.append('affiliate_payout_method', this.partner.affiliate_payout_method);
      params.append('affiliate_payout_account_email', this.partner.affiliate_payout_account_email);

      this.$http.post(`${this.$serverApiLink}api/account/users/affiliate_update_userpayouts`, params).
      then(
          // eslint-disable-next-line no-unused-vars
          response => {
            this.updateLoading = false;
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "app"
            });
            this.showSuccessAlert();
            this.dialog = false;
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.updateLoading = false;
          }
      );
    },
    requestWithdrawal() {
      this.updateLoading = true
      let params = new URLSearchParams();
      params.append('balance', this.balance);
      params.append('withdrawamount', this.withdrawamount);
      
      this.$http.post(`${this.$serverApiLink}api/account/commission/affiliate_withdraw_request`, params).
      then(
          // eslint-disable-next-line no-unused-vars
          response => {
            this.updateLoading = false;
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "app"
            });
            this.showSuccessAlert();
            this.dialog = false;
            
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.updateLoading = false;
          }
      );
    },
    showSuccessAlert() {
      this.success_alert = true;
      setTimeout(() => this.success_alert = false, 3000);
    },
  },
  mounted() {
    this.getDetail();
  },
}
</script>

<style scoped>
.bg {
    background-image: url('https://app.shopimint.com/backend/static/homebackground.png');
    background-size: contain;
    height: calc(100% - 72px);
    margin-bottom: 0px;
}

.bg2 {
    background-image: url('https://app.shopimint.com/backend/static/homebackground2.png');
    background-size: contain;
    height: calc(100% - 72px);
    margin-bottom: 0px;
}
</style>
