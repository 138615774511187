export const fonts = [
  {
    code: "golosText",
    name: "Golos Text",
    family: "font-family: 'Golos Text', sans-serif",
    familyurl: "Golos+Text",
    popular: true,
  },
  {
    code: "alegreya",
    name: "Alegreya",
    family: "font-family: 'Alegreya', serif",
    familyurl: "Alegreya",
    popular: true,
  },
  {
    code: "archivoNarrow",
    name: "ArchivoNarrow ",
    family: "font-family: 'Archivo Narrow', sans-serif",
    familyurl: "Archivo+Narrow",
    popular: false,
  },
  {
    code: "arimo",
    name: "Arimo",
    family: "font-family: 'Arimo', sans-serif",
    familyurl: "Arimo",
    popular: false,
  },
  {
    code: "anonymouspro",
    name: "Anonymous Pro ",
    family: "font-family: 'Anonymous Pro', monospace",
    familyurl: "Anonymous+Pro",
    popular: false,
  },
  {
    code: "belleza",
    name: "Belleza",
    family: "font-family: 'Belleza', sans-serif",
    familyurl: "Belleza",
    popular: false,
  },
  {
    code: "cormorant",
    name: "Cormorant",
    family: "font-family: 'Cormorant', serif",
    familyurl: "Cormorant",
    popular: false,
  },
  {
    code: "cardo",
    name: "Cardo",
    family: "font-family: 'Cardo', serif",
    familyurl: "Cardo",
    popular: false,
  },
  {
    code: "caveatBrush",
    name: "Caveat Brush",
    family: "font-family: 'Caveat Brush', cursive",
    familyurl: "Caveat+Brush",
    popular: false,
  },
  {
    code: "caudex",
    name: "Caudex",
    family: "font-family: 'Caudex', serif",
    familyurl: "Caudex",
    popular: false,
  },
  {
    code: "cinzel",
    name: "Cinzel",
    family: "font-family: 'Cinzel', serif",
    familyurl: "Cinzel",
    popular: false,
  },
  {
    code: "dmSans",
    name: "DM Sans",
    family: "font-family: 'DM Sans', sans-serif",
    familyurl: "DM+Sans",
    popular: false,
  },
  {
    code: "eczar",
    name: "Eczar",
    family: "font-family: 'Eczar', serif",
    familyurl: "Eczar",
    popular: false,
  },
  {
    code: "firaSans",
    name: "Fira Sans",
    family: "font-family: 'Fira Sans', sans-serif",
    familyurl: "Fira+Sans",
    popular: false,
  },
  {
    code: "federo",
    name: "Federo",
    family: "font-family: 'Federo', sans-serif",
    familyurl: "Federo",
    popular: false,
  },
  {
    code: "inter",
    name: "Inter",
    family: "font-family: 'Inter', sans-serif",
    familyurl: "Inter",
    popular: false,
  },
  {
    code: "italiana",
    name: "Italiana",
    family: "font-family: 'Italiana', serif;",
    familyurl: "Italiana",
    popular: false,
  },
  {
    code: "karla",
    name: "Karla",
    family: "font-family: 'Karla', sans-serif",
    familyurl: "Karla",
    popular: false,
  },
  {
    code: "kronaOne",
    name: "Krona One",
    family: "font-family: 'Krona One', sans-serif",
    familyurl: "Krona+One",
    popular: true,
  },
  {
    code: "lato",
    name: "Lato",
    family: "font-family: 'Lato', sans-serif",
    familyurl: "Lato",
    popular: false,
  },
  {
    code: "lora",
    name: "Lora",
    family: "font-family: 'Lora', serif",
    familyurl: "Lora",
    popular: false,
  },
  {
    code: "libreFranklin",
    name: "Libre Franklin",
    family: "font-family: 'Libre Franklin', sans-serif",
    familyurl: "Libre+Franklin",
    popular: false,
  },
  {
    code: "libreBaskerville",
    name: "Libre Baskerville",
    family: "font-family: 'Libre Baskerville', serif",
    familyurl: "Libre+Baskerville",
    popular: false,
  },
  {
    code: "montserrat",
    name: "Montserrat",
    family: "font-family: 'Montserrat', sans-serif",
    familyurl: "Montserrat",
    popular: true,
  },
  {
    code: "manrope",
    name: "Manrope",
    family: "font-family: 'Manrope', sans-serif",
    familyurl: "Manrope",
    popular: false,
  },
  {
    code: "mochiyPopOne",
    name: "Mochiy Pop One",
    family: "font-family: 'Mochiy Pop One', sans-serif",
    familyurl: "Mochiy+Pop+One",
    popular: false,
  },
  {
    code: "nunitoSans",
    name: "Nunito Sans",
    family: "font-family: 'Nunito Sans', sans-serif",
    familyurl: "Nunito+Sans",
    popular: false,
  },
  {
    code: "oldStandardTt",
    name: "Old Standard Tt",
    family: "font-family: 'Old Standard TT', serif",
    familyurl: "Old+Standard+TT",
    popular: false,
  },
  {
    code: "oswald",
    name: "Oswald",
    family: "font-family: 'Oswald', sans-serif",
    familyurl: "Oswald",
    popular: false,
  },
  {
    code: "openSans",
    name: "Open Sans",
    family: "font-family: 'Open Sans', sans-serif",
    familyurl: "Open+Sans",
    popular: false,
  },
  {
    code: "outfit",
    name: "Outfit",
    family: "font-family: 'Outfit', sans-serif",
    familyurl: "Outfit",
    popular: false,
  },
  {
    code: "poppins",
    name: "Poppins",
    family: "font-family: 'Poppins', sans-serif",
    familyurl: "Poppins",
    popular: true,
  },
  {
    code: "ptSans",
    name: "Pt Sans",
    family: "font-family: 'PT Sans', sans-serif",
    familyurl: "PT+Sans",
    popular: false,
  },
  {
    code: "playfairDisplay",
    name: "Playfair Display",
    family: "font-family: 'Playfair Display', serif",
    familyurl: "Playfair+Display",
    popular: false,
  },
  {
    code: "ptSerif",
    name: "Pt Serif",
    family: "font-family: 'PT Serif', serif",
    familyurl: "PT+Serif",
    popular: false,
  },
  {
    code: "roboto",
    name: "Roboto",
    family: "font-family: 'Roboto', sans-serif",
    familyurl: "Roboto",
    popular: false,
  },
  {
    code: "robotoMono",
    name: "Roboto Mono",
    family: "font-family: 'Roboto Mono', monospace",
    familyurl: "Roboto+Mono",
    popular: false,
  },
  {
    code: "rubik",
    name: "Rubik",
    family: "font-family: 'Rubik', sans-serif",
    familyurl: "Rubik",
    popular: false,
  },
  {
    code: "quicksand",
    name: "Quicksand",
    family: "font-family: 'Quicksand', sans-serif",
    familyurl: "Quicksand",
    popular: true,
  },
  {
    code: "sourceSansPro",
    name: "Source Sans Pro",
    family: "font-family: 'Source Sans Pro', sans-serif",
    familyurl: "Source+Sans+Pro",
    popular: false,
  },
  {
    code: "spaceMono",
    name: "Space Mono",
    family: "font-family: 'Space Mono', monospace",
    familyurl: "Space+Mono",
    popular: false,
  },
  {
    code: "spaceGrotesk",
    name: "Space Grotesk",
    family: "font-family: 'Space Grotesk', sans-serif",
    familyurl: "Space+Grotesk",
    popular: false,
  },
  {
    code: "sora",
    name: "Sora",
    family: "font-family: 'Sora', sans-serif",
    familyurl: "Sora",
    popular: false,
  },
  {
    code: "syne",
    name: "Syne",
    family: "font-family: 'Syne', sans-serif",
    familyurl: "Syne",
    popular: false,
  },
  {
    code: "ubuntu",
    name: "Ubuntu",
    family: "font-family: 'Ubuntu', sans-serif",
    familyurl: "Ubuntu",
    popular: true,
  },
  {
    code: "viaodaLibre",
    name: "Viaoda Libre",
    family: "font-family: 'Viaoda Libre', cursive",
    familyurl: "Viaoda+Libre",
    popular: false,
  },
  {
    code: "vollkorn",
    name: "Vollkorn",
    family: "font-family: 'Vollkorn', serif",
    familyurl: "Vollkorn",
    popular: false,
  },
  {
    code: "worksans",
    name: "Work Sans",
    family: "font-family: 'Work Sans', sans-serif",
    familyurl: "Work+Sans",
    popular: false,
  },
];
