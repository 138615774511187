<template>
  <div v-if="loading" >
    <div class="text-center auth_title mb-10">
      {{ $tr("auth", "key_18") }}
    </div>
    <div class="d-flex justify-center">
      <v-progress-circular
          indeterminate
          color="smoke"
          width="2"
      ></v-progress-circular>
    </div>
  </div>

  <div v-else >

    <div class="text-center mt-7 mb-7" style="font-size:18px; color: black; font-weight: bold;">
      {{ resetmessage }}
    </div>
    <div class="text-center">
      <router-link to="/">
        Go back to login
      </router-link>
    </div>

  </div>

</template>

<script>

export default {
  name: 'Reset',
  components: {

  },
  data: () => ({
    loading: true,
    resetmessage: ''
  }),
  methods: {
    startCheck(token) {
      this.$http.get(`${this.$serverApiLink}api/auth/password/${token}`).
      then(
          response => {

            this.loading = false;
            this.resetmessage = response.data.message.success;

            this.$store.commit('setSnackBar', {
              code: 200,
              message: response.data.message
            });
            
            // this.$router.push({name: 'Login'});
          }
      ).catch(
          error => {

            this.loading = false;
            this.resetmessage = error.response.data.message.error;

            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            // this.$router.push({name: 'Login'});
          }
      );
    }
  },
  mounted() {
    this.startCheck(this.$route.query.token);
  }
}
</script>