<template>
  <v-layout fill-height>
    <v-main>
      <PageBar
          :title="$tr('menu', 'key_e4')"
          :preview="false"
          drawer
      />
      <Loader v-if="loading"/>
      <template v-else>
        <EmptyState
            v-if="list.length === 0"
            :title="$tr('menu', 'key_e4')"
            :subtitle="$tr('project', 'key_234') + '- We are working on here' "
        >
          <template v-slot:icon>
            <div style="width: 45px; height: 45px">
              <MailStackIcon :size="45"/>
            </div>
          </template>
        </EmptyState>
        <div v-else>

          <v-parallax
            dark
            src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
            height="300"

          >
            <v-row
              align="center"
              justify="center"
            >
              <v-col
                class="text-center"
                cols="12"
              >
                <h1 class="text-h4 font-weight-medium mb-4">
                   {{ $tr('menu', 'key_e4') }}
                </h1>
                <h4 class="subheading">
                  Build your application today!
                </h4>
              </v-col>
            </v-row>
          </v-parallax>

          <Container class="row">

          <div v-for="(item, index) in list" :key="'blog_'+index" class="col-lg-4 col-sm-6 col-12">

                  <v-card
                    style="border-radius:25px; margin:10px;"
                    :to="{name: 'helpcenterarticle', params: {id: item.id} }"
                  >
                    <v-img
                      :src="item.featured_image"
                      style="border-radius:25px;"
                      height="200px"
                    ></v-img>

                    <v-card-title>
                      {{ item.title }}
                    </v-card-title>

                    <v-card-subtitle>
                        {{ item.category }}
                    </v-card-subtitle>

                    <v-card-actions>
                      <v-btn
                        color="blue lighten-2"
                        text
                        :to="{name: 'helpcenterarticle', params: {id: item.id} }"
                      >
                        Read More
                      </v-btn>

                      <v-spacer></v-spacer>

                      <v-btn icon >
                        <v-icon>mdi-arrow-right</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
          </div>
          </Container>

        </div>
      </template>
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Loader from "@/components/blocks/Loader";
import EmptyState from "@/components/blocks/EmptyState";
// import Title from "@/components/blocks/Title";
import Container from "@/components/blocks/Container";
import MailStackIcon from "@/components/icons/MailStackIcon";

export default {
  name: 'Tickets',
  components: {
    PageBar,
    Loader,
    EmptyState,
    // Title,
    Container,
    MailStackIcon,
  },
  data: () => ({
    loading: false,
    list: [],
    offset: 0,
    count: 0,
    loadBtn: {
      status: true,
      loading: false
    },
  }),
  methods: {
    getBlogList() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/blog/list/1/${this.offset}`).
      then(
          response => {
            this.list = response.data.list;
            this.offset += 20;
            this.count = response.data.count;
            this.loading = false;
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
      );
    },
    loadMore() {
      this.loadBtn.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/blog/list/1/${this.offset}`).
      then(
          response => {
            if (response.data.list.length > 0) {
              for (let i = 0; i < response.data.list.length; i++) {
                this.list.push(response.data.list[i]);
              }
            } else {
              this.loadBtn.status = false;
            }
            this.offset += 20;
            this.loadBtn.loading = false;
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loadBtn.loading = false;
          }
      );
    }
  },
  mounted() {
    this.getBlogList();
  }
}
</script>