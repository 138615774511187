<template>
    <div>
        <v-row class="mb-12 integrationarea" :style="`background-color:`+ item.backgroundcolor + `;`">
          <v-col  md="3" sm="3" cols="3" class="pb-0  "  >
            <div :style="'background-image: url('+item.photo+');'" class="integrationlogo"></div>
          </v-col>
          <v-col  md="9" sm="9" cols="9" class="pb-0" style="line-height: initial;" >
            <h1 style="font-size: 40px;">{{ item.name }} </h1>
            <p style="max-width: 75%;"> {{ item.description}}  </p>
          </v-col>
        </v-row>
    </div>
    
</template>

<script>

export default {
    name: 'IntergrationView',
    props: {
        item: {
            type: Object,
            required: true
        },
    }
}
</script>