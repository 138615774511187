<template>
  <v-card flat class="modal_bar fill-height" tile>
    <div class="fixed_search">
      <v-toolbar
          flat
          class="modal_bar"
      >
        <v-toolbar-title>
          {{ $tr('project', 'key_356') }}
        </v-toolbar-title>
        <v-spacer/>
        <v-btn
            icon
            @click="$emit('close')"
        >
          <CancelIcon/>
        </v-btn>
      </v-toolbar>
      <v-container fluid class="pt-0 pb-0">

        <v-row>

          <v-col md="6">
            <v-text-field
                label="Search Fonts"
                outlined
                color="primary"
                dense
                v-model="search"
            ></v-text-field>
          </v-col>

          <v-col md="3">
            <v-btn-toggle
              v-model="toggle_multiple"
              dense
              background-color="white"
              color="green"
              style="margin-top:10px;"
              light
              multiple
            >
              <v-btn>
                <v-icon>mdi-format-bold</v-icon>
              </v-btn>

              <v-btn>
                <v-icon>mdi-format-italic</v-icon>
              </v-btn>

              <v-btn>
                <v-icon>mdi-format-color-fill</v-icon>
              </v-btn>

            </v-btn-toggle>

          </v-col>

          <v-col md="3">
            <v-switch
                label="Custom Text"
                v-model="customtext"
            ></v-switch>
          </v-col>

        </v-row>
        
      </v-container>

    </div>
    <v-divider/>
    <v-card-text style="height: 400px; padding: 0; padding-top:30px;">

      <v-container fluid class="pt-0 pb-0">
        <v-row>

          <v-col md="12" v-if="customtext">
            <v-text-field
                label="Customize Your Font"
                outlined
                color="primary"
                dense
                v-model="text"
            ></v-text-field>
          </v-col>
          
        </v-row>
        
      </v-container>

      <div class="row" style="margin:10px;" v-if="filteredCodes.length">
        <div :class="(customtext) ? 'col-md-6' : 'col-md-3' " v-for="(font, index) in filteredCodes" :key="'font_'+index">
          
          <div
              :key="'font_item_'+index"
              class="fontcard"
              :style="( toggle_multiple.includes(2) ? 'background-color:black;': 'background-color:white;')"
              exact
              @click="$emit('set', font.code)"
          >

            <div v-if="font.popular" class="legend2">Popular</div>
            
            <div v-if="customtext" 
              :style="font.family 
                + '; text-align: center; font-size:24px; color:black;' 
                + (toggle_multiple.includes(0) ? 'font-weight:bold;' : '') 
                + ( toggle_multiple.includes(1) ?'font-style: italic;':'') 
                + ( toggle_multiple.includes(2) ? 'color:white;': 'color:black;') "  
            >
              <span style="font-size:16px;">{{ font.text }}</span> 
              <br/> 
              <span style="margin-top:10px;">{{ text }}</span>
            </div>

            <div v-else 
              :style="font.family 
                + '; text-align: center; font-size:24px; color:black; margin-top:15px;' 
                + ( toggle_multiple.includes(0) ? 'font-weight:bold;' : '') 
                + ( toggle_multiple.includes(1) ? 'font-style: italic;' : '') 
                + ( toggle_multiple.includes(2) ? 'color:white;': 'color:black;') "  
            >
                {{ font.name }}
            </div>

          </div>

        </div>

        <v-divider/>

        <div class="text-center" style="width:100%;">
            <h4>Are you looking for different font other than this list. Don't worry <br/> We can implement your font into your mobile app. Browse more fonts that support our mobile app. <br/><a href="https://fonts.google.com/" target="_blank" >See more on google fonts</a></h4>

        </div>

      </div>

      

      <!-- <v-list v-if="filteredCodes.length">
        <div v-for="(font, index) in filteredCodes" :key="'font_'+index">
          <v-divider
              v-if="index > 0"
              :key="'font_line_'+index"
          />
          <v-list-item
              :key="'font_item_'+index"
              exact
              @click="$emit('set', font.code)"
          >
            <v-list-item-title>
              <span :style="font.family" >{{ font.name }}</span>
            </v-list-item-title>
            <v-list-item-action style="display: none;">
              <v-list-item-action-text class="text-uppercase">
                {{ font.code }}
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
        </div>
      </v-list> -->
      <v-list v-else>
        <v-list-item>
          <v-list-item-title>
            {{ $tr('project', 'key_282') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<style scoped>
/* @import url('https://fonts.googleapis.com/css?family=Oswald'); */
</style>

<script>
import CancelIcon from "@/components/icons/CancelIcon";
import { fonts } from "@/data/fonts";

export default {
  name: 'SelectFont',
  components: {
    CancelIcon
  },
  props: {
    appname: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    list: fonts,
    search: '',
    customtext: false,
    toggle_multiple: [0],
    text: 'Welcome ',
  }),
  computed: {
    filteredCodes () {
      const filterBy = (term) => {
        const termLowerCase = term.toLowerCase()
        return (person) =>
            Object.keys(person)
                .some(prop => person[prop].toLowerCase().indexOf(termLowerCase) !== -1)
      }
      return this.list.filter(filterBy(this.search));
    }
  },
  methods: {

  },
  mounted () {

    var googlefontlink = "https://fonts.googleapis.com/css2?";
    fonts.forEach((value) => {
      googlefontlink = googlefontlink + "family=" + value.familyurl + "&";
      value.text = value.name;
    });
    const file = document.createElement('link');
    file.rel = 'stylesheet';
    file.href = googlefontlink;
    document.head.appendChild(file);
    this.text = 'Welcome to ' + this.appname;
    console.log(googlefontlink);

    // var apikey = "AIzaSyCWS1m_CRU5ObZUpkYlwfRPS7_KjQQR4is";
    // fonts.forEach((value) => {
    //   const file = document.createElement('link');
    //   file.rel = 'stylesheet';
    //   file.href = 'https://fonts.googleapis.com/css?family='+value.name+'?key='+apikey;
    //   document.head.appendChild(file);
    // });
    
  },
}
</script>