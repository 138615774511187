<template>
  <div class="d-flex justify-center align-center text-center" style="height: calc(100vh - 73px)">
    <div class="d-flex flex-column justify-center align-center">
      <div class="mb-6">
        <img :src="shopimintlogo" alt="Shopimint" :width="300" style="padding-left:15px" class="mr-3"/>
      </div>
      <div class="title mb-3">
        {{ title }}
      </div>
      
      <div class="body-2 smoke--text mb-12" style="margin-bottom: 20px !important;" v-html="subtitle"/>
      
      <div>
        <slot name="action"></slot>
        
      </div>
    </div>
  </div>
</template>

<script>
import logo from "@/assets/images/shopimintlogo.png"
export default {
  name: 'EmptyState',
  data: () => ({
    shopimintlogo: logo
  }),
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    isError: {
      type: Boolean
    }
  }
}
</script>
