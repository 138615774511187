<template>
  <div class="d-flex justify-center align-center mt-8">
    <!-- <v-progress-circular
        indeterminate
        color="#2687f3"
        width="3"
        class="mt-4"
    ></v-progress-circular> -->
    <pulse-loader color="#2687f3" ></pulse-loader>
  </div>
</template>

<script>

import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
  name: 'Loader',
  components: {
      PulseLoader
  },
  data: () => ({

  }),
  methods: {

  },
}
</script>