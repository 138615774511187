<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5903 3C19.7733 3 20.823 3.20271 21.9137 3.78602C22.9028 4.31499 23.685 5.0972 24.214 6.08629C24.7973 7.17697 25 8.22671 25 10.4097V17.5903C25 19.7733 24.7973 20.823 24.214 21.9137C23.685 22.9028 22.9028 23.685 21.9137 24.214C20.823 24.7973 19.7733 25 17.5903 25H10.4097C8.22671 25 7.17697 24.7973 6.08629 24.214C5.0972 23.685 4.31499 22.9028 3.78602 21.9137C3.20271 20.823 3 19.7733 3 17.5903V10.4097C3 8.22671 3.20271 7.17697 3.78602 6.08629C4.31499 5.0972 5.0972 4.31499 6.08629 3.78602C7.17697 3.20271 8.22671 3 10.4097 3H17.5903ZM4.99899 11L5 17.5903C5 19.4714 5.14248 20.2092 5.54964 20.9705C5.89221 21.6111 6.38894 22.1078 7.02948 22.4504C7.79082 22.8575 8.52864 23 10.4097 23H17.5903C19.4714 23 20.2092 22.8575 20.9705 22.4504C21.6111 22.1078 22.1078 21.6111 22.4504 20.9705C22.8575 20.2092 23 19.4714 23 17.5903L22.999 11H4.99899ZM17.5903 5H10.4097C8.52864 5 7.79082 5.14248 7.02948 5.54964C6.38894 5.89221 5.89221 6.38894 5.54964 7.02948C5.26405 7.5635 5.10868 8.08594 5.04136 8.99945H22.9586C22.8913 8.08594 22.7359 7.5635 22.4504 7.02948C22.1078 6.38894 21.6111 5.89221 20.9705 5.54964C20.2092 5.14248 19.4714 5 17.5903 5Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'FeedIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>