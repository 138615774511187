<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.5903 4C21.8191 4 22.6273 4.23206 23.4421 4.66783C24.2569 5.1036 24.8964 5.74307 25.3322 6.55789C25.7679 7.3727 26 8.18092 26 10.4097V17.5903C26 19.8191 25.7679 20.6273 25.3322 21.4421C24.8964 22.2569 24.2569 22.8964 23.4421 23.3322C22.6273 23.7679 21.8191 24 19.5903 24H8.40971C6.18092 24 5.3727 23.7679 4.55789 23.3322C3.74307 22.8964 3.1036 22.2569 2.66783 21.4421C2.23206 20.6273 2 19.8191 2 17.5903V10.4097C2 8.18092 2.23206 7.3727 2.66783 6.55789C3.1036 5.74307 3.74307 5.1036 4.55789 4.66783C5.3727 4.23206 6.18092 4 8.40971 4H19.5903ZM23.999 13H3.999L4 18.1542C4 19.4915 4.13924 19.9764 4.4007 20.4653C4.66216 20.9542 5.04584 21.3378 5.53473 21.5993C5.99306 21.8444 6.44792 21.9821 7.60487 21.9984L7.84583 22H20.1542C21.4915 22 21.9764 21.8608 22.4653 21.5993C22.9542 21.3378 23.3378 20.9542 23.5993 20.4653C23.8444 20.0069 23.9821 19.5521 23.9984 18.3951L24 18.1542L23.999 13ZM8.7454 16C9.15835 16 9.32546 16.0464 9.48842 16.1336C9.65139 16.2207 9.77928 16.3486 9.86643 16.5116C9.95359 16.6745 10 16.8417 10 17.2546V17.7454C10 18.1583 9.95359 18.3255 9.86643 18.4884C9.77928 18.6514 9.65139 18.7793 9.48842 18.8664C9.32546 18.9536 9.15835 19 8.7454 19H8.2546C7.84165 19 7.67454 18.9536 7.51158 18.8664C7.34861 18.7793 7.22072 18.6514 7.13357 18.4884C7.04641 18.3255 7 18.1583 7 17.7454V17.2546C7 16.8417 7.04641 16.6745 7.13357 16.5116C7.22072 16.3486 7.34861 16.2207 7.51158 16.1336C7.67454 16.0464 7.84165 16 8.2546 16H8.7454ZM20.1542 6H7.84583C6.50855 6 6.02362 6.13924 5.53473 6.4007C5.04584 6.66216 4.66216 7.04584 4.4007 7.53473C4.15558 7.99306 4.01788 8.44792 4.00163 9.60487L4 9.84583L3.999 10H23.999L24 9.84583C24 8.50855 23.8608 8.02362 23.5993 7.53473C23.3378 7.04584 22.9542 6.66216 22.4653 6.4007C22.0069 6.15558 21.5521 6.01788 20.3951 6.00163L20.1542 6Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'CreditCardIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>