<template>
    
      <Container v-if="loading">
            <Loader/>
      </Container>

      <Container v-else>

        <Title :title="$tr('project', 'key_83')"/>

        <div class="row">
        <div class="col-md-6 ">
          <div class="permissions_block mb-3 d-flex justify-space-between align-center pa-4">
            <div class="d-flex justify-start align-center">
              <div style="padding:10px">
                
                <div
                    v-if="!settings.logo"
                    class="smoke--text"
                    style="width: 32px; height: 32px"
                >
                  <ImageIcon :size="32"/>
                </div>
                <div
                    style="width: 120px; height: 60px"
                    v-else
                > 
                     
                  <v-img :src="settings.logo" style="width:120px; max-height: 60px">
                    <template v-slot:placeholder>
                      <v-progress-circular
                          indeterminate
                          color="smoke"
                          size="32"
                          width="3"
                      ></v-progress-circular>
                    </template>
                  </v-img>
                </div>
              </div>
              
              <div style="margin-left:5px">
                <div class="body-1 font-weight-medium">
                  {{ $tr('project', 'key_81') }}
                </div>
                <div class="body-2 smoke--text">
                  {{ $tr('project', 'key_87') }}
                </div>
                <div>
                <v-btn
                    :loading="logo_image.isSelecting"
                    small
                    depressed
                    class="mt-3"
                    @click="onButtonLogoClick"
                    style="float:left;"
                >
                    {{ $tr('project', 'key_86') }}
                </v-btn>
                
                <input
                    ref="uploaderB"
                    class="d-none"
                    type="file"
                    accept="image/*"
                    @change="onFileLogoChanged"
                >
                </div>

                <v-btn
                    small
                    depressed
                    class="mt-3"
                    @click="openCreativePortal(settings.logo)"
                    style="float:left; margin-left: 10px;"
                >
                    Open Designer
                </v-btn>
               
              </div>
              
            </div>
            
          </div>
        </div>

        <div class="col-md-6 ">
          <div class="permissions_block mb-3 d-flex justify-space-between align-center pa-4">
            <div class="d-flex justify-start align-center">
              <div>
                <div
                    v-if="!icons.list.android.length"
                    class="smoke--text"
                    style="width: 50px; height: 50px"
                >
                  <AppIcon :size="50"/>
                </div>
                <div
                    style="width: 80px; height: 80px"
                    v-else
                >
                  <v-img
                      :src="`${icons.url}/android/${icons.list.android[3]}?${icons.unix}`"
                      height="80px" width="80px"
                      style="border-radius:10px"
                  >
                    <template v-slot:placeholder>
                      <v-progress-circular
                          indeterminate
                          color="smoke"
                          size="32"
                          width="3"
                      ></v-progress-circular>
                    </template>
                  </v-img>

                </div>
              </div>
              <div style="margin-left:10px;">
                <div class="body-1 font-weight-medium">
                  {{ $tr('project', 'key_89') }}
                </div>
                <div class="body-2 smoke--text">
                  {{ $tr('project', 'key_88') }}
                </div>
                <div>
                    <v-btn
                        :loading="icon_image.isSelecting"
                        small
                        depressed
                        class="mt-3"
                        @click="onButtonIconClick"
                        style="float:left"
                    >
                        {{ $tr('project', 'key_86') }}
                    </v-btn>
                    <input
                        ref="uploaderC"
                        class="d-none"
                        type="file"
                        accept="image/*"
                        @change="onFileIconChanged"
                    >
                </div>
                <v-btn
                    small
                    depressed
                    class="mt-3"
                    @click="openCreativePortal(icons.url + '/original.png')"
                    style="float:left; margin-left: 10px;"
                >
                     <!-- <EditIcon />  --> Open Designer
                </v-btn>

              </div>
            </div>
            
          </div>
        </div>


        <div class="col-md-6 ">
          <div class="permissions_block mb-3 d-flex justify-space-between align-center pa-4">
            <div class="d-flex justify-start align-center">
              <div style="padding:10px">
                
                <div
                    v-if="!settings.background"
                    class="smoke--text"
                    style="width: 32px; height: 32px"
                >
                  <ImageIcon :size="32"/>
                </div>
                <div
                    style="width: 120px; height: 200px"
                    v-else
                > 
                     
                  <v-img
                      :src="settings.background"
                      style="width:120px; max-height: 200px; border-radius: 5px;"
                  >
                    <template v-slot:placeholder>
                      <v-progress-circular
                          indeterminate
                          color="smoke"
                          size="32"
                          width="3"
                      ></v-progress-circular>
                    </template>
                  </v-img>
                </div>
              </div>
              
              <div style="margin-left:5px">
          
                <div class="body-1 font-weight-medium">
                  {{ $tr('project', 'key_80') }}
                </div>
                <div class="body-2 smoke--text">
                  {{ $tr('project', 'key_84') }}
                </div>

                <div>
                  
                  <v-btn
                    :loading="background_image.isSelecting"
                    small
                    depressed
                    class="mt-3"
                    @click="onButtonBackgroundClick"
                  >
                    {{ $tr('project', 'key_86') }}
                  </v-btn>

                  <v-btn
                      small
                      depressed
                      class="mt-3"
                      @click="openCreativePortal(settings.background)"
                      style="margin-left: 10px;"
                    >
                        Open Designer
                  </v-btn>

                  <input
                      ref="uploaderA"
                      class="d-none"
                      type="file"
                      accept="image/*"
                      @change="onFileBackgroundChanged"
                  >
                </div>
               
              </div>
              
            </div>
            
          </div>
        </div>

        <!-- <div class="col-md-6">
          <div class="permissions_block mb-3 d-flex justify-space-between align-center pa-4">
            <div class="d-flex justify-start align-center">
              <v-avatar color="whitesmoke" :size="60" class="mr-4">
                <div
                    v-if="!settings.background"
                    class="smoke--text"
                    style="width: 32px; height: 32px"
                >
                  <ImageIcon :size="32"/>
                </div>
                <div
                    style="width: 32px; height: 32px"
                    v-else
                >
                  <v-img
                      width="32"
                      height="32"
                      :src="settings.background"
                  >
                    <template v-slot:placeholder>
                      <v-progress-circular
                          indeterminate
                          color="smoke"
                          size="32"
                          width="3"
                      ></v-progress-circular>
                    </template>
                  </v-img>
                </div>
              </v-avatar>
              <div>
                <div class="body-1 font-weight-medium">
                  {{ $tr('project', 'key_80') }}
                </div>
                <div class="body-2 smoke--text">
                  {{ $tr('project', 'key_84') }}
                </div>
              </div>
            </div>
            <div>
              <v-btn
                  :loading="background_image.isSelecting"
                  small
                  depressed
                  class="ml-3"
                  @click="onButtonBackgroundClick"
              >
                {{ $tr('project', 'key_86') }}
              </v-btn>
              <input
                  ref="uploaderA"
                  class="d-none"
                  type="file"
                  accept="image/*"
                  @change="onFileBackgroundChanged"
              >

               <v-btn
                    small
                    depressed
                    class="mt-3"
                    @click="openCreativePortal(settings.background)"
                    style="float:left; margin-left: 10px;"
                >
                    Open Designer
              </v-btn>

            </div>
            
          </div>
        </div> -->

        </div>

        <v-dialog
            width="1200"
            scrollable
            v-model="creative"
        >
          <!-- <div style="width:100%; height:600px; background-color:#141517; padding:25px"> -->
          <div style="width:100%; height:600px; background-color:#ffffff; padding:25px">
            <iframe 
              :src="iframe.src"
              :width="1150"
              :height="550"
              type="text/html"
              frameborder="0"
              scrolling="no"
            ></iframe>
          </div>
        </v-dialog>

      </Container>
</template>

<script>
import Container from "@/components/blocks/Container";
import Title from "@/components/blocks/Title";
import Loader from "@/components/blocks/Loader";
import ImageIcon from "@/components/icons/ImageIcon";
import AppIcon from "../../../../../components/blocks/AppIcon.vue";
// import EditIcon from "@/components/icons/EditLogo";

export default {
  name: 'UploadLogo',
  components: {
    Container,
    Title,
    Loader,
    ImageIcon,
    AppIcon
},
  data: () => ({
    loading: true,
    picker: false,
    btnLoad: false,
    settings: {
      background_mode: 0,
      color: "#F44336",
      tagline: "",
      theme: 0,
      delay: 3,
      use_logo: 0,
      background: "",
      logo: ""
    },
    icons: {
      list: {
        android: [],
        ios: []
      },
      load: false,
      url: "",
      unix: 0
    },
    background_image: {
      isSelecting: false,
      selectedFile: null,
      upload: false,
      loading: false
    },
    logo_image: {
      isSelecting: false,
      selectedFile: null,
      upload: false,
      loading: false
    },
    icon_image: {
      isSelecting: false,
      selectedFile: null,
      upload: false,
      loading: false
    },
    creative: false,
    iframe: {
        src: "https://app.shopimint.com/backend/creative/generate",
    },
  }),
  computed: {
    
  },
  methods: {
    openCreativePortal(imageurl) {
      this.iframe.src = "https://app.shopimint.com/backend/creative/generate?imageurl="+imageurl;
      this.creative = true;

      //this.getSplashDetail();
    },
    onButtonIconClick() {
      this.icon_image.isSelecting = true;
      window.addEventListener('focus', () => {
        this.icon_image.isSelecting = false
      }, {once: true});
      this.$refs.uploaderC.click();
    },
    onFileIconChanged(e) {
      this.icon_image.selectedFile = e.target.files[0];
      this.$store.commit('setLoading', true);
      this.icon_image.upload = true;
      let params = new FormData();
      params.set('icon', this.icon_image.selectedFile );
      this.$http.post(
          `${this.$serverApiLink}api/account/assets/upload_icon/${this.$route.params.uid}`, params, {headers: {'Content-Type': 'multipart/form-data' }}).
      then(
          response => {
            let item = response.data;
            this.icon_image.isSelecting = false;
            this.icons.list.android = item.icons.android;
            this.icons.list.ios = item.icons.ios;
            this.icons.url = item.url;
            this.icons.unix = item.unix;
            this.$emit("root_update_icon", item.preview);
            this.$store.commit('setLoading', false);
            this.icon_image.upload = false;
            this.$refs.footer.showSuccessAlert();
            this.getSplashDetail();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.icon_image.upload = false;
            this.$store.commit('setLoading', false);
            this.icon_image.isSelecting = false;
            this.getSplashDetail();
          }
      );
    },
    onButtonLogoClick() {
      this.logo_image.isSelecting = true;
      window.addEventListener('focus', () => {
        this.logo_image.isSelecting = false
      }, {once: true});
      this.$refs.uploaderB.click();
    },
    onFileLogoChanged(e) {
      this.logo_image.selectedFile = e.target.files[0];
      this.$store.commit('setLoading', true);
      this.logo_image.upload = true;
      let params = new FormData();
      params.set('logo', this.logo_image.selectedFile );
      this.$http.post(
          `${this.$serverApiLink}api/account/assets/upload_logo/${this.$route.params.uid}`, params, {headers: {'Content-Type': 'multipart/form-data' }}).
      then(
          response => {
            this.settings.logo = response.data.uri;
            this.logo_image.upload = false;
            this.logo_image.isSelecting = false;
            this.$store.commit('setLoading', false);
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "splash"
            });
            this.$refs.footer.showSuccessAlert();
            this.getSplashDetail();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.logo_image.upload = false;
            this.logo_image.isSelecting = false;
            this.$store.commit('setLoading', false);
            this.getSplashDetail();
          }
      );
    },
    onButtonBackgroundClick() {
      this.background_image.isSelecting = true;
      window.addEventListener('focus', () => {
        this.background_image.isSelecting = false;
      }, {once: true});
      this.$refs.uploaderA.click();
    },
    onFileBackgroundChanged(e) {
      this.background_image.selectedFile = e.target.files[0];
      this.$store.commit('setLoading', true);
      let params = new FormData();
      params.set('screen', this.background_image.selectedFile );
      this.$http.post(
          `${this.$serverApiLink}api/account/assets/upload_splash/${this.$route.params.uid}`, params, {headers: {'Content-Type': 'multipart/form-data' }}).
      then(
          response => {
            this.settings.background = response.data.uri;
            this.background_image.upload = false;
            this.background_image.isSelecting = false;
            this.$store.commit('setLoading', false);
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "splash"
            });
            this.$refs.footer.showSuccessAlert();
            this.getSplashDetail();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.background_image.upload = false;
            this.background_image.isSelecting = false;
            this.$store.commit('setLoading', false);
            this.getSplashDetail();
          }
      );
    },
    setColor(color) {
      this.settings.color = color;
      this.picker = false;
    },
    getSplashDetail() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/assets/splashscreen/${this.$route.params.uid}`).
      then(
          response => {
            this.settings = response.data.detail;
            console.log(response.data.detail);
            this.getIcons();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
          }
      );
    },
    getIcons() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/assets/icons/${this.$route.params.uid}`).
      then(
          response => {
            this.icons.load = true;
            this.icons.list.android = response.data.icons.android;
            this.icons.list.ios = response.data.icons.ios;
            this.icons.url = response.data.url;
            this.icons.unix = response.data.unix;
            this.loading = false;
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
      );
    },
    
  },
  mounted() {
    this.getSplashDetail();

    window.addEventListener("message", (e) => {
        if(e.data.status == 1){
            this.getSplashDetail();
            this.creative = false;
        }else if(e.data.status == 4){
            this.creative_loading = true;
        }else if(e.data.status == 0){
            this.creative_loading = false;
        }
    });
    
  }
}
</script>