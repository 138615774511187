<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25 2C25.5523 2 26 2.44772 26 3V7C26 7.55228 25.5523 8 25 8H23V10C23 10.5128 22.614 10.9355 22.1166 10.9933L22 11H20V13C20 13.5128 19.614 13.9355 19.1166 13.9933L19 14H16.444L16.6249 14.2993C17.2049 15.3198 17.5409 16.4705 17.5929 17.6713L17.6 18C17.6 22.1421 14.2421 25.5 10.1 25.5C5.95787 25.5 2.60001 22.1421 2.60001 18C2.60001 13.8579 5.95787 10.5 10.1 10.5C10.6849 10.5 11.2614 10.5672 11.8208 10.6986L11.874 10.712L20.2929 2.29293C20.4491 2.13663 20.6518 2.03741 20.8686 2.00867L21 2H25ZM24 4H21.414L12.8729 12.5422C12.6345 12.7806 12.2945 12.8809 11.9692 12.8157L11.8482 12.7834C11.2902 12.5965 10.7023 12.5 10.1 12.5C7.06244 12.5 4.60001 14.9624 4.60001 18C4.60001 21.0376 7.06244 23.5 10.1 23.5C13.1376 23.5 15.6 21.0376 15.6 18C15.6 16.7084 15.1547 15.4879 14.352 14.5111C14.0255 14.1137 14.0537 13.5334 14.4172 13.1695L15.2925 12.2933C15.4801 12.1055 15.7346 12 16 12H18V10C18 9.48716 18.386 9.06449 18.8834 9.00673L19 9H21V7C21 6.48716 21.386 6.06449 21.8834 6.00673L22 6H24V4ZM8.50001 18.2C9.21798 18.2 9.80001 18.782 9.80001 19.5C9.80001 20.218 9.21798 20.8 8.50001 20.8C7.78204 20.8 7.20001 20.218 7.20001 19.5C7.20001 18.782 7.78204 18.2 8.50001 18.2Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'KeyIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>