<template>
  <v-layout fill-height>
    <v-main>
      <PageBar
          title="Profilesettingpage"
          :drawer="true"
          pagetype="pages"
      />
      
      <Loader v-if="loading"/>
      <Container v-else>

        <div class="row">
          <h3 class="col">Customise Profile & Settings Page</h3>
          <HelpArticle2  id="6324532ab78f9a796bdeaa52"/>
        </div>

        <!-- <PageSettingNavBar :name="currentpage" /> -->
        <v-row >
          <v-col md="12" style="padding:10px 0px 0px 12px;">
              <h4>Customise Profile & Settings Page</h4>
            </v-col>
            
          </v-row>
            <v-row>
            <div class="col-md-6">
              <div
                class="permissions_block mb-3 d-flex justify-space-between align-center pa-4"
              >
                <div class="d-flex justify-start align-center">
                  <div style="padding: 10px">
                    <div
                      v-if="!page.profilePageBanner"
                      class="smoke--text"
                      style="width: 32px; height: 32px"
                    >
                      <ImageIcon :size="32" />
                    </div>
                    <div style="width: 120px; height: 60px" v-else>
                      <v-img
                        :src="page.profilePageBanner"
                        style="width: 120px; max-height: 60px"
                      >
                        <template v-slot:placeholder>
                          <v-progress-circular
                            indeterminate
                            color="smoke"
                            size="32"
                            width="3"
                          ></v-progress-circular>
                        </template>
                      </v-img>
                    </div>
                  </div>

                  <div style="margin-left: 5px">
                    <div class="body-1 font-weight-medium">
                      {{ $tr('project', 'key_415') }}
                    </div>
                    <div class="body-2 smoke--text">
                      {{ $tr('project', 'key_389') }}
                    </div>
                    <div>
                      <v-btn
                        :loading="logo_image.isSelecting"
                        small
                        depressed
                        class="mt-3"
                        @click="onButtonLogoClick"
                        style="float: left"
                      >
                        {{ $tr('project', 'key_86') }}
                      </v-btn>

                      <input
                        ref="uploaderB"
                        class="d-none"
                        type="file"
                        accept="image/*"
                        @change="onFileLogoChanged"
                      />
                    </div>

                    <v-btn
                      small
                      depressed
                      class="mt-3"
                      @click="openCreativePortal(page.profilePageBanner)"
                      style="float: left; margin-left: 10px"
                    >
                      <!-- <EditIcon />  -->
                      Open Designer
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div
                class="permissions_block mb-3 d-flex justify-space-between align-center pa-4"
              >
                <div class="d-flex justify-start align-center">
                  <div style="padding: 10px">
                    <div
                      v-if="!page.profileDefaultUserImage"
                      class="smoke--text"
                      style="width: 32px; height: 32px"
                    >
                      <ImageIcon :size="32" />
                    </div>
                    <div style="width: 60px; height:60px" v-else>
                      <v-img
                        :src="page.profileDefaultUserImage"
                        style="width: 60px; max-height: 60px"
                      >
                        <template v-slot:placeholder>
                          <v-progress-circular
                            indeterminate
                            color="smoke"
                            size="32"
                            width="3"
                          ></v-progress-circular>
                        </template>
                      </v-img>
                    </div>
                  </div>

                  <div style="margin-left: 5px">
                    <div class="body-1 font-weight-medium">
                      {{ $tr('project', 'key_416') }}
                    </div>
                    <div class="body-2 smoke--text">
                      {{ $tr('project', 'key_389') }}
                    </div>
                    <div>
                      <v-btn
                        :loading="largebanner_image.isSelecting"
                        small
                        depressed
                        class="mt-3"
                        @click="onButtonLargebannerClick"
                        style="float: left"
                      >
                        {{ $tr('project', 'key_86') }}
                      </v-btn>

                      <input
                        ref="uploaderA"
                        class="d-none"
                        type="file"
                        accept="image/*"
                        @change="onFileLargebannerChanged"
                      />
                    </div>

                    <v-btn
                      small
                      depressed
                      class="mt-3"
                      @click="openCreativePortal(page.profileDefaultUserImage)"
                      style="float: left; margin-left: 10px"
                    >
                      <!-- <EditIcon />  -->
                      Open Designer
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <v-col md="12" style="padding:10px 0px 0px 12px;">
              <h4>You can customise the profile page navigation through the navigation page. To customise click the below button</h4>
                <v-btn :to="{ path: 'Navigation', query: { tab: 2 } }" >
                  Customise
                </v-btn>
            </v-col>
          </v-row>
      </Container>
      <Footer
          v-if="!loading"
          :loading="btnLoad"
          ref="footer"
          @click="updateSplash"
      />
      <v-dialog width="1200" scrollable v-model="creative">
        <!-- <div style="width:100%; height:600px; background-color:#141517; padding:25px"> -->
        <div
          style="
            width: 100%;
            height: 600px;
            background-color: #ffffff;
            padding: 25px;
          "
        >
          <iframe
            :src="iframe.src"
            :width="1150"
            :height="550"
            type="text/html"
            frameborder="0"
            scrolling="no"
          ></iframe>
        </div>
      </v-dialog>
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Container from "@/components/blocks/Container";
// import Title from "@/components/blocks/Title";
import Loader from "@/components/blocks/Loader";
// import ImageIcon from "@/components/icons/ImageIcon";
import Footer from "@/components/blocks/Footer";
import ImageIcon from "@/components/icons/ImageIcon";
import HelpArticle2 from "../../../../components/blocks/HelpArticle2.vue";

export default {

  components: {
    PageBar,
    Container,
    // Title,
    Loader,
    Footer,
    ImageIcon,
    HelpArticle2
},
  data: () => ({
    loading: false,
    page: {
      profilePageBanner  :""  ,
      profileDefaultUserImage    :""  ,
    },
    logo_image: {
      isSelecting: false,
      selectedFile: null,
      upload: false,
      loading: false
    },
    largebanner_image: {
      isSelecting: false,
      selectedFile: null,
      upload: false,
      loading: false
    },
    btnLoad: false,
    creative: false,
    iframe: {
        src: "https://app.shopimint.com/backend/creative/generate",
    },
  }),
  computed: {
    enable: function () {
      return [
        {
          text: 'Enable',
          value: '1'
        },
        {
          text: 'Disable',
          value: '0'
        },
      ];
    },
  },
  methods: {
    updateSplash() {
      // this.btnLoad = true;
      // let params = new URLSearchParams();
      // this.$http.post(`${this.$serverApiLink}api/account/pagesettings/update_cartpage/${this.$route.params.uid}`, params).
      // then(
      //     // eslint-disable-next-line no-unused-vars
      //     response => {
      //       this.isUpdate = false;
      //       this.$emit("reload_preview", {
      //         uid: this.$route.params.uid,
      //         mode: "categorypage"
      //       });
      //       this.$refs.footer.showSuccessAlert();
      //     }
      // ).catch(
      //     error => {
      //       this.$store.commit('setSnackBar', {
      //         code: !error.response ? 408 : error.response.status,
      //         message: error.response.data.message
      //       });
      //       this.isUpdate = false;
      //     }
      // );
    },
    
    openCreativePortal(imageurl) {
      this.iframe.src = "https://app.shopimint.com/backend/creative/generate?imageurl="+imageurl;
      this.creative = true;
    },
    onButtonLogoClick() {
      this.logo_image.isSelecting = true;
      window.addEventListener('focus', () => {
        this.logo_image.isSelecting = false
      }, {once: true});
      this.$refs.uploaderB.click();
    },
    onFileLogoChanged(e) {
      this.logo_image.selectedFile = e.target.files[0];
      this.$store.commit('setLoading', true);
      this.logo_image.upload = true;
      let params = new FormData();
      params.set('profilePageBanner', this.logo_image.selectedFile );
      this.$http.post(
          `${this.$serverApiLink}api/account/assets/upload_profilebanner/${this.$route.params.uid}`, params, {headers: {'Content-Type': 'multipart/form-data' }}).
      then(
          response => {
            this.page.profilePageBanner = response.data.uri;
            this.logo_image.upload = false;
            this.logo_image.isSelecting = false;
            this.$store.commit('setLoading', false);
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "profilePageBanner"
            });
            this.$refs.footer.showSuccessAlert();
            this.getBannerDetail();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.logo_image.upload = false;
            this.logo_image.isSelecting = false;
            this.$store.commit('setLoading', false);
            this.getBannerDetail();
          }
      );
    },
    onButtonLargebannerClick() {
      this.largebanner_image.isSelecting = true;
      window.addEventListener('focus', () => {
        this.largebanner_image.isSelecting = false
      }, {once: true});
      this.$refs.uploaderA.click();
    },
    onFileLargebannerChanged(e) {
      this.largebanner_image.selectedFile = e.target.files[0];
      this.$store.commit('setLoading', true);
      this.largebanner_image.upload = true;
      let params = new FormData();
      params.set('profileDefaultUserImage', this.largebanner_image.selectedFile );
      this.$http.post(
          `${this.$serverApiLink}api/account/assets/upload_profileuserbanner/${this.$route.params.uid}`, params, {headers: {'Content-Type': 'multipart/form-data' }}).
      then(
          response => {
            this.page.profileDefaultUserImage = response.data.uri;
            this.largebanner_image.upload = false;
            this.largebanner_image.isSelecting = false;
            this.$store.commit('setLoading', false);
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "ordefailedimage"
            });
            this.$refs.footer.showSuccessAlert();
            this.getBannerDetail();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.largebanner_image.upload = false;
            this.largebanner_image.isSelecting = false;
            this.$store.commit('setLoading', false);
            this.getBannerDetail();
          }
      );
    },
    getPageSettings() {
      this.$http.get(`${this.$serverApiLink}api/account/pagesettings/detail/${this.$route.params.uid}`).
      then(
          response => {
            this.page = response.data.detail;
            this.loading = false;  
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
          }
      );
    },
  },
  mounted() {
    this.getPageSettings();
  }
}
</script>

<style>

.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.card {
  flex-direction: row;
  background: #fff;
  padding: 10px;
  color:black;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border-radius: 10px;
}

.theme--dark .card{
  background-color: rgb(29, 29, 44);
  color:white;
}

.card img {
  width: 30%;
}

.v-text-field input {
  font-size: 0.9em;
  color: black;
  font-weight: bold;
  padding: 0px;;
}

.v-select {
  font-size: 0.9em;
  color: black;
  font-weight: bold;
  padding: 0px;;
}

::v-deep .v-label {
  font-size: 0.9em;
}

::v-deep .v-label--active {
  font-size: 0.9em;
  font-weight: bold;
}

.pagesmenubar {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.pagesmenubar li{
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

</style>