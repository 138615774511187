<template>
  <div class="bar_padding">
    <div>
      <v-toolbar flat color="white" class="bar_page" height="72">
        <div class="d-flex justify-start align-center">
          <div style="margin-right: 16px;">
            <AppIcon :image="loading ? null : image" :size="34"/>
          </div>
          <div>
            <template v-if="loading">
              <div style="padding-top: 6px">
                <v-skeleton-loader
                    v-if="loading"
                    type="text"
                    width="100"
                ></v-skeleton-loader>
                <v-skeleton-loader
                    v-if="loading"
                    type="text"
                    width="180"
                ></v-skeleton-loader>
              </div>
            </template>
            <template v-else>
              <div class="font-weight-bold text-truncate" style="max-width: 180px;">
                {{ name }}
              </div>
              <div class="caption smoke--text text-truncate" style="max-width: 180px;">
                {{ link }}
              </div>
            </template>
          </div>
        </div>
      </v-toolbar>
      <v-divider/>
    </div>
    <Menuitems/>
  </div>
</template>

<script>
import AppIcon from "@/components/blocks/AppIcon";
import Menuitems from "./sidebarcontent/Menuitems.vue";


export default {
  name: 'BuilderSidebar',
  components: {
    AppIcon,
    Menuitems
},
  props: {
    name: {
      type: String,
      default: ""
    },
    link: {
      type: String,
      default: ""
    },
    image: {
      default: ""
    },
  },
  data: () => ({
    loading: true,
    icon: null
  }),
  watch: {

  },
  methods: {
    getAppShortInfo() {
      this.$emit('onStart');
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/apps/short/${this.$route.params.uid}`).
      then(
          response => {
            this.loading = false;
            this.$emit('onLoad', response.data.app);
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.$router.replace({ name: 'Apps' })
          }
      );
    }
  },
  mounted() {
    this.getAppShortInfo();
  }
}
</script>