<template>
  <div class="onclickactions">
    <div v-if="slide.data_action_type != ''">
      <h6 style="padding-top: 2px; padding-left: 4px; padding-right: 4px">
        On Click Actions
      </h6>
      <div>
        <v-chip class="ma-2" small>
          {{ getPageText(slide.data_action) }}</v-chip
        >

        <v-btn icon small color="orange" v-on:click="showdialog = !showdialog">
          <EditMiniIcon :size="16" />
        </v-btn>
      </div>
    </div>
    <div v-if="slide.data_action_type == ''">
      <h6 style="padding-top: 2px; padding-left: 4px; padding-right: 4px">
        On Click Actions
      </h6>
      <div>
        <v-chip class="ma-2" small>
          {{ slide.data_action }}
        </v-chip>

        <v-btn icon small color="orange" v-on:click="showdialog = !showdialog">
          <EditMiniIcon :size="16" />
        </v-btn>
      </div>
    </div>

    <v-dialog
      v-model="showdialog"
      transition="dialog-transition"
      width="100%"
      height="500"
      max-width="800px"
      persistent
      scrollable
      overlay>
      <div class="card-body">
        <div class="row pagemodeltopbar" style="margin: 0">
          <div class="col-8">
            <span style="font-size: 16px; font-weight: bold; margin-top: 10px"
              >Manage onClick Action</span
            >
          </div>
          <div class="col-4">
            <v-btn icon dark @click="showdialog = false" style="float: right">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>

        <v-row style="margin: 10px; margin-top: 80px">
          <v-col md="6" sm="6" cols="12" class="pb-0">
            <v-select
              :label="$tr('project', 'key_56')"
              outlined
              color="primary"
              dense
              :items="actions"
              v-model="slide.data_action_type"></v-select>
          </v-col>
          <v-col
            md="6"
            sm="6"
            cols="12"
            class="pb-0"
            v-if="slide.data_action_type != 'inapp'">
            <v-text-field
              :disabled="slide.data_action_type == 'openmodal'"
              outlined
              color="primary"
              dense
              v-model="dataquery.url"></v-text-field>
            <!-- :label="label" -->
          </v-col>
          <v-col
            md="6"
            sm="6"
            cols="12"
            class="pb-0"
            v-if="slide.data_action_type == 'inapp'">
            <v-select
              label="Select Page"
              outlined
              color="primary"
              dense
              :items="pages"
              v-model="slide.data_action"></v-select>
          </v-col>

          <v-col
            md="6"
            sm="6"
            cols="12"
            class="pb-0"
            v-if="
              slide.data_action_type == 'inapp' &&
              slide.data_action == 'ShopScreen'
            ">
            <v-text-field
              label="Page Title"
              outlined
              color="primary"
              dense
              autofocus
              v-model="dataquery.title"></v-text-field>
          </v-col>

          <v-col
            md="6"
            sm="6"
            cols="12"
            class="pb-0"
            v-if="
              slide.data_action_type == 'inapp' &&
              slide.data_action == 'ShopScreen'
            ">
            <v-select
              label="Select Collection"
              :items="categorieslist"
              dense
              hide-details
              outlined
              v-model="dataquery.category"></v-select>
          </v-col>

          <v-col
            md="6"
            sm="6"
            cols="12"
            class="pb-0"
            v-if="
              slide.data_action_type == 'inapp' &&
              slide.data_action == 'ProductViewScreen'
            ">
            <v-text-field
              label="Select Product"
              outlined
              color="primary"
              dense
              autofocus
              v-model="dataquery.product_id"></v-text-field>
          </v-col>
          <v-col
            md="6"
            sm="6"
            cols="12"
            class="pb-0"
            v-if="
              slide.data_action_type == 'inapp' &&
              slide.data_action == 'OntecsoWebViewScreen'
            ">
            <v-text-field
              label="Add Url"
              outlined
              color="primary"
              dense
              autofocus
              v-model="dataquery.url"></v-text-field>
          </v-col>
          <v-col
            md="6"
            sm="6"
            cols="12"
            class="pb-0"
            v-if="
              slide.data_action_type == 'inapp' &&
              slide.data_action == 'OntecsoHotelBookingMainScreen'
            ">
            <v-text-field
              label="Room Url"
              outlined
              color="primary"
              dense
              autofocus
              v-model="dataquery.url"></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import EditMiniIcon from "../icons/EditMiniIcon.vue";

export default {
  name: "RouteGenerator",
  props: {
    dataquery: {
      type: Object,
      required: true,
    },
    slide: {
      type: Object,
      required: true,
    },
    categorieslist: {
      type: Array,
      required: true,
    },
    platform: {
      type: String,
      required: true,
    },
  },
  components: {
    EditMiniIcon,
  },
  data: function () {
    return {
      action: 0,
      showdialog: false,
    };
  },
  methods: {
    getPageText(value) {
      const page = this.pages.find((page) => page.value === value);
      return page ? page.text : "Unknown Page";
    },
  },
  computed: {
    actions: function () {
      return [
        {
          text: this.$tr("project", "key_e33"),
          value: "inapp",
        },
        {
          text: this.$tr("project", "key_57"),
          value: "internal",
        },
        {
          text: this.$tr("project", "key_58"),
          value: "external",
        },
        {
          text: this.$tr("project", "key_59"),
          value: "share",
        },
        {
          text: this.$tr("project", "key_60"),
          value: "email",
        },
        {
          text: this.$tr("project", "key_298"),
          value: "phone",
        },
        {
          text: this.$tr("project", "key_328"),
          value: "openmodal",
        },
      ];
    },
    pages: function () {
      var shoplist = [
        {
          text: "Shop Page",
          value: "ShopScreen",
        },
        {
          text: "Categories Page",
          value: "CategoriesScreen",
        },
        {
          text: "Open Product",
          value: "ProductViewScreen",
        },
        {
          text: "Cart Page",
          value: "CartScreen",
        },
        {
          text: "WishList Page",
          value: "WishListScreen",
        },
        {
          text: "Orders Page",
          value: "OrdersScreen",
        },
        {
          text: "Brands Page",
          value: "BrandsScreen",
        },
        {
          text: "Search Page",
          value: "SearchScreen",
        },
        {
          text: "Profile Page",
          value: "ProfileScreen",
        },
        {
          text: "Chat Page",
          value: "ChatPage",
        },
        {
          text: "All Sellers", // active e commerce seller page
          value: "AllSellerScreen",
        },
        {
          text: "Settings Page",
          value: "SettingsScreen", // active e commerce page
        },
      ];
      var hotellist = [
        {
          text: "Traveler Home",
          value: "OntecsoHotelBookingMainScreen", // ontecso booking main page
        },
        {
          text: "Traveler Webview",
          value: "OntecsoWebViewScreen", // ontecso booking main page
        },
        {
          text: "Spinwheel",
          value: "OntecsoSpinWheelScreen", // ontecso booking main page
        },
      ];
      if (this.platform == "traveler") {
        return hotellist;
      } else {
        return shoplist;
      }
    },
    label: function () {
      if (
        this.slide.data_action_type == "internal" ||
        this.slide.data_action_type == "external" ||
        this.slide.data_action_type == "share"
      ) {
        return this.$tr("project", "key_61");
      } else if (this.slide.data_action_type === "email") {
        return this.$tr("auth", "key_5");
      } else if (this.slide.data_action_type === "openmodal") {
        return this.$tr("auth", "key_5");
      } else {
        return this.$tr("project", "key_301");
      }
    },
  },
};
</script>
