<template>
  <v-layout fill-height>
    <v-main>
      <PageBar :title="$tr('menu', 'key_40')" :drawer="true" />

      <template v-if="!tab">
        <Loader v-if="loading" />

        <Container v-else style="padding-bottom: 0px">
          <div>
            <v-row>
              <v-col md="4" col="6">
                <v-card outlined class="mb-4 background_block">
                  <v-card-text>
                    <v-img
                      class="white--text align-end"
                      style="max-width: 250px"
                      :src="Android"
                    >
                    </v-img>
                    <v-text-field
                      v-if="detail.android_status == 3"
                      outlined
                      color="primary"
                      readonly
                      v-model="detail.android_link"
                    ></v-text-field>
                    <v-chip
                      v-if="detail.android_status == 0"
                      color="yellow"
                      outlined
                      small
                      text-color="black"
                      >PENDING</v-chip
                    >
                    <v-chip
                      v-if="detail.android_status == 1"
                      color="blue"
                      outlined
                      small
                      text-color="black"
                      >REQUESTED</v-chip
                    >
                    <v-chip
                      v-if="detail.android_status == 2"
                      color="red"
                      outlined
                      small
                      text-color="black"
                      >UPLOADED</v-chip
                    >
                    <v-chip
                      v-if="detail.android_status == 3"
                      color="green"
                      outlined
                      small
                      text-color="green"
                      >LIVE</v-chip
                    >
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="4" col="6">
                <v-card outlined class="mb-4 background_block">
                  <v-card-text>
                    <v-img
                      class="white--text align-end"
                      style="max-width: 250px"
                      :src="Ios"
                    >
                    </v-img>
                    <v-text-field
                      v-if="detail.ios_status == 3"
                      outlined
                      color="primary"
                      readonly
                      v-model="detail.ios_link"
                    ></v-text-field>
                    <v-chip
                      v-if="detail.ios_status == 0"
                      color="yellow"
                      outlined
                      small
                      text-color="black"
                      >PENDING</v-chip
                    >
                    <v-chip
                      v-if="detail.ios_status == 1"
                      color="blue"
                      outlined
                      small
                      text-color="black"
                      >REQUESTED</v-chip
                    >
                    <v-chip
                      v-if="detail.ios_status == 2"
                      color="red"
                      outlined
                      small
                      text-color="black"
                      >UPLOADED</v-chip
                    >
                    <v-chip
                      v-if="detail.ios_status == 3"
                      color="green"
                      outlined
                      small
                      text-color="green"
                      >LIVE</v-chip
                    >
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="4" col="6">
                <v-card outlined class="mb-4 background_block">
                  <v-card-text>
                    <v-img
                      class="white--text align-end"
                      style="max-width: 250px"
                      :src="Huawei"
                    >
                    </v-img>
                    <v-text-field
                      v-if="detail.huawei_status == 3"
                      outlined
                      color="primary"
                      readonly
                      v-model="detail.huawei_link"
                    ></v-text-field>
                    <v-chip
                      v-if="detail.huawei_status == 0"
                      color="yellow"
                      outlined
                      small
                      text-color="black"
                      >PENDING</v-chip
                    >
                    <v-chip
                      v-if="detail.huawei_status == 1"
                      color="blue"
                      outlined
                      small
                      text-color="black"
                      >REQUESTED</v-chip
                    >
                    <v-chip
                      v-if="detail.huawei_status == 2"
                      color="red"
                      outlined
                      small
                      text-color="black"
                      >UPLOADED</v-chip
                    >
                    <v-chip
                      v-if="detail.huawei_status == 3"
                      color="green"
                      outlined
                      small
                      text-color="green"
                      >LIVE</v-chip
                    >
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </Container>
      </template>
      <v-tabs align-with-title v-model="tabModel" @onChange="updateTab">
        <v-tabs-slider color="primary" />
        <v-tab v-for="(tab, i) in tabs" :key="`${i}_page_tab`">
          {{ tab }}
        </v-tab>
      </v-tabs>
      <template v-if="tabModel == 0">
        <Loader v-if="loading" />
        <Container v-else>
          <div>
            <v-row style="padding-top: 10px">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="publisharea"
                    >Step 01 - Active account
                  </v-list-item-title>
                  <v-list-item-subtitle
                    style="margin-bottom: 10"
                    class="publishareap"
                  >
                    &bull; You need to have
                    <a
                      href="https://help.shopimint.com/how-to-get-a-google-play-console/"
                      target="_blank"
                      >Google Play Console
                    </a>
                    .
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    style="margin-bottom: 10"
                    class="publishareap"
                  >
                    &bull; You need to have
                    <a
                      href="https://help.shopimint.com/how-to-get-an-apple-developer-account/"
                      target="_blank"
                      >Apple Developer Account</a
                    >.
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    style="margin-bottom: 10"
                    class="publishareap"
                  >
                    &bull; You need to have
                    <a
                      href="https://help.shopimint.com/how-to-get-a-huawei-app-gallery/"
                      target="_blank"
                      >Huawei Developer Account</a
                    >
                    .
                  </v-list-item-subtitle>
                  <v-list-item-title class="publisharea"
                    >Step 02 - Give permission.
                  </v-list-item-title>
                  <v-list-item-subtitle class="publishareap">
                    &bull; Your need to grant access to shopimint from
                    <a
                      href="https://help.shopimint.com/inviting-shopimint-to-your-google-developer-account/"
                      target="_blank"
                      >Google Play Console
                    </a>
                    .
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="publishareap">
                    &bull; Your need to grant access to shopimint from
                    <a
                      href="https://help.shopimint.com/inviting-shopimint-to-your-apple-developer-account/"
                      target="_blank"
                      >Apple Developer Account
                    </a>
                    .
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="publishareap">
                    &bull; Your need to grant access to shopimint from
                    <a
                      href="https://help.shopimint.com/inviting-shopimint-to-your-huawei-developer-account/"
                      target="_blank"
                      >Huawei Developer Account
                    </a>
                    .
                  </v-list-item-subtitle>
                  <v-list-item-title class="publisharea"
                    >Step 03 - Publish your app
                  </v-list-item-title>
                  <v-list-item-subtitle class="publishareap">
                    &bull; To Publish your app request from clicking the
                    following button.
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-btn
                depressed
                color="primary"
                class="ml-3"
                @click="create.status = true"
              >
                Request to Publish
              </v-btn>
            </v-row>
          </div>
        </Container>
      </template>
      <template v-if="tabModel == 1">
        <Loader v-if="loading" />
        <Container v-else>
          <div>
            <v-row>
              <v-col md="12">
                <Title :title="`${$tr('project', 'key_239')}: ${count}`" />
                <v-list dense two-line class="pa-0 background_block">
                  <div
                    v-for="(item, index) in list"
                    :key="'pending_ticket_'+index"
                  >
                    <v-divider
                      v-if="index > 0"
                      :key="'pending_ticket_line'+index"
                      class="mb-1 mt-1"
                    />
                    <v-list-item
                      :key="'pending_ticket_item'+index"
                      class="pl-0 pr-0 pt-0 pb-0"
                      :to="{name: 'Ticket', params: {ticket_uid: item.uid}}"
                    >
                      <v-list-item-avatar
                        size="40"
                        color="whitesmoke"
                        class="mt-0 mb-0 d-flex justify-center align-center"
                      >
                        <div
                          class="smoke--text"
                          style="width: 24px; height: 24px"
                        >
                          <MailIcon :size="24" />
                        </div>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold">
                          {{ item.title }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="smoke--text">
                          {{ item.message.comment }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action class="mr-2">
                        <v-list-item-action-text
                          class="black--text"
                          v-text="item.message.created"
                        />
                        <v-chip
                          small
                          :class="`mt-2 ${getStatusColor(item.status)}`"
                        >
                          {{ getStatusName(item.status) }}
                        </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                  </div>
                </v-list>
              </v-col>
            </v-row>
          </div>
        </Container>
      </template>

      <v-dialog scrollable max-width="760" v-model="create.status">
        <v-card flat tile>
          <v-card-title class="pa-0">
            <v-toolbar flat class="modal_bar">
              <v-toolbar-title>
                {{ $tr('project', 'key_235') }}
              </v-toolbar-title>
              <v-spacer />
              <v-btn icon @click="create.status = false">
                <CancelIcon />
              </v-btn>
            </v-toolbar>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-0">
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-select
                    label="Ticket Type"
                    :items="supporttype"
                    class="blackcolor"
                    dense
                    outlined
                    v-modal="ticket_type"
                  ></v-select>
                </v-col>
                <v-col md="12" sm="12" cols="12">
                  <v-text-field
                    :label="$tr('project', 'key_236')"
                    outlined
                    class="blackcolor"
                    autofocus
                    color="primary"
                    dense
                    hide-details
                    v-model="create.title"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="create.androidcheck"
                    class="blackcolor"
                    :label="`Android App`"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="create.ioscheck"
                    class="blackcolor"
                    :label="`IOS App`"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="create.huaweicheck"
                    :label="`Huawei App`"
                  ></v-checkbox>
                </v-col>
                <v-col md="12" cols="12">
                  <div class="noticearea" v-if="create.androidcheck === true">
                    You have to create permission in Google Play Console.
                    <a
                      href="https://help.shopimint.com/inviting-shopimint-to-your-google-developer-account/"
                      style="color: black"
                      target="_blank"
                    >
                      To learn how to create permission click Here</a
                    >.
                  </div>
                  <div class="noticearea" v-if="create.ioscheck === true">
                    You have to create permission in Apple Developer Account.<a
                      href="https://help.shopimint.com/inviting-shopimint-to-your-apple-developer-account/"
                      style="color: black"
                      target="_blank"
                    >
                      To learn how to create permission click Here</a
                    >.
                  </div>
                  <div class="noticearea" v-if="create.huaweicheck === true">
                    You have to create permission in Huawei Developer Account.
                    <a
                      href="https://help.shopimint.com/inviting-shopimint-to-your-huawei-developer-account/"
                      style="color: black"
                      target="_blank"
                    >
                      To create permission click Here</a
                    >.
                  </div>
                </v-col>

                <v-col md="12" sm="12" cols="12">
                  <v-textarea
                    :label="$tr('project', 'key_237')"
                    outlined
                    auto-grow
                    rows="10"
                    color="primary"
                    dense
                    hide-details
                    v-model="create.message"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider />
          <v-container fluid class="text-right">
            <v-btn
              color="primary"
              depressed
              :loading="create.loading"
              @click="createTicket"
            >
              {{ $tr('project', 'key_238') }}
            </v-btn>
          </v-container>
        </v-card>
      </v-dialog>
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Container from "@/components/blocks/Container";
import Title from "@/components/blocks/Title";
import Loader from "@/components/blocks/Loader";
import CancelIcon from "@/components/icons/CancelIcon";
import MailIcon from "../../../../components/icons/MailIcon.vue";
import Android from "@/assets/images/publishapp/playstore.png"
import Ios from "@/assets/images/publishapp/app-store.png"
import Huawei from "@/assets/images/publishapp/huaweistore.png"

export default {
  name: 'Build',
  components: {
    PageBar,
    Container,
    Title,
    Loader,
    CancelIcon,
    MailIcon
},
  props: {
    balance: {
      type: Number,
      required: true
    },
  },
  data: () => ({
    loading: true,
    Android:Android,
    Ios:Ios,
    Huawei:Huawei,
    sort: 0,
    count: 0,
    tabModel:0,
    tab:"",
    list: [],
    offset: 0,
    loadBtn: {
      status: true,
      loading: false
    },
    create: {
      title: "",
      message: "",
      status: false,
      loading: false,
      huaweicheck:false,
      androidcheck:false,
      ioscheck:false,

    },
    ticket_type:1,
    detail: {
        android_status: "",
        ios_status: "",
        huawei_status: "",
        android_link: "",
        ios_link: "",
        huawei_link: ""
    },
    dialog: false,
    platform: "android",
    version: "1.0.0",
    signing: {
      loading: true,
      list: []
    },
    sign: "",
    android_file: "apk",
    testFlight: false,
  }),
  watch: {
    platform: function () {
      this.signing = {
        loading: true,
        list: []
      };
      this.sign = "";
      this.getSigning();
    }
  },
  computed :{
    supporttype: function () {
      return [
      "Support",
      "App Publish",
      "App Update",
      "App Change",
      "New Feature Request",
      ];
    },
    tabs: function () {
      return [
        "Create a Request",
        "Requests",
      ];
    },
  },
  methods: {
    downloadApp(link) {
      window.open(link, "_self");
    },
    updateTab(value) {
      this.tab = value;
      this.tabModel = value;
    },
    createBuildDialog() {
      if (this.balance === 0) {
        this.$emit("open_deposit");
      } else {
        this.dialog = true
      }
    },
    createTicket() {
      this.create.loading = true;
      let params = new URLSearchParams();
      params.append('title', this.create.title);
      params.append('message', this.create.message);
      params.append('ticket_type', this.ticket_type);
      params.append('androidcheck', this.create.androidcheck);
      params.append('ioscheck', this.create.ioscheck);
      params.append('huaweicheck', this.create.huaweicheck);
      this.$http.post(`${this.$serverApiLink}api/account/support/create_ticket`, params).
      then(
          response => {
            this.$router.push({name: 'Ticket', params: {ticket_uid: response.data.uid}});
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.create.loading = false;
          }
      );
    },
    getStatusColor(status) {
      if (!status) {
        return "smoke_badge";
      } else if (status === 1) {
        return "warning_badge";
      } else {
        return "success_badge";
      }
    },
    getStatusName(status) {
      if (!status) {
        return this.$tr("project", "key_241");
      } else if (status === 1) {
        return this.$tr("project", "key_242");
      } else {
        return this.$tr("project", "key_243");
      }
    },
    getTicketList() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/support/list/0/${this.offset}`).
      then(
          response => {
            this.list = response.data.list;
            this.offset += 20;
            this.count = response.data.count;
            this.loading = false;
            console.log(this.list)
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
      );
    },
    getPublishstatus() {
      this.$http.get(`${this.$serverApiLink}api/account/apps/apppublishstatus/${this.$route.params.uid}`).
      then(
          response => {
            this.detail = response.data.detail;
            this.loading = false;
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
          }
      );
    },
    changeAndroidFile() {
      this.android_file = this.android_file === 'apk' ? 'aab' : 'apk';
    },
    getBadgeColor(status, error) {
      console.log(error);
      if (!status) {
        return "smoke_badge";
      } else if (status === 1 && !error) {
        return "success_badge";
      } else {
        return "danger_badge";
      }
    },
    getBadgeName(status, error) {
      if (!status) {
        return this.$tr('project', 'key_194');
      } else if (status === 1 && !error) {
        return this.$tr('project', 'key_195');
      } else {
        return this.$tr('project', 'key_196');
      }
    },
  },

  mounted() {
    this.getPublishstatus();
    this.getTicketList();
  }
}
</script>
