<template>
  <v-layout fill-height>
    <v-main>
      <PageBar
        :title="$tr('menu', 'key_36')"
        :preview="false"
        :tabs="tabs"
        @onChange="updateTab"
        :drawer="true" />
      <!-- <NewsletterSidebarPush /> -->
      <Loader v-if="loading" />

      <div v-if="showsetup">
        <v-row
          class="mb-12 integrationarea"
          style="background-color: #ffffcc; color: black">
          <v-col md="3" sm="3" cols="3" class="pb-0 mx-auto">
            <div
              style="
                background-image: url(https://firebase.google.com/static/downloads/brand-guidelines/PNG/logo-standard.png);
              "
              class="integrationlogo"></div>
          </v-col>
          <v-col
            md="9"
            sm="9"
            cols="9"
            class="pb-0"
            style="line-height: initial">
            <h1>Push Notifications</h1>
            <p>
              Please setup Firebase Push Notifications Setup to enable this
              feature
            </p>
            <v-btn :to="{ name: 'Firebase' }"> Setup </v-btn>
          </v-col>
        </v-row>
      </div>

      <template v-if="!tab || tab == 1">
        <v-row>
          <v-col md="6" sm="6" cols="12">
            <v-card-text class="pa-0">
              <v-container fluid>
                <v-row>
                  <v-col md="12" sm="12" cols="12">
                    <v-text-field
                      :label="$tr('project', 'key_236')"
                      outlined
                      autofocus
                      color="primary"
                      dense
                      hide-details
                      v-model="create.title"></v-text-field>
                  </v-col>
                  <v-col md="6" sm="6" cols="12">
                    <v-text-field
                      :label="$tr('project', 'key_264')"
                      outlined
                      readonly
                      color="primary"
                      dense
                      hide-details
                      v-model="create.led.value"
                      @click="create.led.status = true">
                      <template v-slot:append>
                        <v-avatar
                          :color="create.led.value"
                          size="15"
                          style="top: 10px" />
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col md="6" sm="6" cols="12">
                    <v-select
                      label="Audience"
                      outlined
                      color="primary"
                      dense
                      :items="audiences"
                      v-model="create.audience"></v-select>
                  </v-col>

                  <v-col md="12" sm="12" cols="12">
                    <v-textarea
                      :label="$tr('project', 'key_237')"
                      outlined
                      auto-grow
                      rows="6"
                      color="primary"
                      dense
                      hide-details
                      v-model="create.message"></v-textarea>
                  </v-col>
                  <v-col md="12" sm="12" cols="12" class="pb-4">
                    <div>
                      <input
                        ref="fileInput"
                        type="file"
                        accept="image/*"
                        @input="pickFile"
                        @change="onFileBannerChanged" />
                    </div>
                  </v-col>
                  <v-col md="12" sm="12">
                    <h5>Click Actions</h5>
                  </v-col>
                  <v-col md="4" sm="4" cols="12">
                    <v-select
                      label="Select Action"
                      outlined
                      color="primary"
                      dense
                      :items="actions"
                      v-model="create.action"></v-select>
                  </v-col>
                  <v-col
                    md="4"
                    sm="4"
                    cols="12"
                    v-if="create.action == 'inapp'">
                    <v-select
                      label="Select Page"
                      outlined
                      color="primary"
                      dense
                      :items="pages"
                      v-model="create.page"></v-select>
                  </v-col>

                  <v-col
                    md="4"
                    sm="6"
                    cols="12"
                    class="pb-0"
                    v-if="
                      create.action == 'inapp' && create.page == 'ShopScreen'
                    ">
                    <v-text-field
                      label="Select Collection"
                      outlined
                      color="primary"
                      dense
                      autofocus
                      v-model="create.arguments.category"></v-text-field>
                  </v-col>

                  <v-col
                    md="4"
                    sm="6"
                    cols="12"
                    class="pb-0"
                    v-if="
                      create.action == 'inapp' &&
                      create.page == 'ProductViewScreen'
                    ">
                    <v-text-field
                      label="Select Product"
                      outlined
                      color="primary"
                      dense
                      autofocus
                      v-model="create.arguments.product_id"></v-text-field>
                  </v-col>

                  <v-col md="12" sm="12" v-if="tab == 1">
                    <h5>Sheduled Actions</h5>
                  </v-col>
                  <v-col md="6" sm="6" cols="12" v-if="tab == 1">
                    <v-dialog
                      ref="dialog"
                      v-model="dateselector"
                      :return-value.sync="date"
                      persistent
                      width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="Select Time"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="date" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="dateselector = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog.save(date)">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col md="6" sm="6" cols="12" v-if="tab == 1">
                    <v-dialog
                      ref="timeselector"
                      v-model="timeselector"
                      :return-value.sync="time"
                      persistent
                      width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="time"
                          label="Select Time"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="timeselector"
                        v-model="time"
                        full-width>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="timeselector = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.timeselector.save(time)">
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-container>
              <v-divider />
              <v-container fluid class="text-right">
                <v-btn
                  color="primary"
                  depressed
                  :loading="create.loading"
                  @click="startPush">
                  Send Push
                </v-btn>
              </v-container>
            </v-card-text>
          </v-col>
          <v-col
            md="6"
            sm="6"
            cols="12"
            class="pushimage"
            style="
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
            ">
            <div style="padding-left: 170px; height: 50px; overflow: hidden">
              <v-switch
                v-model="awesome"
                :label="!awesome ? 'Notification View' : 'Expanded View'"
                color="indigo darken-3"></v-switch>
            </div>

            <div
              v-if="!awesome"
              @click="awesome = !awesome"
              style="cursor: pointer">
              <img
                height="500"
                max-width="500"
                width="500"
                src="https://app.shopimint.com/backend/static/appstatic/push_notification.png" />
              <div class="centerp">
                <v-row
                  style="
                    align-items: center;
                    padding-left: 15px;
                    padding-right: 15px;
                  ">
                  <v-col md="2" cols="2" style="padding: 0px">
                    <AppIcon
                      :size="40"
                      style="margin-left: 5px; margin-top: 20px"
                      :image="!app.icon ? null : app.icon" />
                  </v-col>
                  <v-col
                    md="8"
                    cols="8"
                    style="padding: 0px; padding-left: 5px">
                    <h5>{{ create.title }}</h5>
                    <p>{{ create.message }}</p>
                  </v-col>
                  <v-col md="2" cols="2" style="padding: 0px">
                    <span class="pushtime">now</span>
                    <AppIcon
                      :size="35"
                      :image="`${previewImage}`"
                      style="margin-top: 35px" />
                  </v-col>
                </v-row>
              </div>
            </div>
            <div v-else @click="awesome = !awesome" style="cursor: pointer">
              <img
                height="500"
                max-width="500"
                width="500"
                src="https://app.shopimint.com/backend/static/appstatic/push-notification2.png" />
              <div class="centerl">
                <v-row
                  style="
                    align-items: center;
                    padding-left: 15px;
                    padding-right: 15px;
                  ">
                  <v-col md="2" cols="2" style="padding: 20px 5px 0px 0px">
                    <AppIcon
                      :size="40"
                      style="margin: auto"
                      :image="!app.icon ? null : app.icon" />
                  </v-col>
                  <v-col md="10" cols="10" style="padding: 15px 0px 0px 0px">
                    <h5>{{ create.title }}</h5>
                    <p>{{ create.message }}</p>
                  </v-col>
                  <!-- <v-col md="2" cols="2">
                        <p>now</p>
                      </v-col> -->
                </v-row>
                <v-row>
                  <v-col md="2" cols="2">
                    <img
                      height="230"
                      width="290"
                      style="
                        border-radius: 0px 0px 15px 15px;
                        object-fit: cover;
                      "
                      :src="!`${previewImage}` ? null : `${previewImage}`" />
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-else-if="tab === 2">
        <template v-if="error">
          <EmptyState
            :title="$tr('project', 'key_288')"
            :subtitle="$tr('project', 'key_289')"
            :is-error="true">
            <template v-slot:icon>
              <div style="width: 45px; height: 45px">
                <ErrorIcon :size="45" />
              </div>
            </template>
          </EmptyState>
        </template>
        <template v-else>
          <EmptyState
            v-if="list.length === 0"
            :title="$tr('project', !uid ? 'key_261' : 'key_259')"
            :subtitle="$tr('project', !uid ? 'key_413' : 'key_262')">
            <template v-slot:icon>
              <div style="width: 45px; height: 45px">
                <AdvIcon :size="45" />
              </div>
            </template>
            <template v-slot:action>
              <v-btn v-if="uid" depressed color="primary" large class="ml-3">
                {{ $tr("project", "key_255") }}
              </v-btn>
            </template>
          </EmptyState>

          <Container v-else>
            <v-row class="mb-2 mt-1">
              <v-col cols="12" md="6" sm="12">
                <v-card outlined style="background: transparent">
                  <v-list-item>
                    <v-list-item-avatar
                      size="60"
                      color="whitesmoke"
                      class="d-flex justify-center align-center">
                      <div
                        class="primary--text"
                        style="width: 32px; height: 32px">
                        <UsersIcon :size="32" />
                      </div>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium text-h6">
                        {{ stat.total }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="smoke--text">
                        {{ $tr("project", "key_256") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-card outlined style="background: transparent">
                  <v-list-item>
                    <v-list-item-avatar
                      size="60"
                      color="whitesmoke"
                      class="d-flex justify-center align-center">
                      <div
                        class="primary--text"
                        style="width: 32px; height: 32px">
                        <UserCheckIcon :size="32" />
                      </div>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium text-h6">
                        {{ stat.active }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="smoke--text">
                        {{ $tr("project", "key_257") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <div class="d-flex justify-space-between align-center">
              <Title :title="`${$tr('project', 'key_258')} (${count})`" />
              <!-- <v-btn
                  small
                  depressed
                  color="primary"
                  class="mb-5"
                  @click="create.status = true"
              >
                {{ $tr('project', 'key_255') }}
              </v-btn> -->
            </div>

            <v-list dense two-line class="pa-0 background_block">
              <div
                v-for="(item, index) in list"
                :key="'pending_ticket_' + index">
                <v-divider
                  v-if="index > 0"
                  :key="'news_' + index"
                  class="mb-1 mt-1" />
                <v-list-item
                  :key="'news_item' + index"
                  two-line
                  class="pl-0 pr-0 pt-0 pb-0">
                  <img
                    :src="item.image"
                    style="
                      width: 80px;
                      height: 50px;
                      border-radius: 5px;
                      margin-right: 15px;
                      object-fit: cover;
                    " />
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">
                      {{ item.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="smoke--text">
                      {{ item.message }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <br />
                      {{
                        item.sheduled == 0
                          ? "Instant Notification : " + item.create_date
                          : "Sheduled On : " + item.sheduled_date
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div class="d-flex justify-center align-center">
                      <div class="text-center ml-4">
                        <v-chip
                          v-if="item.sent == 1"
                          class="ma-2"
                          color="green"
                          text-color="white"
                          small
                          >Sent</v-chip
                        >
                        <v-chip
                          v-if="item.sent == 0"
                          class="ma-2"
                          color="warning"
                          text-color="white"
                          small
                          >Pending</v-chip
                        >
                        <v-chip
                          v-if="item.sent == 9"
                          class="ma-2"
                          color="red"
                          text-color="white"
                          small
                          >Failed</v-chip
                        >
                      </div>

                      <div class="text-center ml-4">
                        <v-list-item-title
                          class="success--text font-weight-bold mb-1">
                          {{ item.sent }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          class="success--text x-small-text">
                          {{ $tr("project", "key_283") }}
                        </v-list-item-subtitle>
                      </div>
                      <div class="text-center ml-4">
                        <v-list-item-title
                          class="warning--text font-weight-bold mb-1">
                          {{ item.app_open }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          class="warning--text x-small-text">
                          {{ $tr("project", "key_284") }}
                        </v-list-item-subtitle>
                      </div>
                      <div class="text-center ml-4">
                        <v-list-item-title
                          class="danger--text font-weight-bold mb-1">
                          {{ item.app_open }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="danger--text x-small-text">
                          {{ $tr("project", "key_285") }}
                        </v-list-item-subtitle>
                      </div>
                      <div class="text-center ml-4">
                        <v-list-item-title class="font-weight-bold mb-1">
                          {{ item.app_open }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="black--text x-small-text">
                          {{ $tr("project", "key_263") }}
                        </v-list-item-subtitle>
                      </div>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </v-list>
            <div
              v-if="loadBtn.status && count > 20"
              class="d-flex justify-center mt-5 mb-5">
              <v-btn
                rounded
                outlined
                :loading="loadBtn.loading"
                @click="loadMore">
                {{ $tr("project", "key_26") }}
              </v-btn>
            </div>
          </Container>
        </template>
      </template>
      <v-dialog scrollable max-width="360" v-model="create.led.status">
        <LedModal
          :color="create.led.value"
          @close="create.led.status = false"
          @select="setLed" />
      </v-dialog>
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Loader from "@/components/blocks/Loader";
import EmptyState from "@/components/blocks/EmptyState";
import Title from "@/components/blocks/Title";
import Container from "@/components/blocks/Container";
import AdvIcon from "@/components/icons/AdvIcon";
import UsersIcon from "@/components/icons/UsersIcon";
import UserCheckIcon from "@/components/icons/UserCheckIcon";
// import RadioIcon from "@/components/icons/RadioIcon";
// import CancelIcon from "@/components/icons/CancelIcon";
// import DropZone from "@/components/form/DropZone";
import LedModal from "@/views/account/newsletter/LedModal";
// import ClockIcon from "@/components/icons/ClockIcon";
import ErrorIcon from "@/components/icons/ErrorIcon";
// import NewsletterSidebarPush from "../../../../../components/sidebars/NewsletterSidebarPush";
import AppIcon from "@/components/blocks/AppIcon";

export default {
  name: "Newsletter",
  components: {
    PageBar,
    Loader,
    EmptyState,
    Title,
    Container,
    AdvIcon,
    UsersIcon,
    UserCheckIcon,
    // RadioIcon,
    // CancelIcon,
    // DropZone,
    LedModal,
    // ClockIcon,
    ErrorIcon,
    // NewsletterSidebarPush,
    AppIcon,
  },
  data: () => ({
    loading: true,
    showsetup: false,
    list: [],
    offset: 0,
    count: 0,
    tab: 0,
    awesome: false,
    previewImage: "https://via.placeholder.com/300",
    loadBtn: {
      status: true,
      loading: false,
    },
    stat: {
      total: 0,
      active: 0,
    },
    uid: null,
    app: {
      icon: null,
    },
    create: {
      status: false,
      title: "",
      message: "",
      led: {
        status: false,
        value: "#2D9CDB",
      },
      image: null,
      loading: false,
      arguments: {
        category_id: "",
      },
      page: "HomeScreen",
      audience: "allusers",
      action: "inapp",
      sound: "Tri-tone",
    },
    error: false,
    timeselector: false,
    dateselector: false,
    time: null,
    date: null,
  }),
  computed: {
    tabs: function () {
      return [
        this.$tr("project", "key_e46"),
        this.$tr("project", "key_e47"),
        this.$tr("project", "key_e48"),
      ];
    },
    audiences: function () {
      return [
        {
          text: "All app Users",
          value: "allusers",
        },
      ];
    },
    pages: function () {
      return [
        {
          text: "Home Page",
          value: "HomeScreen",
        },
        {
          text: "Shop Page",
          value: "ShopScreen",
        },
        {
          text: "View Product",
          value: "ProductViewScreen",
        },
        {
          text: "Cart Page",
          value: "CartScreen",
        },
        {
          text: "WishList Page",
          value: "WishListScreen",
        },
        {
          text: "Orders Page",
          value: "OrdersScreen",
        },
        {
          text: "Brands Page",
          value: "BrandsScreen",
        },
        {
          text: "Search Page",
          value: "SearchScreen",
        },
        {
          text: "Profile Page",
          value: "ProfileScreen",
        },
        {
          text: "Chat Page",
          value: "ChatPage",
        },
      ];
    },
    actions: function () {
      return [
        {
          text: "Open App",
          value: "inapp",
        },
      ];
    },
  },
  // watch: {
  //   '$route.query.uid': function() {
  //     this.getUid();
  //   },
  // },
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      }
    },
    onFileBannerChanged(e) {
      this.create.image = e.target.files[0];
      console.log(this.create.image);
    },
    startPush() {
      this.create.loading = true;
      let params = new FormData();
      params.set("title", this.create.title);
      params.set("message", this.create.message);
      params.set("led", this.create.led.value);
      params.set("audience", this.create.audience);

      params.set("action", this.create.action);
      params.set("page", this.create.page);
      params.set("arguments", JSON.stringify(this.create.arguments));
      params.set("sound", this.create.sound);

      params.set("sheduled", this.tab);
      params.set("sheduled_date", this.date);
      params.set("sheduled_time", this.time);
      params.set("image", this.create.image);

      this.$http
        .post(
          `${this.$serverApiLink}api/account/newsletter/create/${this.uid}`,
          params,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((response) => {
          console.log(response);
          // this.$store.commit("setSnackBar", {
          //   code: response.status,
          //   message: response.data.message,
          // });
          this.create.loading = false;
          this.tab = 2;
          this.create = {
            status: false,
            title: "",
            message: "",
            led: {
              status: false,
              value: "#2D9CDB",
            },
            image: null,
            loading: false,
          };
          this.getTransaction();
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.create.loading = false;
        });
    },
    sendTokenToServer(token) {
      let params = new FormData();
      params.set("fcm_token", token);

      // Add other parameters if necessary
      params.set("title", this.create.title);
      params.set("message", this.create.message);
      // Add more params as needed

      this.$http
        .post(
          `${this.$serverApiLink}api/account/newsletter/saveToken`,
          params,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((response) => {
          console.log("Token saved successfully:", response.data);
        })
        .catch((error) => {
          console.error(
            "Error saving token:",
            error.response ? error.response.data : error
          );
        });
    },
    // updateList() {
    //   this.count = 0;
    //   this.offset = 0;
    //   this.loadBtn = {
    //     status: true,
    //     loading: false
    //   };
    //   this.getTransaction();
    // },
    // updateFile(file) {
    //   this.create.image = file;
    // },
    setLed(value) {
      this.create.led = {
        status: false,
        value: value,
      };
    },
    getuid() {
      this.uid = this.$route.params.uid;
      this.getTransaction();
      this.getAppsList();
    },
    // getUid() {
    //   let uid = this.$route.query.uid === undefined ? null : this.$route.query.uid;
    //   this.list = [];
    //   this.offset = 0;
    //   this.count = 0;
    //   if (!uid) {
    //     this.uid = null;
    //     this.loading = false;
    //   } else {
    //     this.uid = uid;
    //     this.getTransaction();
    //   }
    // },
    getTransaction() {
      this.loading = true;
      this.$http
        .get(
          `${this.$serverApiLink}api/account/newsletter/list/${this.uid}/${this.offset}`
        )
        .then((response) => {
          console.log(response);
          console.log(response.data.message);
          // this.$store.commit("setSnackBar", {
          //   code: response.status,
          //   message: response.data.message,
          // });
          this.list = response.data.list;
          this.offset += 20;
          this.count = response.data.count;
          this.stat = response.data.stat;
          this.loading = false;
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.list = [];
          this.uid = null;
          this.loading = false;
          this.error = true;
          this.showsetup = true;
        });
    },
    getAppsList() {
      this.loading = true;
      this.$http
        .get(`${this.$serverApiLink}api/account/newsletter/getapp/${this.uid}`)
        .then((response) => {
          this.app = response.data.list;
          this.loading = false;
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.loading = false;
          this.showsetup = true;
        });
    },
    updateTab(value) {
      this.tab = value;
    },
    loadMore() {
      this.loadBtn.loading = true;
      this.$http
        .get(
          `${this.$serverApiLink}api/account/newsletter/list/${this.uid}/${this.offset}`
        )
        .then((response) => {
          if (response.data.list.length > 0) {
            for (let i = 0; i < response.data.list.length; i++) {
              this.list.push(response.data.list[i]);
            }
          } else {
            this.loadBtn.status = false;
          }
          this.offset += 20;
          this.loadBtn.loading = false;
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.loadBtn.loading = false;
        });
    },
  },
  mounted() {
    this.getuid();
  },
};
</script>
