<template>
  <v-layout fill-height>
    <v-main>
      <PageBar title="Advanced App Settings" :drawer="true" />

      <v-container v-if="loading">
        <v-progress-circular
          indeterminate
          color="primary"></v-progress-circular>
      </v-container>

      <v-container v-else>
        <v-card style="border-radius: 20px">
          <v-card-title>
            <v-icon left class="mr-2">mdi-link</v-icon>
            App Links
            <v-spacer></v-spacer>
            <v-icon class="ml-2">mdi-information-outline</v-icon>
          </v-card-title>

          <v-card-text>
            <p v-if="errored">An error occurred while fetching the settings.</p>
            <div v-else>
              <v-textarea
                label="Ios Link"
                outlined
                color="primary"
                dense
                rows="1"
                v-model="ios_link">
              </v-textarea
              ><v-textarea
                label="Android Link"
                outlined
                color="primary"
                dense
                rows="1"
                v-model="android_link">
              </v-textarea
              ><v-textarea
                label="Huawei Link"
                outlined
                color="primary"
                dense
                rows="1"
                v-model="huawei_link"></v-textarea>
            </div>
          </v-card-text>

          <!-- <v-btn
            :loading="btnLoad"
            @click="updateAppLinks"
            color="primary"
            style="margin: 10px"
            >Update Links</v-btn
          > -->
        </v-card>
        <v-card style="margin-top: 15px; border-radius: 20px">
          <v-card-title>
            <v-icon left class="mr-2">mdi-bell</v-icon>
            Update Notice
            <v-spacer></v-spacer>
            <v-icon class="ml-2">mdi-information-outline</v-icon>
          </v-card-title>

          <v-card-text class="maintanancemode">
            <p v-if="errored">An error occurred while fetching the settings.</p>
            <div v-else>
              <v-col
                md="12"
                sm="12"
                cols="12"
                class="mb-5"
                style="margin-left: 10px; margin-right: 10px !important">
                <v-row>
                  <v-col md="6">
                    <v-switch
                      v-model="update_enable"
                      :label="
                        update_enable ? 'Update Enabled.' : 'Update Disabled.'
                      "
                      color="success"
                      inset></v-switch>
                    <v-text-field
                      v-model="latest_version"
                      label="Latest Version"
                      outlined
                      color="primary"
                      dense
                      prepend-inner-icon=""
                      append-inner-icon=""
                      hide-details
                      style="
                        border-radius: 15px;
                        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                        font-weight: bold;
                        margin-bottom: 10px;
                      "
                      min="0"
                      max="100"
                      step="0.1">
                    </v-text-field>
                    <v-textarea
                      label="Update Notice"
                      outlined
                      color="primary"
                      dense
                      rows="2"
                      v-model="update_notice"></v-textarea>
                  </v-col>
                  <v-col md="6">
                    <div
                      class="d-flex justify-start align-center"
                      style="padding: 5px; border-radius: 10px">
                      <div style="padding: 10px">
                        <div
                          v-if="!update_image"
                          class="smoke--text"
                          style="width: 32px; height: 32px">
                          <ImageIcon :size="32" />
                        </div>
                        <div style="width: 120px; height: 100px" v-else>
                          <v-img
                            :src="update_image"
                            style="width: 120px; max-height: 100px">
                            <template v-slot:placeholder>
                              <v-progress-circular
                                indeterminate
                                color="smoke"
                                size="32"
                                width="3"></v-progress-circular>
                            </template>
                          </v-img>
                        </div>
                      </div>

                      <div style="margin-left: 5px">
                        <div class="body-1 font-weight-medium">
                          Update Banner
                        </div>
                        <div class="body-2 smoke--text">
                          PNG image is less than 1MB. and 400 x 1000px (
                          Landscape )
                        </div>
                        <div>
                          <v-btn
                            :loading="backgroundimg.isSelecting"
                            small
                            depressed
                            class="mt-3"
                            @click="onButtonLogoClick"
                            style="float: left">
                            Upload
                          </v-btn>

                          <input
                            ref="uploaderB"
                            class="d-none"
                            type="file"
                            accept="image/*"
                            @change="onFileLogoChanged" />
                        </div>

                        <v-btn
                          small
                          depressed
                          class="mt-3"
                          @click="openCreativePortal(update_image)"
                          style="float: left; margin-left: 10px">
                          Open Designer
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </div>
          </v-card-text>
        </v-card>
        <v-card
          style="margin-top: 15px; margin-bottom: 50px; border-radius: 20px">
          <v-card-title>
            <v-icon left class="mr-2">mdi-update</v-icon>
            App Updates
            <v-spacer></v-spacer>
            <v-icon class="ml-2">mdi-information-outline</v-icon>
          </v-card-title>

          <v-card-text class="appudates">
            <template v-if="errored">
              <p>An error occurred while fetching the settings.</p>
            </template>
            <template v-else>
              <v-row class="px-4">
                <v-col md="6" class="mb-5">
                  <h4>Emergency Updates</h4>
                  <v-switch
                    v-model="emergencyupdate_enable"
                    :label="
                      emergencyupdate_enable
                        ? 'Emergency Update Enabled.'
                        : 'Emergency Update Disabled.'
                    "
                    color="success"
                    inset
                    class="mb-4"></v-switch>
                  <v-text-field
                    v-model="required_android_build_number"
                    label="Required Android Build Number"
                    outlined
                    dense
                    color="primary"
                    hide-details
                    class="mb-4"
                    style="
                      border-radius: 15px;
                      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                      font-weight: bold;
                    "></v-text-field>
                  <v-text-field
                    v-model="required_ios_build_number"
                    label="Required iOS Build Number"
                    outlined
                    dense
                    color="primary"
                    hide-details
                    class="mb-4"
                    style="
                      border-radius: 15px;
                      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                      font-weight: bold;
                    "></v-text-field>
                </v-col>

                <v-col md="6" class="mb-5">
                  <h4>Regular Updates</h4>
                  <v-switch
                    v-model="regularupdate_enable"
                    :label="
                      regularupdate_enable
                        ? 'Regular Update Enabled.'
                        : 'Regular Update Disabled.'
                    "
                    color="success"
                    inset
                    class="mb-4"></v-switch>
                  <v-text-field
                    v-model="latest_android_build_number"
                    label="Latest Android Build Number"
                    outlined
                    dense
                    color="primary"
                    hide-details
                    class="mb-4"
                    style="
                      border-radius: 15px;
                      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                      font-weight: bold;
                    "></v-text-field>
                  <v-text-field
                    v-model="latest_ios_build_number"
                    label="Latest iOS Build Number"
                    outlined
                    dense
                    color="primary"
                    hide-details
                    class="mb-4"
                    style="
                      border-radius: 15px;
                      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                      font-weight: bold;
                    "></v-text-field>
                </v-col>
              </v-row>
            </template>
          </v-card-text>
        </v-card>
      </v-container>
      <v-dialog width="1200" scrollable v-model="creative">
        <!-- <div style="width:100%; height:600px; background-color:#141517; padding:25px"> -->
        <div
          style="
            width: 100%;
            height: 600px;
            background-color: #ffffff;
            padding: 25px;
          ">
          <iframe
            :src="iframe.src"
            :width="1150"
            :height="550"
            type="text/html"
            frameborder="0"
            scrolling="no"></iframe>
        </div>
      </v-dialog>
      <Footer ref="footer" :loading="updateLoading" @click="updateAppLinks" />
    </v-main>
  </v-layout>
</template>
<script>
import PageBar from "@/components/blocks/PageBar";
import Footer from "@/components/blocks/Footer";
//import axios from "axios";

export default {
  name: "Appsettings",
  components: {
    PageBar,
    Footer,
  },

  data: () => ({
    loading: true,
    updateLoading: false,
    btnLoad: false,
    info: null,
    errored: false,
    ios_link: "",
    android_link: "",
    huawei_link: "",
    update_enable: 0,
    update_notice: "",
    update_image: "",
    latest_version: 0,
    emergencyupdate_enable: 0,
    regularupdate_enable: 0,
    required_android_build_number: 0,
    required_ios_build_number: 0,
    latest_ios_build_number: 0,
    latest_android_build_number: 0,
    backgroundimg: {
      isSelecting: false,
      selectedFile: null,
      upload: false,
      loading: false,
    },
    creative: false,
    iframe: {
      src: "https://app.shopimint.com/backend/creative/generate",
    },
  }),
  methods: {
    getAppLinks() {
      this.loading = true;

      this.$http
        .get(
          `${this.$serverApiLink}api/account/apps/getadvancedsettings/${this.$route.params.uid}`
        )
        .then((response) => {
          this.settings = response.data.detail;
          console.log(response.data.detail);
          const data = response.data.detail;
          this.ios_link = data.ios_link;
          this.android_link = data.android_link;
          this.huawei_link = data.huawei_link;
          this.update_notice = data.update_notice;
          this.update_image = data.update_image;
          this.update_enable = data.update_enable == 1 ? true : false;
          this.emergencyupdate_enable =
            data.emergencyupdate_enable == 1 ? true : false;
          this.regularupdate_enable =
            data.regularupdate_enable == 1 ? true : false;
          this.latest_version = data.latest_version;
          this.required_android_build_number =
            data.required_android_build_number;
          this.required_ios_build_number = data.required_ios_build_number;
          this.latest_ios_build_number = data.latest_ios_build_number;
          this.latest_android_build_number = data.latest_android_build_number;
          console.log(this.latest_version);
          this.loading = false;
        })
        .catch((error) => {
          this.errored = true;
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.loading = false;
        });
    },
    updateAppLinks() {
      this.btnLoad = true;
      this.updateLoading = true;
      this.$store.commit("setLoading", true);
      let params = new FormData();
      params.set("ios_link", this.ios_link);
      params.set("android_link", this.android_link);
      params.set("huawei_link", this.huawei_link);
      params.set("update_notice", this.update_notice);
      params.set("update_enable", this.update_enable == true ? 1 : 0);
      params.set("latest_version", this.latest_version);
      params.set(
        "emergencyupdate_enable",
        this.emergencyupdate_enable == true ? 1 : 0
      );
      params.set(
        "regularupdate_enable",
        this.regularupdate_enable == true ? 1 : 0
      );
      params.set(
        "required_android_build_number",
        this.required_android_build_number
      );
      params.set("required_ios_build_number", this.required_ios_build_number);
      params.set("latest_ios_build_number", this.latest_ios_build_number);
      params.set(
        "latest_android_build_number",
        this.latest_android_build_number
      );

      this.$http
        .post(
          `${this.$serverApiLink}api/account/apps/updateadvancedsettings/${this.$route.params.uid}`,
          params,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((response) => {
          if (response.data.code == 200) {
            this.updateLoading = false;
            this.btnLoad = false;
            this.$store.commit("setLoading", false);
            // this.$store.commit("setSnackBar", {
            //   code: response.data.code,
            //   message: response.data.message,
            // });
            this.$refs.footer.showSuccessAlert();
            this.getAppLinks();
          }
        })
        .catch((error) => {
          this.updateLoading = false;
          this.btnLoad = false;
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.$store.commit("setLoading", false);
          this.getAppLinks();
        });
    },
    onButtonLogoClick() {
      this.backgroundimg.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.backgroundimg.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploaderB.click();
    },

    onFileLogoChanged(e) {
      this.backgroundimg.selectedFile = e.target.files[0];
      this.$store.commit("setLoading", true);
      this.backgroundimg.upload = true;
      let params = new FormData();
      params.set("updateimg", this.backgroundimg.selectedFile);
      this.$http
        .post(
          `${this.$serverApiLink}api/account/assets/upload_updateimagebanner/${this.$route.params.uid}`,
          params,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((response) => {
          this.update_image = response.data.uri;
          this.backgroundimg.upload = false;
          this.backgroundimg.isSelecting = false;
          this.$store.commit("setLoading", false);
          this.$emit("reload_preview", {
            uid: this.$route.params.uid,
            mode: "updateimg",
          });
          this.$refs.footer.showSuccessAlert();
          this.getAppLinks();
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.backgroundimg.upload = false;
          this.backgroundimg.isSelecting = false;
          this.$store.commit("setLoading", false);
          this.getAppLinks();
        });
    },
    openCreativePortal(imageurl) {
      this.iframe.src =
        "https://app.shopimint.com/backend/creative/generate?imageurl=" +
        imageurl;
      this.creative = true;
      console.log(this.iframe.src);
      //this.getSplashDetail();
    },
  },
  mounted() {
    this.getAppLinks();
  },
};
</script>
