<template>
  <svg width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.3534 19.01L18.692 23.6109C16.8009 25.4774 13.7609 25.4774 11.8698 23.6109L4.95453 16.7853C3.70395 15.5509 3 13.867 3 12.1099V7.85581C3 5.17402 5.17402 3 7.85581 3H12.2634C13.9908 3 15.6487 3.6804 16.8781 4.89387L23.3534 11.2851C25.4865 13.3906 25.509 16.8267 23.4035 18.9598C23.3786 18.985 23.3785 18.985 23.3534 19.01ZM21.9484 17.5865L21.9801 17.5549C23.3096 16.2079 23.2955 14.0381 21.9484 12.7085L15.4732 6.31729C14.6181 5.47325 13.4649 5 12.2634 5H7.85581C6.27859 5 5 6.27859 5 7.85581V12.1099C5 13.3321 5.48963 14.5033 6.35948 15.3619L13.2748 22.1875C14.3869 23.2852 16.1749 23.2852 17.287 22.1875L21.9484 17.5865ZM10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10C12 11.1046 11.1046 12 10 12Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'TagIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>