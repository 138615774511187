<template>
    <div class="container container--fluid">
     
          <div class="pa-4 helparticle" id="app" v-for="post in posts" :key="post.id" >

            <div class="row">
                <div class="col-md-12" style="padding-top: 50px;">
                  <h1 style="padding-bottom: 20px;">How it works </h1>
                </div>
                <div class="col-md-9">
                  <div class="currency"  >
                    <div v-html="post.html"  class="harticle gh-content"></div>
                    <span class="lighten">
                    </span>
                  </div>
                </div>

                <div class="col-md-3" ref="123" >
                  <ul>
                    <li style="padding-bottom: 5px;font-weight: 700;">Page Contents</li>
                    <li style="border-bottom: 1px solid rgb(160, 160, 160, 0.5); padding-top: 5px;" v-for="topic in helpcentertopic" :key="topic.name"> 
                      <a @click="scrollMeTo(topic.link)">{{topic.name}}</a>
                    </li>
                  </ul>
                </div>
            </div>
      </div>
      
    </div>
</template>

<script>

import axios from 'axios';

export default {
    name: 'HelpArticle',
    props: {
        id: String,
        helpcentertopic: Array ,
    },
    data: () => ({
      posts: [],
      loading: true,
      btnLoad: false,
      reissue: false,
      btn_load: false,
    }),
    methods: {
      getarticle(){
          axios
          .get(`https://help.shopimint.com/ghost/api/content/posts/${this.id}/?key=a7fb031a0308d87b3e2c512b6c&include=tags`)
          
          .then(response => {
              this.posts = response.data.posts;
              // console.log("Thehe")
              // console.log(this.id)
              this.loading = false;
          })
          .catch(error => {
              console.log(error)
              console.log("There is a error loading article")
              this.errored = true
          })
          .finally(() => this.loading = false)
      },
      scrollMeTo(refName) {
        var element = this.$refs[refName];
        var top = element.offsetTop;

        window.scrollTo(0, top);
      }
    },
    mounted() {
      this.getarticle();
    }
}
</script>