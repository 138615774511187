<template>
  <!-- <div class="font-weight-bold caption black--text mb-5 mt-2">
    
  </div> -->
  <v-col  class="pb-5">
      <h4>{{ title }}</h4>
  </v-col>
</template>

<script>
export default {
  name: 'Title',
  components: {

  },
  props: {
    title: {
      type: String,
      required: false
    },
  },
  data: () => ({

  }),
  methods: {

  },
}
</script>