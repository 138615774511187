export const countries = [
    
    { text: "Afghanistan", value: "Afghanistan" },
    { text: "Albania", value: "Albania" },
    { text: "Algeria", value: "Algeria " },
    { text: "Andorra", value: "Andorra" },
    { text: "Angola", value: "Angola" },
    { text: "Antigua and Barbuda", value: "Antigua and Barbuda" },
    { text: "Argentina", value: "Argentina " },
    { text: "Armenia", value: "Armenia" },
    { text: "Australia", value: "Australia " },
    { text: "Austria", value: "Austria" },
    { text: "Azerbaijan", value: "Azerbaijan " },
    { text: "Bahamas", value: "Bahamas" },
    { text: "Bahrain", value: "Bahrain" },

    { text: "Bangladesh", value: "Bangladesh" },
    { text: "Barbados", value: "Barbados" },
    { text: "Belarus", value: "Belarus " },
    { text: "Belgium", value: "Belgium" },
    { text: "Belize", value: "Belize" },
    { text: "Benin", value: "Benin" },
    { text: "Bhutan", value: "Bhutan " },
    { text: "Bolivia", value: "Bolivia " },
    { text: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
    { text: "Botswana", value: "Botswana " },
    { text: "Brazil", value: "Brazil" },
    { text: "Brunei", value: "Brunei" },
    { text: "Bulgaria", value: "Bulgaria" },
    { text: "Burkina Faso", value: "Burkina Faso" },
    { text: "Burundi", value: "Burundi " },
    { text: "Côte d'Ivoire", value: "Côte d'Ivoire" },
    { text: "Cabo Verde", value: "Cabo Verde" },
    { text: "Cambodia", value: "Cambodia" },
    { text: "Cameroon", value: "Cameroon " },
    { text: "Canada", value: "Canada" },
    { text: "Central African Republic", value: "Central African Republic " },
    { text: "Chad", value: "Chad" },
    { text: "Chile", value: "Chile " },
    { text: "China", value: "China" },
    { text: "Colombia", value: "Colombia" },
    { text: "Comoros", value: "Comoros" },
    { text: "Congo (Congo-Brazzaville)", value: "Congo (Congo-Brazzaville)" },
    { text: "Costa Rica", value: "Costa Rica " },
    { text: "Croatia", value: "Croatia" },
    { text: "Cuba", value: "Cuba" },
    { text: "Cyprus", value: "Cyprus" },
    { text: "Czechia (Czech Republic)", value: "Czechia (Czech Republic) " },
    { text: "Democratic Republic of the Congo", value: "Democratic Republic of the Congo" },
    { text: "Denmark", value: "Denmark " },
    { text: "Djibouti", value: "Djibouti" },
    { text: "Dominica", value: "Dominica " },
    { text: "Dominican Republic", value: "Dominican Republic" },
    { text: "Ecuador", value: "Ecuador" },
    { text: "Egypt", value: "Egypt" },
    { text: "El Salvador", value: "El Salvador" },
    { text: "Equatorial Guinea", value: "Equatorial Guinea " },
    { text: "Eritrea", value: "Eritrea" },
    { text: "Estonia", value: "Estonia" },
    { text: "Eswatini (fmr. 'Swaziland')", value: "Eswatini (fmr. 'Swaziland')" },
    { text: "Ethiopia", value: "Ethiopia " },
    { text: "Fiji", value: "Fiji" },
    { text: "Finland", value: "Finland " },
    { text: "France", value: "France" },
    { text: "Gabon", value: "Gabon " },
    { text: "Gambia", value: "Gambia" },
    { text: "Georgia", value: "Georgia" },
    { text: "Germany", value: "Germany" },
    { text: "Ghana", value: "Ghana" },
    { text: "Greece", value: "Greece " },
    { text: "Grenada", value: "Grenada" },
    { text: "Guatemala", value: "Guatemala" },
    { text: "Guinea", value: "Guinea" },
    { text: "Guinea-Bissau", value: "Guinea-Bissau " },
    { text: "Guyana", value: "Guyana" },
    { text: "Haiti", value: "Haiti " },
    { text: "Holy See", value: "Holy See" },
    { text: "Hungary", value: "Hungary " },
    { text: "Iceland", value: "Iceland" },
    { text: "India", value: "India" },
    { text: "Indonesia", value: "Indonesia" },
    { text: "Iran", value: "Iran" },
    { text: "Iraq", value: "Iraq " },
    { text: "Ireland", value: "Ireland" },
    { text: "Israel", value: "Israel" },
    { text: "Italy", value: "Italy" },
    { text: "Jamaica", value: "Jamaica " },
    { text: "Japan", value: "Japan" },
    { text: "Jordan", value: "Jordan " },
    { text: "Kazakhstan", value: "Kazakhstan" },
    { text: "Kenya", value: "Kenya " },
    { text: "Kiribati", value: "Kiribati" },
    { text: "Kuwait", value: "Kuwait" },
    { text: "Kyrgyzstan", value: "Kyrgyzstan" },
    { text: "Laos", value: "Laos" },
    { text: "Latvia", value: "Latvia " },
    { text: "Lebanon", value: "Lebanon" },
    { text: "Lesotho", value: "Lesotho" },
    { text: "Liberia", value: "Liberia" },
    { text: "Libya", value: "Libya " },
    { text: "Liechtenstein", value: "Liechtenstein" },
    { text: "Lithuania", value: "Lithuania " },
    { text: "Luxembourg", value: "Luxembourg" },
    { text: "Madagascar", value: "Madagascar " },
    { text: "Malawi", value: "Malawi" },
    { text: "Malaysia", value: "Malaysia" },
    { text: "Maldives", value: "Maldives" },
    { text: "Mali", value: "Mali" },
    { text: "Malta", value: "Malta " },
    { text: "Marshall Islands", value: "Marshall Islands" },
    { text: "Mauritania", value: "Mauritania" },
    { text: "Mauritius", value: "Mauritius" },
    { text: "Mexico", value: "Mexico " },
    { text: "Micronesia", value: "Micronesia" },
    { text: "Moldova", value: "Moldova " },
    { text: "Moldova", value: "Moldova" },
    { text: "Mongolia", value: "Mongolia " },
    { text: "Montenegro", value: "Montenegro" },
    { text: "Morocco", value: "Morocco" },
    { text: "Mozambique", value: "Mozambique" },
    { text: "Myanmar (formerly Burma)", value: "Myanmar (formerly Burma)" },
    { text: "Namibia", value: "Namibia " },
    { text: "Nauru", value: "Nauru" },
    { text: "Nepal", value: "Nepal" },
    { text: "Netherlands", value: "Netherlands" },
    { text: "New Zealand", value: "New Zealand " },
    { text: "Nicaragua", value: "Nicaragua" },
    { text: "Niger", value: "Niger " },
    { text: "Nigeria", value: "Nigeria" },
    { text: "North Korea", value: "North Korea " },
    { text: "North Macedonia", value: "North Macedonia" },
    { text: "Norway", value: "Norway" },
    { text: "Oman", value: "Oman" },
    { text: "Pakistan", value: "Pakistan" },
    { text: "Palau", value: "Palau " },
    { text: "Palestine State", value: "Palestine State" },
    { text: "Panama", value: "Panama" },
    { text: "Papua New Guinea", value: "Papua New Guinea" },
    { text: "Paraguay", value: "Paraguay " },
    { text: "Peru", value: "Peru" },
    { text: "Philippines", value: "Philippines " },
    { text: "Poland", value: "Poland" },
    { text: "Portugal", value: "Portugal " },
    { text: "Qatar", value: "Qatar" },
    { text: "Romania", value: "Romania" },
    { text: "Russia", value: "Russia" },
    { text: "Rwanda", value: "Rwanda" },
    { text: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis " },
    { text: "Saint Lucia", value: "Saint Lucia" },
    { text: "Saint Vincent and the Grenadines", value: "Saint Vincent and the Grenadines" },
    { text: "Samoa", value: "Samoa" },
    { text: "San Marino", value: "San Marino " },
    { text: "Sao Tome and Principe", value: "Sao Tome and Principe" },
    { text: "Saudi Arabia", value: "Saudi Arabia " },
    { text: "Senegal", value: "Senegal" },
    { text: "Serbia", value: "Serbia " },
    { text: "Seychelles", value: "Seychelles" },
    { text: "Seychelles Leone", value: "Seychelles Leone" },
    { text: "Singapore", value: "Singapore" },
    { text: "Slovakia", value: "Slovakia" },
    { text: "Slovenia", value: "Slovenia " },
    { text: "Solomon Islands", value: "Solomon Islands" },
    { text: "Somalia", value: "Somalia" },
    { text: "South Africa", value: "South Africa" },
    { text: "South Korea", value: "South Korea " },
    { text: "South Sudan", value: "South Sudan" },
    { text: "Spain", value: "Spain " },
    { text: "Sri Lanka", value: "Sri Lanka" },
    { text: "Sudan", value: "Sudan " },
    { text: "Suriname", value: "Suriname" },
    { text: "Sweden", value: "Sweden" },
    { text: "Switzerland", value: "Switzerland" },
    { text: "Syria", value: "Syria" },
    { text: "Tajikistan", value: "Tajikistan " },
    { text: "Tanzania", value: "Tanzania" },
    { text: "Thailand", value: "Thailand" },
    { text: "Timor-Leste", value: "Timor-Leste" },
    { text: "Togo", value: "Togo " },
    { text: "Tonga", value: "Tonga" },
    { text: "Trinidad and Tobago", value: "Trinidad and Tobago " },
    { text: "Tunisia", value: "Tunisia" },
    { text: "Turkey", value: "Turkey " },
    { text: "Turkmenistan", value: "Turkmenistan" },
    { text: "Tuvalu", value: "Tuvalu" },
    { text: "Uganda", value: "Uganda" },
    { text: "Ukraine", value: "Ukraine" },
    { text: "United Arab Emirates", value: "United Arab Emirates " },
    { text: "United Kingdom", value: "United Kingdom" },
    { text: "United States of America", value: "United States of America" },
    { text: "Uruguay", value: "Uruguay" },
    { text: "Uzbekistan", value: "Uzbekistan " },
    { text: "Vanuatu", value: "Vanuatu" },
    { text: "Venezuela", value: "Venezuela " },
    { text: "Vietnam", value: "Vietnam" },
    { text: "Yemen", value: "Yemen " },
    { text: "Zambia", value: "Zambia" },
    { text: "Zimbabwe", value: "Zimbabwe" },
];

