<template>
  <v-layout fill-height>
    <v-main>
      <PageBar
          title="Productpage"
          :drawer="true"
          pagetype="pages"
      />
      
      <Loader v-if="loading"/>
      <Container v-else>
        <!-- <PageSettingNavBar :name="currentpage" /> -->

        <div class="row">
          <h3 class="col">Customise Product Page </h3>
          <HelpArticle2  id="63242954b78f9a796bdea96e"/>
        </div>

        <v-row >
          <v-col md="12" style="padding:10px 0px 0px 12px;">
              <h4></h4>
            </v-col>
            <v-col md="12" sm="12" cols="12" >
              <h5>Select Slider Style</h5>
              <v-item-group
                  mandatory
                  v-model="page.productSliderStyle"
                  class="row"
              >
                <div
                    v-for="(item, i) in productsliderstyles"
                    :key="`${i}_app_template_layout_builder`"
                    class="col-md-2"
                >
                  <img
                      :src="item.image"
                      :class="page.productSliderStyle === item.value ? 'app_template active' : 'app_template'"
                      @click="page.productSliderStyle = item.value"
                      style="width:100%"
                  />
                  <div class="caption text-uppercase">
                    {{ item.name }}
                  </div>
                </div>
              </v-item-group>
            </v-col>
            
          </v-row>
            <v-row>
              <v-col md="4" sm="4" cols="4" >
              <div class="componentslider">
                <v-lable>
                  <span class="sliderlable"
                    >Image Height Ratio : {{page.productImageHeightRatio}}</span
                  >
                </v-lable>
                <v-slider
                  v-model="page.productImageHeightRatio"
                  step="0.25"
                  min="0.75"
                  max="2"
                  inverse-label
                  color="#000000"
                  track-color="rgb(0 0 0 / 12%)"
                ></v-slider>
              </div>
            </v-col>
            </v-row>
            <v-row>
            
              <v-col md="4" sm="4" cols="4" >
              <v-select
                  :items="productviewstyle"
                  dense
                  hide-details
                  label="Product View Style"
                  outlined
                  v-model="page.productPagePopupStyle"
              ></v-select>
            </v-col> 
            <v-col md="4" sm="4" cols="4" >
              <v-select
                  :items="enable"
                  dense
                  hide-details
                  label="Show Brands"
                  outlined
                  v-model="page.productShowBrand"
              ></v-select>
            </v-col>
           
            <v-col md="4" sm="4" cols="4" >
              <v-select
                  :items="enable"
                  dense
                  hide-details
                  label="Show Share Icon"
                  outlined
                  v-model="page.productShowShareIcon"
              ></v-select>
            </v-col>
            
            <v-col md="4" sm="4" cols="4" >
              <v-select
                  :items="enable"
                  dense
                  hide-details
                  label="Enable AR"
                  outlined
                  v-model="page.productShowAR"
              ></v-select>
            </v-col>
            <v-col md="4" sm="4" cols="4" >
              <v-select
                  :items="enable"
                  dense
                  hide-details
                  label="Wishlist Icon"
                  outlined
                  v-model="page.productShowWishlist"
              ></v-select>
            </v-col>
            <v-col md="4" sm="4" cols="4" >
              <v-select
                  :items="enable"
                  dense
                  hide-details
                  label="Show Installment Plan Banner"
                  outlined
                  v-model="page.productShowInstallmentBanner"
              ></v-select>
            </v-col>
            <v-col md="4" sm="4" cols="4" >
              <v-select
                  :items="enable"
                  dense
                  hide-details
                  label="Show Upsell Items"
                  outlined
                  v-model="page.productShowUpsellItems"
              ></v-select>
            </v-col>
            <v-col md="4" sm="4" cols="4" v-if="page.productShowUpsellItems == 1">
              <v-select
                  :items="upsell"
                  dense
                  hide-details
                  label="Select Upsell Items"
                  outlined
                  v-model="page.productItems"
              ></v-select>
            </v-col>
            <v-col md="4" sm="4" cols="4" >
              <v-select
                  :items="enable"
                  dense
                  hide-details
                  label="Show Stock Notify Button"
                  outlined
                  v-model="page.productShowStockNotifyButton"
              ></v-select>
            </v-col>
            <v-col md="4" sm="4" cols="4" >
              <v-select
                  :items="enable"
                  dense
                  hide-details
                  label="Show Product Description in product page."
                  outlined
                  v-model="page.productShowDescription"
              ></v-select>
            </v-col>
            <v-col md="4" sm="4" cols="4" >
              <v-text-field
                  label="Add to Cart button Name"
                  outlined
                  color="primary"
                  dense
                  hide-details
                  v-model="page.productAddtoCartButtonName"
              ></v-text-field>
            </v-col>
            <v-col md="4" sm="4" cols="4" >
              <v-text-field
                  label="Out of Stock Message"
                  outlined
                  color="primary"
                  dense
                  hide-details
                  v-model="page.productOutOfStockMessage"
              ></v-text-field>
            </v-col>
            
          </v-row>
          
          <v-row>

            <v-col md="6" >
              <h3>Product Page Navigation</h3>
            </v-col>
            <v-col md="6" >
              <v-btn
                small
                depressed
                class="mt-3"
                @click="addNewProductNav"
                style="float: right"
              >
                Add New Item
              </v-btn>
            </v-col>

            <v-col md="12" >

              <div class="d-flex" style="border:1px solid black" v-for="(element, idx) in productnavlist" :key="element.id" >

                  <div class="col-3">
                    <v-text-field
                      label="Display Name"
                      type="text"
                      outlined
                      color="primary"
                      dense
                      hide-details
                      persistent-hint
                      rounded
                      height="1"
                      v-model="element.name"
                    >
                    </v-text-field>
                  </div>

                  <div class="col-3">
                    <v-select
                        :items="apptypes"
                        dense
                        hide-details
                        label="Select Navigation Type"
                        outlined
                        v-model="element.type"
                    ></v-select>
                  </div>
                  
                  <div class="col-4">
                    <v-text-field
                      v-if="element.type == 'internal' || element.type == 'external' "
                      label="Link"
                      type="text"
                      outlined
                      color="primary"
                      dense
                      hide-details
                      persistent-hint
                      rounded
                      height="1"
                      v-model="element.value"
                    >
                    </v-text-field>
                  </div>

                  <div class="col-2">
                    <v-btn icon small color="danger" @click="productnavlist.splice(idx, 1);" >
                        <DeleteMiniIcon :size="16"/>
                    </v-btn>
                  </div>

              </div>

            </v-col>
          </v-row>

      </Container>
      <Footer
          v-if="!loading"
          :loading="btnLoad"
          ref="footer"
          @click="updateSplash"
      />
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Container from "@/components/blocks/Container";
import Loader from "@/components/blocks/Loader";
import Footer from "@/components/blocks/Footer";

import Sliderstyle2 from "@/assets/images/productcards/imagesliderwiththumbnails.png";
import Sliderstyle1 from "@/assets/images/productcards/imagesliderwithdots.png";
import HelpArticle2 from "../../../../components/blocks/HelpArticle2.vue";
import DeleteMiniIcon from "../../../../components/icons/DeleteMiniIcon.vue";

export default {

  components: {
    PageBar,
    Container,
    Loader,
    Footer,
    HelpArticle2,
    DeleteMiniIcon
},
  data: () => ({
    loading: false,
    btnLoad: false,
    productnavlist: [],
    page: {
      productPageStyle                  :""  ,
      productImageHeightRatio           :""  ,
      productPagePopupStyle             :""  ,
      productSliderStyle                :""  ,
      productShowBrand                  :""  ,
      productShowAR                     :""  ,
      productShowShareIcon              :""  ,
      productShowWishlist               :""  ,
      productShowInstallmentBanner      :""  ,
      productShowUpsellItems            :""  ,
      productItems                      :""  ,
      productShowStockNotifyButton      :""  ,
      productOutOfStockMessage          :""  ,
      productAddtoCartButtonName        :""  ,
      productShowDescription            :""  ,
    },
    productsliderstyles: [
      {
        image: Sliderstyle2,
        name: "Image Slider With Thumbnails",
        value: "style_1"
      },
      {
        image: Sliderstyle1,
        name: "Image Slider With Dots",
        value: "style_2"
      }
    ],
  }),
  computed: {
    enable: function () {
      return [
        {
          text: 'Enable',
          value: '1'
        },
        {
          text: 'Disable',
          value: '0'
        },
      ];
    },
    productviewstyle: function () {
      return [
        {
          text: 'Full Screen',
          value: 'style1'
        },
        {
          text: 'Bottom Sheet',
          value: 'style2'
        },
      ];
    },
    upsell : function () {
      return [
        {
          text : 'Recent Items',
          value : 'recent'
        },
        {
          text : 'Most Viewed',
          value : 'mostviewed'
        },
        {
          text : 'Simillar Items',
          value : 'simillar'
        },
      ]
    },
    apptypes : function () {
      return [
        {
          text : 'Chat Page',
          value : 'chatPage'
        },
        {
          text : 'Product Description',
          value : 'htmlPage'
        },
        {
          text : 'Reviews',
          value : 'reviews'
        },
        {
          text : 'Internal Web Page',
          value : 'internal'
        },
        {
          text : 'External Web Page',
          value : 'external'
        },
      ]
    }
  },
  methods: {

    addNewProductNav(){
      this.productnavlist.push({
        type:  'internal',
        name:  'Change This Name',
        value: 'https://shopimint.com'
      });
    },
    updateSplash() {
      this.btnLoad = true;
      let params = new URLSearchParams();
      params.append('productImageHeightRatio', this.page.productImageHeightRatio);
      params.append('productSliderStyle', this.page.productSliderStyle);
      params.append('productShowBrand', this.page.productShowBrand);
      params.append('productPagePopupStyle', this.page.productPagePopupStyle);
      params.append('productShowAR', this.page.productShowAR);
      params.append('productShowWishlist', this.page.productShowWishlist);
      params.append('productShowInstallmentBanner', this.page.productShowInstallmentBanner);
      params.append('productShowUpsellItems', this.page.productShowUpsellItems);
      params.append('productShowStockNotifyButton', this.page.productShowStockNotifyButton);
      params.append('productOutOfStockMessage', this.page.productOutOfStockMessage);
      params.append('productAddtoCartButtonName', this.page.productAddtoCartButtonName);
      params.append('productShowDescription', this.page.productShowDescription);
      params.append('productShowShareIcon', this.page.productShowShareIcon);
      params.append('productItems', this.page.productItems);
      params.append('productnavlist', JSON.stringify(this.productnavlist));
      console.log(params);
      this.$http.post(`${this.$serverApiLink}api/account/pagesettings/update_productpage/${this.$route.params.uid}`, params).
      then(
          // eslint-disable-next-line no-unused-vars
          response => {
            this.btnLoad = false;
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "productpage"
            });
            this.$refs.footer.showSuccessAlert();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.btnLoad = false;
          }
      );
    },
    getPageSettings() {
      this.$http.get(`${this.$serverApiLink}api/account/pagesettings/detail/${this.$route.params.uid}`).
      then(
          response => {
            this.page = response.data.detail;
            this.productnavlist = JSON.parse(this.page.product_page_nav);
            this.loading = false;  
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
          }
      );
    },
    
  },
  mounted() {
    this.getPageSettings();
  }
}
</script>

<style>

.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.card {
  flex-direction: row;
  background: #fff;
  padding: 10px;
  color:black;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border-radius: 10px;
}

.theme--dark .card{
  background-color: rgb(29, 29, 44);
  color:white;
}

.card img {
  width: 30%;
}

.v-text-field input {
  font-size: 0.9em;
  color: black;
  font-weight: bold;
  padding: 0px;;
}

.v-select {
  font-size: 0.9em;
  color: black;
  font-weight: bold;
  padding: 0px;;
}

::v-deep .v-label {
  font-size: 0.9em;
}

::v-deep .v-label--active {
  font-size: 0.9em;
  font-weight: bold;
}

.pagesmenubar {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.pagesmenubar li{
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

</style>