<template>
    <v-layout fill-height>
    <v-main>
      <PageBar :title="$tr('menu', 'key_e8')" :drawer="true" />
      <Loader v-if="loading" />
      <template>
        <!--  -->
          <v-row class="mb-6">
            <v-col md="12" sm="12" cols="12">
              <div>
                <v-parallax dark src="https://static.vecteezy.com/system/resources/previews/004/750/609/original/flat-design-abstract-background-soft-liquid-shapes-template-with-modern-gradient-background-colors-cool-aesthetic-background-design-suitable-for-social-media-post-mobile-app-banner-web-ads-free-vector.jpg" height="200">
                  <v-row align="center" justify="center">
                    <v-col class="text-center" cols="12">
                      <h2 class="text-h5 font-weight-medium mb-4" style="color:black;">
                        {{ $tr('project', 'key_e31') }}
                      </h2>
                      <h4 class="paragraph" style="color:grey; font-weight: 400;">
                        {{ $tr('project', 'key_e32') }}
                      </h4>
                    </v-col>
                  </v-row>
                </v-parallax>
              </div>
            </v-col>
          </v-row>

        <Container v-if="!tab">
          <Container class="row">
            <div v-for="(item, index) in intergration" :key="'blog_' + index" class="col-lg-4 col-sm-6 col-12">
                <MarketingCard :item="item"/>
            </div>
          </Container>
        </Container>

      </template>
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import MarketingCard from "@/components/blocks/MarketingCard.vue";
import Container from "../../../../components/blocks/Container.vue";

export default {
  name: 'Marketing',
  components: {
    PageBar,
    MarketingCard,
    Container
},
  data: () => ({
    loading: false,
    uid: '',
    intergration: [
      {
        "id": 5,
        "name": "Push Notifications",
        "description": "Push Notifications",
        "route_name": "Pushnotification",
        "photo": "https://app.shopimint.com/backend/static/marketing/image.png"
      },
      {
        "id": 3,
        "name": "App Walkthrough",
        "description": "App Walkthrough",
        "route_name": "Walkthrough",
        "photo": "https://app.shopimint.com/backend/static/marketing/walk.jpg"
      },
      {
        "id": 4,
        "name": "Marketing Event Notifications",
        "description": "Marketing Event Notifications",
        "route_name": "Eventnotifications",
        "photo": "https://app.shopimint.com/backend/static/marketing/marketing.jpg"
      },
      
    ],
    picker: {
      status: false,
      mode: "theme",
      color: "#ffffff"
    },
    tab: 0,
    app: {
      color_theme: "#F44336",
      color_title: 0,
      loader: 0,
      pull_to_refresh: 0,
      loader_color: "#F44336",
      template: 0,
      btn_color: "#F44336",
      display_title: 0,
      icon_color: "#F44336",
      active_color: "#F44336",
    }
  }),
  computed: {
    titles: function () {
      return [
        {
          text: this.$tr('project', 'key_37'),
          value: 0
        },
        {
          text: this.$tr('project', 'key_38'),
          value: 1
        }
      ];
    },
  },
  methods: {
    getuid(){
      this.uid = this.$route.params.uid;
    },
    getIntergrations() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/intergrations/getintergrations/${this.$route.params.id}`).
        then(
          response => {
            this.intergration = response.data.intergration;
            this.loading = false;
          }
        ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
        );
    },
    startUpdate() {
      if (!this.tab) {
        this.updateMain();
      } else if (this.tab === 1) {
        this.updateDrawer();
      }
    },
    updateTab(value) {
      this.tab = value;
    }
  },
  mounted() {
    this.getuid();
    // this.getIntergrations();
  }
}
</script>