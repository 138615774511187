<template>
  <v-card flat tile>
    <v-card-title class="pa-0">
      <v-toolbar
          flat
          class="modal_bar"
      >
        <v-toolbar-title>
          {{ $tr('project', !itemName.length ? 'key_e45' : 'key_e44') }}
        </v-toolbar-title>
        <v-spacer/>
        <v-btn
            icon
            @click="$emit('close')"
        >
          <CancelIcon/>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-divider/>
    <v-card-text class="pa-0">
      <v-container fluid>
        <v-row>
          <v-col md="12" sm="12" cols="12" class="pb-0">
            <v-text-field
                :label="$tr('project', 'key_55')"
                outlined
                color="primary"
                dense
                autofocus
                v-model="name"
            ></v-text-field>
            <v-textarea
                :label="$tr('project', 'key_405')"
                outlined
                color="primary"
                dense
                autofocus
                v-model="description"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="8" sm="8" cols="8" class="pb-0">
            <h3>Manage Slides</h3>
          </v-col>
          <v-col md="4" sm="4" cols="4" class="pb-0 text-right">
            <v-btn
              small
              depressed
              color="primary"
              outlined
              class="mb-5"
              @click="add" 
              >Add Slides
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="12">

             <!-- <draggable class="dragArea2 list-group" :list="list" group="people" v-bind="dragOptions"> -->
             <v-expansion-panels>
             <div 
                  class="col-sm-12" 
                  v-for="(element,idx) in list" 
                  :key="element.id">

                <v-expansion-panel>
                <!-- <v-expansion-panel
                  class="col-sm-12" 
                  v-for="element in list" 
                  :key="element.id"
                > -->
                    <v-expansion-panel-header>
                        <img v-bind:src="element.image" class="col-6" style="border-radius:25px;" />
                        <span style="font-size: 12px; font-weight: bold;">{{ element.name }}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div class="row">
                          <div class="col-md-12">
                              <h5>Change Image</h5>
                              <div class="permissions_block mb-3" >
                                <div class="d-flex justify-space-between align-center pa-4">
                                  <div class="row justify-space-between align-center">
                                      <div v-if="! element.loading" class="col-12">
                                        <input
                                          ref="uploaderA"
                                          type="file"
                                          accept="image/*"
                                          @change="onFileUploadChanged(element,$event)"
                                          style="width:250px; overflow: hidden;"
                                        >
                                      </div>
                                      <div
                                          style="width: 32px; height: 32px"
                                          class="col-6"
                                          v-else
                                      >
                                        <v-progress-circular
                                          indeterminate
                                          color="blue"
                                          size="32"
                                          width="3"
                                        ></v-progress-circular>
                                      </div>
                                  </div>
                                  <v-btn icon small color="danger" @click="list.splice(idx, 1);" >
                                        <DeleteMiniIcon :size="16"/>
                                    </v-btn>
                                </div>
                              </div>
                          </div>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </div>
            </v-expansion-panels> 
            <!-- </draggable> -->
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col md="12">
             <RawDisplayer class="col-md-6" :value="list" title="List" />
          </v-col>
        </v-row> -->
        
      </v-container>
      <v-divider/>
    </v-card-text>
    <v-container fluid class="text-right">
      <v-btn color="primary" :loading="loading" depressed @click="save">
        {{ $tr('project', 'key_173') }}
      </v-btn>
    </v-container>
  </v-card>
</template>

<script>
import CancelIcon from "@/components/icons/CancelIcon";
// import VueUploadMultipleImage from 'vue-upload-multiple-image';
// import RawDisplayer from "@/components/blocks/RawDisplayer";
// import draggable from "vuedraggable";
import DeleteMiniIcon from "@/components/icons/DeleteMiniIcon";
// import ImageIcon from "@/components/icons/ImageIcon";
// import Loader from "@/components/blocks/Loader";
let id = 3;

export default {
  name: 'NewGallery',
  components: {
    CancelIcon,
    // VueUploadMultipleImage,
    // RawDisplayer,
    // draggable,
    DeleteMiniIcon
    // Loader,
    // ImageIcon
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    itemName: {
      type: String,
      default: ""
    },
    itemDescription: {
      type: String,
      default: ""
    },
    itemAction: {
      type: Number,
      default: 0
    },
    itemIcon: {
      type: String,
      default: "albums-outline"
    },
    itemLink: {
      type: String,
      default: ""
    },
  },
  data: () => ({
    name: "",
    description: "",
    action: 0,
    icon: "albums-outline",
    link: "",
    search: "",
    selectedIcon: "",
    dialog: false,
    images: [],
    btnLoad: false,
    list: [],
    background_image: {
      isSelecting: false,
      selectedFile: null,
      upload: false,
      loading: false
    },
  }),
  computed: {
    actions: function () {
      return [
        {
          text: this.$tr('project', 'key_57'),
          value: 0
        },
        {
          text: this.$tr('project', 'key_58'),
          value: 1
        },
        {
          text: this.$tr('project', 'key_59'),
          value: 2
        },
        {
          text: this.$tr('project', 'key_60'),
          value: 3
        },
        {
          text: this.$tr('project', 'key_298'),
          value: 4
        },
        {
          text: this.$tr('project', 'key_328'),
          value: 5
        },
      ];
    },
    label: function () {
      if (this.action === 0 || this.action === 1) {
        return this.$tr('project', 'key_61')
      } else if (this.action === 2 || this.action === 5) {
        return this.$tr('project', 'key_48')
      } else if (this.action === 3) {
        return this.$tr('auth', 'key_5')
      } else {
        return this.$tr('project', 'key_301')
      }
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    removeAt(idx) {
      this.list.splice(idx, 1);
    },
    add: function() {
      id++;
      this.list.push({ 
        name: "Image " + id, 
        id , image: 'https://via.placeholder.com/600x300.png?text=Shopimint+-+Sample%20Slide' , 
        text: "" , 
        random: Math.random().toString(36).slice(2) , 
        loading:false 
      });
    },
    updateIcon(icon) {
      this.icon = icon;
      this.dialog = false;
    },
    save() {
      // alert(JSON.stringify(this.list));
      this.$emit("save", {
        name: this.name,
        description: this.description,
        link: JSON.stringify(this.list),
        icon: this.icon,
        action: this.action
      });
    },
    clean() {
      this.name = "";
      this.description="",
      this.link = "[{}]";
      this.action = 0;
      this.icon = "albums-outline";
      this.search = "";
    },
    onButtonUploadClick() {
      this.background_image.isSelecting = true;
      window.addEventListener('focus', () => {
        this.background_image.isSelecting = false
      }, {once: true});
      // this.$refs.uploaderA.click();
    },
    onFileUploadChanged(item, e) {
      
      item.loading = true;
      this.background_image.selectedFile = e.target.files[0];
      // this.$store.commit('setLoading', true);
      let params = new FormData();
      params.set('slides', this.background_image.selectedFile );
      this.$http.post(
          `${this.$serverApiLink}api/account/assets/upload_walkthrough/${this.$route.params.uid}`, params, {headers: {'Content-Type': 'multipart/form-data' }}).
      then(
          response => {
            console.log(item);
            item.image = response.data.slides;
            item.loading = false;
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "splash"
            });
          }
      ).catch(
          error => {
            item.loading = false;
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.background_image.upload = false;
            // this.$store.commit('setLoading', false);
          }
      );
    },
  },
  beforeMount() {
    this.name = this.itemName;
    this.action = this.itemAction;
    this.description = this.itemDescription;
    this.icon = this.itemIcon;
    this.link = this.itemLink;
    this.list = JSON.parse(this.itemLink);
  },
  mounted() {
  }
}
</script>

<style scoped>
#my-strictly-unique-vue-upload-multiple-image {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  width: 100%;
}

.image-container{
  width: 100%;
}

.file-select > .select-button {
  padding: 1rem;

  color: white;
  background-color: #2EA169;

  border-radius: .3rem;

  text-align: center;
  font-weight: bold;
}

.file-select > input[type="file"] {
  display: none;
}
</style>