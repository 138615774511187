<template>
  <v-layout fill-height>
    <v-main>
      <PageBar
        :title="$tr('menu', 'key_11')"
        :drawer="true"
        @onChange="updateTab">
      </PageBar>

      <template v-if="tabs2">
        <v-tabs align-with-title v-model="tabModel2" @change="updateTab">
          <v-tabs-slider color="primary" />
          <v-tab v-for="(tab, i) in tabs2" :key="`${i}_page_tab`">
            {{ tab }}
          </v-tab>
        </v-tabs>
      </template>

      <Container style="padding-bottom: 10px" v-if="!tab">
        <v-row
          class="welcomearea"
          style="align-items: center; justify-content: center">
          <div class="md-4 text-center">
            <h4>Welcome to Shopimint App Builder</h4>
          </div>
          <div class="md-4 text-center" style="text-align: center">
            <a
              href="https://help.shopimint.com/app-builder-basic-design-guidelines/"
              target="_blank"
              style="padding: 10px">
              <v-btn color="secondary" outlined rounded small>
                See Video Guide
              </v-btn>
            </a>
          </div>
        </v-row>
        <v-row>
          <v-col md="4" xs="12" class="text-center pt-5">
            <h3>Startup Guide</h3>
            <p style="color: grey">Lets build your first App</p>

            <Progress
              type="circle"
              :percentage="progresscount * 10"
              status="success"
              :width="140"
              stroke-width="10">
              <h3 class="notranslate">{{ parseInt(progresscount) }} / 10</h3>
              <span style="font-size: 0.8em">completed</span>
            </Progress>
          </v-col>

          <v-col md="4" xs="12">
            <v-list subheader>
              <v-list-item
                v-for="task in tasks"
                :key="task.title"
                md="6"
                sm="12"
                style="max-height: 30px"
                class="listtile"
                :to="{ name: task.route_name }"
                exact
                @click="changetabs(task.route_type)">
                <v-list-item-avatar size="30" v-if="task.status == 1">
                  <v-img
                    :alt="`${task.title} avatar`"
                    :src="correcticon"
                    height="30"
                    width="30"></v-img>
                </v-list-item-avatar>

                <v-list-item-avatar
                  size="30"
                  v-if="task.status == 0"
                  color="#e6ffe6">
                  <span
                    style="width: 100%; color: grey; font-weight: lighter"
                    >{{ task.stepid }}</span
                  >
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-text="task.title"
                    style="font-size: 0.9em">
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>

          <v-col md="4" xs="12">
            <v-list subheader>
              <v-list-item
                v-for="task in tasks2"
                :key="task.title"
                md="6"
                sm="12"
                style="max-height: 30px"
                class="listtile"
                :to="{ name: task.route_name }"
                exact>
                <v-list-item-avatar size="30" v-if="task.status == 1">
                  <v-img
                    :alt="`${task.title} avatar`"
                    :src="correcticon"
                    height="30"
                    width="30"></v-img>
                </v-list-item-avatar>

                <v-list-item-avatar
                  size="30"
                  v-if="task.status == 0"
                  color="#e6ffe6">
                  <span
                    style="width: 100%; color: grey; font-weight: lighter"
                    >{{ task.stepid }}</span
                  >
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="task.title"
                    style="font-size: 0.9em"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </Container>
      <template v-if="!tab">
        <Loader v-if="loading" />
        <Container v-else>
          <Title :title="$tr('project', 'key_127')" />
          <v-row>
            <v-col md="12" sm="12" cols="12" class="pb-0">
              <v-text-field
                :label="$tr('project', 'key_7')"
                outlined
                color="primary"
                dense
                v-model="name"></v-text-field>
            </v-col>

            <!-- <v-col md="6" sm="12" cols="12" class="pb-0">
              <v-select
                  :label="$tr('project', 'key_e3')"
                  outlined
                  color="primary"
                  dense
                  :items="platformItems"
                  v-model="platform"
                  :on-change="checkConnectStatus"
                  :disabled="status === 1"
              ></v-select>
            </v-col>

            <v-col md="6" sm="12" cols="12" class="pb-0">
              <v-text-field
                  :label="$tr('project', 'key_8')"
                  outlined
                  color="primary"
                  dense
                  :value="link"
                  disabled
              ></v-text-field>
            </v-col> -->

            <UploadLogo />
          </v-row>
        </Container>
      </template>

      <!-- Connect Store Area -->

      <template v-else-if="tab === 1">
        <Loader v-if="loading" />
        <Container v-else>
          <v-row>
            <v-col md="6" sm="12" cols="12" class="pb-0">
              <v-select
                label="Platform"
                outlined
                color="primary"
                dense
                :items="platformItems"
                v-model="platform"
                :on-change="checkConnectStatus"
                :disabled="status === 1"></v-select>
            </v-col>

            <v-col md="6" sm="12" cols="12" class="pb-0">
              <v-text-field
                label="Link to your website"
                outlined
                color="primary"
                dense
                v-model="link"
                :disabled="status === 1"></v-text-field>
            </v-col>

            <v-col cols="12" class="pb-5">
              <h4>Step 01 : Connect with Rest API</h4>
            </v-col>

            <v-col md="6" sm="12" cols="12" class="pb-4">
              <v-text-field
                label="Consumer Key"
                outlined
                color="primary"
                dense
                hide-details
                :type="!hide.cskey ? 'password' : 'text'"
                :disabled="status === 1"
                v-model="cskey">
                <template v-slot:append>
                  <v-btn
                    icon
                    width="40"
                    depressed
                    height="40"
                    color="smoke"
                    @click="hide.cskey = !hide.cskey">
                    <v-icon
                      v-text="
                        !hide.cskey ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                      " />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col md="6" sm="12" cols="12" class="pb-4">
              <v-text-field
                label="Consumer Secret"
                outlined
                color="primary"
                dense
                hide-details
                :type="!hide.cssec ? 'password' : 'text'"
                :disabled="status === 1"
                v-model="cssec">
                <template v-slot:append>
                  <v-btn
                    icon
                    width="40"
                    depressed
                    height="40"
                    color="smoke"
                    @click="hide.cssec = !hide.cssec">
                    <v-icon
                      v-text="
                        !hide.cssec ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                      " />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>

            <v-col md="12" style="margin-top: 0px" v-if="connectionloading">
              <v-alert dense text type="info">Connecting...</v-alert>
            </v-col>

            <v-col md="12" style="margin-top: 0px" v-if="!connectionloading">
              <v-alert v-if="appconnected" dense text type="success"
                >Connected Successfully</v-alert
              >

              <v-alert v-if="!appconnected" dense text type="warning"
                >Connection Problem. Check your API Key & Secret
                <v-btn small style="float: right" @click="checkConnectStatus">
                  Check Conection Again
                </v-btn>
              </v-alert>
            </v-col>

            <v-col cols="12" class="pb-5" v-if="platform == 'woocommerce'">
              <h4>Step 02 : Install Shopimint Plugin</h4>
            </v-col>

            <v-col md="4" class="pb-5" v-if="platform == 'woocommerce'">
              <a
                href="https://app.shopimint.com/downloads/shopimint-api-new.zip"
                target="_blank">
                <v-btn
                  :loading="loading3"
                  :disabled="loading3"
                  color="blue-grey"
                  class="ma-2 white--text"
                  @click="loader = 'loading3'">
                  Download Wordpress Plugin
                  <v-icon right dark> mdi-cloud-download </v-icon>
                </v-btn>
              </a>
            </v-col>
          </v-row>
        </Container>
      </template>

      <!-- Permission Area -->

      <template v-else-if="tab === 2">
        <Loader v-if="loading" />
        <Container v-else>
          <v-row>
            <v-col cols="12" class="pb-5">
              <h4>{{ $tr("project", "key_e35") }}</h4>
            </v-col>

            <v-col md="4" sm="12" cols="12" class="pb-0">
              <v-text-field
                :label="$tr('project', 'key_27')"
                outlined
                color="primary"
                dense
                :disabled="status === 1"
                v-model="appId"></v-text-field>
            </v-col>

            <v-col md="4" sm="12" cols="12" class="pb-0" style="display: none">
              <v-select
                :label="$tr('project', 'key_29')"
                outlined
                color="primary"
                dense
                :items="orientItems"
                v-model="orientation"></v-select>
            </v-col>

            <v-col md="4" sm="12" cols="12" class="pb-0">
              <v-text-field
                :label="$tr('project', 'key_109')"
                outlined
                color="primary"
                dense
                readonly
                v-model="language"
                @click="selectISO = true">
                <template v-slot:append>
                  <v-btn icon color="smoke" @click="selectISO = true">
                    <MoreIcon :size="24" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12" class="pb-0">
              <v-text-field
                :label="$tr('project', 'key_129')"
                outlined
                color="primary"
                dense
                v-model="email"></v-text-field>
            </v-col>

            <v-col md="4" sm="12" cols="12" class="pb-0">
              <v-text-field
                :label="$tr('project', 'key_345')"
                outlined
                color="primary"
                dense
                readonly
                v-model="currency"
                @click="selectCurrency = true">
                <template v-slot:append>
                  <v-btn icon color="smoke" @click="selectCurrency = true">
                    <MoreIcon :size="24" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12" class="pb-0">
              <v-text-field
                :label="$tr('project', 'key_346')"
                outlined
                color="primary"
                dense
                v-model="currency_symbol"></v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-select
                :items="currencyf"
                dense
                hide-details
                :label="$tr('project', 'key_347')"
                outlined
                v-model="currency_format"></v-select>
            </v-col>

            <v-col md="12" sm="12" cols="12" class="pb-0">
              <v-textarea
                :label="$tr('project', 'key_28')"
                outlined
                color="primary"
                dense
                rows="1"
                v-model="user_agent"></v-textarea>
            </v-col>

            <v-col
              md="12"
              sm="12"
              cols="12"
              class="mb-5 supercache"
              style="margin-left: 10px; margin-right: 10px !important">
              <h4>Super Cache Settings ⚡</h4>
              <p>
                By enabling super cache your homepage app data will loaded
                before the app startup and cache them on our servers including
                products. These caches will be automaticly refresh on 15
                miniutes.
              </p>

              <v-row>
                <v-col md="4" class="pb-0">
                  <v-switch
                    v-model="supercache"
                    :label="
                      supercache
                        ? 'Super Cache Enabled.'
                        : 'Super Cache Disabled'
                    "
                    color="success"
                    inset></v-switch>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              md="12"
              sm="12"
              cols="12"
              class="mb-5 maintanancemode"
              style="margin-left: 10px; margin-right: 10px !important">
              <h4>Maintainance Mode</h4>
              <v-row>
                <v-col md="6">
                  <v-switch
                    v-model="maintenance_enable"
                    :label="
                      maintenance_enable
                        ? 'Maintainance Mode is Active.'
                        : 'Maintainance Mode is Deactive'
                    "
                    color="success"
                    inset></v-switch>
                  <v-textarea
                    label="Notice"
                    outlined
                    color="primary"
                    dense
                    rows="1"
                    v-model="maintenance_notice"></v-textarea>
                </v-col>
                <v-col md="6">
                  <div
                    class="d-flex justify-start align-center"
                    style="padding: 5px; border-radius: 10px">
                    <div style="padding: 10px">
                      <div
                        v-if="!maintenance_image"
                        class="smoke--text"
                        style="width: 32px; height: 32px">
                        <ImageIcon :size="32" />
                      </div>
                      <div style="width: 120px; height: 100px" v-else>
                        <v-img
                          :src="maintenance_image"
                          style="width: 120px; max-height: 100px">
                          <template v-slot:placeholder>
                            <v-progress-circular
                              indeterminate
                              color="smoke"
                              size="32"
                              width="3"></v-progress-circular>
                          </template>
                        </v-img>
                      </div>
                    </div>

                    <div style="margin-left: 5px">
                      <div class="body-1 font-weight-medium">
                        Maintenance Banner
                      </div>
                      <div class="body-2 smoke--text">
                        PNG image is less than 1MB. and 400 x 1000px ( Landscape
                        )
                      </div>
                      <div>
                        <v-btn
                          :loading="backgroundimg.isSelecting"
                          small
                          depressed
                          class="mt-3"
                          @click="onButtonLogoClick"
                          style="float: left">
                          {{ $tr("project", "key_86") }}
                        </v-btn>

                        <input
                          ref="uploaderB"
                          class="d-none"
                          type="file"
                          accept="image/*"
                          @change="onFileLogoChanged" />
                      </div>

                      <v-btn
                        small
                        depressed
                        class="mt-3"
                        @click="openCreativePortal(maintenance_image)"
                        style="float: left; margin-left: 10px">
                        Open Designer
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col md="12" sm="12" cols="12" class="pb-5">
              <h4>Backup Your Theme Settings</h4>
            </v-col>

            <v-col md="4" class="pb-0">
              <v-btn
                :loading="loading4"
                :disabled="loading4"
                color="blue-grey"
                class="ma-2 white--text"
                @click="createbackup">
                Create Backup
                <v-icon right dark> mdi-cloud-upload </v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <!-- <Title :title="$tr('project', 'key_128')"/>
          <div class="permissions_block mb-3">
            <div class="d-flex justify-space-between align-center pa-4">
              <div class="d-flex justify-start align-center">
                <v-avatar color="whitesmoke" :size="60" class="mr-4">
                  <div class="smoke--text" style="width: 32px; height: 32px">
                    <LocationIcon :size="32"/>
                  </div>
                </v-avatar>
                <div>
                  <div class="body-1 font-weight-medium">
                    {{ $tr('project', 'key_121') }}
                  </div>
                  <div class="body-2 smoke--text">
                    {{ $tr('project', 'key_122') }}
                  </div>
                </div>
              </div>
              <v-switch
                  :true-value="1"
                  :false-value="0"
                  v-model="permissions.gps"
                  inset
              ></v-switch>
            </div>
          </div> 
          
          <div class="permissions_block mb-3">
            <div class="d-flex justify-space-between align-center pa-4">
              <div class="d-flex justify-start align-center">
                <v-avatar color="whitesmoke" :size="60" class="mr-4">
                  <div class="smoke--text" style="width: 32px; height: 32px">
                    <CameraIcon :size="32"/>
                  </div>
                </v-avatar>
                <div>
                  <div class="body-1 font-weight-medium">
                    {{ $tr('project', 'key_123') }}
                  </div>
                  <div class="body-2 smoke--text">
                    {{ $tr('project', 'key_124') }}
                  </div>
                </div>
              </div>
              <v-switch
                  :true-value="1"
                  :false-value="0"
                  v-model="permissions.camera"
                  inset
              ></v-switch>
            </div>
          </div>
          <div class="permissions_block mb-3">
            <div class="d-flex justify-space-between align-center pa-4">
              <div class="d-flex justify-start align-center">
                <v-avatar color="whitesmoke" :size="60" class="mr-4">
                  <div class="smoke--text" style="width: 32px; height: 32px">
                    <VoiceIcon :size="32"/>
                  </div>
                </v-avatar>
                <div>
                  <div class="body-1 font-weight-medium">
                    {{ $tr('project', 'key_125') }}
                  </div>
                  <div class="body-2 smoke--text">
                    {{ $tr('project', 'key_124') }}
                  </div>
                </div>
              </div>
              <v-switch
                  :true-value="1"
                  :false-value="0"
                  v-model="permissions.microphone"
                  inset
              ></v-switch>
            </div> 
          </div> -->
        </Container>
      </template>
      <template v-else-if="tab === 3">
        <Container>
          <v-row class="pb-0">
            <v-col md="3" sm="12" cols="12">
              <v-card outlined class="mb-4 background_block" to="Pages">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ users }} -->
                  </div>
                  <div class="black--text">Home Settings</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-card outlined class="mb-4 background_block" to="Productpage">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ apps }} -->
                  </div>
                  <div class="black--text">Product Page Settings</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-card outlined class="mb-4 background_block" to="Productcard">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ paid_apps }} -->
                  </div>
                  <div class="black--text">Product Card Settings</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-card outlined class="mb-3 background_block" to="Categorypage">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ builds }} -->
                  </div>
                  <div class="black--text">Category Page Settings</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-card outlined class="mb-3 background_block" to="Wishlistpage">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ builds }} -->
                  </div>
                  <div class="black--text">Wishlist Page Settings</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-card outlined class="mb-3 background_block" to="Cartpage">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ builds }} -->
                  </div>
                  <div class="black--text">Cart & Order Page Settings</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-card
                outlined
                class="mb-3 background_block"
                to="Profilesettingpage">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ builds }} -->
                  </div>
                  <div class="black--text">
                    Profile & Settings Page Settings
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-card outlined class="mb-3 background_block" to="Loginregpage">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ builds }} -->
                  </div>
                  <div class="black--text">
                    Login & Registration Page Settings
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-card outlined class="mb-3 background_block" to="Design">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ builds }} -->
                  </div>
                  <div class="black--text">Customize you app design</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-card outlined class="mb-3 background_block" to="Navigation">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ builds }} -->
                  </div>
                  <div class="black--text">Add Navigation Settings</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-card outlined class="mb-3 background_block" to="Walkthrough">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ builds }} -->
                  </div>
                  <div class="black--text">Setup App Walkthrough</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-card
                outlined
                class="mb-3 background_block"
                to="Eventnotifications">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ builds }} -->
                  </div>
                  <div class="black--text">Setup Marketing Notifications</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-card
                outlined
                class="mb-3 background_block"
                to="Pushnotification">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ builds }} -->
                  </div>
                  <div class="black--text">Setup Push Notifications</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-card outlined class="mb-3 background_block" to="Firebase">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ builds }} -->
                  </div>
                  <div class="black--text">Firebase Integration</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-card outlined class="mb-3 background_block" to="Firebase">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ builds }} -->
                  </div>
                  <div class="black--text">Firebase Integration</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-card outlined class="mb-3 background_block" to="Livechat">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ builds }} -->
                  </div>
                  <div class="black--text">Live Chat Intergration</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-card
                outlined
                class="mb-3 background_block"
                to="Googleanalytics">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ builds }} -->
                  </div>
                  <div class="black--text">Google Analytics Settings</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-card
                outlined
                class="mb-3 background_block"
                to="Buynowpaylater">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ builds }} -->
                  </div>
                  <div class="black--text">Buy Now Pay Later Settings</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-card outlined class="mb-3 background_block" to="Sociallogin">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ builds }} -->
                  </div>
                  <div class="black--text">Social Media Login Settings</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-card outlined class="mb-3 background_block" to="Arsetup">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ builds }} -->
                  </div>
                  <div class="black--text">Augemented Reality Settings</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-card outlined class="mb-3 background_block" to="Facebookpixel">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ builds }} -->
                  </div>
                  <div class="black--text">FaceBook Pixel Settings</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-card
                outlined
                class="mb-3 background_block"
                to="Paymentgateways">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ builds }} -->
                  </div>
                  <div class="black--text">Payment Gateways Settings</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-card outlined class="mb-3 background_block" to="Appsettings">
                <v-card-text>
                  <div class="headline font-weight-medium primary--text">
                    <!-- {{ builds }} -->
                  </div>
                  <div class="black--text">Advanced App Settings</div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </Container>
      </template>
      <Footer ref="footer" :loading="updateLoading" @click="updateMain" />
      <v-dialog scrollable max-width="680" v-model="selectISO">
        <SelectISO @close="selectISO = false" @set="setISO" />
      </v-dialog>
      <v-dialog scrollable max-width="680" v-model="selectCurrency">
        <SelectCurrency @close="selectCurrency = false" @set="setCurrency" />
      </v-dialog>
      <v-dialog width="1200" scrollable v-model="creative">
        <!-- <div style="width:100%; height:600px; background-color:#141517; padding:25px"> -->
        <div
          style="
            width: 100%;
            height: 600px;
            background-color: #ffffff;
            padding: 25px;
          ">
          <iframe
            :src="iframe.src"
            :width="1150"
            :height="550"
            type="text/html"
            frameborder="0"
            scrolling="no"></iframe>
        </div>
      </v-dialog>
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Container from "@/components/blocks/Container";
import Title from "@/components/blocks/Title";
import Loader from "@/components/blocks/Loader";
import SelectISO from "@/views/account/apps/builder/modals/SelectISO";
import SelectCurrency from "@/views/account/apps/builder/modals/SelectCurrency";
import MoreIcon from "@/components/icons/MoreIcon";
// import LocationIcon from "@/components/icons/LocationIcon";
// import CameraIcon from "@/components/icons/CameraIcon";
// import VoiceIcon from "@/components/icons/VoiceIcon";
import correcticon from "@/components/icons/correcticon.svg.png";
import Footer from "@/components/blocks/Footer";
import Progress from "vue-multiple-progress";
import UploadLogo from "./components/UploadLogo.vue";
import ImageIcon from "../../../../components/icons/ImageIcon.vue";
// import WooCommerceRestApi from "woocommerce/woocommerce-rest-api";

// const WooCommerceRestApi = require("@woocommerce/woocommerce-rest-api").default;

let appconnected = null;
let connectionloading = true;

export default {
  name: "Main",
  components: {
    PageBar,
    Container,
    Title,
    Loader,
    SelectISO,
    SelectCurrency,
    MoreIcon,
    // LocationIcon,
    // CameraIcon,
    // VoiceIcon,
    Footer,
    Progress,
    UploadLogo,
    ImageIcon,
  },
  data: () => ({
    fill: { gradient: ["red", "green", "blue"] },
    correcticon: correcticon,
    appconnected: appconnected,
    connectionloading: connectionloading,
    selectISO: false,
    selectCurrency: false,
    loading: true,
    loading4: false,
    hide: {
      cssec: false,
      cskey: false,
    },
    backgroundimg: {
      isSelecting: false,
      selectedFile: null,
      upload: false,
      loading: false,
    },
    creative: false,
    iframe: {
      src: "https://app.shopimint.com/backend/creative/generate",
    },
    tab: 0,
    name: "",
    link: "",
    appId: "",
    cskey: "",
    cssec: "",
    platform: "",
    email: "",
    currencysymbol: "$",
    currencyformat: "0",
    currency: "USD",
    orientation: 0,
    user_agent: "",
    status: 0,
    orientations: [],
    gps: 0,
    language: "EN",
    btnLoad: false,
    progresscount: 0,
    maintenance_enable: 0,
    maintenance_image: "",
    maintenance_notice: "",
    permissions: {
      gps: 0,
      camera: 0,
      microphone: 0,
    },
    supercache: false,
    updateLoading: false,
    tasks: [],
    tasks2: [],
    tabModel2: 0,
  }),
  computed: {
    // tabs: function () {
    //   return [];
    // },
    tabs2: function () {
      return [
        this.$tr("project", "key_119"),
        this.$tr("project", "key_e34"),
        this.$tr("project", "key_120"),
        this.$tr("project", "key_459"),
      ];
    },
    orientItems: function () {
      return [
        {
          text: this.$tr("project", "key_30"),
          value: 0,
        },
        {
          text: "Create New App",
          value: 1,
        },
        {
          text: this.$tr("project", "key_32"),
          value: 2,
        },
      ];
    },
    platformItems: function () {
      return [
        {
          text: "Woocommerce",
          value: "woocommerce",
        },
        {
          text: "Shopify",
          value: "shopify",
        },
        {
          text: "Magento",
          value: "magento",
        },
        {
          text: "Opencart",
          value: "opencart",
        },
        {
          text: "Drupal",
          value: "drupal",
        },
        {
          text: "Active E-Commerce",
          value: "activeEcommerce",
        },
      ];
    },
    currencyf: function () {
      return [
        {
          text: "#,###.##",
          value: "#,##0.00",
        },
        {
          text: "#.###,##",
          value: "#.##0.00",
        },
      ];
    },
  },
  methods: {
    // progress(event,progress,stepValue){
    //   console.log(stepValue);
    // },
    // progress_end(event){
    //   console.log("Circle progress end");
    // },
    openCreativePortal(imageurl) {
      this.iframe.src =
        "https://app.shopimint.com/backend/creative/generate?imageurl=" +
        imageurl;
      this.creative = true;
      //this.getSplashDetail();
    },
    updateTab(value) {
      this.tab = value;
      this.tabModel2 = value;
    },
    changetabs(value) {
      this.tab = value;
      this.tabModel2 = value;
    },
    setISO(value) {
      this.language = value.toUpperCase();
      this.selectISO = false;
    },
    setCurrency(value) {
      this.currency = value.toUpperCase();
      this.selectCurrency = false;
    },
    getMainDetail() {
      this.loading = true;
      this.$http
        .get(
          `${this.$serverApiLink}api/account/apps/detail/${this.$route.params.uid}`
        )
        .then((response) => {
          const data = response.data.app;
          this.name = data.name;
          this.link = data.link;
          this.appId = data.app_id;
          this.orientation = data.orientation;
          this.user_agent = data.user_agent;
          this.status = data.status;
          this.gps = data.gps;
          this.language = data.language;
          this.currency = data.currency;
          this.currency_symbol = data.currency_symbol;
          this.currency_format = data.currency_format;
          this.maintenance_enable = data.maintenance_enable == 1 ? true : false;
          this.maintenance_image = data.maintenance_image;
          this.maintenance_notice = data.maintenance_notice;
          this.email = data.email;
          this.permissions = data.permissions;
          this.loading = false;
          this.supercache = data.supercache == 1 ? true : false;

          this.cskey = data.cskey;
          this.cssec = data.cssec;
          this.platform = data.platform;

          this.getTasks(data.tasks);

          this.$emit("root_update_plan", data.current_plan);

          this.checkConnectStatus();
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.loading = false;
        });
    },
    getTasks(mytasks) {
      if (mytasks.length != 0) {
        this.progresscount =
          parseInt(mytasks.appname) +
          parseInt(mytasks.applogo) +
          parseInt(mytasks.setupdesign) +
          parseInt(mytasks.selecttheme) +
          parseInt(mytasks.connectstore) +
          parseInt(mytasks.runondevice) +
          parseInt(mytasks.setupnavigation) +
          parseInt(mytasks.setuphomepage) +
          parseInt(mytasks.setuponesignal) +
          parseInt(mytasks.publishstore);
        this.tasks = [
          {
            stepid: 1,
            title: "Setup Basic App Details",
            status: mytasks.appname,
            route_name: "Main",
            route_type: 2,
          },
          {
            stepid: 2,
            title: "Upload Logo & App Icon",
            status: mytasks.applogo,
            route_name: "Main",
            route_type: 0,
          },
          {
            stepid: 3,
            title: "Select Template",
            status: mytasks.selecttheme,
            route_name: "Templates",
            route_type: 0,
          },
          {
            stepid: 4,
            title: "Connect your store",
            status: mytasks.connectstore,
            route_name: "Main",
            route_type: 1,
          },
          {
            stepid: 5,
            title: "Customise Design",
            status: mytasks.setupdesign,
            route_name: "Design",
            route_type: 0,
          },
        ];

        this.tasks2 = [
          {
            stepid: 6,
            title: "Run & Test",
            status: mytasks.runondevice,
            route_name: "Design",
            route_type: 1,
          },
          {
            stepid: 7,
            title: "Setup Navigation",
            status: mytasks.setupnavigation,
            route_name: "Navigation",
            route_type: 1,
          },
          {
            stepid: 8,
            title: "Setup Home Page",
            status: mytasks.setuphomepage,
            route_name: "Pages",
            route_type: 1,
          },
          {
            stepid: 9,
            title: "Setup Notifications",
            status: mytasks.setuponesignal,
            route_name: "Firebase",
            route_type: 1,
          },
          {
            stepid: 10,
            title: "Publish App",
            status: mytasks.publishstore,
            route_name: "Build",
            route_type: 1,
          },
        ];
      }
    },
    checkConnectStatus() {
      this.connectionloading = true;
      if (this.platform == "woocommerce") {
        // alert(`${this.link}/wp-json/wc/v3/settings?consumer_key=${this.cskey}&consumer_secret=${this.cssec}`);
        this.$http
          .get(
            `${this.link}/wp-json/wc/v3/settings?consumer_key=${this.cskey}&consumer_secret=${this.cssec}`
          )
          .then((response) => {
            this.appconnected = true;
            this.connectionloading = false;
            console.log("Store Connection Successful : " + response.status);
            this.updateConnectionSuccessStatus(1);
          })
          .catch((error) => {
            this.appconnected = false;
            this.connectionloading = false;
            console.log("Store Connection Problem : " + error);
            this.updateConnectionSuccessStatus(0);
            // this.$store.commit('setSnackBarCustom', {
            //   code: !error.response ? 408 : error.response.status,
            //   message: 'Connection Problem with your website platform.'
            // });
          });

        // const woo = new WooCommerceRestApi({
        //     url: this.link, // Your store URL
        //     consumerKey: this.cskey, // Your consumer key
        //     consumerSecret: this.cssec, // Your consumer secret
        //     version: 'wc/v3', // WooCommerce WP REST API version
        //     queryStringAuth: true
        // });

        // woo.get("settings")
        // .then((response) => {
        //     console.log(response.data);
        // })
        // .catch((error) => {
        //     console.log(error.response.data);
        // });
      } else {
        console.log(this.platform + "is not setupped yet.");
        this.appconnected = false;
        this.connectionloading = false;
        // this.$store.commit('setSnackBar', {
        //   code: !error.response ? 408 : error.re,
        //   message: error.response.data.message
        // });
      }
    },
    onButtonLogoClick() {
      this.backgroundimg.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.backgroundimg.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploaderB.click();
    },
    onFileLogoChanged(e) {
      this.backgroundimg.selectedFile = e.target.files[0];
      this.$store.commit("setLoading", true);
      this.backgroundimg.upload = true;
      let params = new FormData();
      params.set("maintenance_image", this.backgroundimg.selectedFile);
      this.$http
        .post(
          `${this.$serverApiLink}api/account/assets/upload_maintenanceimage/${this.$route.params.uid}`,
          params,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((response) => {
          this.maintenance_image = response.data.uri;
          this.backgroundimg.upload = false;
          this.backgroundimg.isSelecting = false;
          this.$store.commit("setLoading", false);
          this.$emit("reload_preview", {
            uid: this.$route.params.uid,
            mode: "maintanaceimg",
          });
          this.$refs.footer.showSuccessAlert();
          this.getMainDetail();
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.backgroundimg.upload = false;
          this.backgroundimg.isSelecting = false;
          this.$store.commit("setLoading", false);
          this.getMainDetail();
        });
    },
    updateMain() {
      this.updateLoading = true;
      let params = new URLSearchParams();
      params.append("link", this.link);
      params.append("name", this.name);
      params.append("app_id", this.appId);
      params.append("user_agent", this.user_agent);
      params.append("orientation", this.orientation);
      params.append("gps", this.gps);
      params.append("language", this.language);
      params.append("currency", this.currency);
      params.append("currency_symbol", this.currency_symbol);
      params.append("currency_format", this.currency_format);
      params.append("email", this.email);
      params.append("gps", this.permissions.gps);
      params.append("camera", this.permissions.camera);
      params.append("microphone", this.permissions.microphone);
      params.append("supercache", this.supercache == true ? 1 : 0);

      params.append(
        "maintenance_enable",
        this.maintenance_enable == true ? 1 : 0
      );
      params.append("maintenance_notice", this.maintenance_notice);

      params.append("cskey", this.cskey);
      params.append("cssec", this.cssec);
      params.append("platform", this.platform);

      this.$http
        .post(
          `${this.$serverApiLink}api/account/apps/update/${this.$route.params.uid}`,
          params
        )
        .then(
          // eslint-disable-next-line no-unused-vars
          (response) => {
            this.updateLoading = false;
            this.$emit("root_update_name", this.name);
            this.$emit("root_update_link", this.link);
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "app",
            });
            this.$refs.footer.showSuccessAlert();
            this.getMainDetail();
          }
        )
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.updateLoading = false;
          this.getMainDetail();
        });
    },
    updateConnectionSuccessStatus(status) {
      let params = new URLSearchParams();
      params.append("status", status);
      this.$http
        .post(
          `${this.$serverApiLink}api/account/apps/connectionsuccess/${this.$route.params.uid}`,
          params
        )
        .then
        // response => {
        //   console.log("Update Status : " + response.data.updatestatus);
        //   if(response.data.updatestatus == 1){
        //       this.getMainDetail();
        //   }
        // }
        ()
        .catch
        // error => {
        //   this.$store.commit('setSnackBar', {
        //     code: !error.response ? 408 : error.response.status,
        //     message: error.response.data.message
        //   });
        // }
        ();
    },
    createbackup() {
      let params = new URLSearchParams();
      this.loading4 = true;
      params.append("status", 1);
      this.$http
        .post(
          `${this.$serverApiLink}api/account/ThemeBuilder/export/${this.$route.params.uid}`,
          params
        )
        .then((response) => {
          if (response.data.code == 200) {
            this.$refs.footer.showSuccessAlert();
          }
          this.loading4 = false;
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.loading4 = false;
        });
    },
  },
  mounted() {
    this.getMainDetail();
  },
};
</script>

<style scoped>
.listtile {
  border-radius: 20px;
  max-height: 30px;
}
.listtile:hover {
  background: rgba(184, 246, 255, 0.3);
}
.v-list-item--link::before {
  background-color: rgba(184, 246, 255, 0) !important;
}
</style>
