<template>
  <v-app id="private">
    <v-overlay v-if="creative_loading" style='z-index:20001;'>
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
    </v-overlay>
    <v-navigation-drawer
        app
        :width="!sidebar ? 66 : 340"
        v-model="$store.state.left_drawer"
    >
      <AppSidebar @open_launcher="launcher = true"/>
      <template v-if="sidebar === 'profile'">
        <ProfileSidebar/>
        <LogoutButton/>
      </template>
      <template v-if="sidebar === 'keys'">
        <KeysSidebar/>
      </template>
      <template v-if="sidebar === 'support'">
        <SupportSidebar/>
      </template>
      <template v-if="sidebar === 'newsletter'">
        <NewsletterSidebar/>
      </template>
      <template v-if="sidebar === 'admin'">
        <AdminSidebar/>
      </template>
      <template v-if="sidebar === 'builder'">
        <BuilderSidebar
            :name="app.name"
            :link="app.link"
            :image="app.icon"
            @onStart="loading = true"
            @onLoad="onLoad"
        />
      </template>
      <template v-if="sidebar === 'buildernodevice'">
        <BuilderSidebarWithoutDevice
            :name="app.name"
            :link="app.link"
            :image="app.icon"
            @onStart="loading = true"
            @onLoad="onLoad"
        />
      </template>
      <template v-slot:append>
        <template v-if="sidebar === 'builder' || sidebar === 'buildernodevice' ">
          <Balance :balance="balance.value" :current_plan="balance.plan" :loading="loading" @open_deposit="deposit = true"/>
        </template>
      </template>
    </v-navigation-drawer>
    <!--  <v-navigation-drawer
        v-if="sidebar === 'builder'"
        clipped
        app
        width="334"
        right
        style="overflow: hidden auto"
        v-model="$store.state.right_drawer"
    >
      <Preview :uid="app.uid"/> 
    </v-navigation-drawer> -->
    <v-navigation-drawer
        v-if="actionbar !== undefined"
        clipped
        app
        width="334"
        right
        v-model="$store.state.right_drawer"
    >
      <template v-if="actionbar === 'support'">
        <SupportActionbar/>
      </template>
      <template v-if="actionbar === 'builder'">
        <BuilderAdminSidebar
            :name="app.name"
            :link="app.link"
            :image="app.icon"
            :balance="app.balance"
            @onStart="loading = true"
            @onLoad="onLoad"
            @updateBalance="updateBuilds"
        />
      </template>
      <template v-if="actionbar === 'customer'">
        <CustomerSidebar/>
      </template>
    </v-navigation-drawer>
    <router-view
        v-on:open_launcher="launcher = true"
        v-on:root_update_name="update_name"
        v-on:root_update_link="update_link"
        v-on:root_update_icon="update_icon"
        v-on:root_update_plan="update_plan"
        v-on:open_deposit="deposit = true"
        v-on:reload_preview="reloadPreview"
        v-on:show_creative_loading="showCreativeLoading"
        :balance="balance.value"
        :user_id="app.user_id"
    />
    <v-dialog
        fullscreen
        scrollable
        persistent
        v-model="launcher"
    >
      <AppWizard @close="launcher = false"/>
    </v-dialog>

    <v-dialog
        width="1200"
        scrollable
        v-model="creative"
    >
      <!-- <div style="width:100%; height:600px; background-color:#141517; padding:25px"> -->
      <div style="width:100%; height:600px; background-color:#ffffff; padding:25px">
        <iframe 
          :src="iframe.src"
          :width="1150"
          :height="550"
          type="text/html"
          frameborder="0"
          scrolling="no"
        ></iframe>
      </div>
    </v-dialog>

    <v-dialog
        fullscreen
        scrollable
        persistent
        v-model="deposit"
    >
      <Deposit :app="app" @close="deposit = false" @add="addBuild"/>
    </v-dialog>
  </v-app>
</template>

<script>
import {config} from "@/config/config";
import AppSidebar from "@/components/sidebars/AppSidebar";
import ProfileSidebar from "@/components/sidebars/ProfileSidebar";
import KeysSidebar from "@/components/sidebars/KeysSidebar";
import SupportSidebar from "@/components/sidebars/SupportSidebar";
import NewsletterSidebar from "@/components/sidebars/NewsletterSidebar";
import LogoutButton from "@/components/sidebars/LogoutButton";
import AppWizard from "@/components/modals/AppWizard";
import BuilderSidebar from "@/components/sidebars/BuilderSidebar";
// import BuilderSidebarWithoutDevice from "@/components/sidebars/BuilderSidebarWithoutDevice";
import Balance from "@/components/sidebars/Balance";
import AdminSidebar from "@/components/sidebars/AdminSidebar";
import SupportActionbar from "@/components/sidebars/SupportActionbar";
import BuilderAdminSidebar from "@/components/sidebars/BuilderAdminSidebar";
import CustomerSidebar from "@/components/sidebars/CustomerSidebar";
import Deposit from "@/components/modals/Deposit";
import axios from "axios";
// import Preview from "@/components/sidebars/Preview";
// import { UpdateSnack } from "@/snack/build";
// import Pixie from "../assets/pixie/pixie.umd.js";

const API_LINK = config.serverUrl;

export default {
  name: 'PrivateLayout',
  components: {
    SupportSidebar,
    AppSidebar,
    ProfileSidebar,
    KeysSidebar,
    LogoutButton,
    AppWizard,
    NewsletterSidebar,
    BuilderSidebar,
    // BuilderSidebarWithoutDevice,
    Balance,
    AdminSidebar,
    SupportActionbar,
    BuilderAdminSidebar,
    CustomerSidebar,
    Deposit,
    // Preview
  },
  data: () => ({
    sidebar: null,
    actionbar: undefined,
    actions: null,
    launcher: false,
    deposit: false,
    creative: false,
    balance: {
      loading: true,
      value: 0,
      plan: '',
    },
    app: {
      name: "",
      link: "",
      balance: 0,
      icon: null,
      uid: undefined,
      user_id: 0
    },
    iframe: {
        src: "https://app.shopimint.com/backend/creative/generate",
    },
    loading: true,
    creative_loading: false,
  }),
  watch: {
    '$route.meta': function() {
      this.sidebarChecking();
    },
  },
  methods: {
    showCreativeLoading(value) {
      this.creative_loading = value;
    },
    reloadPreview(object) {
      console.log(object);
      this.updateShopiApp(object.uid, object.mode);
      // UpdateSnack(object.uid, object.mode)
    },
    updateShopiApp(uid , mode){
      axios.get(`${API_LINK}api/account/adminapp/reloadapp/${uid}/${mode}`).
      then(
          response => {
              // console.log(response.data.config);
              console.log(response.status);
          }
      ).catch(
          error => {
              console.log(error)
          }
      );
    },
    sidebarChecking() {
      if (Object.keys(this.$route.meta).length) {
        this.sidebar = this.$route.meta["sidebar"];
        this.actionbar = this.$route.meta["actionbar"];
      } else {
        this.actionbar = this.$route.meta["actionbar"];
        this.sidebar = null;
      }
    },
    addBuild(count) {
      this.balance.value = this.balance.value + count;
    },
    updateBuilds(count) {
      this.app.balance = count;
    },
    onLoad(app) {
      this.app = app;
      this.balance = {
        loading: false,
        value: app.balance,
        plan: '',
      }
      this.loading = false;
    },
    update_name(value) {
      this.app.name = value;
    },
    update_icon(value) {
      this.app.icon = value;
    },
    update_link(value) {
      this.app.link = value;
    },
    update_plan(value) {
      this.balance.plan = value;
    },
  },
  beforeMount() {
    this.sidebarChecking();
  },
  mounted() {
      window.addEventListener("message", (e) => {
        if(e.data.status == 1){
            console.log("RECEIVED message from CHILD TO PARENT : ", e.data);
            this.creative = false;
            this.creative_loading = false;
        }else if(e.data.status == 4){
            this.creative_loading = true;
        }else if(e.data.status == 0){
            this.creative_loading = false;
        }
      });
  }

}
</script>