<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 2C20.6274 2 26 7.37258 26 14C26 17.9296 24.0964 21.5416 20.9565 23.7789C20.5067 24.0994 19.8823 23.9946 19.5618 23.5448C19.2413 23.0951 19.3461 22.4706 19.7959 22.1501C22.4146 20.2842 24 17.2759 24 14C24 8.47715 19.5228 4 14 4C8.47715 4 4 8.47715 4 14C4 17.2731 5.58266 20.2791 8.1976 22.1455C8.64713 22.4664 8.75145 23.0909 8.4306 23.5404C8.10975 23.9899 7.48523 24.0942 7.03571 23.7734C3.90029 21.5355 2 17.9262 2 14C2 7.37258 7.37258 2 14 2ZM14 7C17.866 7 21 10.134 21 14C21 16.0968 20.0713 18.0443 18.4959 19.3655L18.4627 19.3932C18.0373 19.7455 17.407 19.6863 17.0547 19.2609C16.7024 18.8356 16.7616 18.2052 17.1869 17.8529L17.2107 17.8331C18.3377 16.8879 19 15.4991 19 14C19 11.2386 16.7614 9 14 9C11.2386 9 9 11.2386 9 14C9 15.5078 9.67017 16.904 10.8085 17.8491C11.2334 18.2019 11.2919 18.8324 10.9391 19.2573C10.5863 19.6822 9.95583 19.7407 9.53091 19.3879C7.93969 18.0668 7 16.1091 7 14C7 10.134 10.134 7 14 7ZM14 12C15.1046 12 16 12.8954 16 14C16 15.1046 15.1046 16 14 16C12.8954 16 12 15.1046 12 14C12 12.8954 12.8954 12 14 12Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'RadioIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>