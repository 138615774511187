<template>
  <v-layout fill-height>
    <v-main>
      <PageBar :title="$tr('menu', 'key_e15')" :drawer="true" />
      <IntergrationView
        v-for="item in intergration.filter(info => info.route_name === 'Buynowpaylater')"
        :key="item.id"
        :item="item"
      />
      <Loader v-if="loading" />
      <Container v-else>
        <Title :title="$tr('project', 'key_385')" />
        <!-- <UploadLogo/> -->
        <div class="row">
          <div class="col-md-6">
            <div
              class="permissions_block mb-3 d-flex justify-space-between align-center pa-4"
            >
              <div class="d-flex justify-start align-center">
                <div style="padding: 10px">
                  <div
                    v-if="!settings.buynowpaylater_image"
                    class="smoke--text"
                    style="width: 32px; height: 32px"
                  >
                    <ImageIcon :size="32" />
                  </div>
                  <div style="width: 120px; height: 60px" v-else>
                    <v-img
                      :src="settings.buynowpaylater_image"
                      style="width: 120px; max-height: 60px"
                    >
                      <template v-slot:placeholder>
                        <v-progress-circular
                          indeterminate
                          color="smoke"
                          size="32"
                          width="3"
                        ></v-progress-circular>
                      </template>
                    </v-img>
                  </div>
                </div>

                <div style="margin-left: 5px">
                  <div class="body-1 font-weight-medium">
                    {{ $tr('project', 'key_386') }}
                  </div>
                  <div class="body-2 smoke--text">
                    {{ $tr('project', 'key_388') }}
                  </div>
                  <div>
                    <v-btn
                      :loading="logo_image.isSelecting"
                      small
                      depressed
                      class="mt-3"
                      @click="onButtonLogoClick"
                      style="float: left"
                    >
                      {{ $tr('project', 'key_86') }}
                    </v-btn>

                    <input
                      ref="uploaderB"
                      class="d-none"
                      type="file"
                      accept="image/*"
                      @change="onFileLogoChanged"
                    />
                  </div>

                  <v-btn
                    small
                    depressed
                    class="mt-3"
                    @click="openCreativePortal(settings.buynowpaylater_image)"
                    style="float: left; margin-left: 10px"
                  >
                    <!-- <EditIcon />  -->
                    Open Designer
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div
              class="permissions_block mb-3 d-flex justify-space-between align-center pa-4"
            >
              <div class="d-flex justify-start align-center">
                <div style="padding: 10px">
                  <div
                    v-if="!settings.buynowpaylater_largebanner"
                    class="smoke--text"
                    style="width: 32px; height: 32px"
                  >
                    <ImageIcon :size="32" />
                  </div>
                  <div style="width: 120px; height: 60px" v-else>
                    <v-img
                      :src="settings.buynowpaylater_largebanner"
                      style="width: 120px; max-height: 60px"
                    >
                      <template v-slot:placeholder>
                        <v-progress-circular
                          indeterminate
                          color="smoke"
                          size="32"
                          width="3"
                        ></v-progress-circular>
                      </template>
                    </v-img>
                  </div>
                </div>

                <div style="margin-left: 5px">
                  <div class="body-1 font-weight-medium">
                    {{ $tr('project', 'key_387') }}
                  </div>
                  <div class="body-2 smoke--text">
                    {{ $tr('project', 'key_389') }}
                  </div>
                  <div>
                    <v-btn
                      :loading="largebanner_image.isSelecting"
                      small
                      depressed
                      class="mt-3"
                      @click="onButtonLargebannerClick"
                      style="float: left"
                    >
                      {{ $tr('project', 'key_86') }}
                    </v-btn>

                    <input
                      ref="uploaderA"
                      class="d-none"
                      type="file"
                      accept="image/*"
                      @change="onFileLargebannerChanged"
                    />
                  </div>

                  <v-btn
                    small
                    depressed
                    class="mt-3"
                    @click="openCreativePortal(settings.buynowpaylater_largebanner)"
                    style="float: left; margin-left: 10px"
                  >
                    <!-- <EditIcon />  -->
                    Open Designer
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="permissions_block">
            <div class="d-flex justify-space-between align-center pa-4">
              <div class="d-flex justify-start align-center">
                <!-- <v-avatar color="whitesmoke" :size="60" class="mr-4"> -->
                  <!-- <div class="android--text" style="width: 32px; height: 32px">
                    <AndroidLogoIcon :size="32"/>
                  </div> -->
                <!-- </v-avatar> -->
                <div>
                  <div class="body-1 font-weight-medium">
                    {{ $tr('project', 'key_397') }}
                  </div>
                </div>
              </div>
              <v-switch
                  :true-value="1"
                  :false-value="0"
                  v-model="settings.buynowpaylater_enabled"
                  inset
              ></v-switch>
            </div>
          </div>
      </Container>
      <HelpArticle id="6328191eb78f9a796bdeacfc" :helpcentertopic="helpcentertopic"/>
      <Footer
          v-if="!loading"
          :loading="btnLoad"
          ref="footer"
          @click="updatePushDetail"
      />
      <v-dialog width="1200" scrollable v-model="creative">
        <!-- <div style="width:100%; height:600px; background-color:#141517; padding:25px"> -->
        <div
          style="
            width: 100%;
            height: 600px;
            background-color: #ffffff;
            padding: 25px;
          "
        >
          <iframe
            :src="iframe.src"
            :width="1150"
            :height="550"
            type="text/html"
            frameborder="0"
            scrolling="no"
          ></iframe>
        </div>
      </v-dialog>
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Container from "@/components/blocks/Container";
import Title from "@/components/blocks/Title";
import Loader from "@/components/blocks/Loader";
// import AndroidLogoIcon from "@/components/icons/AndroidLogoIcon";
import Footer from "@/components/blocks/Footer";
import IntergrationView from "../../../../../components/blocks/IntergrationView.vue";
// import UploadLogo from "../components/UploadLogo.vue";
import ImageIcon from "@/components/icons/ImageIcon";
import HelpArticle from "../../../../../components/blocks/HelpArticle.vue";

export default {
  name: 'Push',
  components: {
    PageBar,
    Container,
    Title,
    Loader,
    // AndroidLogoIcon,
    Footer,
    IntergrationView,
    ImageIcon
    // UploadLogo
    ,
    HelpArticle
},
  data: () => ({
    loading: true,
    btnLoad: false,
    intergration: [],
    settings: {
      buynowpaylater_enabled : "",
    },
    helpcentertopic: [
      {
        "name" : "Upload Banner",
        "link" : "123",
      },
      {
        "name" : "Upload Explanation Banner",
        "link" : "123",
      },
      {
        "name" : "Enable Installment Banner on Product Page",
        "link" : "123",
      }
    ],
    reissue: false,
    btn_load: false,
    logo_image: {
      isSelecting: false,
      selectedFile: null,
      upload: false,
      loading: false
    },
    largebanner_image: {
      isSelecting: false,
      selectedFile: null,
      upload: false,
      loading: false
    },
    creative: false,
    iframe: {
        src: "https://app.shopimint.com/backend/creative/generate",
    },
  }),
  methods: {
    openCreativePortal(imageurl) {
      this.iframe.src = "https://app.shopimint.com/backend/creative/generate?imageurl="+imageurl;
      this.creative = true;
    },
    onButtonLogoClick() {
      this.logo_image.isSelecting = true;
      window.addEventListener('focus', () => {
        this.logo_image.isSelecting = false
      }, {once: true});
      this.$refs.uploaderB.click();
    },
    onFileLogoChanged(e) {
      this.logo_image.selectedFile = e.target.files[0];
      this.$store.commit('setLoading', true);
      this.logo_image.upload = true;
      let params = new FormData();
      params.set('buynowpaylater_image', this.logo_image.selectedFile );
      this.$http.post(
          `${this.$serverApiLink}api/account/assets/upload_paylaterbanner/${this.$route.params.uid}`, params, {headers: {'Content-Type': 'multipart/form-data' }}).
      then(
          response => {
            this.settings.buynowpaylater_image = response.data.uri;
            this.logo_image.upload = false;
            this.logo_image.isSelecting = false;
            this.$store.commit('setLoading', false);
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "splash"
            });
            this.$refs.footer.showSuccessAlert();
            this.getBannerDetail();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.logo_image.upload = false;
            this.logo_image.isSelecting = false;
            this.$store.commit('setLoading', false);
            this.getBannerDetail();
          }
      );
    },
    onButtonLargebannerClick() {
      this.largebanner_image.isSelecting = true;
      window.addEventListener('focus', () => {
        this.largebanner_image.isSelecting = false
      }, {once: true});
      this.$refs.uploaderA.click();
    },
    onFileLargebannerChanged(e) {
      this.largebanner_image.selectedFile = e.target.files[0];
      this.$store.commit('setLoading', true);
      this.largebanner_image.upload = true;
      let params = new FormData();
      params.set('buynowpaylater_largebanner', this.largebanner_image.selectedFile );
      this.$http.post(
          `${this.$serverApiLink}api/account/assets/upload_paylaterlargebanner/${this.$route.params.uid}`, params, {headers: {'Content-Type': 'multipart/form-data' }}).
      then(
          response => {
            this.settings.buynowpaylater_largebanner = response.data.uri;
            this.largebanner_image.upload = false;
            this.largebanner_image.isSelecting = false;
            this.$store.commit('setLoading', false);
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "splash"
            });
            this.$refs.footer.showSuccessAlert();
            this.getBannerDetail();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.largebanner_image.upload = false;
            this.largebanner_image.isSelecting = false;
            this.$store.commit('setLoading', false);
            this.getBannerDetail();
          }
      );
    },
    updatePushDetail() {
      this.btnLoad = true;
      let params = new URLSearchParams();
      params.append('buynowpaylater_enabled', this.settings.buynowpaylater_enabled);
      this.$http.post(`${this.$serverApiLink}api/account/appintergration/updatebuynow/${this.$route.params.uid}`, params ).
      then(
          // eslint-disable-next-line no-unused-vars
          response => {
            this.btnLoad = false;
            this.$refs.footer.showSuccessAlert();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
              });
            console.log("gehe")
            this.btnLoad = false;
          }
      );
    },
    getBannerDetail() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/assets/buynowpaylater/${this.$route.params.uid}`).
      then(
          response => {
            this.settings = response.data.detail;
            console.log(response.data.detail);
            this.loading = false;
            this.getPushSettings();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
          }
      );
    },
    getPushSettings() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/appintergration/buynow/${this.$route.params.uid}`).
      then(
          response => {
            this.settings = response.data.detail;
            this.getIntergrations();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
      );
    },
    getIntergrations() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/intergrations/getintergrations/${this.$route.params.id}`).
        then(
          response => {
            this.intergration = response.data.intergration;
            this.loading = false;
          }
        ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
        );
    },
  },
  mounted() {
    this.getBannerDetail();

    window.addEventListener("message", (e) => {
        if(e.data.status == 1){
            this.getBannerDetail();
            this.creative = false;
        }else if(e.data.status == 4){
            this.creative_loading = true;
        }else if(e.data.status == 0){
            this.creative_loading = false;
        }
    });
  }
}
</script>
