<template>
  <v-list nav class="pt-0 pb-0">
    <v-subheader class="font-weight-medium smoke--text">
      {{ $tr("menu", "key_16") }}
    </v-subheader>
    <v-list-item-group color="rgba(153, 162, 173, 1)">
      <v-list-item
        v-for="(item, index) in items"
        :key="'profile_navigation_' + index"
        :to="{ name: item.to }"
        exact>
        <v-list-item-avatar class="null_border_radius" size="22">
          <div class="primary_icon list_icon">
            <HomeIcon v-if="index === 0" :size="22" />
            <PaletteIcon v-else-if="index === 1" :size="22" />
            <PushIcon v-else-if="index === 2" :size="22" />
            <PagesIcon v-else-if="index === 3" :size="22" />
            <CompassIcon v-else-if="index === 4" :size="22" />
            <!-- <SlideshowIcon v-else-if="index === 5" :size="22"/> -->
            <GlobeIcon v-else-if="index === 5" :size="22" />
            <!-- <ImageIcon v-else-if="index === 7" :size="22"/> -->
            <MarketingIcon v-else-if="index === 6" :size="22" />
            <SettingsIcon v-else-if="index === 7" :size="22" />
            <AnalyticsIcon v-else-if="index === 8" :size="22" />
            <CheckDeviceIcon v-else-if="index === 9" :size="22" />
            <AppPublishIcon v-else-if="index === 10" :size="22" />
          </div>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            class="black--text"
            v-text="$tr('menu', item.name)" />
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import PaletteIcon from "@/components/icons/PaletteIcon";
import CompassIcon from "@/components/icons/CompassIcon";
import HomeIcon from "@/components/icons/HomeIcon";
import PagesIcon from "@/components/icons/AppsIcon";
import GlobeIcon from "@/components/icons/GlobeIcon";
import PushIcon from "@/components/icons/PushIcon";
// import ImageIcon from "@/components/icons/ImageIcon";
import CheckDeviceIcon from "@/components/icons/CheckDeviceIcon";
// import SlideshowIcon from "@/components/icons/CameraIcon";
import AppPublishIcon from "@/components/icons/AppleLogoIcon";
import MarketingIcon from "@/components/icons/SendIcon";
import SettingsIcon from "@/components/icons/GearIcon";
import AnalyticsIcon from "../../icons/BillIcon.vue";

export default {
  name: "Menuitems",
  props: {},
  components: {
    CheckDeviceIcon,
    // ImageIcon,
    PagesIcon,
    PaletteIcon,
    CompassIcon,
    // SlideshowIcon,
    HomeIcon,
    GlobeIcon,
    PushIcon,
    AppPublishIcon,
    MarketingIcon,
    SettingsIcon,
    AnalyticsIcon,
  },
  data: () => ({
    items: [
      {
        name: "key_11",
        to: "Main",
      },
      {
        name: "key_12",
        to: "Design",
      },
      {
        name: "key_e5",
        to: "Templates",
      },
      {
        name: "key_e1",
        to: "Pages",
      },
      {
        name: "key_22",
        to: "Navigation",
      },
      // {
      //   name: "key_e2",
      //   to: "Slideshow"
      // },
      {
        name: "key_23",
        to: "Localization",
      },
      // {
      //   name: "key_13",
      //   to: "Assets"
      // },
      {
        name: "key_e8",
        to: "Marketing",
      },
      {
        name: "key_e6",
        to: "Intergration",
      },
      // {
      //   name: "key_39",
      //   to: "Analytics"
      // },
      {
        name: "key_15",
        to: "Build",
      },
      {
        name: "key_e3",
        to: "Publish",
      },
    ],
  }),
};
</script>
