<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.3256 16.0802L5.24167 15.3423C4.28816 15.2429 3.47542 14.6071 3.14955 13.7055C2.68021 12.407 3.35238 10.9739 4.65087 10.5046L19.386 5.17864C20.045 4.94045 20.7665 4.94045 21.4255 5.17864C22.9837 5.74184 23.7903 7.46157 23.2271 9.01977L17.9012 23.7549C17.5753 24.6565 16.7626 25.2923 15.8091 25.3916C14.4358 25.5347 13.2065 24.5374 13.0635 23.1641L12.3256 16.0802ZM5.24893 12.4236C5.04396 12.5427 4.94702 12.7948 5.03045 13.0257C5.09563 13.206 5.25818 13.3332 5.44888 13.353L13.3397 14.175C13.8101 14.224 14.1818 14.5957 14.2308 15.066L15.0527 22.9569C15.0813 23.2315 15.3272 23.431 15.6018 23.4024C15.7925 23.3825 15.9551 23.2554 16.0203 23.075L21.3462 8.33992C21.5339 7.82053 21.2651 7.24728 20.7457 7.05955C20.526 6.98015 20.2855 6.98015 20.0658 7.05955L5.33072 12.3855L5.24893 12.4236Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'LocationIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>