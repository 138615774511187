<template>
    <div style="padding:10px;">
      <v-btn
        icon
        color="gray"
        class="text-left"
        @click="dialog.status = true"
      >
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>
      <v-dialog
          scrollable
          max-width="880"
          v-model="dialog.status"
      >
          <div class="container container--fluid">
              <div class="pa-4 helparticle" id="app" v-for="post in posts" :key="post.id" >
                <div class="row">
                    <div class="col-md-12" style="padding-top: 50px;">
                      <h1 style="padding-bottom: 20px;">How it works </h1>
                    </div>
                    <div class="col-md-12">
                      <div class="currency"  >
                        <div v-html="post.html"  class="harticle gh-content"></div>
                        <span class="lighten">
                        </span>
                      </div>
                    </div>
                </div>
              </div>
            </div>
      </v-dialog>
    </div>
</template>

<script>

import axios from 'axios';

export default {
    name: 'HelpArticle2',
    props: {
        id: String,
        helpcentertopic: Object,
    },
    data: () => ({
      posts: [],
      loading: true,
      btnLoad: false,
      reissue: false,
      btn_load: false,
      dialog: {
        status: false,
        loading: false
      },
    }),
    methods: {
      getarticle(){
          axios
          .get(`https://help.shopimint.com/ghost/api/content/posts/${this.id}/?key=a7fb031a0308d87b3e2c512b6c&include=tags`)
          
          .then(response => {
              this.posts = response.data.posts;
              // console.log("Thehe")
              // console.log(this.id)
              this.loading = false;
          })
          .catch(error => {
              console.log(error)
              console.log("There is a error loading article")
              this.errored = true
          })
          .finally(() => this.loading = false)
      },
    },
    mounted() {
      this.getarticle();
    }
}
</script>