<template>
  <v-card
    flat
    class="modal_bar overflow-y-auto fill-height depositarea"
    tile
    
  >
    <div style="position: fixed; width: 100%; height: 65px; z-index: 10">
      <v-toolbar flat class="modal_bar">
        <div
          class="d-flex justify-center align-center align-self-center"
          style="width: 100vw"
        >
          <div />
          <img :src="$store.state.config.logo" height="32" alt="Logo" />
        </div>
        <v-btn
          icon
          @click="$emit('close')"
          style="position: absolute; right: 26px"
        >
          <CancelIcon />
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
    </div>
    <div
      class="d-flex justify-center align-center"
      style="margin-bottom: 20px; margin-top: 100px"
    >
      <div
        class="container flex-column"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 1190px;
        "
      >
        <template v-if="app.uid === undefined || loading">
          <Loader />
        </template>
        <template v-else>
          <div class="mb-8">
            <v-row class="d-flex justify-center align-center">
              <v-col md="2">
                <AppIcon :image="app.icon" :uid="app.id" />
              </v-col>
              <v-col class="text-center">
                <div class="headline font-weight-medium mb-1">
                  Let's upgrade & publish your dream app
                </div>
                {{ app.name }}: {{ $tr('project', 'key_306') }}
              </v-col>
            </v-row>
          </div>
          <template v-if="step === 1">
            <div class="block_width mb-5">
              <v-item-group mandatory class="mb-2" v-model="plan">
                <!-- <v-row>
                  <v-col
                      v-for="(item, i) in plans"
                      :key="`${i}_app_plan_layout`"
                      cols="12"
                      md="12"
                      @click="plan = i"
                  >
                    <v-card
                        outlined
                        :class="`pa-3 ${plan === i ? 'app_template active' : 'app_template'}`"
                    >
                      <div class="d-flex justify-space-between align-center">
                        <div>
                          <div class="title">
                            {{ item.name }} <span class="body-2">plan</span>
                          </div>
                          <div class="caption smoke--text" v-if="i === 0">
                            {{ $tr('project', 'key_309') }}
                          </div>
                          <div class="caption success--text font-weight-bold" v-else>
                            {{ $tr('project', 'key_310') }} {{ item.save }} {{ item.currency }}
                          </div>
                        </div>
                        <div class="title">
                          {{ item.price }} {{ item.symbol }} <span class="body-2">/ Annualy</span>
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                </v-row> -->

                <v-row class="justify-center align-center">
                  <h4>Monthly &nbsp; &nbsp;</h4>
                  <v-switch v-model="switch1" inset color="black"></v-switch>
                  <h4>Yearly</h4>
                </v-row>
                <v-row v-if="switch1 == true">
                  <!-- style="font-family:'Comfortaa', sans-serif;" -->
                  <!-- truee yearly -->
                  <v-col>
                    <div class="single-pricing-box">
                      <img
                        src="https://app.shopimint.com/backend/static/plans/shopimintpro.png"
                        width="200px"
                      />
                      <h4>The perfect plan for small businesses</h4>
                      <h1 class="price">
                        $ {{ plans[0]['display_price'] }}/
                        <span class="body-2">mo</span>
                        <span
                          style="
                            color: white;
                            font-size: 12px;
                            background-color: red;
                            padding: 8px;
                            border-radius: 15px;
                            margin-top: 5px;
                            max-width: fit-content;
                            margin-left: 10px;
                          "
                          >Save {{ plans[0]['save'] }}%
                        </span>
                      </h1>
                      <h4 style="padding-bottom: 10px">
                        Billed Annualy - USD $348
                      </h4>

                      <ul class="features-list">
                        <li>
                          <RightIcon />
                          Theme Collection
                        </li>
                        <li>
                          <RightIcon />
                          App Builder with Page Customizations
                        </li>
                        <li>
                          <RightIcon />
                          10 Push Notifications Per Month
                        </li>
                        <li>
                          <RightIcon />
                          IOS & Android App
                        </li>
                        <li>
                          <RightIcon />
                          Payment Gateways & Buy Now Pay Later Intergration
                        </li>
                      </ul>
                    </div>
                    <v-btn
                      v-if="app.uid !== undefined || !loading"
                      block
                      depressed
                      color="primary"
                      large
                      class="mt-8"
                      @click="gotoplan(0)"
                    >
                      Get Started
                    </v-btn>
                  </v-col>
                  <v-col>
                    <div :v-model="plan" class="single-pricing-box">
                      <img
                        src="https://app.shopimint.com/backend/static/plans/shopimintultimate.png"
                        width="200px"
                      />
                      <span
                        style="
                          color: white;
                          font-size: 13px;
                          background-color: black;
                          padding: 10px;
                          border-radius: 15px;
                          float: right;
                          margin-top: -10px;
                          margin-right: -20px;
                        "
                        >Most Popular</span
                      >
                      <h4>The perfect plan for growing businesses</h4>
                      <h1 class="price">
                        $ {{ plans[1]['display_price'] }}/
                        <span class="body-2">mo</span>
                        <span
                          style="
                            color: white;
                            font-size: 12px;
                            background-color: red;
                            padding: 8px;
                            border-radius: 15px;
                            margin-top: 5px;
                            max-width: fit-content;
                            margin-left: 10px;
                          "
                          >Save {{ plans[1]['save'] }}%
                        </span>
                      </h1>
                      <h4 style="padding-bottom: 10px">
                        Billed Annualy - USD $888
                      </h4>

                      <ul class="features-list">
                        <li>
                          <RightIcon />
                          All Shopimint Pro Features
                        </li>
                        <li>
                          <RightIcon />
                          Unlimited Push Notifications
                        </li>
                        <li>
                          <RightIcon />
                          Abonded Cart Notifications with Marketing Automation
                        </li>
                        <li>
                          <RightIcon />
                          Augmented Reality ( AR )
                        </li>
                        <li>
                          <RightIcon />
                          Multi Language & Multi Currency
                        </li>
                      </ul>
                    </div>
                    <v-btn
                      v-if="app.uid !== undefined || !loading"
                      block
                      depressed
                      color="primary"
                      large
                      class="mt-8"
                      @click="gotoplan(1)"
                    >
                      Get Started
                    </v-btn>
                  </v-col>
                  <v-col>
                    <div class="single-pricing-box">
                      <img
                        src="https://app.shopimint.com/backend/static/plans/shopimintenterprice.png"
                        width="200px"
                      />
                      <h4>
                        The perfect plan for Shopify Plus & high-volume stores
                        who need more control.
                      </h4>
                      <h1 class="price">
                        <span style="font-size: 12px"
                          >Starting From <br
                        /></span>
                        $ {{ plans[2]['display_price'] }}/
                        <span class="body-2">mo</span>
                      </h1>
                      <ul class="features-list">
                        <li>
                          <RightIcon />
                          All Shopimint Ultimate Features
                        </li>
                        <li>
                          <RightIcon />
                          Dedicated Success Manager
                        </li>
                        <li>
                          <RightIcon />
                          Premium Intergrations
                        </li>
                        <li>
                          <RightIcon />
                          App Customizations with Designer
                        </li>
                      </ul>
                    </div>
                    <v-btn
                      v-if="app.uid !== undefined || !loading"
                      block
                      depressed
                      color="primary"
                      large
                      class="mt-8"
                      @click="gotoplan(2)"
                    >
                      Get Started
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col>
                    <div class="single-pricing-box">
                      <img
                        src="https://app.shopimint.com/backend/static/plans/shopimintpro.png"
                        width="200px"
                      />
                      <h4>The perfect plan for small businesses</h4>
                      <h1 class="price">
                        $ {{ plans[3]['display_price'] }}/
                        <span class="body-2">mo</span>
                      </h1>
                      <!-- <span style="color: white; font-size: 12px; background-color: red; padding: 8px; border-radius: 15px; margin-top: 5px; max-width: fit-content;">Save {{ plans[0]['save'] }}% </span> -->
                      <ul class="features-list">
                        <li>
                          <RightIcon />
                          Theme Collection
                        </li>
                        <li>
                          <RightIcon />
                          App Builder with Page Customizations
                        </li>
                        <li>
                          <RightIcon />
                          10 Push Notifications Per Month
                        </li>
                        <li>
                          <RightIcon />
                          IOS & Android App
                        </li>
                        <li>
                          <RightIcon />
                          Payment Gateways & Buy Now Pay Later Intergration
                        </li>
                      </ul>
                    </div>
                    <v-btn
                      v-if="app.uid !== undefined || !loading"
                      block
                      depressed
                      color="primary"
                      large
                      class="mt-8"
                      @click="gotoplan(3)"
                    >
                      Get Started
                    </v-btn>
                  </v-col>
                  <v-col>
                    <div class="single-pricing-box">
                      <img
                        src="https://app.shopimint.com/backend/static/plans/shopimintultimate.png"
                        width="200px"
                      />
                      <span
                        style="
                          color: white;
                          font-size: 13px;
                          background-color: black;
                          padding: 10px;
                          border-radius: 15px;
                          float: right;
                          margin-top: -10px;
                          margin-right: -20px;
                        "
                        >Most Popular</span
                      >
                      <h4>The perfect plan for growing businesses</h4>
                      <h1 class="price">
                        $ {{ plans[4]['display_price'] }}/
                        <span class="body-2">mo</span>
                      </h1>
                      <!-- <span style="color: white; font-size: 12px; background-color: red; padding: 8px; border-radius: 15px; margin-top: 5px; max-width: fit-content;">Save {{ plans[0]['save'] }}% </span> -->
                      <ul class="features-list">
                        <li>
                          <RightIcon />
                          All Shopimint Pro Features
                        </li>
                        <li>
                          <RightIcon />
                          Unlimited Push Notifications
                        </li>
                        <li>
                          <RightIcon />
                          Abonded Cart Notifications with Marketing Automation
                        </li>
                        <li>
                          <RightIcon />
                          Augmented Reality ( AR )
                        </li>
                        <li>
                          <RightIcon />
                          Multi Language & Multi Currency
                        </li>
                      </ul>
                    </div>
                    <v-btn
                      v-if="app.uid !== undefined || !loading"
                      block
                      depressed
                      color="primary"
                      large
                      class="mt-8"
                      @click="gotoplan(4)"
                    >
                      Get Started
                    </v-btn>
                  </v-col>
                  <v-col>
                    <div class="single-pricing-box">
                      <img
                        src="https://app.shopimint.com/backend/static/plans/shopimintenterprice.png"
                        width="200px"
                      />
                      <h4>
                        The perfect plan for Shopify Plus & high-volume stores
                        who need more control.
                      </h4>
                      <h1 class="price">
                        <span style="font-size: 12px"
                          >Starting From <br
                        /></span>
                        $ {{ plans[5]['display_price'] }}/
                        <span class="body-2">mo</span>
                      </h1>
                      <ul class="features-list">
                        <li>
                          <RightIcon />
                          All Shopimint Ultimate Features
                        </li>
                        <li>
                          <RightIcon />
                          Dedicated Success Manager
                        </li>
                        <li>
                          <RightIcon />
                          Premium Intergrations
                        </li>
                        <li>
                          <RightIcon />
                          App Customizations with Designer
                        </li>
                      </ul>
                    </div>
                    <v-btn
                      v-if="app.uid !== undefined || !loading"
                      block
                      depressed
                      color="primary"
                      large
                      class="mt-8"
                      @click="gotoplan(5)"
                    >
                      Get Started
                    </v-btn>
                  </v-col>
                </v-row>
              </v-item-group>
              <v-btn
                v-if="app.uid !== undefined || !loading"
                block
                depressed
                color="primary"
                large
                class="mt-8"
                @click="goToMethods"
                style="display: none"
              >
                {{ $tr('project', 'key_16') }}
              </v-btn>
            </div>
          </template>
          <template v-else-if="step === 2">
            <div class="block_width mb-5">
              <div class="d-flex justify-start align-center mb-3">
                <v-btn icon color="smoke" small class="mr-3" @click="step = 1">
                  <v-icon small>mdi-arrow-left</v-icon>
                </v-btn>
                <div class="caption smoke--text">
                  {{ $tr('project', 'key_434') }}
                </div>
              </div>
              <div class="row whitebackground">
                <div class="col-md-8">
                  <v-row class="pa-3">
                    <v-col cols="12">
                      <div class="caption smoke--text">
                        {{ $tr('project', 'key_435') }}
                      </div>
                    </v-col>
                    <v-col cols="6" md="6">
                      <v-text-field
                        :label="$tr('project', 'key_436')"
                        outlined
                        color="primary"
                        dense
                        hide-details
                        v-model="user.first_name"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="6">
                      <v-text-field
                        :label="$tr('project', 'key_437')"
                        outlined
                        color="primary"
                        dense
                        hide-details
                        v-model="user.last_name"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="6">
                      <v-text-field
                        :label="$tr('project', 'key_438')"
                        outlined
                        color="primary"
                        dense
                        hide-details
                        v-model="user.email"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="6">
                      <v-text-field
                        :label="$tr('project', 'key_439')"
                        outlined
                        color="primary"
                        dense
                        hide-details
                        v-model="user.phone_number"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        :label="$tr('project', 'key_440')"
                        outlined
                        color="primary"
                        dense
                        hide-details
                        v-model="user.billing_address"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" md="4">
                      <v-select
                        :label="$tr('project', 'key_441')"
                        :items="countriesitems"
                        dense
                        hide-details
                        outlined
                        v-model="user.country"
                      ></v-select>
                    </v-col>
                    <v-col cols="4" md="4">
                      <v-text-field
                        :label="$tr('project', 'key_443')"
                        outlined
                        color="primary"
                        dense
                        hide-details
                        v-model="user.state"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" md="4">
                      <v-text-field
                        :label="$tr('project', 'key_442')"
                        outlined
                        color="primary"
                        dense
                        hide-details
                        v-model="user.zip_code"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <div class="col-md-4">
                  <v-item-group mandatory class="mb-2">
                    <div class="caption smoke--text mb-2">Order Details</div>
                    <div v-if="plan === 0 | plan === 3">
                      <img
                        src="https://app.shopimint.com/backend/static/plans/shopimintpro.png"
                        width="200px"
                      />
                      <v-simple-table>
                        <tbody style="font-weight: bold">
                          <tr>
                            <td style="padding: 11px 0px">Shopimint Pro</td>
                            <td style="padding: 11px 0px">${{subtotal}}</td>
                          </tr>
                          <tr>
                            <td style="padding: 11px 0px">Discount</td>
                            <td style="padding: 11px 0px">${{discount}}</td>
                          </tr>
                          <tr>
                            <td style="padding: 11px 0px">Total</td>
                            <td style="padding: 11px 0px">${{totalPrice }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <p class="caption smoke--text mb-2"></p>
                    </div>
                    <div v-else-if="plan === 1 | plan === 4">
                      <img
                        src="https://app.shopimint.com/backend/static/plans/shopimintultimate.png"
                        width="200px"
                      />
                      <v-simple-table>
                        <tbody style="font-weight: bold">
                          <tr>
                            <td style="padding: 11px 0px">
                              Shopimint Ultimate
                            </td>
                            <td style="padding: 11px 0px">${{subtotal }}</td>
                          </tr>
                          <tr>
                            <td style="padding: 11px 0px">Discount</td>
                            <td style="padding: 11px 0px">${{discount}}</td>
                          </tr>
                          <tr>
                            <td style="padding: 11px 0px">Total</td>
                            <td style="padding: 11px 0px">${{totalPrice }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </div>
                    <div v-else-if="plan === 2 | plan === 5">
                      <img
                        src="https://app.shopimint.com/backend/static/plans/shopimintenterprice.png"
                        width="200px"
                      />
                      <v-simple-table>
                        <tbody style="font-weight: bold">
                          <tr>
                            <td style="padding: 11px 0px">
                              Shopimint Enterpirce
                            </td>
                            <td style="padding: 11px 0px">${{subtotal }}</td>
                          </tr>
                          <tr>
                            <td style="padding: 11px 0px">Discount</td>
                            <td style="padding: 11px 0px">${{discount}}</td>
                          </tr>
                          <tr>
                            <td style="padding: 11px 0px">Total</td>
                            <td style="padding: 11px 0px">${{totalPrice }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </div>

                    <div class="caption mb-2">
                      Have a coupon? <br /><span> </span>
                      <button
                        style="text-decoration: underline"
                        @click="coupenshow = !coupenshow"
                      >
                        CLICK HERE TO ENTER YOUR CODE
                      </button>
                    </div>
                    <div v-if="coupenshow">
                      <v-row>
                        <v-col sm="7">
                          <v-text-field
                            :label="$tr('project', 'key_444')"
                            outlined
                            color="primary"
                            dense
                            class="mb-2"
                            hide-details
                            v-model="code"
                          ></v-text-field>
                        </v-col>
                        <v-col sm="5">
                          <v-btn
                            block
                            depressed
                            color="dark"
                            style="
                              color: white;
                              background-color: black;
                              height: 45px;
                              margin-top: 3px;
                            "
                            medium
                            :loading="isCheck"
                            @click="checkcoupon"
                          >
                            {{ $tr('project', 'key_445') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                    <span
                      v-if="couponvalid == 0"
                      class="caption"
                      style="color: red"
                      >{{couponmsg}}</span
                    >
                    <span
                      v-if="couponvalid == 1"
                      class="caption"
                      style="color: green"
                      >{{couponmsg}}</span
                    >
                  </v-item-group>
                  <v-item-group mandatory class="mb-2" v-model="method">
                    <v-row>
                      <v-col cols="12" md="12">
                        <div class="caption" style="padding-top: 5px">
                          {{ $tr('project', 'key_200') }}
                        </div>
                      </v-col>
                      <v-col
                        v-for="(item, i) in methods"
                        :key="`${i}_deposit_method`"
                        cols="12"
                        md="12"
                        @click="method = i"
                      >
                        <v-card
                          outlined
                          :class="`pa-3 ${method === i ? 'app_template active' : 'app_template'}`"
                        >
                          <div class="d-flex justify-start align-center">
                            <img
                              :src="item.logo"
                              width="52"
                              class="rounded-circle mr-4"
                            />
                            <div class="title">
                              {{ item.name }}
                            </div>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-btn
                      v-if="app.uid !== undefined || !loading"
                      block
                      depressed
                      color="primary"
                      style="margin-top: 10px"
                      large
                      @click="goToPay"
                    >
                      {{ $tr('project', 'key_16') }}
                    </v-btn>
                  </v-item-group>
                </div>
              </div>
            </div>
          </template>
          <template v-if="step === 3">
            <div class="block_width mb-5">
              <div class="d-flex justify-start align-center mb-3">
                <v-btn icon color="smoke" small class="mr-3" @click="step = 2">
                  <v-icon small>mdi-arrow-left</v-icon>
                </v-btn>
                <div class="caption smoke--text">
                  {{ $tr('project', 'key_311') }}
                </div>
              </div>
              <div class="row whitebackground">
                <div class="col-md-5">
                  <div class="caption smoke--text mb-2">Order Details</div>
                  <div v-if="plan === 0 | plan === 3">
                    <img
                      src="https://app.shopimint.com/backend/static/plans/shopimintpro.png"
                      width="200px"
                    />
                    <v-simple-table>
                      <tbody style="font-weight: bold">
                        <tr>
                          <td style="padding: 11px 0px;">Shopimint Pro</td>
                          <td style="padding: 11px 0px;">${{subtotal}}</td>
                        </tr>
                        <tr>
                          <td style="padding: 11px 0px;">Discount</td>
                          <td style="padding: 11px 0px;">${{discount}}</td>
                        </tr>
                        <tr>
                          <td style="padding: 11px 0px;">Total</td>
                          <td style="padding: 11px 0px;">${{totalPrice }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <p class="caption smoke--text mb-2"></p>
                  </div>
                  <div v-else-if="plan === 1 | plan === 4">
                    <img
                      src="https://app.shopimint.com/backend/static/plans/shopimintultimate.png"
                      width="200px"
                    />
                    <v-simple-table>
                      <tbody style="font-weight: bold">
                        <tr>
                          <td style="padding: 11px 0px;">Shopimint Ultimate</td>
                          <td style="padding: 11px 0px;">${{subtotal }}</td>
                        </tr>
                        <tr>
                          <td style="padding: 11px 0px;">Discount</td>
                          <td style="padding: 11px 0px;">${{discount}}</td>
                        </tr>
                        <tr>
                          <td style="padding: 11px 0px;">Total</td>
                          <td style="padding: 11px 0px;">${{totalPrice }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </div>
                  <div v-else-if="plan === 2 | plan === 5">
                    <img
                      src="https://app.shopimint.com/backend/static/plans/shopimintenterprice.png"
                      width="200px"
                    />
                    <v-simple-table>
                      <tbody style="font-weight: bold">
                        <tr>
                          <td style="padding: 11px 0px;">
                            Shopimint Enterpirce
                          </td>
                          <td style="padding: 11px 0px;">${{subtotal }}</td>
                        </tr>
                        <tr>
                          <td style="padding: 11px 0px;">Discount</td>
                          <td style="padding: 11px 0px;">${{discount}}</td>
                        </tr>
                        <tr>
                          <td style="padding: 11px 0px;">Total</td>
                          <td style="padding: 11px 0px;">${{totalPrice }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="mb-5">
                    <stripe-element-card
                      :key="`${$store.state.darkMode}_stripe_key_${key}`"
                      ref="elementRef"
                      class="red--text"
                      :pk="$store.state.config.stripe_key"
                      :classes="classes"
                      :elementStyle="stylesCard"
                      @token="tokenCreated"
                      @loading="loadingStripe"
                    />
                  </div>
                  <v-btn
                    v-if="app.uid !== undefined || !loading"
                    block
                    depressed
                    color="primary"
                    large
                    @click="submitStripeForm"
                  >
                    {{ $tr('project', 'key_312') }} {{ totalPrice }}
                    {{ currency }}
                  </v-btn>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
    <template v-if="!loading">
      <form ref="ppForm" method="POST" :action="methods[method].api_value_2">
        <input
          type="hidden"
          name="business"
          :value="methods[method].api_value_1"
        />
        <input
          type="hidden"
          name="item_name"
          :value="$tr('project', 'key_305')"
        />
        <input type="hidden" name="custom" :value="app.uid" />
        <input type="hidden" name="item_number" :value="plans[plan].id" />
        <input type="hidden" name="amount" :value="totalPrice" />
        <input type="hidden" name="no_shipping" value="1" />
        <input type="hidden" name="currency_code" :value="currencyCode" />
        <input type="hidden" name="cancel_return" :value="returnUrl" />
        <input type="hidden" name="return" value="" />
        <input type="hidden" name="cmd" value="_xclick" />
      </form>
    </template>
    <template v-if="razorOrderID">
      <Razorpay
        :keyID="methods[method].api_value_1"
        :amount="totalPrice * 100"
        :currency="currencyCode"
        :name="`${app.name}: ${$tr('project', 'key_306')}`"
        :orderID="razorOrderID"
        @success="createTransactionRazor"
      />
    </template>
    <template v-if="openPayStack">
      <Paystack
        :email="$store.state.user.email"
        :currency="currencyCode"
        :keyID="methods[method].api_value_1"
        :amount="totalPrice * 100"
        :planID="plans[plan].id"
        :appUID="app.uid"
        @close="openPayStack = false"
        @success="createTransactionStack"
      />
    </template>
  </v-card>
</template>

<script>
import CancelIcon from "@/components/icons/CancelIcon";
import Logo from "@/assets/images/logosvg.svg";
import Loader from "@/components/blocks/Loader";
import AppIcon from "@/components/blocks/AppIcon";
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import Razorpay from "@/components/payment/Razorpay";
import Paystack from "@/components/payment/Paystack";
import RightIcon from "../icons/RightIcon.vue";
import { countries } from "@/data/countries";

export default {
  name: 'Deposit',
  components: {
    CancelIcon,
    Loader,
    AppIcon,
    StripeElementCard,
    Razorpay,
    Paystack,
    RightIcon
},
  props: {
    app: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    classes: {
      base: "card_input_base",
      focus: "card_input_focus"
    },
    logoSvg: Logo,
    plan: 3,
    loading: true,
    isCheck:false,
    plans: 0,
    accessSource: false,
    isRequest: false,
    countrylist:countries ,
    key: 1,
    coupenshow:"",
    methods: [],
    step: 1,
    user: {
      email:"",
      first_name:"",
      last_name:"",
      phone_number:"",
      billing_address:"",
      country:"",
      zip_code:"",
      state:"",
    },
    code:"",
    method: 0,
    razorOrderID: null,
    openPayStack: false,
    switch1: true,
    planname:"",
    subtotal:0,
    discount:0,
    couponcode:"",
    fulltotal:0,
    couponmsg:"",
    couponvalid:null,
  }),
  computed: {
    totalPrice: function () {
      // let total = this.plans[this.plan].price * 1;
      let total = this.fulltotal;
      console.log(this.plan)
      return total.toFixed(2);
    },
    currency: function () {
      return this.plans[this.plan].symbol;
    },
    currencyCode: function () {
      return this.plans[this.plan].currency;
    },
    stylesCard: function () {
      return {
        base: {
          color: this.$store.state.darkMode ? '#fff' : '#000',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: '#fce883',
          },
          '::placeholder': {
            color: this.$store.state.darkMode ? '#959cb7' : '#99A2AD',
          },
        }
      };
    },
    returnUrl: function () {
      return window.location;
    },
    countriesitems : function(){
      return this.countrylist;
    }
  },
  watch: {
    method: function () {
      this.razorOrderID = null;
      this.openPayStack = false;
    }
  },
  methods: {
    goToPay() {
      console.log(this.methods[this.method].id);
      if (this.methods[this.method].id === 1) {
        //update
        let params = new URLSearchParams();
        params.append('email', this.user.email);
        params.append('first_name', this.user.first_name);
        params.append('last_name', this.user.last_name);
        params.append('phone_number', this.user.phone_number);
        params.append('billing_address', this.user.billing_address);
        params.append('country', this.user.country);
        params.append('zip_code', this.user.zip_code);
        params.append('state', this.user.state);

        this.$http.post(`${this.$serverApiLink}api/account/users/update_users/${this.$route.params.uid}`, params).
        then(
            // eslint-disable-next-line no-unused-vars
            response => {
              //strip method
              this.step = 3;
            }
        ).catch(
            error => {
              this.$store.commit('setSnackBar', {
                code: !error.response ? 408 : error.response.status,
                message: error.response.data.message
              });
              this.btnLoad = false;
            }
        );
      } else if (this.methods[this.method].id === 2) {
        // paypal
        this.$store.commit('setLoading', true);
        const form = this.$refs.ppForm
        form.submit();
      } else if (this.methods[this.method].id === 3) {
        // razorpay
        this.createOrderRazor();
      } else if (this.methods[this.method].id === 4) {
        // paystack
        this.openPayStack = true;
      }
    },
    calculateprice(){
      this.fulltotal = this.subtotal - this.discount;
    },
    checkcoupon(){
      this.isCheck = true;
      let params = new URLSearchParams();

      params.append('code', this.code);
      params.append('plan', this.plan);

      this.$http.post(`${this.$serverApiLink}api/account/coupon/validatecoupon/${this.$route.params.uid}`, params).
      then(
          // eslint-disable-next-line no-unused-vars
          response => {
            console.log("success");

            if (response.data.status == 1) {
              this.subtotal = response.data.subtotal ;
              this.discount = response.data.discount ;
              this.fulltotal = response.data.fulltotal ;
              this.couponmsg = "Coupon Added Successfully" ;
              this.couponvalid = 1;
              this.calculateprice();
              this.isCheck = false;
            } else {
              this.subtotal = this.plans[this.plan].price * 1;
              this.discount = 0;
              this.fulltotal = this.plans[this.plan].price * 1;
              this.couponmsg = "Invalid Coupon Code" ;
              this.couponvalid = 0;
              this.calculateprice();
              this.isCheck = false;
            }
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 400 : error.response.status,
              message: 'Something went wrong. Please try again after reloading page.'
            });
            this.isUpdate = false;
          }
      );
    },
    gotoplan(selectedplan){
      this.plan = selectedplan;
      this.subtotal = this.plans[this.plan].price * 1;
      this.fulltotal = this.plans[this.plan].price * 1;
      this.goToMethods();
    },
    goToMethods() {
      this.step = 2;
      this.$http.get(`${this.$serverApiLink}api/account/users/detail/${this.$route.params.uid}`).
      then(
          response => {
            this.user = response.data.detail;
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
          }
      );
    },
    submitStripeForm() {
      this.$refs.elementRef.submit();
    },
    loadingStripe(status) {
      if (!this.isRequest) {
        this.$store.commit('setLoading', status);
      }
    },
    createTransactionStack(reference) {
      this.openPayStack = false;
      this.$store.commit('setLoading', true);
      this.$http.get(`${this.$serverApiLink}api/account/deposit/paystack_make_pay/${reference}`).then(
          // eslint-disable-next-line no-unused-vars
          response => {
            this.$store.commit('setLoading', false);
            this.$store.commit('setSnackBar', {
              code: 200,
              message: [this.$tr("project", "key_313")]
            });
            this.$emit("add", this.plans[this.plan].count);
            this.step = 1;
            this.method = 0;
            this.$emit("close");
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.$store.commit('setLoading', false);
          }
      );
    },
    createTransactionRazor(payment) {
      this.razorOrderID = null;
      this.$store.commit('setLoading', true);
      let params = new URLSearchParams();
      params.append('order_id', payment.razorpay_order_id);
      params.append('razorpay_payment_id', payment.razorpay_payment_id);
      params.append('razorpay_signature', payment.razorpay_signature);
      this.$http.post(`${this.$serverApiLink}api/account/deposit/razorpay_make_pay`, params).then(
          // eslint-disable-next-line no-unused-vars
          response => {
            this.$store.commit('setLoading', false);
            this.$store.commit('setSnackBar', {
              code: 200,
              message: [this.$tr("project", "key_313")]
            });
            this.$emit("add", this.plans[this.plan].count);
            this.step = 1;
            this.method = 0;
            this.$emit("close");
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.$store.commit('setLoading', false);
          }
      );
    },
    createOrderRazor() {
      this.razorOrderID = null;
      this.$store.commit('setLoading', true);
      this.$http.get(`${this.$serverApiLink}api/account/deposit/create_order_razorpay/${this.app.uid}/${this.plans[this.plan].id}`).then(
          response => {
            this.$store.commit('setLoading', false);
            this.razorOrderID = response.data.id;
          }
      ).catch(
        error => {
          this.$store.commit('setSnackBar', {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message
          });
          this.$store.commit('setLoading', false);
          this.isRequest = false;
        }
      );
    },
    tokenCreated(token) {
      this.isRequest = true;
      this.$store.commit('setLoading', true);
      let params = new URLSearchParams();
      params.append('token', token.id);
      params.append('totalPrice', this.totalPrice);
      this.$http.post(`${this.$serverApiLink}api/account/deposit/make_pay/${this.app.uid}/${this.plans[this.plan].id}`, params).then(
          // eslint-disable-next-line no-unused-vars
          response => {
            this.key = this.key + 1;
            this.$store.commit('setLoading', false);
            this.isRequest = false;
            this.$store.commit('setSnackBar', {
              code: 200,
              message: [this.$tr("project", "key_313")]
            });
            this.$emit("add", this.plans[this.plan].count);
            this.$emit("root_update_plan", 'ultimate');
            this.step = 1;
            this.method = 0;
            this.$emit("close");
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.$store.commit('setLoading', false);
            this.isRequest = false;
          }
      );
    },
    getPlans() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/deposit/plans`).
      then(
          response => {
            this.plans = response.data.list;
            this.getDepositMethods();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
      );
    },
    getDepositMethods() {
      this.$http.get(`${this.$serverApiLink}api/account/deposit/methods`).
      then(
          response => {
            this.methods = response.data.list;
            this.loading = false;
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
      );
    }
  },
  mounted() {
    this.getPlans();
  }
}
</script>