<template>
    <v-card style="margin:5px; border-radius: 20px;" :to="item.route_name" >
        <v-img :src="item.photo"></v-img>
        <v-card-subtitle class="pt-1 pb-1 font-weight-normal text-center" style="font-size:18px;">
            {{ item.name }}
        </v-card-subtitle>
        <v-card-subtitle>
            <span style="font-size:14px;">
            {{ item.description}}
        </span>
        </v-card-subtitle>
        
        <v-card-actions class="text-end" style="width:100%;">
            <v-btn  color="blue" text small :to="item.route_name" > Configure
            <v-icon style="font-size:18px;">{{ show ? 'mdi-chevron-up' : 'mdi-arrow-right' }}</v-icon></v-btn>
            <!-- <v-btn icon @click="show = !show">
                
            </v-btn> -->
        </v-card-actions>
    </v-card>
</template>

<script>

export default {
    name: 'IntergrationCard',
    props: {
        item: {
            type: Object,
            required: true
        },
    },
    data: () => ({
        show :"",
    }),
}
</script>