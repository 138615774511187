<template>
  <v-layout fill-height>
    <v-main>
      <PageBar
        :title="$tr('menu', 'key_12')"
        :tabs="tabs"
        :drawer="true"
        @onChange="updateTab" />
      <Loader v-if="loading" />
      <template v-else>
        <Container v-if="!tab">
          <!--  App configurations -->
          <Title :title="$tr('project', 'key_127')" />
          <v-row class="mb-6">
            <v-col md="6" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_9')"
                outlined
                readonly
                color="primary"
                dense
                hide-details
                v-model="app.color_theme">
                <template v-slot:append>
                  <v-btn icon color="smoke" @click="openThemePicker(app)">
                    <v-avatar :size="18" :color="app.color_theme" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col md="6" sm="12" cols="12">
              <v-select
                :items="titles"
                dense
                hide-details
                :label="$tr('project', 'key_10')"
                outlined
                v-model="app.color_title"></v-select>
            </v-col>
          </v-row>

          <!-- App Bar Templates -->
          <Title :title="$tr('project', 'key_e7')" />
          <v-row>
            <v-col md="12" sm="12" cols="12" class="pb-10">
              <v-item-group mandatory v-model="app.appbar_style" class="row">
                <div
                  v-for="(item, i) in appbartemplates"
                  :key="`${i}_app_template_layout_builder`"
                  class="col-md-2 col-sm-2">
                  <img
                    :src="item.image"
                    :class="
                      app.appbar_style === i
                        ? 'app_template active'
                        : 'app_template'
                    "
                    @click="app.appbar_style = i"
                    style="width: 100%" />
                  <div class="caption text-uppercase">
                    {{ item.name }}
                  </div>
                </div>
              </v-item-group>
            </v-col>
          </v-row>

          <v-row class="mb-6" v-if="app.appbar_style == 3">
            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_420')"
                outlined
                color="primary"
                dense
                hide-details
                type="number"
                v-model="app.searchbar_width"></v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_421')"
                outlined
                color="primary"
                dense
                hide-details
                type="number"
                v-model="app.searchbar_height"></v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_422')"
                outlined
                readonly
                color="primary"
                dense
                hide-details
                v-model="app.searchbar_hint_text_color">
                <template v-slot:append>
                  <v-btn
                    icon
                    color="smoke"
                    @click="opensearchbar_hint_text_colorPicker(app)">
                    <v-avatar
                      :size="18"
                      :color="app.searchbar_hint_text_color" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_423')"
                outlined
                color="primary"
                dense
                hide-details
                type="number"
                v-model="app.searchbar_hint_text_size"></v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_424')"
                outlined
                color="primary"
                dense
                hide-details
                type="number"
                v-model="app.searchbar_border_radius"></v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_425')"
                outlined
                readonly
                color="primary"
                dense
                hide-details
                v-model="app.searchbar_fill_color">
                <template v-slot:append>
                  <v-btn
                    icon
                    color="smoke"
                    @click="opensearchbar_fill_colorPicker(app)">
                    <v-avatar :size="18" :color="app.searchbar_fill_color" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row class="mb-6">
            <v-col md="6" sm="12" cols="12" style="display: none">
              <v-select
                :items="statusItems"
                dense
                hide-details
                :label="$tr('project', 'key_131')"
                outlined
                v-model="app.display_title"></v-select>
            </v-col>

            <v-col md="6" sm="12" cols="12" style="display: none">
              <v-select
                :items="yesnooption"
                dense
                hide-details
                :label="$tr('project', 'key_e11')"
                outlined
                v-model="app.appbarbg_isnetworkimage"></v-select>
            </v-col>

            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_e12')"
                outlined
                color="primary"
                dense
                hide-details
                type="number"
                v-model="app.common_appbar_expanded_height"></v-text-field>
            </v-col>

            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_e49')"
                outlined
                color="primary"
                dense
                hide-details
                type="number"
                v-model="app.appbar_expanded_height"></v-text-field>
            </v-col>

            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_e13')"
                outlined
                readonly
                color="primary"
                dense
                hide-details
                v-model="app.appbar_background_color">
                <template v-slot:append>
                  <v-btn
                    icon
                    color="smoke"
                    @click="openAppBarBackgroundColorPicker(app)">
                    <v-avatar :size="18" :color="app.appbar_background_color" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>

            <v-col md="12" sm="12" cols="12">
              <Title :title="$tr('project', 'key_348')" />
              <v-col md="12" sm="12" cols="12" class="menuico">
                <v-item-group mandatory v-model="app.menuIcon" class="row">
                  <div
                    v-for="(item, i) in appmenuicons"
                    :key="`${i}_app_template_layout_builder`"
                    class="col">
                    <img
                      :src="item.image"
                      :class="
                        app.menuIcon === item.value
                          ? 'app_template active'
                          : 'app_template'
                      "
                      @click="app.menuIcon = item.value"
                      style="width: 50px" />
                  </div>
                </v-item-group>
              </v-col>
            </v-col>
          </v-row>

          <div class="permissions_block mb-3">
            <div class="d-flex justify-space-between align-center">
              <div class="d-flex justify-start align-center">
                <v-avatar
                  color="whitesmoke"
                  :size="60"
                  class="mr-4 pa-4"
                  v-if="!app.appbar_background_image">
                  <div class="smoke--text" style="width: 32px; height: 32px">
                    <ImageIcon :size="32" />
                  </div>
                </v-avatar>

                <div v-else style="width: 140px; height: 60px; margin: 10px">
                  <v-img
                    width="130"
                    height="60"
                    :src="app.appbar_background_image"
                    style="border-radius: 10px">
                    <template v-slot:placeholder>
                      <v-progress-circular
                        indeterminate
                        color="smoke"
                        size="32"
                        width="3"></v-progress-circular>
                    </template>
                  </v-img>
                </div>

                <div>
                  <div class="body-1 font-weight-medium">
                    {{ $tr("project", "key_e14") }}
                  </div>
                  <div class="body-2 smoke--text">
                    {{ $tr("project", "key_460") }}
                  </div>
                </div>
              </div>
              <div class="pa-4">
                <v-btn
                  :loading="appbarbackground_image.isSelecting"
                  small
                  depressed
                  class="ml-3"
                  @click="onButtonappbarBackgroundClick">
                  {{ $tr("project", "key_86") }}
                </v-btn>

                <v-btn
                  small
                  depressed
                  class="ml-3"
                  @click="openCreativePortal(app.appbar_background_image)">
                  Open Designer
                </v-btn>

                <input
                  ref="uploaderC"
                  class="d-none"
                  type="file"
                  accept="image/*"
                  @change="onFileappbarBackgroundChanged" />
              </div>
            </div>
          </div>
        </Container>

        <!-- branding area -->
        <Container v-else-if="tab === 1">
          <div class="text-left">
            <Title :title="$tr('project', 'key_350')" />
          </div>
          <v-simple-table
            light
            style="
              border: 1px solid black;
              margin-left: 10px;
              margin-right: 10px;
            ">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>
                    <h4>Selected Font</h4>
                    <h1 :style="selectedfontdetail.family">
                      {{ selectedfontdetail.name }}
                    </h1>
                  </td>
                  <td>
                    <v-btn
                      style="float: right"
                      depressed
                      color="secondary"
                      class="ml-3 text-left"
                      @click="selectFont = true">
                      Change Font &nbsp;
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div class="text-left" style="padding-top: 30px">
            <Title :title="$tr('project', 'key_365')" />
          </div>
          <v-simple-table light>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>
                    <v-select
                      :items="fontsizes"
                      dense
                      hide-details
                      :label="$tr('project', 'key_366')"
                      outlined
                      v-model="branding.buttonTitleTextSize"></v-select>
                  </td>
                  <td>
                    <v-select
                      :items="borderradius"
                      dense
                      hide-details
                      :label="$tr('project', 'key_367')"
                      outlined
                      v-model="branding.buttonBorderRadius"></v-select>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-simple-table light>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                    <Title :title="$tr('project', 'key_368')" />
                  </td>
                  <td style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                    <v-text-field
                      :label="$tr('project', 'key_74')"
                      outlined
                      readonly
                      color="primary"
                      dense
                      hide-details
                      v-model="branding.primaryButtonColor">
                      <template v-slot:append>
                        <v-btn
                          icon
                          color="smoke"
                          @click="openprimaryButtonColorPicker()">
                          <v-avatar
                            :size="18"
                            :color="branding.primaryButtonColor" />
                        </v-btn>
                      </template>
                    </v-text-field>
                  </td>
                  <td style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                    <v-text-field
                      :label="$tr('project', 'key_75')"
                      outlined
                      readonly
                      color="primary"
                      dense
                      hide-details
                      v-model="branding.primaryButtonTextColor">
                      <template v-slot:append>
                        <v-btn
                          icon
                          color="smoke"
                          @click="openprimaryButtonTextColorPicker()">
                          <v-avatar
                            :size="18"
                            :color="branding.primaryButtonTextColor" />
                        </v-btn>
                      </template>
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <td style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                    <Title :title="$tr('project', 'key_369')" />
                  </td>
                  <td style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                    <v-text-field
                      :label="$tr('project', 'key_74')"
                      outlined
                      readonly
                      color="primary"
                      dense
                      hide-details
                      v-model="branding.secondaryButtonColor">
                      <template v-slot:append>
                        <v-btn
                          icon
                          color="smoke"
                          @click="opensecondaryButtonColorPicker()">
                          <v-avatar
                            :size="18"
                            :color="branding.secondaryButtonColor" />
                        </v-btn>
                      </template>
                    </v-text-field>
                  </td>
                  <td style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                    <v-text-field
                      :label="$tr('project', 'key_75')"
                      outlined
                      readonly
                      color="primary"
                      dense
                      hide-details
                      v-model="branding.secondaryButtonTextColor">
                      <template v-slot:append>
                        <v-btn
                          icon
                          color="smoke"
                          @click="opensecondaryButtonTextColorPicker()">
                          <v-avatar
                            :size="18"
                            :color="branding.secondaryButtonTextColor" />
                        </v-btn>
                      </template>
                    </v-text-field>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div class="text-left" style="padding-top: 15px">
            <Title :title="$tr('project', 'key_349')" />
          </div>
          <div style="background-color: black; padding: 2px">
            <v-simple-table light>
              <template v-slot:default>
                <thead style="background-color: black; border-radius: 40px">
                  <tr>
                    <th class="text-left" style="color: white">Text Type</th>
                    <th class="text-left" style="color: white">Font Size</th>
                    <th class="text-left" style="color: white">Font Color</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                      <Title :title="$tr('project', 'key_351')" />
                    </td>
                    <td
                      style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                      <v-select
                        :items="titlefontsizes"
                        dense
                        hide-details
                        outlined
                        v-model="branding.title_size"></v-select>
                    </td>
                    <td
                      style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                      <v-text-field
                        :label="$tr('project', 'key_75')"
                        outlined
                        readonly
                        color="primary"
                        dense
                        hide-details
                        v-model="branding.titlecolor">
                        <template v-slot:append>
                          <v-btn icon color="smoke" @click="openTitlePicker()">
                            <v-avatar :size="18" :color="branding.titlecolor" />
                          </v-btn>
                        </template>
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                      <Title :title="$tr('project', 'key_352')" />
                    </td>
                    <td
                      style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                      <v-select
                        :items="fontsizes"
                        dense
                        hide-details
                        outlined
                        v-model="branding.subtitlesize"></v-select>
                    </td>
                    <td
                      style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                      <v-text-field
                        :label="$tr('project', 'key_75')"
                        outlined
                        readonly
                        color="primary"
                        dense
                        hide-details
                        v-model="branding.subtitlecolor">
                        <template v-slot:append>
                          <v-btn
                            icon
                            color="smoke"
                            @click="openSubtitlePicker()">
                            <v-avatar
                              :size="18"
                              :color="branding.subtitlecolor" />
                          </v-btn>
                        </template>
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                      <Title :title="$tr('project', 'key_353')" />
                    </td>
                    <td
                      style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                      <v-select
                        :items="fontsizes"
                        dense
                        hide-details
                        outlined
                        v-model="branding.secondaryfontsize"></v-select>
                    </td>
                    <td
                      style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                      <v-text-field
                        :label="$tr('project', 'key_75')"
                        outlined
                        readonly
                        color="primary"
                        dense
                        hide-details
                        v-model="branding.secondaryfontcolor">
                        <template v-slot:append>
                          <v-btn
                            icon
                            color="smoke"
                            @click="openSecondaryfontPicker()">
                            <v-avatar
                              :size="18"
                              :color="branding.secondaryfontcolor" />
                          </v-btn>
                        </template>
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                      <Title :title="$tr('project', 'key_354')" />
                    </td>
                    <td
                      style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                      <v-select
                        :items="fontsizes"
                        dense
                        hide-details
                        outlined
                        v-model="branding.bodyfontsize"></v-select>
                    </td>
                    <td
                      style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                      <v-text-field
                        :label="$tr('project', 'key_75')"
                        outlined
                        readonly
                        color="primary"
                        dense
                        hide-details
                        v-model="branding.bodyfontcolor">
                        <template v-slot:append>
                          <v-btn
                            icon
                            color="smoke"
                            @click="openBodyfontPicker()">
                            <v-avatar
                              :size="18"
                              :color="branding.bodyfontcolor" />
                          </v-btn>
                        </template>
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                      <Title :title="$tr('project', 'key_358')" />
                    </td>
                    <td
                      style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                      <v-select
                        :items="fontsizes"
                        dense
                        hide-details
                        outlined
                        v-model="branding.hightlightfontsize"></v-select>
                    </td>
                    <td
                      style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                      <v-text-field
                        :label="$tr('project', 'key_75')"
                        outlined
                        readonly
                        color="primary"
                        dense
                        hide-details
                        v-model="branding.hightlightfontcolor">
                        <template v-slot:append>
                          <v-btn
                            icon
                            color="smoke"
                            @click="openHightlightPicker()">
                            <v-avatar
                              :size="18"
                              :color="branding.hightlightfontcolor" />
                          </v-btn>
                        </template>
                      </v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>

          <div class="text-left" style="padding-top: 15px">
            <Title :title="$tr('project', 'key_359')" />
          </div>
          <v-simple-table light>
            <template v-slot:default>
              <!-- <thead>
                <tr >
                  <th class="text-left" style="color: black;">
                    Notification Type
                  </th>
                  <th class="text-left" style="color: black;">
                    Title
                  </th>
                  <th class="text-left" style="color: black;">
                    Message
                  </th>
                  <th class="text-left" style="color: black;">
                    Actions
                  </th>
                </tr>
              </thead> -->
              <tbody>
                <tr>
                  <td
                    style="
                      border-bottom: thin solid rgb(255 255 255 / 12%);
                      display: none;
                    ">
                    <v-text-field
                      :label="$tr('project', 'key_360')"
                      outlined
                      readonly
                      color="primary"
                      dense
                      hide-details
                      v-model="branding.primary_color">
                      <template v-slot:append>
                        <v-btn
                          icon
                          color="smoke"
                          @click="openPrimaryColorPicker()">
                          <v-avatar
                            :size="18"
                            :color="branding.primary_color" />
                        </v-btn>
                      </template>
                    </v-text-field>
                  </td>
                  <td
                    style="
                      border-bottom: thin solid rgb(255 255 255 / 12%);
                      display: none;
                    ">
                    <v-text-field
                      :label="$tr('project', 'key_361')"
                      outlined
                      readonly
                      color="primary"
                      dense
                      hide-details
                      v-model="branding.secondary_color">
                      <template v-slot:append>
                        <v-btn
                          icon
                          color="smoke"
                          @click="openSecondaryColorPicker()">
                          <v-avatar
                            :size="18"
                            :color="branding.secondary_color" />
                        </v-btn>
                      </template>
                    </v-text-field>
                  </td>
                  <td style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                    <v-text-field
                      :label="$tr('project', 'key_362')"
                      outlined
                      readonly
                      color="primary"
                      dense
                      hide-details
                      v-model="branding.warning_color">
                      <template v-slot:append>
                        <v-btn
                          icon
                          color="smoke"
                          @click="openWarningColorPicker()">
                          <v-avatar
                            :size="18"
                            :color="branding.warning_color" />
                        </v-btn>
                      </template>
                    </v-text-field>
                  </td>
                  <td style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                    <v-text-field
                      :label="$tr('project', 'key_363')"
                      outlined
                      readonly
                      color="primary"
                      dense
                      hide-details
                      v-model="branding.danger_color">
                      <template v-slot:append>
                        <v-btn
                          icon
                          color="smoke"
                          @click="openDangerColorPicker()">
                          <v-avatar :size="18" :color="branding.danger_color" />
                        </v-btn>
                      </template>
                    </v-text-field>
                  </td>
                  <td style="border-bottom: thin solid rgb(255 255 255 / 12%)">
                    <v-text-field
                      :label="$tr('project', 'key_364')"
                      outlined
                      readonly
                      color="primary"
                      dense
                      hide-details
                      v-model="branding.success_color">
                      <template v-slot:append>
                        <v-btn
                          icon
                          color="smoke"
                          @click="openSuccessColorPicker()">
                          <v-avatar
                            :size="18"
                            :color="branding.success_color" />
                        </v-btn>
                      </template>
                    </v-text-field>
                  </td>
                </tr>
                <tr></tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-row class="md-6" style="display: none">
            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_360')"
                outlined
                readonly
                color="primary"
                dense
                hide-details
                v-model="branding.primary_color">
                <template v-slot:append>
                  <v-btn icon color="smoke" @click="openPrimaryColorPicker()">
                    <v-avatar :size="18" :color="branding.primary_color" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_361')"
                outlined
                readonly
                color="primary"
                dense
                hide-details
                v-model="branding.secondary_color">
                <template v-slot:append>
                  <v-btn icon color="smoke" @click="openSecondaryColorPicker()">
                    <v-avatar :size="18" :color="branding.secondary_color" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_362')"
                outlined
                readonly
                color="primary"
                dense
                hide-details
                v-model="branding.warning_color">
                <template v-slot:append>
                  <v-btn icon color="smoke" @click="openWarningColorPicker()">
                    <v-avatar :size="18" :color="branding.warning_color" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_363')"
                outlined
                readonly
                color="primary"
                dense
                hide-details
                v-model="branding.danger_color">
                <template v-slot:append>
                  <v-btn icon color="smoke" @click="openDangerColorPicker()">
                    <v-avatar :size="18" :color="branding.danger_color" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_364')"
                outlined
                readonly
                color="primary"
                dense
                hide-details
                v-model="branding.success_color">
                <template v-slot:append>
                  <v-btn icon color="smoke" @click="openSuccessColorPicker()">
                    <v-avatar :size="18" :color="branding.success_color" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </Container>

        <Container v-else-if="tab === 10" style="display: none">
          <Title :title="$tr('project', 'key_349')" />
          <v-row class="mb-6">
            <v-col md="4" sm="12" cols="12">
              <Title :title="$tr('project', 'key_351')" />
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-select
                :items="fontsizes"
                dense
                hide-details
                outlined
                v-model="branding.title_size"></v-select>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_72')"
                outlined
                readonly
                color="primary"
                dense
                hide-details
                v-model="branding.titlecolor">
                <template v-slot:append>
                  <v-btn icon color="smoke" @click="openTitlePicker()">
                    <v-avatar :size="18" :color="branding.titlecolor" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <Title :title="$tr('project', 'key_352')" />
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-select
                :items="fontsizes"
                dense
                hide-details
                outlined
                v-model="branding.subtitlesize"></v-select>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_72')"
                outlined
                readonly
                color="primary"
                dense
                hide-details
                v-model="branding.subtitlecolor">
                <template v-slot:append>
                  <v-btn icon color="smoke" @click="openSubtitlePicker()">
                    <v-avatar :size="18" :color="branding.subtitlecolor" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <Title :title="$tr('project', 'key_353')" />
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-select
                :items="fontsizes"
                dense
                hide-details
                outlined
                v-model="branding.secondaryfontsize"></v-select>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_72')"
                outlined
                readonly
                color="primary"
                dense
                hide-details
                v-model="branding.secondaryfontcolor">
                <template v-slot:append>
                  <v-btn icon color="smoke" @click="openSecondaryfontPicker()">
                    <v-avatar :size="18" :color="branding.secondaryfontcolor" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <Title :title="$tr('project', 'key_354')" />
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-select
                :items="fontsizes"
                dense
                hide-details
                outlined
                v-model="branding.bodyfontsize"></v-select>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_72')"
                outlined
                readonly
                color="primary"
                dense
                hide-details
                v-model="branding.bodyfontcolor">
                <template v-slot:append>
                  <v-btn icon color="smoke" @click="openBodyfontPicker()">
                    <v-avatar :size="18" :color="branding.bodyfontcolor" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </Container>

        <!-- app drawer Templates -->
        <Container v-else-if="tab === 2">
          <Title :title="$tr('project', 'key_111')" />
          <v-row class="mb-6">
            <v-col md="6" sm="12" cols="12" style="display: none">
              <v-text-field
                :label="$tr('project', 'key_112')"
                outlined
                color="primary"
                dense
                hide-details
                v-model="drawer.title"></v-text-field>
            </v-col>
            <v-col md="6" sm="12" cols="12" style="display: none">
              <v-text-field
                :label="$tr('project', 'key_113')"
                outlined
                color="primary"
                dense
                hide-details
                v-model="drawer.subtitle"></v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-select
                :items="backgrounds"
                dense
                hide-details
                :label="$tr('project', 'key_114')"
                outlined
                v-model="drawer.mode"></v-select>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_74')"
                outlined
                readonly
                color="primary"
                dense
                hide-details
                v-model="drawer.color">
                <template v-slot:append>
                  <v-btn icon color="smoke" @click="openDrawerPicker()">
                    <v-avatar :size="18" :color="drawer.color" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_75')"
                outlined
                readonly
                color="primary"
                dense
                hide-details
                v-model="drawer.theme">
                <template v-slot:append>
                  <v-btn
                    icon
                    color="smoke"
                    @click="openDrawerTextColorPicker()">
                    <v-avatar :size="18" :color="drawer.theme" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-select
                :items="statusItems"
                dense
                hide-details
                :label="$tr('project', 'key_82')"
                outlined
                v-model="drawer.logo_enabled"></v-select>
            </v-col>

            <v-col md="4" sm="12" cols="12">
              <v-select
                :items="yesnooption"
                dense
                hide-details
                :label="$tr('project', 'key_e36')"
                outlined
                v-model="drawer.social"></v-select>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-select
                :items="alignmentItems"
                dense
                hide-details
                :label="$tr('project', 'key_e37')"
                outlined
                v-model="drawer.alignment"></v-select>
            </v-col>

            <v-col md="12" sm="12" cols="12" class="pb-10">
              <Title :title="$tr('project', 'key_11')" />
              <v-item-group mandatory v-model="drawer.drawerStyle" class="row">
                <div
                  v-for="(item, i) in drawertemplates"
                  :key="`${i}_app_template_layout_builder`"
                  class="col-md-2">
                  <img
                    :src="item.image"
                    :class="
                      drawer.drawerStyle == i
                        ? 'app_template active'
                        : 'app_template'
                    "
                    @click="drawer.drawerStyle = i"
                    style="width: 100%" />
                  <div
                    class="caption text-uppercase"
                    style="text-align: center">
                    {{ item.name }}
                  </div>
                </div>
              </v-item-group>
            </v-col>
          </v-row>
          <Title :title="$tr('project', 'key_83')" />
          <div class="permissions_block mb-3">
            <div class="d-flex justify-space-between align-center">
              <div class="d-flex justify-start align-center">
                <v-avatar
                  color="whitesmoke"
                  :size="60"
                  class="mr-4 pa-4"
                  v-if="!drawer.background">
                  <div class="smoke--text" style="width: 32px; height: 32px">
                    <ImageIcon :size="32" />
                  </div>
                </v-avatar>

                <div v-else style="width: 140px; height: 200px; margin: 10px">
                  <v-img
                    width="120"
                    max-height="200"
                    :src="drawer.background"
                    style="border-radius: 10px">
                    <template v-slot:placeholder>
                      <v-progress-circular
                        indeterminate
                        color="smoke"
                        size="32"
                        width="3"></v-progress-circular>
                    </template>
                  </v-img>
                </div>

                <div>
                  <div class="body-1 font-weight-medium">
                    {{ $tr("project", "key_e50") }}
                  </div>
                  <div class="body-2 smoke--text">
                    {{ $tr("project", "key_84") }}
                  </div>
                </div>
              </div>
              <div>
                <v-btn
                  :loading="background_image.isSelecting"
                  small
                  depressed
                  class="ml-3"
                  @click="onButtonBackgroundClick">
                  {{ $tr("project", "key_86") }}
                </v-btn>

                <v-btn
                  small
                  depressed
                  class="ml-3"
                  @click="openCreativePortal(drawer.background)">
                  Open Designer
                </v-btn>

                <input
                  ref="uploaderA"
                  class="d-none"
                  type="file"
                  accept="image/*"
                  @change="onFileBackgroundChanged" />
              </div>
            </div>
          </div>

          <div class="permissions_block mb-3">
            <div class="d-flex justify-space-between align-center">
              <div class="d-flex justify-start align-center">
                <div
                  color="whitesmoke"
                  :size="60"
                  class="mr-4"
                  v-if="!drawer.logo">
                  <v-img
                    width="130"
                    height="60"
                    :src="settings.logo"
                    style="border-radius: 10px">
                  </v-img>
                </div>
                <div v-else style="width: 140px; height: 60px; margin: 10px">
                  <v-img
                    width="130"
                    height="60"
                    :src="drawer.logo"
                    style="border-radius: 10px">
                    <template v-slot:placeholder>
                      <v-progress-circular
                        indeterminate
                        color="smoke"
                        size="32"
                        width="3"></v-progress-circular>
                    </template>
                  </v-img>
                </div>
                <div>
                  <div class="body-1 font-weight-medium">
                    {{ $tr("project", "key_81") }}
                  </div>
                  <div class="body-2 smoke--text">
                    {{ $tr("project", "key_87") }}
                  </div>
                </div>
              </div>
              <div>
                <v-btn
                  :loading="logo_image.isSelecting"
                  small
                  depressed
                  class="ml-3"
                  @click="onButtonLogoClick">
                  {{ $tr("project", "key_86") }}
                </v-btn>

                <v-btn
                  small
                  depressed
                  class="ml-3"
                  @click="openCreativePortal(drawer.logo)">
                  Open Designer
                </v-btn>

                <input
                  ref="uploaderB"
                  class="d-none"
                  type="file"
                  accept="image/*"
                  @change="onFileLogoChanged" />
              </div>
            </div>
          </div>
        </Container>

        <!-- Bottom Bar Section -->
        <Container v-else-if="tab === 3">
          <div class="d-flex justify-space-between align-center">
            <Title :title="$tr('project', 'key_e9')" />
          </div>
          <v-row class="mb-6">
            <v-col md="6" sm="12" cols="12" style="display: none">
              <v-select
                :items="statusItems"
                dense
                hide-details
                :label="$tr('project', 'key_42')"
                outlined
                v-model="app.pull_to_refresh"></v-select>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_329')"
                outlined
                readonly
                color="primary"
                dense
                hide-details
                v-model="app.icon_color">
                <template v-slot:append>
                  <v-btn icon color="smoke" @click="openIconPicker()">
                    <v-avatar :size="18" :color="app.icon_color" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_330')"
                outlined
                readonly
                color="primary"
                dense
                hide-details
                v-model="app.active_color">
                <template v-slot:append>
                  <v-btn icon color="smoke" @click="openActivePicker()">
                    <v-avatar :size="18" :color="app.active_color" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_e42')"
                outlined
                readonly
                color="primary"
                dense
                hide-details
                v-model="app.bottomBarBackgroundColor">
                <template v-slot:append>
                  <v-btn icon color="smoke" @click="openBottomPicker()">
                    <v-avatar
                      :size="18"
                      :color="app.bottomBarBackgroundColor" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <Title :title="$tr('project', 'key_11')" />
          <!-- {{ app.template }} -->
          <v-row>
            <v-col md="12" sm="12" cols="12" class="pb-0">
              <v-item-group
                mandatory
                v-model="app.template"
                class="d-flex justify-start align-center">
                <div
                  v-for="(item, i) in templates"
                  :key="`${i}_app_template_layout_builder`"
                  class="col-md-4">
                  <img
                    :src="item.image"
                    :class="
                      app.template === i
                        ? 'app_template active'
                        : 'app_template'
                    "
                    @click="app.template = i"
                    style="width: 100%" />
                  <div class="caption text-uppercase">
                    {{ item.name }}
                  </div>
                </div>
              </v-item-group>
            </v-col>
          </v-row>
        </Container>

        <!-- Loading Icon Section -->
        <Container v-else-if="tab === 4">
          <div class="d-flex justify-space-between align-center">
            <Title title="Loading Icon" />
          </div>
          <v-row class="mb-6">
            <v-col md="4" sm="12" cols="12" style="display: none">
              <v-select
                :items="loaders"
                dense
                hide-details
                :label="$tr('project', 'key_39')"
                outlined
                v-model="app.loader"></v-select>
            </v-col>

            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_49')"
                outlined
                readonly
                color="primary"
                dense
                hide-details
                v-model="app.loader_color">
                <template v-slot:append>
                  <v-btn icon color="smoke" @click="openLoaderPicker()">
                    <v-avatar :size="18" :color="app.loader_color" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>

            <v-col md="4" sm="12" cols="12">
              <v-text-field
                :label="$tr('project', 'key_370')"
                outlined
                color="primary"
                dense
                hide-details
                v-model="app.loader_width"></v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-select
                :items="appdelays"
                dense
                hide-details
                :label="$tr('project', 'key_456')"
                outlined
                v-model="app.delay"></v-select>
            </v-col>

            <v-col md="12" sm="12" cols="12" class="pb-10">
              <v-item-group mandatory v-model="app.loader" class="row">
                <div
                  v-for="(item, i) in loadingicons"
                  :key="`${i}_app_template_layout_builder`"
                  class="col-sm-3 col-md-2 col-xl-1"
                  style="width: 100px; height: 150px; text-align: center">
                  <img
                    :src="item.image"
                    :class="
                      app.loader === i - 1
                        ? 'app_template active'
                        : 'app_template'
                    "
                    @click="app.loader = i - 1"
                    style="
                      width: 100px;
                      height: 100px;
                      padding: 5px;
                      border-radius: 20px;
                    " />
                  <div class="caption text-uppercase text-center">
                    {{ item.name }}
                  </div>
                </div>
              </v-item-group>
            </v-col>
          </v-row>
        </Container>

        <!-- WebView Styles Section -->
        <Container v-else-if="tab === 5">
          <div class="d-flex justify-space-between align-center">
            <Title :title="$tr('project', 'key_133')" />
            <v-btn
              small
              depressed
              color="primary"
              outlined
              class="mb-5"
              @click="styleDialog.status = true">
              {{ $tr("project", "key_134") }}
            </v-btn>
          </div>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $tr("project", "key_135") }}
                  </th>
                  <th class="text-right" width="100px">
                    {{ $tr("project", "key_136") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!styles.length">
                  <tr>
                    <td colspan="2">
                      {{ $tr("project", "key_137") }}
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(item, i) in styles" :key="`${i}_styles_div`">
                    <td>.{{ item.name }}</td>
                    <td class="text-right">
                      <v-btn icon small color="danger" @click="removeStyle(i)">
                        <DeleteMiniIcon :size="16" />
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
          <v-row>
            <v-col>
              <Title :title="$tr('project', 'key_432')" />
              <v-textarea
                outlined
                color="white"
                class="customcss"
                dense
                height="300px"
                style="
                  background-color: rgb(205 205 205);
                  caret-color: black !important;
                "
                v-model="app.customcss"></v-textarea>
            </v-col>
          </v-row>
        </Container>
      </template>
      <Footer
        v-if="!loading && tab != 6"
        ref="footer"
        :loading="isUpdate"
        @click="startUpdate" />
      <v-dialog scrollable max-width="500" v-model="picker.status">
        <SelectColor
          :color="picker.color"
          @close="picker.status = false"
          @save="saveColor" />
      </v-dialog>
      <v-dialog scrollable max-width="500" v-model="styleDialog.status">
        <v-card flat tile>
          <v-toolbar flat class="modal_bar">
            <v-toolbar-title>
              {{ $tr("project", "key_294") }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="styleDialog.status = false">
              <CancelIcon />
            </v-btn>
          </v-toolbar>
          <v-divider />
          <v-container fluid>
            <v-row>
              <v-col md="12" sm="12" cols="12" class="pb-0">
                <v-text-field
                  :label="$tr('project', 'key_295')"
                  outlined
                  autofocus
                  color="primary"
                  dense
                  prefix="."
                  v-model="styleDialog.name"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-divider />
          <v-container fluid class="text-right">
            <v-btn
              depressed
              color="primary"
              min-width="100"
              :loading="styleDialog.loading"
              @click="createStyle">
              {{ $tr("project", "key_173") }}
            </v-btn>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog scrollable max-width="900" v-model="selectFont">
        <SelectFont
          @close="selectFont = false"
          @set="setFont"
          :appname="app.appname" />
      </v-dialog>
      <v-dialog width="1200" scrollable v-model="creative">
        <!-- <div style="width:100%; height:600px; background-color:#141517; padding:25px"> -->
        <div
          style="
            width: 100%;
            height: 600px;
            background-color: #ffffff;
            padding: 25px;
          ">
          <iframe
            :src="iframe.src"
            :width="1150"
            :height="550"
            type="text/html"
            frameborder="0"
            scrolling="no"></iframe>
        </div>
      </v-dialog>
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Container from "@/components/blocks/Container";
import Title from "@/components/blocks/Title";
import Loader from "@/components/blocks/Loader";
import SelectColor from "@/views/account/apps/builder/modals/SelectColor";
import DrawerTemplate from "@/assets/images/templates/drawer.png";
import TabsTemplate from "@/assets/images/templates/tabs.png";
import BarTemplate from "@/assets/images/templates/bar.png";
import BlankTemplate from "@/assets/images/templates/blank.png";
import Footer from "@/components/blocks/Footer";
import ImageIcon from "@/components/icons/ImageIcon";
import DeleteMiniIcon from "@/components/icons/DeleteMiniIcon";
import CancelIcon from "@/components/icons/CancelIcon";

import Appbarstyle01 from "@/assets/images/appbars/appbarstyle01.png";
import Appbarstyle02 from "@/assets/images/appbars/appbarstyle02.png";
import Appbarstyle03 from "@/assets/images/appbars/appbarstyle03.png";
import Appbarstyle04 from "@/assets/images/appbars/appbarstyle04.png";
import Appbarstyle05 from "@/assets/images/appbars/appbarstyle05.png";

// import Overlay from "@/assets/images/drawer/drawerstyle1.jpg";
import ScaleRight from "@/assets/images/drawer/drawerstyle1.png";
import ScaleRotate from "@/assets/images/drawer/drawerstyle2.png";
// import Stack from "@/assets/images/drawer/drawerstyle4.jpg";
import ScaleBottom from "@/assets/images/drawer/drawerstyle3.png";
import ScaleTop from "@/assets/images/drawer/drawerstyle4.png";
// import FixedStack from "@/assets/images/drawer/drawerstyle7.jpg";
// import ThreeDin from "@/assets/images/drawer/drawerstyle8.jpg";
// import ThreeDout from "@/assets/images/drawer/drawerstyle9.jpg";
// import Popup from "@/assets/images/drawer/drawerstyle10.jpg";

import BotttombarStyle1 from "@/assets/images/bottombar/bottombar1.png";
import BotttombarStyle2 from "@/assets/images/bottombar/bottombar2.png";
import BotttombarStyle3 from "@/assets/images/bottombar/bottombar3.png";

import loading1 from "@/assets/images/loadingspinners/RotatingPlane.gif";
import loading2 from "@/assets/images/loadingspinners/DoubleBounce.gif";
import loading3 from "@/assets/images/loadingspinners/Wave.gif";
import loading4 from "@/assets/images/loadingspinners/WanderingCubes.gif";
import loading5 from "@/assets/images/loadingspinners/ChasingDots.gif";
import loading6 from "@/assets/images/loadingspinners/ThreeBounce.gif";
import loading7 from "@/assets/images/loadingspinners/Circle.gif";
import loading8 from "@/assets/images/loadingspinners/CubeGrid.gif";
import loading9 from "@/assets/images/loadingspinners/FadingCircle.gif";
import loading10 from "@/assets/images/loadingspinners/RotatingCircle.gif";
import loading11 from "@/assets/images/loadingspinners/FoldingCube.gif";

import loading12 from "@/assets/images/loadingspinners/dual-ring.gif";
import loading13 from "@/assets/images/loadingspinners/FadingCube.gif";
import loading14 from "@/assets/images/loadingspinners/FadingFour.gif";
import loading15 from "@/assets/images/loadingspinners/grid.gif";
import loading16 from "@/assets/images/loadingspinners/heart.gif";
import loading17 from "@/assets/images/loadingspinners/hour-glass.gif";
import loading18 from "@/assets/images/loadingspinners/itemBuilder.gif";
import loading19 from "@/assets/images/loadingspinners/piano-wave.gif";

import loading20 from "@/assets/images/loadingspinners/pouring-hour-glass-refined.gif";
import loading21 from "@/assets/images/loadingspinners/PouringHourGlass.gif";
import loading22 from "@/assets/images/loadingspinners/ring.gif";
import loading23 from "@/assets/images/loadingspinners/ripple.gif";
import loading24 from "@/assets/images/loadingspinners/spinning-circle.gif";
import loading25 from "@/assets/images/loadingspinners/spinning-lines.gif";
import loading26 from "@/assets/images/loadingspinners/square_circle.gif";
import loading27 from "@/assets/images/loadingspinners/three-in-out.gif";
import loading28 from "@/assets/images/loadingspinners/dancing-square.gif";
import SelectFont from "./modals/SelectFont.vue";

import menuicon1 from "@/assets/images/menuicons/apps-outline.svg";
import menuicon2 from "@/assets/images/menuicons/chevron-forward-outline.svg";
import menuicon3 from "@/assets/images/menuicons/ellipsis-horizontal-circle-outline.svg";
import menuicon4 from "@/assets/images/menuicons/ellipsis-horizontal-outline.svg";
import menuicon5 from "@/assets/images/menuicons/ellipsis-vertical-circle-outline.svg";
import menuicon6 from "@/assets/images/menuicons/ellipsis-vertical-outline.svg";
import menuicon7 from "@/assets/images/menuicons/filter-circle-outline.svg";
import menuicon8 from "@/assets/images/menuicons/filter-outline.svg";
import menuicon9 from "@/assets/images/menuicons/grid-outline.svg";
import menuicon10 from "@/assets/images/menuicons/layers-outline.svg";
import menuicon11 from "@/assets/images/menuicons/list-circle-outline.svg";
import menuicon12 from "@/assets/images/menuicons/list-outline.svg";
import menuicon13 from "@/assets/images/menuicons/menu-outline.svg";
import menuicon14 from "@/assets/images/menuicons/reorder-four-outline.svg";
import menuicon15 from "@/assets/images/menuicons/reorder-three-outline.svg";
import menuicon16 from "@/assets/images/menuicons/reorder-two-outline.svg";
// import MoreIcon from "../../../../components/icons/MoreIcon.vue";

import { fonts } from "@/data/fonts";

export default {
  name: "Design",
  components: {
    PageBar,
    Container,
    Title,
    Loader,
    SelectColor,
    Footer,
    ImageIcon,
    DeleteMiniIcon,
    CancelIcon,
    SelectFont,
    // MoreIcon
  },
  data: () => ({
    loading: true,
    selectFont: false,
    icon: null,
    allfonts: fonts,
    fontloadonce: false,
    picker: {
      status: false,
      mode: "theme",
      color: "#FF4444",
    },
    selectedfontdetail: {
      code: "",
      name: "",
      family: "",
      familyurl: "",
      popular: true,
    },
    tab: 0,
    app: {
      //App
      appname: "",

      //Color Configrations
      color_theme: "#FF4444",
      color_title: 0,

      //App Bar Configrations
      appbar_style: 0,
      appbarbg_isnetworkimage: 0,
      appbar_background_image: "",
      appbar_expanded_height: 100,
      common_appbar_expanded_height: 60,
      appbar_background_color: "#FF4444",
      menuIcon: "",
      searchbar_width: "240",
      searchbar_height: "30",
      searchbar_hint_text_color: "#808080",
      searchbar_hint_text_size: "12",
      searchbar_border_radius: "20",
      searchbar_fill_color: "#FFFFFF",

      //Loading Configarations
      loader: 0,
      loader_color: "#F44336",
      loader_width: 50,
      delay: "",

      //App Configarations
      pull_to_refresh: 0,

      //Bottom Bar
      icon_color: "#F44336",
      active_color: "#F44336",
      bottomBarBackgroundColor: "#F44336",
      bottomBarStyle: "style_1",
      bottomBarActiveColor: "#0000FF",
      bottomBarIconColor: "#000000",

      template: 0,
      btn_color: "#F44336",
      display_title: 0,
      customcss: "",
    },
    drawer: {
      mode: 0,
      color: "#F44336",
      theme: "#F44336",
      logo_enabled: 0,
      title: "",
      subtitle: "",
      logo: null,
      background: null,
      social: 1,
      alignment: "left",
      drawerStyle: 0,
    },

    branding: {
      title_size: 22,
      titlecolor: "#F44336",
      subtitlesize: 16,
      subtitlecolor: "#07C620",
      secondaryfontsize: 14,
      secondaryfontcolor: "#602815",
      bodyfontsize: 12,
      bodyfontcolor: "#0D0D6D",
      hightlightfontsize: 16,
      hightlightfontcolor: "#0D0D6D",
      font: "Helvetica",
      primary_color: "#007BFF",
      secondary_color: "#6C757D",
      warning_color: "#FFC107",
      danger_color: "#42BA96",
      success_color: "#42BA96",
      buttonTitleTextSize: 20,
      buttonBorderRadius: 15,
      primaryButtonColor: "#000000",
      primaryButtonTextColor: "#FFFFFF",
      secondaryButtonColor: "#A1A1A1",
      secondaryButtonTextColor: "#FFFFFF",
    },
    settings: {
      background: "",
      logo: "",
    },
    styles: [],
    templates: [
      {
        image: BotttombarStyle2,
        name: "Style 1",
      },
      {
        image: BotttombarStyle1,
        name: "Style 2",
      },
      {
        image: BotttombarStyle3,
        name: "Style 3",
      },
    ],
    appbartemplates: [
      {
        image: Appbarstyle01,
        name: "App Bar 01",
      },
      {
        image: Appbarstyle02,
        name: "App Bar 02",
      },
      {
        image: Appbarstyle03,
        name: "App Bar 03",
      },
      {
        image: Appbarstyle04,
        name: "App Bar 04",
      },
      {
        image: Appbarstyle05,
        name: "App Bar 05",
      },
    ],
    appmenuicons: [
      {
        image: menuicon1,
        name: "apps-outline",
        value: "apps-outline.svg",
      },
      {
        image: menuicon2,
        name: "chevron-forward-outline",
        value: "chevron-forward-outline.svg",
      },
      {
        image: menuicon3,
        name: "ellipsis-horizontal-circle-outline",
        value: "ellipsis-horizontal-circle-outline.svg",
      },
      {
        image: menuicon4,
        name: "ellipsis-horizontal-outline",
        value: "ellipsis-horizontal-outline.svg",
      },
      {
        image: menuicon5,
        name: "ellipsis-vertical-circle-outline",
        value: "ellipsis-vertical-circle-outline.svg",
      },
      {
        image: menuicon6,
        name: "ellipsis-vertical-outline",
        value: "ellipsis-vertical-outline.svg",
      },
      {
        image: menuicon7,
        name: "filter-circle-outline",
        value: "filter-circle-outline.svg",
      },
      {
        image: menuicon8,
        name: "filter-outline",
        value: "filter-outline.svg",
      },
      {
        image: menuicon9,
        name: "grid-outline",
        value: "grid-outline.svg",
      },
      {
        image: menuicon10,
        name: "layers-outline",
        value: "layers-outline.svg",
      },
      {
        image: menuicon11,
        name: "list-circle-outline",
        value: "list-circle-outline.svg",
      },
      {
        image: menuicon12,
        name: "list-outline",
        value: "list-outline.svg",
      },
      {
        image: menuicon13,
        name: "menu-outline",
        value: "menu-outline.svg",
      },
      {
        image: menuicon14,
        name: "reorder-four-outline",
        value: "reorder-four-outline.svg",
      },
      {
        image: menuicon15,
        name: "reorder-three-outline",
        value: "reorder-three-outline.svg",
      },
      {
        image: menuicon16,
        name: "reorder-two-outline",
        value: "reorder-two-outline.svg",
      },
    ],

    drawertemplates: [
      // {
      //   image: Overlay,
      //   name: "Overlay"
      // },
      {
        image: ScaleRight,
        name: "Overlay",
      },
      {
        image: ScaleRotate,
        name: "Scale Bottom",
      },
      // {
      //   image: Stack,
      //   name: "Stack"
      // },
      {
        image: ScaleBottom,
        name: "3D Style 01",
      },
      {
        image: ScaleTop,
        name: "3D Style 02",
      },
      // {
      //   image: FixedStack,
      //   name: "Fixed Stack"
      // },
      // {
      //   image: ThreeDin,
      //   name: "3D in"
      // },
      // {
      //   image: ThreeDout,
      //   name: "3D Out"
      // },
      // {
      //   image: Popup,
      //   name: "popup"
      // },
    ],
    menuicons: [],
    bottombartemplates: [
      {
        image: DrawerTemplate,
        name: "Drawer + Bar",
      },
      {
        image: TabsTemplate,
        name: "Tabs + Bar",
      },
      {
        image: BarTemplate,
        name: "Bar",
      },
      {
        image: BlankTemplate,
        name: "Blank",
      },
    ],
    loadingicons: [
      { image: loading1, name: "RotatingPlane" },
      { image: loading2, name: "DoubleBounce" },
      { image: loading3, name: "Wave" },
      { image: loading4, name: "WanderingCubes" },
      { image: loading5, name: "ChasingDots" },
      { image: loading6, name: "ThreeBounce" },
      { image: loading7, name: "Circle" },
      { image: loading8, name: "CubeGrid" },
      { image: loading9, name: "FadingCircle" },
      { image: loading10, name: "RotatingCircle" },
      { image: loading11, name: "FoldingCube" },

      { image: loading12, name: "Dual Ring" },
      { image: loading13, name: "Fading Cube" },
      { image: loading14, name: "Fading Four" },
      { image: loading15, name: "Grid" },
      { image: loading16, name: "Heart" },
      { image: loading17, name: "Hour Glass" },
      { image: loading18, name: "Item Builder" },
      { image: loading19, name: "Piano Wave" },
      { image: loading20, name: "Pouring Glass SM" },
      { image: loading21, name: "Pouring Glass LG" },
      { image: loading22, name: "Ring" },
      { image: loading23, name: "Ripple" },
      { image: loading24, name: "Spinning Circle" },
      { image: loading25, name: "Spinning Lines" },
      { image: loading26, name: "Square Circle" },
      { image: loading27, name: "Three in Out" },
      { image: loading28, name: "Dancing Squre" },
    ],
    isUpdate: false,

    appbarbackground_image: {
      isSelecting: false,
      selectedFile: null,
      upload: false,
      loading: false,
    },
    background_image: {
      isSelecting: false,
      selectedFile: null,
      upload: false,
      loading: false,
    },
    logo_image: {
      isSelecting: false,
      selectedFile: null,
      upload: false,
      loading: false,
    },
    styleDialog: {
      status: false,
      name: "",
      loading: false,
    },
    creative: false,
    iframe: {
      src: "https://app.shopimint.com/backend/creative/generate",
    },
  }),
  computed: {
    backgrounds: function () {
      return [
        {
          text: this.$tr("project", "key_115"),
          value: 0,
        },
        {
          text: this.$tr("project", "key_116"),
          value: 1,
        },
        {
          text: this.$tr("project", "key_117"),
          value: 2,
        },
      ];
    },
    titlefontsizes: function () {
      return [
        {
          text: "20px",
          value: 20,
        },
        {
          text: "19px",
          value: 19,
        },
        {
          text: "18px",
          value: 18,
        },
        {
          text: "17px",
          value: 17,
        },
        {
          text: "16px",
          value: 16,
        },
        {
          text: "15px",
          value: 15,
        },
        {
          text: "14px",
          value: 14,
        },
        {
          text: "13px",
          value: 13,
        },
        {
          text: "12px",
          value: 12,
        },
        {
          text: "11px",
          value: 11,
        },
        {
          text: "10px",
          value: 10,
        },
        {
          text: "9px",
          value: 9,
        },
      ];
    },
    fontsizes: function () {
      return [
        {
          text: "16px",
          value: 16,
        },
        {
          text: "15px",
          value: 15,
        },
        {
          text: "14px",
          value: 14,
        },
        {
          text: "13px",
          value: 13,
        },
        {
          text: "12px",
          value: 12,
        },
        {
          text: "11px",
          value: 11,
        },
        {
          text: "10px",
          value: 10,
        },
        {
          text: "9px",
          value: 9,
        },
      ];
    },
    borderradius: function () {
      return [
        {
          text: "10px",
          value: 10,
        },
        {
          text: "20px",
          value: 20,
        },
        {
          text: "30px",
          value: 30,
        },
      ];
    },
    tabs: function () {
      return [
        this.$tr("project", "key_35"),
        this.$tr("project", "key_e43"),
        this.$tr("project", "key_e8"),
        this.$tr("project", "key_e9"),
        this.$tr("project", "key_e10"),
        this.$tr("project", "key_132"),
      ];
    },
    titles: function () {
      return [
        {
          text: this.$tr("project", "key_37"),
          value: 0,
        },
        {
          text: this.$tr("project", "key_38"),
          value: 1,
        },
      ];
    },
    statusItems: function () {
      return [
        {
          text: this.$tr("project", "key_43"),
          value: 0,
        },
        {
          text: this.$tr("project", "key_44"),
          value: 1,
        },
      ];
    },
    alignmentItems: function () {
      return [
        {
          text: this.$tr("project", "key_e39"),
          value: "left",
        },
        {
          text: this.$tr("project", "key_e40"),
          value: "right",
        },
        {
          text: this.$tr("project", "key_e41"),
          value: "center",
        },
      ];
    },
    yesnooption: function () {
      return [
        {
          text: "No",
          value: 0,
        },
        {
          text: "Yes",
          value: 1,
        },
      ];
    },
    appdelays: function () {
      return [
        {
          text: "0s",
          value: 0,
        },
        {
          text: "1s",
          value: 1,
        },
        {
          text: "2s",
          value: 2,
        },
        {
          text: "3s",
          value: 3,
        },
        {
          text: "4s",
          value: 4,
        },
        {
          text: "5s",
          value: 5,
        },
      ];
    },
    loaders: function () {
      return [
        {
          text: this.$tr("project", "key_48"),
          value: 0,
        },
        {
          text: this.$tr("project", "key_40"),
          value: 1,
        },
        {
          text: this.$tr("project", "key_41"),
          value: 2,
        },
      ];
    },
  },
  methods: {
    openCreativePortal(imageurl) {
      this.iframe.src =
        "https://app.shopimint.com/backend/creative/generate?imageurl=" +
        imageurl;
      this.creative = true;
      console.log(this.iframe.src);
      //this.getSplashDetail();
    },
    startUpdate() {
      if (!this.tab) {
        this.updateMain();
      } else if (this.tab === 1) {
        this.updateBranding();
      } else if (this.tab === 2) {
        this.updateDrawer();
      } else if (this.tab === 5) {
        this.updateCSS();
      } else {
        this.updateMain();
      }
    },
    setFont(value) {
      this.branding.font = value.toString();
      this.selectFont = false;
      this.setselectedfont();
    },
    updateMain() {
      this.isUpdate = true;
      let params = new URLSearchParams();
      params.append("color_theme", this.app.color_theme);
      params.append("color_title", this.app.color_title);
      params.append("appbar_style", this.app.appbar_style);
      params.append(
        "appbarbg_isnetworkimage",
        this.app.appbarbg_isnetworkimage
      );
      params.append("appbar_expanded_height", this.app.appbar_expanded_height);
      params.append(
        "appbar_background_color",
        this.app.appbar_background_color
      );
      params.append(
        "common_appbar_expanded_height",
        this.app.common_appbar_expanded_height
      );
      params.append("menuIcon", this.app.menuIcon);

      params.append("searchbar_width", this.app.searchbar_width);
      params.append("searchbar_height", this.app.searchbar_height);
      params.append(
        "searchbar_hint_text_color",
        this.app.searchbar_hint_text_color
      );
      params.append(
        "searchbar_hint_text_size",
        this.app.searchbar_hint_text_size
      );
      params.append(
        "searchbar_border_radius",
        this.app.searchbar_border_radius
      );
      params.append("searchbar_fill_color", this.app.searchbar_fill_color);

      params.append("template", this.app.template);
      params.append("bottomBarStyle", this.app.template);
      params.append("loader", this.app.loader);
      params.append("pull_to_refresh", this.app.pull_to_refresh);
      params.append("loader_color", this.app.loader_color);
      params.append("loader_width", this.app.loader_width);
      params.append("delay", this.app.delay);
      params.append("display_title", this.app.display_title);
      params.append("icon_color", this.app.icon_color);
      params.append("active_color", this.app.active_color);
      params.append(
        "bottomBarBackgroundColor",
        this.app.bottomBarBackgroundColor
      );
      params.append("customcss", this.app.customcss);

      console.log(this.app.appbar_style);

      this.$http
        .post(
          `${this.$serverApiLink}api/account/design/update_detail/${this.$route.params.uid}`,
          params
        )
        .then(
          // eslint-disable-next-line no-unused-vars
          (response) => {
            this.isUpdate = false;
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "app",
            });
            // this.$store.commit("setSnackBar", {
            //   code: 200,
            //   message: response.data.message,
            // });
            this.$refs.footer.showSuccessAlert();
          }
        )
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.isUpdate = false;
        });
    },
    updateBranding() {
      this.isUpdate = true;
      let params = new URLSearchParams();
      params.append("title_size", this.branding.title_size);
      params.append("titlecolor", this.branding.titlecolor);
      params.append("subtitlesize", this.branding.subtitlesize);
      params.append("subtitlecolor", this.branding.subtitlecolor);
      params.append("secondaryfontsize", this.branding.secondaryfontsize);
      params.append("secondaryfontcolor", this.branding.secondaryfontcolor);
      params.append("bodyfontsize", this.branding.bodyfontsize);
      params.append("bodyfontcolor", this.branding.bodyfontcolor);
      params.append("hightlightfontsize", this.branding.hightlightfontsize);
      params.append("hightlightfontcolor", this.branding.hightlightfontcolor);
      params.append("font", this.branding.font);
      params.append("primary_color", this.branding.primary_color);
      params.append("secondary_color", this.branding.secondary_color);
      params.append("warning_color", this.branding.warning_color);
      params.append("danger_color", this.branding.danger_color);
      params.append("success_color", this.branding.success_color);
      params.append("buttonTitleTextSize", this.branding.buttonTitleTextSize);
      params.append("buttonBorderRadius", this.branding.buttonBorderRadius);
      params.append("primaryButtonColor", this.branding.primaryButtonColor);
      params.append(
        "primaryButtonTextColor",
        this.branding.primaryButtonTextColor
      );
      params.append("secondaryButtonColor", this.branding.secondaryButtonColor);
      params.append(
        "secondaryButtonTextColor",
        this.branding.secondaryButtonTextColor
      );

      this.$http
        .post(
          `${this.$serverApiLink}api/account/branding/update_branding/${this.$route.params.uid}`,
          params
        )
        .then(
          // eslint-disable-next-line no-unused-vars
          (response) => {
            this.isUpdate = false;
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "app",
            });
            this.$refs.footer.showSuccessAlert();
          }
        )
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.btnLoad = false;
        });
    },
    updateDrawer() {
      this.isUpdate = true;
      let params = new URLSearchParams();
      params.append("mode", this.drawer.mode);
      params.append("color", this.drawer.color);
      params.append("theme", this.drawer.theme);
      params.append("logo_enabled", this.drawer.logo_enabled);
      params.append("title", this.drawer.title);
      params.append("subtitle", this.drawer.subtitle);
      params.append("social", this.drawer.social);
      params.append("alignment", this.drawer.alignment);
      params.append("drawerStyle", this.drawer.drawerStyle);

      this.$http
        .post(
          `${this.$serverApiLink}api/account/drawer/update_drawer/${this.$route.params.uid}`,
          params
        )
        .then(
          // eslint-disable-next-line no-unused-vars
          (response) => {
            this.isUpdate = false;
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "app",
            });
            this.$refs.footer.showSuccessAlert();
          }
        )
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.btnLoad = false;
        });
    },

    updateCSS() {
      this.isUpdate = true;
      let params = new URLSearchParams();
      params.append("customcss", this.app.customcss);

      this.$http
        .post(
          `${this.$serverApiLink}api/account/design/update_css/${this.$route.params.uid}`,
          params
        )
        .then(
          // eslint-disable-next-line no-unused-vars
          (response) => {
            this.isUpdate = false;
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "customcss",
            });
            this.$refs.footer.showSuccessAlert();
          }
        )
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.isUpdate = false;
        });
    },

    saveColor(color) {
      if (this.picker.mode === "theme") {
        this.app.color_theme = color;
      } else if (this.picker.mode === "loader") {
        this.app.loader_color = color;
      } else if (this.picker.mode === "drawer") {
        this.drawer.color = color;
      } else if (this.picker.mode === "icon") {
        this.app.icon_color = color;
      } else if (this.picker.mode === "active") {
        this.app.active_color = color;
      } else if (this.picker.mode === "appbarbackground") {
        this.app.appbar_background_color = color;
      } else if (this.picker.mode === "bottombackground") {
        this.app.bottomBarBackgroundColor = color;
      } else if (this.picker.mode === "branding") {
        this.branding.titlecolor = color;
      } else if (this.picker.mode === "brandingsubtitle") {
        this.branding.subtitlecolor = color;
      } else if (this.picker.mode === "secondaryfont") {
        this.branding.secondaryfontcolor = color;
      } else if (this.picker.mode === "bodyfont") {
        this.branding.bodyfontcolor = color;
      } else if (this.picker.mode === "hightlightfontcolor") {
        this.branding.hightlightfontcolor = color;
      } else if (this.picker.mode === "primary_color") {
        this.branding.primary_color = color;
      } else if (this.picker.mode === "secondary_color") {
        this.branding.secondary_color = color;
      } else if (this.picker.mode === "warning_color") {
        this.branding.warning_color = color;
      } else if (this.picker.mode === "danger_color") {
        this.branding.danger_color = color;
      } else if (this.picker.mode === "success_color") {
        this.branding.success_color = color;
      } else if (this.picker.mode === "primaryButtonColor") {
        this.branding.primaryButtonColor = color;
      } else if (this.picker.mode === "primaryButtonTextColor") {
        this.branding.primaryButtonTextColor = color;
      } else if (this.picker.mode === "secondaryButtonColor") {
        this.branding.secondaryButtonColor = color;
      } else if (this.picker.mode === "secondaryButtonTextColor") {
        this.branding.secondaryButtonTextColor = color;
      } else if (this.picker.mode === "drawertext") {
        this.drawer.theme = color;
      } else if (this.picker.mode === "searchbarbackground") {
        this.app.searchbar_fill_color = color;
      } else if (this.picker.mode === "searchbartext") {
        this.app.searchbar_hint_text_color = color;
      }
      this.picker.status = false;
    },
    openAppBarBackgroundColorPicker() {
      this.picker = {
        status: true,
        mode: "appbarbackground",
        color: this.app.appbar_background_color,
      };
    },
    opensearchbar_fill_colorPicker() {
      this.picker = {
        status: true,
        mode: "searchbarbackground",
        color: this.app.searchbar_fill_color,
      };
    },
    opensearchbar_hint_text_colorPicker() {
      this.picker = {
        status: true,
        mode: "searchbartext",
        color: this.app.searchbar_hint_text_color,
      };
    },
    openIconPicker() {
      this.picker = {
        status: true,
        mode: "icon",
        color: this.app.icon_color,
      };
    },
    openActivePicker() {
      this.picker = {
        status: true,
        mode: "active",
        color: this.app.active_color,
      };
    },
    openThemePicker() {
      this.picker = {
        status: true,
        mode: "theme",
        color: this.app.color_theme,
      };
    },
    openLoaderPicker() {
      this.picker = {
        status: true,
        mode: "loader",
        color: this.app.loader_color,
      };
    },
    openDrawerPicker() {
      this.picker = {
        status: true,
        mode: "drawer",
        color: this.drawer.color,
      };
    },
    openBottomPicker() {
      this.picker = {
        status: true,
        mode: "bottombackground",
        color: this.app.bottomBarBackgroundColor,
      };
    },
    openTitlePicker() {
      this.picker = {
        status: true,
        mode: "branding",
        color: this.branding.titlecolor,
      };
    },
    openSubtitlePicker() {
      this.picker = {
        status: true,
        mode: "brandingsubtitle",
        color: this.branding.subtitlecolor,
      };
    },
    openSecondaryfontPicker() {
      this.picker = {
        status: true,
        mode: "secondaryfont",
        color: this.branding.secondaryfontcolor,
      };
    },
    openBodyfontPicker() {
      this.picker = {
        status: true,
        mode: "bodyfont",
        color: this.branding.bodyfontcolor,
      };
    },
    openHightlightPicker() {
      this.picker = {
        status: true,
        mode: "hightlightfontcolor",
        color: this.branding.hightlightfontcolor,
      };
    },
    openPrimaryColorPicker() {
      this.picker = {
        status: true,
        mode: "primary_color",
        color: this.branding.primary_color,
      };
    },
    openSecondaryColorPicker() {
      this.picker = {
        status: true,
        mode: "secondary_color",
        color: this.branding.secondary_color,
      };
    },
    openWarningColorPicker() {
      this.picker = {
        status: true,
        mode: "warning_color",
        color: this.branding.warning_color,
      };
    },
    openDangerColorPicker() {
      this.picker = {
        status: true,
        mode: "danger_color",
        color: this.branding.danger_color,
      };
    },
    openSuccessColorPicker() {
      this.picker = {
        status: true,
        mode: "success_color",
        color: this.branding.success_color,
      };
    },
    openprimaryButtonColorPicker() {
      this.picker = {
        status: true,
        mode: "primaryButtonColor",
        color: this.branding.primaryButtonColor,
      };
    },
    openprimaryButtonTextColorPicker() {
      this.picker = {
        status: true,
        mode: "primaryButtonTextColor",
        color: this.branding.primaryButtonTextColor,
      };
    },
    opensecondaryButtonColorPicker() {
      this.picker = {
        status: true,
        mode: "secondaryButtonColor",
        color: this.branding.secondaryButtonColor,
      };
    },
    opensecondaryButtonTextColorPicker() {
      this.picker = {
        status: true,
        mode: "secondaryButtonTextColor",
        color: this.branding.secondaryButtonTextColor,
      };
    },
    openDrawerTextColorPicker() {
      this.picker = {
        status: true,
        mode: "drawertext",
        color: this.drawer.theme,
      };
    },
    updateTab(value) {
      this.tab = value;
    },
    getDetail() {
      this.loading = true;
      this.$http
        .get(
          `${this.$serverApiLink}api/account/design/detail/${this.$route.params.uid}`
        )
        .then((response) => {
          this.app = response.data.layout;
          this.getDrawerSettings();
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
        });
    },
    getDrawerSettings() {
      this.$http
        .get(
          `${this.$serverApiLink}api/account/drawer/detail/${this.$route.params.uid}`
        )
        .then((response) => {
          this.drawer = response.data.detail;
          this.getBrandingSettings();
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
        });
    },
    getBrandingSettings() {
      this.$http
        .get(
          `${this.$serverApiLink}api/account/branding/detail/${this.$route.params.uid}`
        )
        .then((response) => {
          this.branding = response.data.detail;
          this.getStyles();
          this.setselectedfont();
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
        });
    },
    getStyles() {
      this.$http
        .get(
          `${this.$serverApiLink}api/account/design/styles/${this.$route.params.uid}`
        )
        .then((response) => {
          this.styles = response.data.styles;
          this.loading = false;
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.loading = false;
        });
    },

    // appbar backgroundimg
    onButtonappbarBackgroundClick() {
      this.appbarbackground_image.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.appbarbackground_image.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploaderC.click();
    },
    onFileappbarBackgroundChanged(e) {
      this.appbarbackground_image.selectedFile = e.target.files[0];
      this.$store.commit("setLoading", true);
      this.appbarbackground_image.upload = true;
      let params = new FormData();
      params.set(
        "appbar_background_image",
        this.appbarbackground_image.selectedFile
      );
      this.$http
        .post(
          `${this.$serverApiLink}api/account/design/upload_background/${this.$route.params.uid}`,
          params,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((response) => {
          this.app.appbar_background_image = response.data.uri;
          this.appbarbackground_image.upload = false;
          this.$store.commit("setLoading", false);
          this.$emit("reload_preview", {
            uid: this.$route.params.uid,
            mode: "appbar",
          });
          this.$refs.footer.showSuccessAlert();
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.appbarbackground_image.upload = false;
          this.$store.commit("setLoading", false);
        });
    },
    // brawerimageupload
    onButtonBackgroundClick() {
      this.background_image.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.background_image.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploaderA.click();
    },
    onFileBackgroundChanged(e) {
      this.background_image.selectedFile = e.target.files[0];
      this.$store.commit("setLoading", true);
      this.background_image.upload = true;
      let params = new FormData();
      params.set("background", this.background_image.selectedFile);
      this.$http
        .post(
          `${this.$serverApiLink}api/account/drawer/upload_background/${this.$route.params.uid}`,
          params,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((response) => {
          this.drawer.background = response.data.uri;
          this.background_image.upload = false;
          this.$store.commit("setLoading", false);
          this.$emit("reload_preview", {
            uid: this.$route.params.uid,
            mode: "app",
          });
          this.$refs.footer.showSuccessAlert();
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.background_image.upload = false;
          this.$store.commit("setLoading", false);
        });
    },
    onButtonLogoClick() {
      this.logo_image.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.logo_image.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploaderB.click();
    },
    onFileLogoChanged(e) {
      this.logo_image.selectedFile = e.target.files[0];
      this.$store.commit("setLoading", true);
      let params = new FormData();
      params.set("logo", this.logo_image.selectedFile);
      this.$http
        .post(
          `${this.$serverApiLink}api/account/drawer/upload_logo/${this.$route.params.uid}`,
          params,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((response) => {
          this.drawer.logo = response.data.uri;
          this.logo_image.upload = false;
          this.$store.commit("setLoading", false);
          this.$emit("reload_preview", {
            uid: this.$route.params.uid,
            mode: "app",
          });
          this.$refs.footer.showSuccessAlert();
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.$store.commit("setLoading", false);
        });
    },
    createStyle() {
      this.styleDialog.loading = true;
      let params = new URLSearchParams();
      params.append("name", this.styleDialog.name);
      this.$http
        .post(
          `${this.$serverApiLink}api/account/design/create_div/${this.$route.params.uid}`,
          params
        )
        .then((response) => {
          this.styles.push({
            id: response.data.id,
            name: response.data.name,
            loading: false,
          });
          this.styleDialog = {
            status: false,
            name: "",
            loading: false,
          };
          this.$emit("reload_preview", {
            uid: this.$route.params.uid,
            mode: "app",
          });
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.styleDialog.loading = false;
        });
    },
    abcd(i) {
      console.log("ABCD >>" + i);
    },
    removeStyle(index) {
      this.$store.commit("setLoading", true);
      this.$http
        .get(
          `${this.$serverApiLink}api/account/design/remove_div/${this.styles[index].id}`
        )
        .then(
          // eslint-disable-next-line no-unused-vars
          (response) => {
            this.styles.splice(index, 1);
            this.$store.commit("setLoading", false);
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "app",
            });
          }
        )
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.$store.commit("setLoading", false);
        });
    },
    setselectedfont() {
      // this.selectedfontdetail.name = this.branding.font;
      var googlefontlink = "https://fonts.googleapis.com/css2?";
      this.allfonts.forEach((value) => {
        if (this.branding.font == value.code) {
          googlefontlink = googlefontlink + "family=" + value.familyurl;
          this.selectedfontdetail = value;
        }
      });

      if (!this.fontloadonce) {
        const file = document.createElement("link");
        file.rel = "stylesheet";
        file.href = googlefontlink;
        document.head.appendChild(file);
      }
    },
  },
  mounted() {
    this.getDetail();
  },
};
</script>
