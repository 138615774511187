<template>
  <div style="background-color: aliceblue">
    <div v-if="slide.data_action_type != ''">
      <h6 style="padding-top: 2px; padding-left: 4px; padding-right: 4px">
        On Click Actions
      </h6>
      <div>
        <v-chip class="ma-2" small>
          {{ slide.data_action }}
        </v-chip>

        <v-btn icon small color="orange" v-on:click="showdialog = !showdialog">
          <EditMiniIcon :size="16" />
        </v-btn>
      </div>
    </div>
    <div v-if="slide.data_action_type == ''">
      <h6 style="padding-top: 2px; padding-left: 4px; padding-right: 4px">
        On Click Actions
      </h6>
      <div>
        <v-chip class="ma-2" small>
          {{ slide.data_action }}
        </v-chip>

        <v-btn icon small color="orange" v-on:click="showdialog = !showdialog">
          <EditMiniIcon :size="16" />
        </v-btn>
      </div>
    </div>

    <v-dialog
      v-model="showdialog"
      transition="dialog-transition"
      width="100%"
      height="500"
      max-width="800px"
      persistent
      scrollable
      overlay>
      <div class="card-body">
        <div class="row pagemodeltopbar" style="margin: 0">
          <div class="col-8">
            <span style="font-size: 16px; font-weight: bold; margin-top: 10px"
              >Manage onClick Action</span
            >
          </div>
          <div class="col-4">
            <v-btn icon dark @click="showdialog = false" style="float: right">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>

        <v-row style="margin: 10px; margin-top: 80px">
          <v-col md="6" sm="6" cols="12" class="pb-0">
            <v-select
              :label="$tr('project', 'key_461')"
              outlined
              color="primary"
              dense
              :items="storytype"
              v-model="slide.type"></v-select>
          </v-col>
          <v-col
            md="6"
            sm="6"
            cols="12"
            class="pb-0"
            v-if="slide.type == 'video'">
            <v-text-field
              label="Enter Video URL"
              outlined
              color="primary"
              dense
              autofocus
              v-model="slide.video_url"></v-text-field>
          </v-col>
          <v-col
            md="6"
            sm="6"
            cols="12"
            class="pb-0"
            v-if="slide.type == 'image'">
            <v-text-field
              label="Enter Image URL"
              outlined
              color="primary"
              dense
              autofocus
              v-model="slide.big_image_url"></v-text-field>
          </v-col>
          <v-col md="6" sm="6" cols="12" class="pb-0">
            <v-select
              :label="$tr('project', 'key_56')"
              outlined
              color="primary"
              dense
              :items="actions"
              v-model="slide.data_action_type"></v-select>
          </v-col>
          <v-col
            md="6"
            sm="6"
            cols="12"
            class="pb-0"
            v-if="slide.data_action_type == 'inapp'">
            <v-select
              label="Select Page"
              outlined
              color="primary"
              dense
              :items="pages"
              v-model="slide.story_type"></v-select>
          </v-col>

          <v-col
            md="6"
            sm="6"
            cols="12"
            class="pb-0"
            v-if="
              slide.data_action_type == 'inapp' &&
              slide.story_type == 'category'
            ">
            <v-text-field
              label="Page Title"
              outlined
              color="primary"
              dense
              autofocus
              v-model="slide.title"></v-text-field>
          </v-col>

          <v-col
            md="6"
            sm="6"
            cols="12"
            class="pb-0"
            v-if="
              slide.data_action_type == 'inapp' &&
              slide.story_type == 'category'
            ">
            <v-select
              label="Select Collection"
              :items="categorieslist"
              dense
              hide-details
              outlined
              v-model="slide.categoryid"></v-select>
          </v-col>

          <v-col
            md="6"
            sm="6"
            cols="12"
            class="pb-0"
            v-if="
              slide.data_action_type == 'inapp' && slide.story_type == 'product'
            ">
            <v-text-field
              label="Select Product"
              outlined
              color="primary"
              dense
              autofocus
              v-model="slide.product_id"></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import EditMiniIcon from "../icons/EditMiniIcon.vue";

export default {
  name: "RouteGenerator",
  props: {
    dataquery: {
      type: Object,
      required: true,
    },
    slide: {
      type: Object,
      required: true,
    },
    categorieslist: {
      type: Array,
      required: true,
    },
  },
  components: {
    EditMiniIcon,
  },
  data: function () {
    return {
      action: 0,
      showdialog: false,
      data_story_type: "inapp",
    };
  },
  methods: {},
  computed: {
    actions: function () {
      return [
        {
          text: this.$tr("project", "key_e33"),
          value: "inapp",
        },
      ];
    },
    pages: function () {
      return [
        {
          text: "Collection",
          value: "category",
        },
        {
          text: "Open Product",
          value: "product",
        },
      ];
    },
    storytype: function () {
      return [
        {
          text: "Video Story",
          value: "video",
        },
        {
          text: "Image Story",
          value: "image",
        },
      ];
    },
    label: function () {
      if (
        this.slide.data_action_type == "internal" ||
        this.slide.data_action_type == "external" ||
        this.slide.data_action_type == "share"
      ) {
        return this.$tr("project", "key_61");
      } else if (this.slide.data_action_type === "email") {
        return this.$tr("auth", "key_5");
      } else if (this.slide.data_action_type === "openmodal") {
        return this.$tr("auth", "key_5");
      } else {
        return this.$tr("project", "key_301");
      }
    },
  },
};
</script>
