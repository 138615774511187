export const homecomponents = [
  // slider section
  {
    id: 1,
    type: "slider",
    name: "Image Slider",
    displayname: "Image Slider Default",
    vicon: "slider_defalt.png",
    styles: {
      slidertype: "DEFAULT",
      borderradius: "10",
      aspectratio: "0.5", //height width auto
      scale: "0.9",
      viewportFraction: "0.8",
      autoPlay: true,
      loop: true,
      autoPlayAnimationDuration: 3000,
      directon: "horizontal",
      boxfit: "cover",
      sliderid: 1,
      indicator: false,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 10,
      paddingBottom: 10,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
      color: "",
      bgcolor: "",
    },
    data_id: 1,
    data_query: {},
    data_action_type: "",
    video_url: "",
    data: [
      {
        name: "Image ",
        image: "https://app.shopimint.com/backend/static/image-placeholder.jpg",
        text: "",
        random: "gad1fea71",
        loading: false,
        data_query: {
          title: "Shop Page",
          category: "",
        },
        data_action: "ShopScreen",
        data_action_type: "inapp",
      },
    ],
  },
  {
    id: 2,
    type: "slider",
    name: "Image Slider ",
    displayname: "Image Slider Stack",
    vicon: "slider_stack.png",
    styles: {
      slidertype: "STACK",
      borderradius: "10",
      aspectratio: "0.5", //height width auto
      scale: "0.9",
      viewportFraction: "0.8",
      autoPlay: true,
      loop: true,
      autoPlayAnimationDuration: 3000,
      directon: "horizontal",
      boxfit: "cover",
      sliderid: 1,
      indicator: false,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
      color: "",
      bgcolor: "",
    },
    data_id: 1,
    data_query: {},
    data_action_type: "",
    data: [
      {
        name: "Image ",
        image: "https://app.shopimint.com/backend/static/image-placeholder.jpg",
        text: "",
        random: "gs54rd5gs",
        loading: false,
        data_query: {
          title: "Shop Page",
          category: "",
        },
        data_action: "ShopScreen",
        data_action_type: "inapp",
      },
    ],
  },
  {
    id: 3,
    type: "slider",
    name: "Image Slider ",
    displayname: "Image Slider Tinder",
    vicon: "slider_tinder.png",
    styles: {
      slidertype: "TINDER",
      borderradius: "10",
      aspectratio: "0.5", //height width auto
      scale: "0.9",
      viewportFraction: "0.8",
      autoPlay: true,
      loop: true,
      autoPlayAnimationDuration: 3000,
      directon: "horizontal",
      boxfit: "cover",
      sliderid: 1,
      indicator: false,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
      color: "",
      bgcolor: "",
    },
    data_id: 1,
    data_query: {},
    data_action_type: "",
    data: [
      {
        name: "Image ",
        image: "https://app.shopimint.com/backend/static/image-placeholder.jpg",
        text: "",
        random: "af68e46a",
        loading: false,
        data_query: {
          title: "Shop Page",
          category: "",
        },
        data_action: "ShopScreen",
        data_action_type: "inapp",
      },
    ],
  },
  {
    id: 4,
    type: "slider",
    name: "Image Slider ",
    displayname: "Image Slider Custom",
    vicon: "slider_custom.png",
    styles: {
      slidertype: "CUSTOM",
      borderradius: "0",
      aspectratio: "0.5", //height width auto
      scale: "0.9",
      viewportFraction: "0.8",
      autoPlay: true,
      loop: true,
      autoPlayAnimationDuration: 3000,
      directon: "horizontal",
      boxfit: "cover",
      sliderid: 1,
      indicator: false,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
      color: "",
      bgcolor: "",
    },
    data_id: 1,
    data_query: {},
    data_action_type: "",
    data: [
      {
        name: "Image ",
        image: "https://app.shopimint.com/backend/static/image-placeholder.jpg",
        text: "",
        random: "af68e46a",
        loading: false,
        data_query: {
          title: "Shop Page",
          category: "",
        },
        data_action: "ShopScreen",
        data_action_type: "inapp",
      },
    ],
  },
  {
    id: 5,
    type: "imagebanner",
    name: "Image Grid",
    displayname: "Single Image",
    vicon: "Image_components.png",
    styles: {
      style_type: "style1",
      column: "1",
      row: "1", // rows will be hide beacause it row count depends on slide count
      sliderid: "",

      size_type: "auto", //auto,custom,aspect_ratio ( if auto hide width & height )
      width: "200",
      height: "200",
      aspect_ratio: "2",

      align: "center",
      boxfit: "cover",
      borderradius: "0",

      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 10,
      paddingBottom: 10,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,

      color: "", //No color option on slider
      bgcolor: "",
    },
    data_id: 1,
    data_query: "",
    data: [
      {
        name: "Image ",
        image: "https://app.shopimint.com/backend/static/image-placeholder.jpg",
        text: "",
        random: "gr84g984",
        loading: false,
        data_query: {
          title: "Shop Page",
          category: "",
        },
        data_action: "ShopScreen",
        data_action_type: "inapp",
      },
    ],
  },
  {
    id: 6,
    type: "imagebanner",
    name: "Image Grid",
    displayname: "Image Grid - 2 Column",
    vicon: "2Image_components .png",
    styles: {
      style_type: "style1",
      column: "2",
      row: "1", // rows will be hide beacause it row count depends on slide count
      sliderid: "",

      size_type: "auto", //auto,custom,aspect_ratio ( if auto hide width & height )
      width: "200",
      height: "200",
      aspect_ratio: "1",

      align: "center",
      boxfit: "cover",
      borderradius: "5",

      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 10,
      paddingBottom: 10,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,

      color: "", //No color option on slider
      bgcolor: "",
    },
    data_id: 1,
    data_query: "",
    data: [
      {
        name: "Image ",
        image: "https://app.shopimint.com/backend/static/image-placeholder.jpg",
        text: "",
        random: "jdt8gev6",
        loading: false,
        data_query: {
          title: "Shop Page",
          category: "",
        },
        data_action: "ShopScreen",
        data_action_type: "inapp",
      },
      {
        name: "Image undefined",
        image: "https://app.shopimint.com/backend/static/image-placeholder.jpg",
        text: "",
        random: "yjf4d03zgz8",
        loading: false,
        data_query: {
          title: "Shop Page",
          category: "",
        },
        data_action: "ShopScreen",
        data_action_type: "inapp",
      },
    ],
  },
  {
    id: 7,
    type: "imagebanner",
    name: "Image Grid",
    displayname: "Image Grid - 3 Column",
    vicon: "3Image_components .png",
    styles: {
      style_type: "style1",
      column: "3",
      row: "1", // rows will be hide beacause it row count depends on slide count
      sliderid: "",

      size_type: "auto", //auto,custom,aspect_ratio ( if auto hide width & height )
      width: "200",
      height: "200",
      aspect_ratio: "1",

      align: "center",
      boxfit: "cover",
      borderradius: "5",

      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 10,
      paddingBottom: 10,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,

      color: "", //No color option on slider
      bgcolor: "",
    },
    data_id: 1,
    data_query: "",
    data: [
      {
        name: "Image ",
        image: "https://app.shopimint.com/backend/static/image-placeholder.jpg",
        text: "",
        random: "jdt8gev6",
        loading: false,
        data_query: {
          title: "Shop Page",
          category: "",
        },
        data_action: "ShopScreen",
        data_action_type: "inapp",
      },
      {
        name: "Image undefined",
        image: "https://app.shopimint.com/backend/static/image-placeholder.jpg",
        text: "",
        random: "yjf4d03zgz8",
        loading: false,
        data_query: {
          title: "Shop Page",
          category: "",
        },
        data_action: "ShopScreen",
        data_action_type: "inapp",
      },
      {
        name: "Image ",
        image: "https://app.shopimint.com/backend/static/image-placeholder.jpg",
        text: "",
        random: "45gecsa894",
        loading: false,
        data_query: {
          title: "Shop Page",
          category: "",
        },
        data_action: "ShopScreen",
        data_action_type: "inapp",
      },
    ],
  },
  {
    id: 8,
    type: "imagecarousel",
    name: "Image Carousel",
    displayname: "Image Carousel Rounded",
    vicon: "Image_corousel_round.png",
    styles: {
      style_type: "style1",
      column: "3",
      row: "1", // rows will be hide beacause it row count depends on slide count
      sliderid: "",

      size_type: "auto", //auto,custom,aspect_ratio ( if auto hide width & height )
      width: "150",
      height: "150",
      aspect_ratio: "1",

      align: "center",
      boxfit: "cover",
      borderradius: "100",

      paddingLeft: 10,
      paddingRight: 0,
      paddingTop: 10,
      paddingBottom: 10,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,

      color: "", //No color option on slider
      bgcolor: "",
    },
    data_id: 1,
    data_query: "",
    data: [
      {
        name: "Image ",
        image: "https://app.shopimint.com/backend/static/placeholder_img.png",
        text: "",
        random: " tujnrurh",
        loading: false,
        data_query: {
          title: "Shop Page",
          category: "",
        },
        data_action: "ShopScreen",
        data_action_type: "inapp",
      },
      {
        name: "Image ",
        image: "https://app.shopimint.com/backend/static/placeholder_img.png",
        text: "",
        random: "abvjyndedsdryb",
        loading: false,
        data_query: {
          title: "Shop Page",
          category: "",
        },
        data_action: "ShopScreen",
        data_action_type: "inapp",
      },
      {
        name: "Image undefined",
        image: "https://app.shopimint.com/backend/static/placeholder_img.png",
        text: "",
        random: "bsdyryubj",
        loading: false,
        data_query: {
          title: "Shop Page",
          category: "",
        },
        data_action: "ShopScreen",
        data_action_type: "inapp",
      },
    ],
  },
  {
    id: 9,
    type: "imagecarousel",
    name: "Image Carousel",
    displayname: "Image Carousel Rectangled",
    vicon: "Image_corousel_rectangle.png",
    styles: {
      style_type: "style1",
      column: "2",
      row: "1", // rows will be hide beacause it row count depends on slide count
      sliderid: "",

      size_type: "auto", //auto,custom,aspect_ratio ( if auto hide width & height )
      width: "200",
      height: "200",
      aspect_ratio: "1",

      align: "center",
      boxfit: "cover",
      borderradius: "0",

      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,

      color: "", //No color option on slider
      bgcolor: "",
    },
    data_id: 1,
    data_query: "",
    data: [
      {
        name: "Image ",
        image: "https://app.shopimint.com/backend/static/image-placeholder.jpg",
        text: "",
        random: "ajdrtfjf",
        loading: false,
        data_query: {
          title: "Shop Page",
          category: "",
        },
        data_action: "ShopScreen",
        data_action_type: "inapp",
      },
      {
        name: "Image undefined",
        image: "https://app.shopimint.com/backend/static/image-placeholder.jpg",
        text: "",
        random: "JHrtdjhdt",
        loading: false,
        data_query: {
          title: "Shop Page",
          category: "",
        },
        data_action: "ShopScreen",
        data_action_type: "inapp",
      },
      {
        name: "Image ",
        image: "https://app.shopimint.com/backend/static/image-placeholder.jpg",
        text: "",
        random: "HRfhzdeth",
        loading: false,
        data_query: {
          title: "Shop Page",
          category: "",
        },
        data_action: "ShopScreen",
        data_action_type: "inapp",
      },
    ],
  },
  {
    id: 10,
    type: "title",
    name: "Text",
    displayname: "Heading",
    vicon: "text_style2.png",
    styles: {
      style: "style2", //style1,style2,style3,style4,style5,style6
      align: "left",

      text: "Heading",
      fontsize: "18",
      color: "#000000",

      subtitle: "Subtitle Here",
      subtitle_fontsize: "14",
      subtitle_color: "#000000",
      subtitle_padding: "5",
      fontweight: "bold",
      subtitle_fontweight: "normal",

      button_style: "style3", //style1,style2,style3
      btn_text: "View More",
      btn_fontsize: "12",
      btn_color: "#000000",
      btn_textcolor: "#000000",
      btn_padding: "10",
      btn_border_radius: "10",

      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
    },
    data_id: 1,
    data_query: {
      title: "",
    },
    data_action: "ShopScreen",
    data_action_type: "",
  },
  {
    id: 11,
    type: "title",
    name: "Text",
    displayname: "Heading Center with Button",
    vicon: "text_style_3.png",
    styles: {
      style: "style3", //style1,style2,style3,style4,style5,style6
      align: "center",

      text: "Heading",
      fontsize: "18",
      color: "#000000",

      subtitle: "Subtitle Here",
      subtitle_fontsize: "14",
      subtitle_color: "#000000",
      subtitle_padding: "5",
      fontweight: "bold",
      subtitle_fontweight: "normal",

      button_style: "style1", //style1,style2,style3
      btn_text: "View More",
      btn_fontsize: "12",
      btn_color: "#000000",
      btn_textcolor: "#FFFFFF",
      btn_padding: "10",
      btn_border_radius: "10",

      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
    },
    data_id: 1,
    data_query: {
      title: "",
    },
    data_action: "ShopScreen",
    data_action_type: "",
  },
  {
    id: 12,
    type: "title",
    name: "Text",
    displayname: "Heading Left with Button",
    vicon: "text_style_6.png",
    styles: {
      style: "style6", //style1,style2,style3,style4,style5,style6
      align: "left",

      text: "Heading",
      fontsize: "18",
      color: "#000000",

      subtitle: "Subtitle Here",
      subtitle_fontsize: "14",
      subtitle_color: "#000000",
      subtitle_padding: "5",
      fontweight: "bold",
      subtitle_fontweight: "normal",

      button_style: "style1", //style1,style2,style3
      btn_text: "View More",
      btn_fontsize: "12",
      btn_color: "#000000",
      btn_textcolor: "#FFFFFF",
      btn_padding: "10",
      btn_border_radius: "10",

      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
    },
    data_id: 1,
    data_query: {
      title: "",
    },
    data_action: "ShopScreen",
    data_action_type: "",
  },
  {
    id: 13,
    type: "corosel",
    name: "Category Carousel",
    displayname: "Category Carousel - Round Style",
    vicon: "category_corousel_round.png",
    styles: {
      style_type: "style_1",

      width: "250",
      height: "250",
      borderradius: "100",

      show_category_title: true,
      fontsize: "12",
      align: "center",
      title_top_padding: 0,

      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,

      color: "",
      bgcolor: "",

      autoPlay: true,
      loop: true,
    },
    data_id: 1,
    data_query: {
      parent: "0",
    },
    data_action: "",
    data_action_type: "",
  },
  {
    id: 14,
    type: "corosel",
    name: "Category Carousel",
    displayname: "Category Carousel - Rectangle Style",
    vicon: "category_corousel_rectangle.png",
    styles: {
      style_type: "style_1",

      width: "350",
      height: "250",
      borderradius: "10",

      show_category_title: true,
      fontsize: "12",
      align: "center",
      title_top_padding: 0,

      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,

      color: "",
      bgcolor: "",

      autoPlay: true,
      loop: true,
    },
    data_id: 1,
    data_query: {
      parent: "0",
    },
    data_action: "",
    data_action_type: "",
  },
  {
    id: 15,
    type: "product",
    name: "Product Carousel",
    displayname: "Product Carousel",
    vicon: "product_carousal.png",
    styles: {
      style_type: "style1",
      column: "3",
      itemcount: "12",
      borderradius: "0",

      // width: '100%',
      // height: 'auto',
      // backgroundcolor: '',

      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,

      color: "",
      bgcolor: "",

      autoPlay: true,
      loop: true,
      autoPlayAnimationDuration: "800",
    },
    data_id: 1,
    data_query: {
      per_page: "12",
      on_sale: false,
    },
    data_action: "",
    data_action_type: "",
  },
  {
    id: 16,
    type: "productgrid",
    name: "Product Grid",
    displayname: "Product Grid 2 Column",
    vicon: "product_grid_2col.png",
    styles: {
      style_type: "style1",
      column: "2",
      itemcount: "6",
      borderradius: "0",

      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,

      color: "",
      bgcolor: "",
    },
    data_id: 1,
    data_query: {
      per_page: "6",
      on_sale: "true",
    },
    data_action: "ShopScreen",
    data_action_type: "",
  },
  {
    id: 17,
    type: "productgrid",
    name: "Product Grid",
    displayname: "Product Grid 3 Column",
    vicon: "product_grid3_col.png",
    styles: {
      style_type: "style1",
      column: "3",
      itemcount: "6",
      borderradius: "0",

      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,

      color: "",
      bgcolor: "",
    },
    data_id: 1,
    data_query: {
      per_page: "6",
      on_sale: "true",
    },
    data_action: "ShopScreen",
    data_action_type: "",
  },
  {
    id: 18,
    type: "productlist",
    name: "Product Infinity Scroll",
    displayname: "Product Infinity Scroll - 2 Column",
    vicon: "product_grid2_col_infiinty.png",
    styles: {
      style_type: "style1",
      column: "2",
      borderradius: "0",
      itemcount: "20", // Items that load one time

      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,

      color: "",
      bgcolor: "",
    },
    data_id: 1,
    data_query: {
      per_page: "20", // Items that load one time
      on_sale: "true",
    },
    data_action: "ShopScreen",
    data_action_type: "",
  },
  {
    id: 19,
    type: "productlist",
    name: "Product Infinity Scroll",
    displayname: "Product Infinity Scroll - 3 Column",
    vicon: "product_grid3_col_infinity.png",
    styles: {
      style_type: "style1",
      column: "3",
      borderradius: "0",
      itemcount: "20", // Items that load one time

      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,

      color: "",
      bgcolor: "",
    },
    data_id: 1,
    data_query: {
      per_page: "20", // Items that load one time
      on_sale: "true",
    },
    data_action: "ShopScreen",
    data_action_type: "",
  },
  {
    id: 20,
    type: "countdown",
    name: "Countdown Timer",
    displayname: "Countdown - Large Style",
    vicon: "countdown_big.png",
    styles: {
      style_type: "style1",

      countdown_title: "Super Deals",
      countdown_subtitle: "Incredible offers only for you ",
      countdown_title_color: "#000000",
      countdown_timer_color: "#000000",
      countdown_sec_color: "#000000",
      expire_time: "2023-01-31 10:10:25",

      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
      borderradius: 0,

      color: "",
      bgcolor: "",
    },
    data_id: 1,
    data_query: {
      on_sale: true,
    },
    data_action: "ShopScreen",
    data_action_type: "inapp",
  },
  {
    id: 21,
    type: "countdown",
    name: "Countdown Timer",
    displayname: "Countdown - Small Style",
    vicon: "countdown_with text.png",
    styles: {
      style_type: "style2",

      countdown_title: "Flash Sale - Upto 40% off ",
      countdown_title_color: "#000000",
      countdown_timer_color: "#000000",
      countdown_sec_color: "#000000",
      expire_time: "2023-01-01 10:10:25",

      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
      borderRadius: 0,

      color: "",
      bgcolor: "",
    },
    data_id: 1,
    data_query: {
      on_sale: true,
    },
    data_action: "ShopScreen",
    data_action_type: "inapp",
  },
  {
    id: 22,
    type: "searchbox",
    name: "Search",
    displayname: "Search Bar Style 1",
    vicon: "search_1_defalut.png",
    styles: {
      style_type: "style1",

      text: "Search Products",
      fontsize: "16",

      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,

      color: "",
      bgcolor: "",
    },
    data_id: 1,
    data_query: {
      default_search: "",
    },
    data_action: "searchPage",
    data_action_type: "inapp",
  },
  {
    id: 9,
    type: "searchbox",
    name: "Search Bar",
    displayname: "Search Bar Style 2",
    vicon: "search_2.png",
    styles: {
      style_type: "style1",

      text: "Search Products",
      fontsize: "16",

      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,

      color: "",
      bgcolor: "",
    },
    data_id: 1,
    data_query: {
      default_search: "",
    },
    data_action: "searchPage",
    data_action_type: "inapp",
  },
  {
    id: 23,
    type: "story",
    name: "Live Stories",
    displayname: "Live Stories - Round Style",
    vicon: "story_round.png",
    styles: {
      style_type: "style1",
      column: "2",
      row: "1", // rows will be hide beacause it row count depends on slide count
      sliderid: "",

      size_type: "auto", //auto,custom,aspect_ratio ( if auto hide width & height )
      width: "200",
      height: "200",
      aspect_ratio: "1",

      align: "center",
      boxfit: "cover",
      borderradius: "100",

      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,

      color: "", //No color option on slider
      bgcolor: "",
    },
    data_id: 1,
    data_query: "",
    data: [],
  },
  {
    id: 24,
    type: "story",
    name: "Live Stories",
    displayname: "Live Stories - Rectangle Style",
    vicon: "story_rectangle.png",
    styles: {
      style_type: "style2",
      column: "3",
      row: "1", // rows will be hide beacause it row count depends on slide count
      sliderid: "",

      size_type: "auto", //auto,custom,aspect_ratio ( if auto hide width & height )
      width: "200",
      height: "200",
      aspect_ratio: "1",

      align: "center",
      boxfit: "cover",
      borderradius: "0",

      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,

      color: "", //No color option on slider
      bgcolor: "",
    },
    data_id: 1,
    data_query: "",
    data: [],
  },
  {
    id: 25,
    type: "appbarslider",
    name: "App Bar Slider",
    displayname: "Slider for App Bar style 5",
    vicon: "app_bar_slider.png",
    styles: {
      slidertype: "DEFAULT",
      borderradius: "0",
      aspectratio: "0.5", //height width auto
      scale: "0.9",
      viewportFraction: "0.8",
      autoPlay: true,
      loop: true,
      autoPlayAnimationDuration: 3000,
      directon: "horizontal",
      boxfit: "cover",
      sliderid: 1,
      indicator: false,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
      color: "",
      bgcolor: "",
    },
    data_id: 1,
    data_query: {},
    data_action_type: "",
    data: [
      {
        name: "Image ",
        image: "https://app.shopimint.com/backend/static/image-placeholder.jpg",
        text: "",
        random: "ntzhtnt",
        loading: false,
        data_query: {
          title: "Shop Page",
          category: "",
        },
        data_action: "ShopScreen",
        data_action_type: "inapp",
      },
      {
        name: "Image ",
        image: "https://app.shopimint.com/backend/static/image-placeholder.jpg",
        text: "",
        random: "hrdrght",
        loading: false,
        data_query: {
          title: "Shop Page",
          category: "",
        },
        data_action: "ShopScreen",
        data_action_type: "inapp",
      },
      {
        name: "Image ",
        image: "https://app.shopimint.com/backend/static/image-placeholder.jpg",
        text: "",
        random: "fbhdfdfbhd",
        loading: false,
        data_query: {
          title: "Shop Page",
          category: "",
        },
        data_action: "ShopScreen",
        data_action_type: "inapp",
      },
    ],
  },
  {
    id: 26,
    name: "Youtube Video",
    type: "youtubevideo",
    displayname: "Video",
    vicon: "video_component.png",
    styles: {
      style_type: "style1",
      column: "1",
      row: "1",
      aspect_ratio: 2,
      align: "center",
      boxfit: "cover",
      borderradius: "0",
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 10,
      marginRight: 10,
      marginTop: 15,
      marginBottom: 15,
      color: "",
      bgcolor: "#000000",
      videotypes: "youtube",
      videoId: "6VbOozPmbr4",
      videoURL: "https://www.youtube.com/watch?v=6VbOozPmbr4",
    },
    data: {
      name: "Image undefined",
      url: "https://www.youtube.com/watch?v=6VbOozPmbr4",
      videoId: "6VbOozPmbr4",
      random: "csckw6fwk2s",
      autoplay: false,
    },
    data_query: "",
    data_id: 1,
  },
  {
    id: 27,
    name: "Custom Component",
    type: "custom",
    displayname: "Custom Component",
    vicon: "video_component.png",
    styles: {
      style_type: "style1",
      column: "1",
      row: "1",
      aspect_ratio: 2,
      align: "center",
      boxfit: "cover",
      borderradius: "0",
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 10,
      marginRight: 10,
      marginTop: 15,
      marginBottom: 15,
      color: "",
      bgcolor: "",
    },
    data: {},
    data_query: "",
    data_id: 1,
  },
  {
    id: 27,
    type: "categorygridcarousel",
    name: "Category Carousel",
    displayname: "Category Carousel - Rectangle Style",
    vicon: "Image_corousel_rectangle.png",
    styles: {
      style_type: "style1",
      column: "2",
      row: "1", // rows will be hide beacause it row count depends on slide count
      sliderid: "",

      size_type: "auto", //auto,custom,aspect_ratio ( if auto hide width & height )
      width: "200",
      height: "200",
      aspect_ratio: "1",

      align: "center",
      boxfit: "cover",
      borderradius: "0",

      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,

      color: "", //No color option on slider
      bgcolor: "",
    },
    data_id: 1,
    data_query: "",
    data: [
      {
        name: "Image ",
        image: "https://app.shopimint.com/backend/static/image-placeholder.jpg",
        text: "",
        random: "ajdrtfjf",
        loading: false,
        data_query: {
          title: "Shop Page",
          category: "",
        },
        data_action: "ShopScreen",
        data_action_type: "inapp",
      },
      {
        name: "Image undefined",
        image: "https://app.shopimint.com/backend/static/image-placeholder.jpg",
        text: "",
        random: "JHrtdjhdt",
        loading: false,
        data_query: {
          title: "Shop Page",
          category: "",
        },
        data_action: "ShopScreen",
        data_action_type: "inapp",
      },
      {
        name: "Image ",
        image: "https://app.shopimint.com/backend/static/image-placeholder.jpg",
        text: "",
        random: "HRfhzdeth",
        loading: false,
        data_query: {
          title: "Shop Page",
          category: "",
        },
        data_action: "ShopScreen",
        data_action_type: "inapp",
      },
    ],
  },
];
