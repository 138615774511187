<template>
  <v-layout fill-height>
    <v-main>
      <PageBar
          :title="$tr('menu', 'key_e11')"
          :drawer="true"
      />
      <IntergrationView  v-for="item in intergration.filter(info => info.route_name === 'arsetup')" :key="item.id"  :item="item" />
      <Loader v-if="loading"/>
      <Container v-else>
        <Title :title="$tr('project', 'key_342')"/>
        <div class="permissions_block">
          <div class="d-flex justify-space-between align-center pa-4">
            <div class="d-flex justify-start align-center">
              <!-- <v-avatar color="whitesmoke" :size="60" class="mr-4"> -->
                <!-- <div class="android--text" style="width: 32px; height: 32px">
                  <AndroidLogoIcon :size="32"/>
                </div> -->
              <!-- </v-avatar> -->
              <div>
                <div class="body-1 font-weight-medium">
                  {{ $tr('project', 'key_384') }}
                </div>
              </div>
            </div>
            <v-switch
                :true-value="1"
                :false-value="0"
                v-model="settings.ar_enabled"
                inset
            ></v-switch>
          </div>
        </div>
      </Container>
      <HelpArticle id="63281998b78f9a796bdead14" :helpcentertopic="helpcentertopic"/>
      <Footer
          v-if="!loading"
          :loading="btnLoad"
          ref="footer"
          @click="updatePushDetail"
      />
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Container from "@/components/blocks/Container";
import Title from "@/components/blocks/Title";
import Loader from "@/components/blocks/Loader";
// import AndroidLogoIcon from "@/components/icons/AndroidLogoIcon";
import Footer from "@/components/blocks/Footer";
import IntergrationView from "../../../../../components/blocks/IntergrationView.vue";
import HelpArticle from "../../../../../components/blocks/HelpArticle.vue";

export default {
  name: 'Push',
  components: {
    PageBar,
    Container,
    Title,
    Loader,
    // AndroidLogoIcon,
    Footer,
    IntergrationView,
    HelpArticle
},
  data: () => ({
    loading: true,
    btnLoad: false,
    intergration: [],
    helpcentertopic: [
      {
        "name" : "Enable AR to Your App",
        "link" : "123",
      }
    ],
    settings: {
      ar_enabled: "",
    },
    reissue: false,
    btn_load: false
  }),
  methods: {
    getPushSettings() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/Appintergration/arsetup/${this.$route.params.uid}`).
      then(
          response => {
            this.settings = response.data.detail;
            this.getIntergrations();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
      );
    },
    getIntergrations() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/intergrations/getintergrations/${this.$route.params.id}`).
        then(
          response => {
            this.intergration = response.data.intergration;
            this.loading = false;
          }
        ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
        );
    },
    updatePushDetail() {
      this.btnLoad = true;
      let params = new URLSearchParams();
      params.append('ar_enabled', this.settings.ar_enabled);
      this.$http.post(`${this.$serverApiLink}api/account/Appintergration/updatearsetup/${this.$route.params.uid}`, params).
      then(
          // eslint-disable-next-line no-unused-vars
          response => {
            this.btnLoad = false;
            this.$refs.footer.showSuccessAlert();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.btnLoad = false;
          }
      );
    },
  },
  mounted() {
    this.getPushSettings();
  }
}
</script>