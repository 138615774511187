<template>
  <v-layout fill-height>
    <v-main>
      <PageBar :title="$tr('menu', 'key_39')" :drawer="true" />
      <Loader v-if="loading" />
      <template>
        <Container>
          <v-row class="pb-0">
            <v-col md="4" sm="12" cols="12">
              <v-card outlined class="mb-4 background_block">
                <v-list-item>
                  <v-list-item-content style="align-self: center">
                    <div class="headline font-weight-medium primary--text">
                      {{ android }}
                    </div>
                    <div class="black--text">
                      {{ $tr("project", "key_452") }}
                    </div>
                  </v-list-item-content>

                  <v-list-item-avatar size="80" color="white"
                    ><img :src="Android"
                  /></v-list-item-avatar>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-card outlined class="mb-4 background_block">
                <v-list-item>
                  <v-list-item-content style="align-self: center">
                    <div class="headline font-weight-medium primary--text">
                      {{ ios }}
                    </div>
                    <div class="black--text">
                      {{ $tr("project", "key_453") }}
                    </div>
                  </v-list-item-content>

                  <v-list-item-avatar size="80" color="white"
                    ><img :src="Apple"
                  /></v-list-item-avatar>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <v-card outlined class="mb-4 background_block">
                <v-list-item>
                  <v-list-item-content style="align-self: center">
                    <div class="headline font-weight-medium primary--text">
                      {{ huawei }}
                    </div>
                    <div class="black--text">
                      {{ $tr("project", "key_454") }}
                    </div>
                  </v-list-item-content>

                  <v-list-item-avatar size="80" color="white"
                    ><img :src="Huawei"
                  /></v-list-item-avatar>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" sm="12" cols="12">
              <v-card outlined class="mb-4 background_block">
                <v-card-title class="pa-0">
                  <v-toolbar flat class="background_block">
                    <v-toolbar-title>
                      {{ $tr("project", "key_455") }}
                    </v-toolbar-title>
                    <v-spacer />
                    <v-btn-toggle v-model="period">
                      <v-btn depressed small>
                        {{ $tr("admin", "key_86") }}
                      </v-btn>
                      <v-btn depressed small>
                        {{ $tr("admin", "key_87") }}
                      </v-btn>
                    </v-btn-toggle>
                  </v-toolbar>
                </v-card-title>
                <v-divider />
                <div class="pa-4 mb-4" style="height: 400px">
                  <div
                    v-if="chartLoading"
                    class="d-flex justify-center align-center"
                    style="height: 100%">
                    <v-progress-circular
                      indeterminate
                      color="smoke"
                      width="2"
                      class="mt-4"></v-progress-circular>
                  </div>
                  <LineChartGenerator
                    v-else
                    :chart-options="chartOptions"
                    :chart-data="chartData" />
                </div>
                <div class="d-flex justify-space-between align-center pa-4">
                  <div class="text-center">
                    <v-skeleton-loader
                      v-if="chartLoading"
                      type="text"
                      width="100"></v-skeleton-loader>
                    <div
                      v-else
                      class="text-h6 font-weight-medium primary--text">
                      0
                    </div>
                    <div class="body-2 black--text">
                      {{ $tr("admin", "key_82") }}
                    </div>
                  </div>
                  <div class="text-center">
                    <v-skeleton-loader
                      v-if="chartLoading"
                      type="text"
                      width="100"></v-skeleton-loader>
                    <div
                      v-else
                      class="text-h6 font-weight-medium primary--text">
                      0
                    </div>
                    <div class="body-2 black--text">
                      {{ $tr("admin", "key_83") }}
                    </div>
                  </div>
                  <div class="text-center">
                    <v-skeleton-loader
                      v-if="chartLoading"
                      type="text"
                      width="100"></v-skeleton-loader>
                    <div
                      v-else
                      class="text-h6 font-weight-medium primary--text">
                      0
                    </div>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col md="6" sm="6" cols="6">
              <v-sparkline
                :value="value"
                :gradient="gradient"
                :smooth="radius || false"
                :padding="padding"
                :line-width="width"
                :stroke-linecap="lineCap"
                :gradient-direction="gradientDirection"
                :fill="fill"
                :type="type"
                :auto-line-width="autoLineWidth"
                auto-draw></v-sparkline>
            </v-col>
            <v-col md="6" sm="6" cols="6">
              <v-sparkline
                :value="value"
                :gradient="gradient"
                :smooth="radius || false"
                :padding="padding"
                :line-width="width"
                :stroke-linecap="lineCap"
                :gradient-direction="gradientDirection"
                :fill="fill"
                :type="type"
                :auto-line-width="autoLineWidth"
                auto-draw></v-sparkline>
            </v-col>
            <v-col>
              To find tips to increase your sales in your company
              <a href="https://help.shopimint.com">click here </a>
            </v-col>
          </v-row>
        </Container>
      </template>
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Android from "@/assets/images/publishapp/playstoreicon.png";
import Apple from "@/assets/images/publishapp/appstoreicon.png";
import Huawei from "@/assets/images/publishapp/appgalleryicon.png";
import { Line as LineChartGenerator } from "vue-chartjs/legacy";

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import Loader from "../../../../components/blocks/Loader.vue";
import Container from "../../../../components/blocks/Container.vue";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

const gradients = [
  ["#222"],
  ["#42b3f4"],
  ["red", "orange", "yellow"],
  ["purple", "violet"],
  ["#00c6ff", "#F0F", "#FF0"],
  ["#f72047", "#ffd200", "#1feaea"],
];

export default {
  name: "Analytics",
  components: {
    PageBar,
    LineChartGenerator,
    Loader,
    Container,
  },
  data: () => ({
    loading: false,
    period: 0,
    Android: Android,
    Huawei: Huawei,
    Apple: Apple,
    uid: "",
    picker: {
      status: false,
      mode: "theme",
      color: "#ffffff",
    },
    width: 2,
    radius: 10,
    padding: 8,
    lineCap: "round",
    gradient: gradients[3],
    value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 10],
    gradientDirection: "top",
    gradients,
    fill: false,
    type: "trend",
    autoLineWidth: false,
    chartData: {
      labels: [],
      datasets: [
        {
          label: "",
          backgroundColor: "#1145FF",
          data: [],
          borderColor: "#1145FF",
        },
      ],
    },
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          grid: {
            color: "#a8a9b0",
          },
        },
        x: {
          grid: {
            color: "#a8a9b0",
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return self.getTooltipContext(context);
            },
          },
        },
      },
    },
    chartLoading: false,
    android: 0,
    ios: 0,
    huawei: 0,
    transactions: {
      amount: 0,
      builds: 0,
      count: 0,
    },
  }),
  computed: {},
  methods: {
    getuid() {
      this.uid = this.$route.params.uid;
    },
  },
  mounted() {
    this.getuid();
  },
};
</script>
