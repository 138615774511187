<template>
  <v-layout fill-height>
    <v-main>
      <PageBar title="Categorypage" :drawer="true" pagetype="pages" />

      <Loader v-if="loading" />
      <Container v-else>
        <!-- <PageSettingNavBar :name="currentpage" /> -->
        <div class="row">
          <h3 class="col">Customise Category Page</h3>
          <HelpArticle2 id="6324509fb78f9a796bdeaa2d" />
        </div>

        <v-row>
          <v-col md="12" sm="12" cols="12">
            <h5>Select Category Page Design</h5>
            <v-item-group
              mandatory
              v-model="page.productCategoryPageStyle"
              class="row"
            >
              <div
                v-for="(item, i) in productCardStyles"
                :key="`${i}_app_template_layout_builder`"
                class="col-md-2"
              >
                <img
                  :src="item.image"
                  :class="page.productCategoryPageStyle === i ? 'app_template active' : 'app_template'"
                  @click="page.productCategoryPageStyle = i"
                  style="width: 100%"
                />
                <div class="caption text-uppercase">
                  {{ item.name }}
                </div>
              </div>
            </v-item-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" sm="4" cols="4">
            <v-select
              :items="enable"
              dense
              hide-details
              label="Hide Empty Categories"
              outlined
              v-model="page.productCategoryHideEmptyCategories"
            ></v-select>
          </v-col>

          <v-col md="4" sm="4" cols="4">
            <v-select
              :items="sizes"
              dense
              hide-details
              label="Category Card Size"
              outlined
              v-model="page.productCategorycardSize"
            ></v-select>
          </v-col>

          <v-col md="4" sm="4" cols="4">
            <v-text-field
              label="Border Radius"
              type="text"
              outlined
              color="primary"
              dense
              hide-details
              v-model="page.productCategorycardborderradius"
              persistent-hint
              rounded
              height="1"
            >
            </v-text-field>
          </v-col>

          <v-col md="4" sm="4" cols="4">
            <v-text-field
              label="Category Card Text Color"
              outlined
              readonly
              color="primary"
              dense
              hide-details
              v-model="page.productCategorycardColor"
            >
              <template v-slot:append>
                <v-btn
                  icon
                  color="smoke"
                  @click="openProductCategorycardColorPicker()"
                >
                  <v-avatar :size="18" :color="page.productCategorycardColor" />
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col md="4" sm="4" cols="4">
            <v-text-field
              label="Category Card Background Color"
              outlined
              readonly
              color="primary"
              dense
              hide-details
              v-model="page.productCategorycardbackgroundColor"
            >
              <template v-slot:append>
                <v-btn
                  icon
                  color="smoke"
                  @click="openProductCategorycardbackgroundColorPicker()"
                >
                  <v-avatar
                    :size="18"
                    :color="page.productCategorycardbackgroundColor"
                  />
                </v-btn>
              </template>
            </v-text-field>
          </v-col>

          <div class="col-md-6">
            <div
              class="permissions_block mb-3 d-flex justify-space-between align-center pa-4"
            >
              <div class="d-flex justify-start align-center">
                <div style="padding: 10px">
                  <div
                    v-if="!page.productCategorycardPlaceholderImage"
                    class="smoke--text"
                    style="width: 32px; height: 32px"
                  >
                    <ImageIcon :size="32" />
                  </div>
                  <div style="width: 120px; height: 120px" v-else>
                    <v-img
                      :src="page.productCategorycardPlaceholderImage"
                      style="width: 120px; max-height: 120px"
                    >
                      <template v-slot:placeholder>
                        <v-progress-circular
                          indeterminate
                          color="smoke"
                          size="32"
                          width="3"
                        ></v-progress-circular>
                      </template>
                    </v-img>
                  </div>
                </div>

                <div style="margin-left: 5px">
                  <div class="body-1 font-weight-medium">
                    {{ $tr('project', 'key_446') }}
                  </div>
                  <div class="body-2 smoke--text">
                    {{ $tr('project', 'key_389') }}
                  </div>
                  <div>
                    <v-btn
                      :loading="logo_image.isSelecting"
                      small
                      depressed
                      class="mt-3"
                      @click="onButtonLogoClick"
                      style="float: left"
                    >
                      {{ $tr('project', 'key_86') }}
                    </v-btn>

                    <input
                      ref="uploaderB"
                      class="d-none"
                      type="file"
                      accept="image/*"
                      @change="onFileLogoChanged"
                    />
                  </div>

                  <v-btn
                    small
                    depressed
                    class="mt-3"
                    @click="openCreativePortal(page.productCategorycardPlaceholderImage)"
                    style="float: left; margin-left: 10px"
                  >
                    <!-- <EditIcon />  -->
                    Open Designer
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </v-row>
      </Container>
      <Footer
        v-if="!loading"
        :loading="btnLoad"
        ref="footer"
        @click="updateSplash"
      />
      <v-dialog scrollable max-width="500" v-model="picker.status">
        <SelectColor
          :color="picker.color"
          @close="picker.status = false"
          @save="saveColor"
        />
      </v-dialog>
      <v-dialog width="1200" scrollable v-model="creative">
        <!-- <div style="width:100%; height:600px; background-color:#141517; padding:25px"> -->
        <div
          style="
            width: 100%;
            height: 600px;
            background-color: #ffffff;
            padding: 25px;
          "
        >
          <iframe
            :src="iframe.src"
            :width="1150"
            :height="550"
            type="text/html"
            frameborder="0"
            scrolling="no"
          ></iframe>
        </div>
      </v-dialog>
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Container from "@/components/blocks/Container";
// import Title from "@/components/blocks/Title";
import Loader from "@/components/blocks/Loader";
// import ImageIcon from "@/components/icons/ImageIcon";
import Footer from "@/components/blocks/Footer";

import Catcardstyle01 from "@/assets/images/categorycard/category-card1.png";
import Catcardstyle02 from "@/assets/images/categorycard/category-card2.png";

import ImageIcon from "@/components/icons/ImageIcon";
import SelectColor from "./modals/SelectColor.vue";
import HelpArticle2 from "../../../../components/blocks/HelpArticle2.vue";

export default {

  components: {
    PageBar,
    Container,
    // Title,
    Loader,
    ImageIcon,
    Footer,
    SelectColor,
    HelpArticle2
},
  data: () => ({
    loading: false,
    page: {
      productCategoryPageStyle          :""  ,
      productCategoryHideEmptyCategories    :""  ,
      productCategorycardSize  :""  ,
      productCategorycardColor  :""  ,
      productCategorycardbackgroundColor: "",
      productCategorycardborderradius:"",
      productCategorycardPlaceholderImage :"",
    },
    logo_image: {
      isSelecting: false,
      selectedFile: null,
      upload: false,
      loading: false
    },
    btnLoad: false,
    creative: false,
    iframe: {
        src: "https://app.shopimint.com/backend/creative/generate",
    },
    productCardStyles: [
      {
        image: Catcardstyle01,
        name: "Style 1"
      },
      {
        image: Catcardstyle02,
        name: "Style 2"
      }
    ],
    picker: {
      status: false,
      mode: "categorycolor",
      color: "#FF4444"
    },
  }),

  computed: {
    enable: function () {
      return [
        {
          text: 'Enable',
          value: '1'
        },
        {
          text: 'Disable',
          value: '0'
        },
      ];
    },
    sizes: function () {
      return [
        {
          text: 'Large',
          value: 'large'
        },
        {
          text: 'Medium',
          value: 'medium'
        },
        {
          text: 'Small',
          value: 'small'
        },
      ];
    }
  },
  methods: {
    updateSplash() {
      this.btnLoad = true;
      let params = new URLSearchParams();
      params.append('productCategoryPageStyle', this.page.productCategoryPageStyle);
      params.append('productCategoryHideEmptyCategories', this.page.productCategoryHideEmptyCategories);
      params.append('productCategorycardSize', this.page.productCategorycardSize);
      params.append('productCategorycardColor', this.page.productCategorycardColor);
      params.append('productCategorycardbackgroundColor', this.page.productCategorycardbackgroundColor);
      params.append('productCategorycardborderradius', this.page.productCategorycardborderradius);
      this.$http.post(`${this.$serverApiLink}api/account/pagesettings/update_categorypage/${this.$route.params.uid}`, params).
      then(
          // eslint-disable-next-line no-unused-vars
          response => {
            this.btnLoad = false;
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "categorypage"
            });
            this.$refs.footer.showSuccessAlert();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.btnLoad = false;
          }
      );
    },
    openCreativePortal(imageurl) {
      this.iframe.src = "https://app.shopimint.com/backend/creative/generate?imageurl="+imageurl;
      this.creative = true;
    },
    onButtonLogoClick() {
      this.logo_image.isSelecting = true;
      window.addEventListener('focus', () => {
        this.logo_image.isSelecting = false
      }, {once: true});
      this.$refs.uploaderB.click();
    },
    onFileLogoChanged(e) {
      this.logo_image.selectedFile = e.target.files[0];
      this.$store.commit('setLoading', true);
      this.logo_image.upload = true;
      let params = new FormData();
      params.set('productCategorycardPlaceholderImage', this.logo_image.selectedFile );
      this.$http.post(
          `${this.$serverApiLink}api/account/assets/upload_categoryplaceholder/${this.$route.params.uid}`, params, {headers: {'Content-Type': 'multipart/form-data' }}).
      then(
          response => {
            this.page.productCategorycardPlaceholderImage = response.data.uri;
            this.logo_image.upload = false;
            this.logo_image.isSelecting = false;
            this.$store.commit('setLoading', false);
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "categorypagebanner"
            });
            this.$refs.footer.showSuccessAlert();
            this.getBannerDetail();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.logo_image.upload = false;
            this.logo_image.isSelecting = false;
            this.$store.commit('setLoading', false);
            this.getBannerDetail();
          }
      );
    },
    saveColor(color) {
      if (this.picker.mode === "categorycolor") {
        this.page.productCategorycardColor = color;
      } else if (this.picker.mode === "categorybackgroundcolor" ){
        this.page.productCategorycardbackgroundColor = color;
      } this.picker.status = false ;
    },
    openProductCategorycardColorPicker() {
      this.picker = {
        status: true,
        mode: "categorycolor",
        color: this.page.productCategorycardColor
      }
    },
    openProductCategorycardbackgroundColorPicker() {
      this.picker = {
        status: true,
        mode: "categorybackgroundcolor",
        color: this.page.productCategorycardbackgroundColor
      }
    },
    getPageSettings() {
      this.$http.get(`${this.$serverApiLink}api/account/pagesettings/detail/${this.$route.params.uid}`).
      then(
          response => {
            this.page = response.data.detail;
            this.loading = false;
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
          }
      );
    },
  },
  mounted() {
    this.getPageSettings();
  }
}
</script>

<style>
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.card {
  flex-direction: row;
  background: #fff;
  padding: 10px;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border-radius: 10px;
}

.theme--dark .card {
  background-color: rgb(29, 29, 44);
  color: white;
}

.card img {
  width: 30%;
}

.v-text-field input {
  font-size: 0.9em;
  color: black;
  font-weight: bold;
  padding: 0px;
}

.v-select {
  font-size: 0.9em;
  color: black;
  font-weight: bold;
  padding: 0px;
}

::v-deep .v-label {
  font-size: 0.9em;
}

::v-deep .v-label--active {
  font-size: 0.9em;
  font-weight: bold;
}

.pagesmenubar {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.pagesmenubar li {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}
</style>
