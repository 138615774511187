<template>
  <div class="bar_padding">
    <v-divider />
    <v-list-item
      style="padding-top: 7px; padding-bottom: 8px"
      @click="$emit('open_deposit')">
      <div v-if="current_plan == 'Enterprise'">
        <img
          src="https://shopimint.vercel.app/_next/image/?url=%2F_next%2Fstatic%2Fmedia%2Fshopimint_enterprice.be966348.png&w=256&q=75"
          style="width: 80%; margin: auto" />
      </div>
      <div v-if="current_plan == 'Ultimate'">
        <img
          src="https://shopimint.vercel.app/_next/image/?url=%2F_next%2Fstatic%2Fmedia%2Fshopimint_ultimate.607de522.png&w=256&q=75"
          style="width: 80%; margin: auto" />
      </div>
      <div v-if="current_plan == 'Pro'">
        <img
          src="https://shopimint.vercel.app/_next/image/?url=%2F_next%2Fstatic%2Fmedia%2Fshopimint_pro.73714dd1.png&w=256&q=75"
          style="width: 80%; margin: auto" />
      </div>
      <div v-if="current_plan == 'starter'">
        <img
          src="https://app.shopimint.com/img/shopimint_trail.png"
          style="width: 80%; margin: auto" />
      </div>
      <div v-if="current_plan == ''">
        <img
          src="https://app.shopimint.com/img/shopimint_trail.png"
          style="width: 80%; margin: auto" />
      </div>
      <!-- <v-list-item-avatar>
        <v-progress-circular
            v-if="loading"
            :width="2"
            :size="37"
            color="smoke"
            indeterminate
        />
        <v-progress-circular
            v-else
            :width="2"
            :size="37"
            :color="balance > 0 ? 'success' : 'orange'"
            :value="100"
        >
          {{ balance }}
        </v-progress-circular>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="font-weight-medium">
          {{ $tr('menu', 'key_20') }}
        </v-list-item-title>
      </v-list-item-content> -->
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: "Balance",
  components: {},
  props: {
    balance: {
      type: Number,
      default: 0,
    },
    current_plan: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    // current_plan:"",
  }),
  methods: {},
  mounted() {},
};
</script>
