<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.30277 7.97274C7.55378 8.46469 7.35846 9.06697 6.86651 9.31798C6.37456 9.56898 5.77227 9.37366 5.52127 8.88171C4.73963 7.34977 5.02193 5.47045 6.25686 4.23552L8.37818 2.1142C9.94028 0.552103 12.4729 0.552103 14.035 2.1142C15.5971 3.6763 15.5971 6.20896 14.035 7.77105C13.6445 8.16158 13.0113 8.16158 12.6208 7.77105C12.2303 7.38053 12.2303 6.74736 12.6208 6.35684C13.4019 5.57579 13.4019 4.30946 12.6208 3.52841C11.8398 2.74736 10.5734 2.74736 9.79239 3.52841L7.67107 5.64973C7.05355 6.26725 6.91232 7.20748 7.30277 7.97274ZM8.84933 8.17362C8.59833 7.68167 8.79365 7.07938 9.2856 6.82838C9.77755 6.57737 10.3798 6.7727 10.6308 7.26464C11.4125 8.79658 11.1302 10.6759 9.89525 11.9108L7.77393 14.0322C6.21183 15.5943 3.67917 15.5943 2.11708 14.0322C0.554979 12.4701 0.554979 9.9374 2.11708 8.3753C2.5076 7.98478 3.14077 7.98478 3.53129 8.3753C3.92181 8.76583 3.92181 9.39899 3.53129 9.78951C2.75024 10.5706 2.75024 11.8369 3.53129 12.6179C4.31234 13.399 5.57867 13.399 6.35972 12.6179L8.48104 10.4966C9.09855 9.87911 9.23979 8.93887 8.84933 8.17362Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'LocationIcon',
  props: {
    size: {
      type: Number,
      default: 16
    }
  }
}
</script>