<template>
  <v-layout fill-height>
    <v-main>
      <PageBar :title="$tr('menu', 'key_e1')" :drawer="true" pagetype="pages" />

      <Loader v-if="loading" />
      <Container v-else>
        <!-- <PageSettingNavBar :name="currentpage" /> -->

        <Title :title="$tr('project', 'key_e4')" style="display: none" />

        <div class="row">
          <div class="col-sm-6">
            <div class="row">
              <!-- <h3 class="col">Componenets</h3> -->
              <!-- <HelpArticle2 class="col" id="632428b8b78f9a796bdea960" /> -->
            </div>

            <div class="row" style="margin-top: 0px">
              <div class="col-md-12">
                <div class="row" style="margin-top: 5px">
                  <div>
                    <div
                      :class="
                        selected == 'popular'
                          ? 'activecategory card pagesidecard'
                          : 'card pagesidecard'
                      "
                      v-on:click="selected = 'popular'">
                      <div class="card-body justify-content-center">
                        <h5
                          class="card-title text-center"
                          style="font-size: 12px">
                          <v-icon :size="20" style="height: 0px; color: orange"
                            >mdi-star
                          </v-icon>
                          Popular
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      :class="
                        selected == 'title'
                          ? 'activecategory card pagesidecard'
                          : 'card pagesidecard'
                      "
                      v-on:click="selected = 'title'">
                      <div class="card-body justify-content-center">
                        <h5
                          class="card-title text-center"
                          style="font-size: 12px">
                          Text
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      :class="
                        selected == 'image'
                          ? 'activecategory card pagesidecard'
                          : 'card pagesidecard'
                      "
                      v-on:click="selected = 'image'">
                      <div class="card-body justify-content-center">
                        <h5
                          class="card-title text-center"
                          style="font-size: 12px">
                          Image
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      :class="
                        selected == 'slider'
                          ? 'activecategory card pagesidecard'
                          : 'card pagesidecard'
                      "
                      v-on:click="selected = 'slider'">
                      <div class="card-body justify-content-center">
                        <h5
                          class="card-title text-center"
                          style="font-size: 12px">
                          Slider
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      :class="
                        selected == 'product'
                          ? 'activecategory card pagesidecard'
                          : 'card pagesidecard'
                      "
                      v-on:click="selected = 'product'">
                      <div class="card-body justify-content-center">
                        <h5
                          class="card-title text-center"
                          style="font-size: 12px">
                          Product
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      :class="
                        selected == 'corosel'
                          ? 'activecategory card pagesidecard'
                          : 'card pagesidecard'
                      "
                      v-on:click="selected = 'corosel'">
                      <div class="card-body justify-content-center">
                        <h5
                          class="card-title text-center"
                          style="font-size: 12px">
                          Collection
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      :class="
                        selected == 'searchbox'
                          ? 'activecategory card pagesidecard'
                          : 'card pagesidecard'
                      "
                      v-on:click="selected = 'searchbox'">
                      <div class="card-body justify-content-center">
                        <h5
                          class="card-title text-center"
                          style="font-size: 12px">
                          Search
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      :class="
                        selected == 'countdown'
                          ? 'activecategory card pagesidecard'
                          : 'card pagesidecard'
                      "
                      v-on:click="selected = 'countdown'">
                      <div class="card-body justify-content-center">
                        <h5
                          class="card-title text-center"
                          style="font-size: 12px">
                          Countdown Timer
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      :class="
                        selected == 'story'
                          ? 'activecategory card pagesidecard'
                          : 'card pagesidecard'
                      "
                      v-on:click="selected = 'story'">
                      <div class="card-body justify-content-center">
                        <h5
                          class="card-title text-center"
                          style="font-size: 12px">
                          Live Story
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      :class="
                        selected == 'youtubevideo'
                          ? 'activecategory card pagesidecard'
                          : 'card pagesidecard'
                      "
                      v-on:click="selected = 'youtubevideo'">
                      <div class="card-body justify-content-center">
                        <h5
                          class="card-title text-center"
                          style="font-size: 12px">
                          Video
                        </h5>
                      </div>
                    </div>
                  </div>
                  <!-- <div >
                    <div
                      :class="(selected == 'imagecarousel') ? 'activecategory card pagesidecard' : 'card pagesidecard'"
                      v-on:click="selected = 'imagecarousel'"
                    >
                      <div class="card-body justify-content-center">
                        <h5
                          class="card-title text-center"
                          style="font-size: 12px"
                        >
                          Carousel
                        </h5>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>

              <div class="col-md-12">
                <div class="wrappercomponent">
                  <draggable
                    class="ch-masonry"
                    style="padding-top: 20px"
                    :list="list1"
                    :group="{ name: 'people', pull: 'clone', put: false }"
                    :clone="clone"
                    @start="start">
                    <div
                      class="ch-masonry__item"
                      v-for="element in list1"
                      :key="element.id">
                      <ComponenetItemCard
                        :status="true"
                        :name="element.name"
                        :displayname="element.displayname"
                        :image="element.vicon"
                        :selectedcategory="selected"
                        :type="element.type" />
                    </div>
                  </draggable>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-sm-6"
            style="align-items: center; justify-content: center; width: 100%">
            <div
              class="dragArea2"
              :style="
                Object.keys(list2).length == 0
                  ? `background-image: url('https://app.shopimint.com/backend/static/dragdropbackground.png'); background-size: 367px 98px; margin-top:25px`
                  : 'margin-top:25px'
              ">
              <!-- <div class="col-sm-12" v-if="Object.keys(list2).length == 0">
              <div style="text-align: center; padding:30px; border-radius: 10px;  width:100%; border: 1px dashed black;">
                Drag Your Items to here
              </div>
            </div> -->

              <!-- <v-app-bar
                color="white"
              >
                <v-app-bar-nav-icon></v-app-bar-nav-icon>

                <v-toolbar-title>App Bar</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn icon>
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>

              </v-app-bar> -->

              <draggable
                class="list-group"
                :list="list2"
                group="people"
                v-bind="dragOptions"
                handle=".handle"
                style="margin-top: 20px; width: 100%">
                <!-- <div class="list-group-item" v-for="element in list2" :key="element.id">
                {{ element.name }}
              </div> -->
                <transition-group
                  type="transition"
                  :name="!drag ? 'flip-list' : null">
                  <div
                    class="col-sm-12"
                    style="padding: 0px; margin: 0px"
                    v-for="(element, idx) in list2"
                    :key="element.id">
                    <ActiveComponenetCard
                      :element="element"
                      :status="true"
                      :componentitem="element"
                      :name="element.name"
                      :vicon="element.vicon"
                      :styles="element.styles"
                      :idx="idx"
                      :rowid="element.id"
                      :type="element.type"
                      :data="element.data"
                      :data_id="element.data_id"
                      :dataloadtype="element.dataloadtype"
                      :data_query="element.data_query"
                      :query="element.query"
                      :activelist="list2"
                      :categorieslist="categorieslist"
                      :platform="platform" />
                  </div>
                </transition-group>
              </draggable>
            </div>
            <!-- <p style="text-align: left; padding-left: 20px; padding-top:10px ;">Powered by Shopimint</p> -->
          </div>

          <!-- <div class="col-md-6" style="display: block">
            <div v-for="(item, index) in list2" :key="item.id">
              {{index}}. {{item.name}}. {{item.vicon}}
            </div>
          </div>
          <RawDisplayer
            class="col-md-6"
            :value="list2"
            title="List"
            style="display: block"
          />
          <div class="col-6" style="display: block">
            <div v-for="(item, index) in list2" :key="item.id">
              {{index}}. {{item.name}}. {{item.vicon}}
            </div> 
          </div> -->
        </div>
      </Container>
      <Footer
        v-if="!loading"
        :loading="btnLoad"
        ref="footer"
        @click="updatePage" />
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Container from "@/components/blocks/Container";
import Title from "@/components/blocks/Title";
import Loader from "@/components/blocks/Loader";
// import ImageIcon from "@/components/icons/ImageIcon";
import Footer from "@/components/blocks/Footer";
// import PageSettingNavBar from "@/components/blocks/PageSettingNavBar";

import { homecomponents } from "@/data/homecomponents";
import draggable from "vuedraggable";
// import sliderico from "@/assets/images/builder/sliderico.png";
import ComponenetItemCard from "@/components/blocks/ComponenetItemCard";
import ActiveComponenetCard from "@/components/blocks/ActiveComponenetCard";
// import RawDisplayer from "@/components/blocks/RawDisplayer";
// import HelpArticle2 from "../../../../components/blocks/HelpArticle2.vue";

// let idGlobal = 8;

export default {
  name: "Pages",

  display: "Clone on Control",
  instruction: "Press Ctrl to clone element from list 1",
  order: 4,

  components: {
    PageBar,
    Container,
    Title,
    Loader,
    Footer,
    draggable,
    ComponenetItemCard,
    // PageSettingNavBar,
    ActiveComponenetCard,
    // RawDisplayer,
    // HelpArticle2
  },
  data: () => ({
    selected: "popular",
    loading: true,
    picker: false,
    btnLoad: false,
    currentpage: 1,
    list1: homecomponents,
    list2: [],
    platform: "",
    categorieslist: [],
    controlOnStart: true,
    drag: false,
    selectedpage: "pages",
  }),
  computed: {
    background: function () {
      return [
        {
          text: this.$tr("project", "key_72"),
          value: 0,
        },
        {
          text: this.$tr("project", "key_73"),
          value: 1,
        },
      ];
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    tabs: function () {
      return [
        this.$tr("menu", "key_e20"),
        this.$tr("menu", "key_e19"),
        this.$tr("menu", "key_e21"),
        this.$tr("menu", "key_e22"),
        this.$tr("menu", "key_e23"),
        this.$tr("menu", "key_e24"),
        this.$tr("menu", "key_e25"),
        this.$tr("menu", "key_e26"),
        this.$tr("menu", "key_e27"),
        this.$tr("menu", "key_e28"),
      ];
    },
  },
  methods: {
    // routepage(){
    //   console.log(this.selectedpage);
    //   this.$router.push({name: this.selectedpage, params: {uid: this.$route.params.uid}});
    // },

    //Drag Builder Methords
    clone({
      name,
      type,
      vicon,
      styles,
      dataloadtype,
      data,
      data_query,
      data_id,
      data_action,
      data_action_type,
    }) {
      return {
        name,
        type,
        vicon,
        styles,
        dataloadtype,
        data,
        data_query,
        data_id,
        data_action,
        data_action_type,
        id: this.getid(),
      };
    },
    getid() {
      return Math.floor(Math.random() * 10000 + 1);
    },
    // pullFunction() {
    //   return this.controlOnStart ? "clone" : true;
    // },
    start({ originalEvent }) {
      this.controlOnStart = originalEvent.ctrlKey;
    },
    //Crud Methords
    getHomePageDetails() {
      this.loading = true;
      this.$http
        .get(
          `${this.$serverApiLink}api/account/apps/homepagedetails/${this.$route.params.uid}`
        )
        .then((response) => {
          const data = response.data.app;
          this.list2 = JSON.parse(data.home_settings);
          this.platform = data.platform;
          this.loading = false;
          this.getCategories();
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
        });
    },
    getCategories() {
      // this.loading = true;
      this.$http
        .get(
          `${this.$serverApiLink}api/account/cachemanager/getCategories/${this.$route.params.uid}`
        )
        .then((response) => {
          const data = response.data;
          this.categorieslist = data.categories;
          // this.loading = false;
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
        });
    },
    updatePage() {
      this.btnLoad = true;

      let params = new URLSearchParams();
      params.append("home_settings", JSON.stringify(this.list2));
      this.$http
        .post(
          `${this.$serverApiLink}api/account/apps/updatehomepage/${this.$route.params.uid}`,
          params
        )
        .then(
          // eslint-disable-next-line no-unused-vars
          (response) => {
            this.btnLoad = false;
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "splash",
            });
            this.$refs.footer.showSuccessAlert();
          }
        )
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.btnLoad = false;
        });
    },
  },
  mounted() {
    this.getHomePageDetails();
    this.$emit("reload_preview", {
      uid: this.$route.params.uid,
      mode: "splash",
    });
  },
  beforeDestroy() {
    this.$emit("reload_preview", {
      uid: this.$route.params.uid,
      mode: "app",
    });
  },
};
</script>

<style>
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.card {
  flex-direction: row;
  background: #fff;
  padding: 10px;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border-radius: 10px;
}

.theme--dark .card {
  background-color: rgb(29, 29, 44);
  color: white;
}

.card img {
  width: 30%;
}

.v-text-field input {
  font-size: 0.9em;
  color: black;
  font-weight: bold;
  padding: 0px;
}

.v-select {
  font-size: 0.9em;
  color: black;
  font-weight: bold;
  padding: 0px;
}

::v-deep .v-label {
  font-size: 0.9em;
}

::v-deep .v-label--active {
  font-size: 0.9em;
  font-weight: bold;
}

.pagesmenubar {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.pagesmenubar li {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}
</style>
