<template>
  <div
    class="component-item"
    @mouseover="activeeditelement = true"
    @mouseleave="activeeditelement = false">
    <div
      class="card componeneteditcard"
      style="
        height: 45px;
        width: 120px;
        margin-left: 240px;
        margin-bottom: -60px;
        background-color: white;
        position: relative;
        z-index: 3;
      ">
      <!-- v-if="activeeditelement" -->
      <v-icon :size="30" class="handle" style="cursor: grab; height: 0px"
        >mdi-drag</v-icon
      >
      <v-btn
        icon
        small
        color="orange"
        style="cursor: pointer; height: 0px"
        v-on:click="seen = !seen">
        <EditMiniIcon :size="20" />
      </v-btn>
      <v-btn
        icon
        small
        color="danger"
        style="cursor: pointer; height: 0px"
        @click="activelist.splice(idx, 1)">
        <DeleteMiniIcon :size="20" />
      </v-btn>
    </div>

    <!-- Custom Card-->
    <div
      class="row"
      style="
        margin: 0px;
        padding: 0px;
        padding-top: 15px;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        min-height: 45px;
      "
      v-if="
        type != 'imagebanner' &&
        type != 'imagecarousel' &&
        type != 'categorygridcarousel' &&
        type != 'slider' &&
        type != 'title' &&
        type != 'corosel' &&
        type != 'product' &&
        type != 'productgrid' &&
        type != 'productlist' &&
        type != 'countdown' &&
        type != 'searchbox' &&
        type != 'story' &&
        type != 'appbarslider' &&
        type != 'youtubevideo'
      "
      v-bind:style="{
        marginLeft: styles.marginLeft + 'px !important',
        marginRight: styles.marginRight + 'px !important',
        marginTop: styles.marginTop + 'px !important',
        marginBottom: styles.marginBottom + 'px !important',
        backgroundColor: styles.bgcolor,
      }">
      <div
        style="
          background-color: #d3d3d3;
          width: 100%;
          height: 100px;
          margin: 5px;
          border-radius: 10px;
        ">
        <div class="row">
          <div class="col">
            <img
              :src="element.backgroundimage"
              style="width: 100%; height: 100px; border-radius: 10px" />
          </div>
          <div class="col">
            <p style="margin-top: 40px; text-align: center; font-weight: bold">
              {{ element.type }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row"
      style="
        margin: 0px;
        padding: 0px;
        padding-top: 15px;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        min-height: 45px;
      "
      v-if="type === 'imagebanner'"
      v-bind:style="{
        marginLeft: styles.marginLeft + 'px !important',
        marginRight: styles.marginRight + 'px !important',
        marginTop: styles.marginTop + 'px !important',
        marginBottom: styles.marginBottom + 'px !important',
        backgroundColor: styles.bgcolor,
      }">
      <div
        v-bind:class="
          styles.column == 1 ? 'col-12' : styles.column == 2 ? 'col-6' : 'col-4'
        "
        v-for="sliderelement in data"
        :key="sliderelement.id"
        style="margin: 0px; padding: 0px"
        v-bind:style="{
          paddingLeft: styles.paddingLeft + 'px',
          paddingRight: styles.paddingRight + 'px',
          paddingTop: styles.paddingTop + 'px',
          paddingBottom: styles.paddingBottom + 'px',
        }">
        <img
          :src="sliderelement.image"
          style="width: 100%"
          v-if="styles.boxfit == 'fill' || styles.boxfit == 'fitWidth'"
          v-bind:style="{
            borderRadius: styles.borderradius + 'px !important',
          }" />
        <v-responsive
          v-else
          :aspect-ratio="styles.aspect_ratio"
          v-bind:style="{
            background:
              'url(' + sliderelement.image + ') ' + 'no-repeat center center',
            backgroundSize: styles.boxfit,
            borderRadius: styles.borderradius + 'px !important',
          }">
        </v-responsive>
      </div>
    </div>

    <div
      class="row col-12 scrolling-wrapper-flexbox"
      style="margin: 0px; padding: 0px; width: 100%; min-height: 45px"
      v-if="type === 'imagecarousel' || type === 'categorygridcarousel'"
      v-bind:style="{
        marginLeft: styles.marginLeft + 'px !important',
        marginRight: styles.marginRight + 'px !important',
        marginTop: styles.marginTop + 'px !important',
        marginBottom: styles.marginBottom + 'px !important',
        backgroundColor: styles.bgcolor,
      }">
      <div
        class="coruselcard"
        style="margin: 0px; padding: 0px; display: inline-block"
        v-for="sliderelement in data"
        :key="sliderelement.id">
        <div v-if="styles.boxfit == 'fill' || styles.boxfit == 'fitWidth'">
          <img
            :src="sliderelement.image"
            :width="styles.width / 3"
            v-bind:style="{
              paddingLeft: styles.paddingLeft + 'px',
              paddingRight: styles.paddingRight + 'px',
              paddingTop: styles.paddingTop + 'px',
              paddingBottom: styles.paddingBottom + 'px',
              borderRadius: styles.borderradius + 'px !important',
            }" />
        </div>

        <v-responsive
          v-else
          :width="styles.width / 3"
          :height="styles.height / 3"
          v-bind:style="{
            background:
              'url(' + sliderelement.image + ') ' + 'no-repeat center center',
            backgroundSize: styles.boxfit,
            borderRadius: styles.borderradius + 'px !important',
          }">
        </v-responsive>
      </div>
    </div>

    <div
      class="row"
      style="
        margin: 0px;
        width: 100%;
        padding: 0px;
        padding-top: 15px;
        min-height: 45px;
      "
      v-if="type === 'slider'">
      <div
        class="col-12"
        style="padding: 0px"
        v-bind:style="{
          marginLeft: styles.marginLeft + 'px',
          marginRight: styles.marginRight + 'px',
          marginTop: styles.marginTop + 'px',
          marginBottom: styles.marginBottom + 'px',
        }">
        <v-responsive
          :aspect-ratio="styles.aspect_ratio"
          style="overflow: hidden"
          v-bind:style="{
            marginLeft: styles.marginLeft + 'px',
            marginRight: styles.marginRight + 'px',
            marginTop: styles.marginTop + 'px',
            marginBottom: styles.marginBottom + 'px',
          }">
          <v-carousel
            :aspect-ratio="styles.aspect_ratio"
            height="auto"
            :show-arrows="false"
            :cycle="true"
            hide-delimiters
            show-arrows-on-hover
            :style="{ borderRadius: styles.borderradius }">
            <v-carousel-item
              v-for="sliderelement in data"
              :key="sliderelement.id"
              :src="sliderelement.image">
            </v-carousel-item>
          </v-carousel>
        </v-responsive>
      </div>
    </div>

    <div
      class="row"
      style="margin: 15px 0px 0px 0px; padding: 0px; width: 100%"
      v-if="type === 'youtubevideo'">
      <div
        v-bind:style="{
          marginLeft: styles.marginLeft + 'px !important',
          marginRight: styles.marginRight + 'px !important',
          marginTop: styles.marginTop + 'px !important',
          marginBottom: styles.marginBottom + 'px !important',
          backgroundColor: styles.bgcolor,
        }">
        <div
          class="col-12"
          style="margin: 0px; padding: 0px"
          v-bind:style="{
            paddingLeft: styles.paddingLeft + 'px',
            paddingRight: styles.paddingRight + 'px',
            paddingTop: styles.paddingTop + 'px',
            paddingBottom: styles.paddingBottom + 'px',
          }">
          <iframe
            width="370"
            height="200"
            :src="
              'https://www.youtube.com/embed/' + styles.videoId + '?controls=0'
            "
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share">
          </iframe>
        </div>
      </div>
    </div>

    <div
      class="row"
      style="margin: 0px; width: 100%; padding: 0px; padding-top: 10px"
      v-if="type === 'searchbox'"
      v-bind:style="{
        marginLeft: styles.marginLeft + 'px !important',
        marginRight: styles.marginRight + 'px !important',
        marginTop: styles.marginTop + 'px !important',
        marginBottom: styles.marginBottom + 'px !important',
        backgroundColor: styles.bgcolor,
      }">
      <div
        class="col-12"
        style="padding: 0px; margin: 0px"
        v-bind:style="{
          paddingLeft: styles.paddingLeft + 'px !important',
          paddingRight: styles.paddingRight + 'px !important',
          paddingTop: styles.paddingTop + 'px !important',
          paddingBottom: styles.paddingBottom + 'px !important',
        }">
        <v-text-field
          :label="styles.text"
          solo
          prepend-inner-icon="fa-search"></v-text-field>
      </div>
    </div>

    <div
      class="row"
      style="margin: 0px; width: 100%; padding: 0px; padding-top: 10px"
      v-if="type === 'title'"
      v-bind:style="{
        textAlign: styles.align,
        marginLeft: styles.marginLeft + 'px !important',
        marginRight: styles.marginRight + 'px !important',
        marginTop: styles.marginTop + 'px !important',
        marginBottom: styles.marginBottom + 'px !important',
        backgroundColor: styles.bgcolor,
      }">
      <div
        v-bind:class="
          styles.style == 'style1' ||
          styles.style == 'style2' ||
          styles.style == 'style3' ||
          styles.style == 'style4'
            ? 'col-12'
            : 'col-8'
        "
        v-bind:style="{
          textAlign: styles.align,
          paddingLeft: styles.paddingLeft + 'px',
          paddingRight: styles.paddingRight + 'px',
          paddingTop: styles.paddingTop + 'px',
          paddingBottom: styles.paddingBottom + 'px',
        }"
        v-if="
          styles.style == 'style2' ||
          styles.style == 'style3' ||
          styles.style == 'style6'
        ">
        <span
          v-bind:style="{
            textAlign: styles.align,
            fontSize: styles.fontsize + 'px',
            color: styles.color,
            fontWeight: styles.fontweight,
          }"
          >{{ styles.text }}</span
        >
        <br />
        <span
          v-bind:style="{
            textAlign: styles.align,
            fontSize: styles.subtitle_fontsize + 'px',
            color: styles.subtitle_color,
            fontWeight: styles.subtitle_fontweight,
            paddingTop: styles.subtitle_padding + 'px',
          }"
          >{{ styles.subtitle }}</span
        >
      </div>

      <div
        v-bind:class="
          styles.style == 'style1' ||
          styles.style == 'style2' ||
          styles.style == 'style3' ||
          styles.style == 'style4'
            ? 'col-12'
            : 'col-8'
        "
        v-bind:style="{
          textAlign: styles.align,
          paddingLeft: styles.paddingLeft + 'px',
          paddingRight: styles.paddingRight + 'px',
          paddingTop: styles.paddingTop + 'px',
          paddingBottom: styles.paddingBottom + 'px',
        }"
        v-if="
          styles.style == 'style1' ||
          styles.style == 'style4' ||
          styles.style == 'style5'
        ">
        <span
          v-bind:style="{
            textAlign: styles.align,
            fontSize: styles.subtitle_fontsize + 'px',
            color: styles.subtitle_color,
            fontWeight: styles.subtitle_fontweight,
          }"
          >{{ styles.subtitle }}</span
        >
        <br />
        <span
          v-bind:style="{
            textAlign: styles.align,
            fontSize: styles.fontsize + 'px',
            color: styles.color,
            fontWeight: styles.fontweight,
            paddingTop: styles.subtitle_padding + 'px',
          }"
          >{{ styles.text }}</span
        >
      </div>

      <div
        v-bind:class="
          styles.style == 'style5' || styles.style == 'style6'
            ? 'col-4'
            : 'col-12'
        "
        style="padding-top: 0px"
        v-bind:style="{
          paddingLeft: styles.paddingLeft + 'px',
          paddingRight: styles.paddingRight + 'px',
          paddingTop: styles.paddingTop + 'px',
          paddingBottom: styles.paddingBottom + 'px',
        }"
        v-if="
          styles.style == 'style3' ||
          styles.style == 'style4' ||
          styles.style == 'style5' ||
          styles.style == 'style6'
        ">
        <v-btn
          v-if="styles.button_style == 'style1'"
          elevation="2"
          small
          :color="styles.btn_textcolor"
          v-bind:style="{
            background: styles.btn_color,
            fontSize: styles.btn_fontsize + 'px',
            color: styles.subtitle_color,
          }"
          >{{ styles.btn_text }}</v-btn
        >

        <v-btn
          v-if="styles.button_style == 'style2'"
          small
          outlined
          v-bind:style="{
            fontSize: styles.btn_fontsize + 'px',
            color: styles.subtitle_color,
          }"
          >{{ styles.btn_text }}</v-btn
        >

        <v-btn
          text
          v-if="styles.button_style == 'style3'"
          small
          v-bind:style="{
            fontSize: styles.btn_fontsize + 'px',
            color: styles.subtitle_color,
          }"
          >{{ styles.btn_text }}</v-btn
        >
      </div>
    </div>

    <div
      class="row"
      style="margin: 0px; width: 100%; padding: 0px; padding-top: 10px"
      v-if="
        type == 'productgrid' ||
        type == 'productlist' ||
        type == 'story' ||
        type == 'appbarslider' ||
        type == 'corosel' ||
        type == 'product'
      ">
      <div class="col-12">
        <img
          :src="require(`@/assets/images/builder/${vicon}`)"
          alt=""
          style="width: 100%; border-radius: 10px"
          class="mr-3" />
      </div>
    </div>

    <div
      class="row"
      style="margin: 0px; width: 100%; padding-top: 10px"
      v-if="type === 'countdown'"
      v-bind:style="{
        marginLeft: styles.marginLeft + 'px !important',
        marginRight: styles.marginRight + 'px !important',
        marginTop: styles.marginTop + 'px !important',
        marginBottom: styles.marginBottom + 'px !important',
      }">
      <div class="col-12" style="padding: 0px; text-align: center">
        <span
          v-bind:style="{
            align: 'center',
            color: styles.countdown_title_color,
            fontWeight: 'bold',
          }"
          >{{ styles.countdown_title }}</span
        >
      </div>
      <div
        class="col-12"
        style="padding: 0px; text-align: center"
        v-bind:style="{
          paddingLeft: styles.paddingLeft + 'px',
          paddingRight: styles.paddingRight + 'px',
          paddingTop: styles.paddingTop + 'px',
          paddingBottom: styles.paddingBottom + 'px',
          backgroundColor: styles.bgcolor,
        }">
        <div
          v-bind:style="{
            align: 'center',
            color: styles.countdown_timer_color,
            fontWeight: 'bold',
          }">
          <div class="row">
            <div class="col-2"></div>
            <div
              class="col-2"
              v-bind:style="{
                fontSize: '25px',
                align: 'center',
                color: styles.countdown_timer_color,
                fontWeight: 'bold',
              }">
              00 <br />
              <span
                :style="{ fontSize: '14px', color: styles.countdown_sec_color }"
                >Days</span
              >
            </div>
            <div
              class="col-2"
              v-bind:style="{
                fontSize: '25px',
                align: 'center',
                color: styles.countdown_timer_color,
                fontWeight: 'bold',
              }">
              00 <br />
              <span
                :style="{ fontSize: '14px', color: styles.countdown_sec_color }"
                >Hours</span
              >
            </div>
            <div
              class="col-2"
              v-bind:style="{
                fontSize: '25px',
                align: 'center',
                color: styles.countdown_timer_color,
                fontWeight: 'bold',
              }">
              00 <br />
              <span
                :style="{ fontSize: '14px', color: styles.countdown_sec_color }"
                >Mins</span
              >
            </div>
            <div
              class="col-2"
              v-bind:style="{
                fontSize: '25px',
                align: 'center',
                color: styles.countdown_timer_color,
                fontWeight: 'bold',
              }">
              00 <br />
              <span
                :style="{ fontSize: '14px', color: styles.countdown_sec_color }"
                >Sec</span
              >
            </div>
            <div class="col-2"></div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row"
      style="margin: 5px 0px; width: 100%; padding: 20px"
      v-if="false">
      <!-- <div class="col-1 handle">
        <v-icon class="handle" style="cursor: grab">mdi-drag</v-icon>
      </div> -->
      <div class="col-2 col-xl-2 handle" style="cursor: grab">
        <img
          :src="require(`@/assets/images/builder/${vicon}`)"
          alt=""
          style="width: 50px; height: 60px; border-radius: 10px"
          v-on:click="seen = !seen" />
      </div>
      <h5 class="card-title col-8 col-xl-8">
        <span v-on:click="seen = !seen">&nbsp; {{ name }} {{ data_id }}</span>
        <!-- <v-btn icon small color="orange" v-on:click="seen = !seen">
          <EditMiniIcon :size="16" />
        </v-btn> -->
      </h5>
      <!-- <div class="col-1">
        <v-btn icon small color="danger" @click="activelist.splice(idx, 1);">
          <DeleteMiniIcon :size="16" />
        </v-btn>
      </div> -->
    </div>

    <!-- <div v-if="seen" > -->

    <v-dialog
      v-model="seen"
      transition="dialog-bottom-transition"
      width="100%"
      height="500"
      max-width="800px"
      persistent
      scrollable
      overlay>
      <div class="card-body">
        <div class="row pagemodeltopbar" style="margin: 0">
          <div class="col-8">
            <span style="font-size: 16px; font-weight: bold; padding-top: 14px"
              >Customize {{ name }}
            </span>
          </div>
          <div class="col-4">
            <v-btn icon dark @click="seen = false" style="float: right">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>

        <div style="height: 60px; width: 100px"></div>

        <div
          class="row"
          style="margin: 10px"
          v-if="type == 'categorygridcarousel'">
          <div class="col-4">
            <v-text-field
              label="Custom Type"
              outlined
              color="primary"
              dense
              hide-details
              v-model="element.type"
              persistent-hint
              rounded
              height="1">
            </v-text-field>
          </div>
        </div>
        <div
          class="row"
          style="margin: 10px"
          v-if="
            type != 'title' &&
            type != 'imagebanner' &&
            type != 'imagecarousel' &&
            type != 'categorygridcarousel' &&
            type != 'slider' &&
            type != 'corosel' &&
            type != 'product' &&
            type != 'productgrid' &&
            type != 'productlist' &&
            type != 'countdown' &&
            type != 'searchbox' &&
            type != 'story' &&
            type != 'appbarslider' &&
            type != 'youtubevideo'
          ">
          <div class="col-4">
            <v-text-field
              label="Custom Type"
              outlined
              color="primary"
              dense
              hide-details
              v-model="element.type"
              persistent-hint
              rounded
              height="1">
            </v-text-field>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-2">
                <img
                  :src="element.backgroundimage || 'placeholder.jpg'"
                  style="
                    border-radius: 3px;
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    background-color: bisque;
                  " />
                <v-btn
                  icon
                  small
                  color="danger"
                  @click="deleteItem(1)"
                  style="
                    position: relative;
                    top: -35px;
                    left: 5px;
                    background-color: white;
                  ">
                  <DeleteMiniIcon :size="16" />
                </v-btn>
              </div>

              <div class="col-10">
                <input
                  ref="uploaderA"
                  type="file"
                  accept="image/*"
                  @change="onFileUploadChanged(element, $event)"
                  style="width: 100%; overflow: hidden; font-size: 14px" />
              </div>
            </div>
          </div>
        </div>

        <div class="row" style="margin: 10px" v-if="type === 'title'">
          <div class="col-4">
            <v-text-field
              label="Title Content"
              outlined
              color="primary"
              dense
              hide-details
              v-model="styles.text"
              persistent-hint
              rounded
              height="1">
            </v-text-field>
          </div>

          <div class="col-4">
            <v-text-field
              label="Sub Heading"
              outlined
              color="primary"
              dense
              hide-details
              v-model="styles.subtitle"
              persistent-hint
              rounded
              height="1">
            </v-text-field>
          </div>

          <div class="col-4">
            <v-text-field
              label="Button Text"
              outlined
              color="primary"
              dense
              hide-details
              v-model="styles.btn_text"
              persistent-hint
              rounded
              height="1">
            </v-text-field>
          </div>
        </div>

        <div class="row" style="margin: 5px" v-if="type === 'title'">
          <v-col md="12" sm="12" cols="12" class="textstyles">
            <span style="margin-bottom: 5px">Text Styles</span>
            <v-item-group mandatory v-model="styles.style" class="row">
              <div
                v-for="(item, i) in textstyletemplates"
                :key="`${i}_app_template_layout_builder`"
                class="col-md-4">
                <img
                  :src="item.image"
                  :class="
                    styles.style === item.value
                      ? 'app_template active'
                      : 'app_template'
                  "
                  @click="styles.style = item.value"
                  style="width: 100%" />
              </div>
            </v-item-group>
          </v-col>

          <div class="col-sm-12">
            <span>Title Styles</span>
          </div>
          <div class="col-sm-4">
            <v-select
              label="Text Size"
              :items="fontsizesdata"
              dense
              hide-details
              outlined
              v-model="styles.fontsize"></v-select>
          </div>
          <div class="col-sm-4">
            <v-select
              label="Alignment"
              :items="alignmenetdata"
              dense
              hide-details
              outlined
              v-model="styles.align"></v-select>
          </div>
          <div class="col-sm-4">
            <v-select
              label="Font Weight"
              :items="fontweights"
              dense
              hide-details
              outlined
              v-model="styles.fontweight"></v-select>
          </div>

          <div class="col-sm-12">
            <span>Subtitle Styles</span>
          </div>
          <div class="col-sm-4">
            <v-select
              label="Subtitle Font  Size"
              :items="fontsizesdata"
              dense
              hide-details
              outlined
              v-model="styles.subtitle_fontsize"></v-select>
          </div>
          <div class="col-sm-4">
            <v-text-field
              label="Subtitle Padding"
              outlined
              color="primary"
              dense
              type="number"
              hide-details
              v-model="styles.subtitle_padding"
              persistent-hint
              rounded
              height="1">
            </v-text-field>
          </div>

          <div class="col-sm-4">
            <v-select
              label="Subtitle Font Weight"
              :items="fontweights"
              dense
              hide-details
              outlined
              v-model="styles.subtitle_fontweight"></v-select>
          </div>
          <div
            class="col-sm-12"
            v-if="
              styles.style === 'style3' ||
              styles.style === 'style4' ||
              styles.style === 'style5' ||
              styles.style === 'style6'
            ">
            <span>Button Styles</span>
          </div>
          <v-col
            md="12"
            sm="12"
            cols="12"
            class="textstyles"
            v-if="
              styles.style === 'style3' ||
              styles.style === 'style4' ||
              styles.style === 'style5' ||
              styles.style === 'style6'
            ">
            <v-item-group mandatory v-model="styles.button_style" class="row">
              <div
                v-for="(item, i) in buttontemplates"
                :key="`${i}_app_template_layout_builder`"
                class="col-md-3">
                <img
                  :src="item.image"
                  :class="
                    styles.button_style === item.value
                      ? 'app_template active'
                      : 'app_template'
                  "
                  @click="styles.button_style = item.value"
                  style="width: 100%" />
              </div>
            </v-item-group>
          </v-col>

          <div
            class="col-sm-4"
            v-if="
              styles.style === 'style3' ||
              styles.style === 'style4' ||
              styles.style === 'style5' ||
              styles.style === 'style6'
            ">
            <v-select
              label="Button Font Size"
              :items="fontsizesdata"
              dense
              hide-details
              outlined
              v-model="styles.btn_fontsize"></v-select>
          </div>
          <div
            class="col-sm-4"
            v-if="
              styles.style === 'style3' ||
              styles.style === 'style4' ||
              styles.style === 'style5' ||
              styles.style === 'style6'
            ">
            <v-text-field
              label="Button Padding"
              outlined
              color="primary"
              dense
              type="number"
              hide-details
              v-model="styles.btn_padding"
              persistent-hint
              rounded
              height="1">
            </v-text-field>
          </div>
          <div
            class="col-sm-4"
            v-if="
              styles.style === 'style3' ||
              styles.style === 'style4' ||
              styles.style === 'style5' ||
              styles.style === 'style6'
            ">
            <v-text-field
              label="Button Border Radius"
              outlined
              color="primary"
              dense
              type="number"
              hide-details
              v-model="styles.btn_border_radius"
              persistent-hint
              rounded
              height="1">
            </v-text-field>
          </div>
        </div>

        <div class="row" style="margin: 0px">
          <div class="row col-12" style="margin: 0px" v-if="type === 'slider'">
            <div class="col-sm-12">
              <span style="margin-bottom: 5px">Slider Type</span>
              <v-item-group mandatory v-model="styles.slidertype" class="row">
                <div
                  v-for="(item, i) in slidertypes"
                  :key="`${i}_app_template_layout_builder`"
                  class="col-md-3">
                  <img
                    :src="item.image"
                    :class="
                      styles.slidertype === item.value
                        ? 'app_template active'
                        : 'app_template'
                    "
                    @click="styles.slidertype = item.value"
                    style="width: 100%" />
                </div>
              </v-item-group>
            </div>
          </div>

          <div
            class="row col-12 pad10"
            style="margin: 0px"
            v-if="
              type === 'slider' ||
              type === 'appbarslider' ||
              type === 'imagecarousel'
            ">
            <div class="col-sm-4">
              <v-checkbox
                label="Autoplay"
                dense
                hide-details
                outlined
                v-model="styles.autoPlay"></v-checkbox>
            </div>
            <div class="col-sm-4">
              <v-checkbox
                label="Loop"
                dense
                hide-details
                outlined
                v-model="styles.loop"></v-checkbox>
            </div>
            <div class="col-sm-4">
              <v-checkbox
                label="Indicator"
                dense
                hide-details
                outlined
                v-model="styles.indicator"></v-checkbox>
            </div>

            <div class="col-sm-4">
              <v-select
                label="Scroll Direction"
                :items="scrolldirectiondata"
                dense
                hide-details
                outlined
                v-model="styles.directon"></v-select>
            </div>

            <div class="col-sm-4">
              <v-select
                label="Duration"
                :items="durationtime"
                dense
                hide-details
                outlined
                v-model="styles.autoPlayAnimationDuration"></v-select>
            </div>

            <div class="col-sm-4">
              <v-select
                label="Slider Image Fit"
                :items="boxfits"
                dense
                hide-details
                outlined
                v-model="styles.boxfit"></v-select>
            </div>

            <div class="col-sm-3">
              <div class="componentslider">
                <v-lable>
                  <span class="sliderlable"
                    >Border Radius : {{ styles.borderradius }}</span
                  >
                </v-lable>
                <v-slider
                  v-model="styles.borderradius"
                  step="0.1"
                  min="0"
                  max="100"
                  inverse-label
                  color="#000000"
                  track-color="rgb(0 0 0 / 12%)"></v-slider>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="componentslider">
                <v-lable>
                  <span class="sliderlable"
                    >Aspect Ratio : {{ styles.aspectratio }}</span
                  >
                </v-lable>
                <v-slider
                  v-model="styles.aspectratio"
                  step="0.1"
                  min="0.5"
                  max="4"
                  inverse-label
                  color="#000000"
                  track-color="rgb(0 0 0 / 12%)"></v-slider>
              </div>
            </div>
            <div
              class="col-sm-3"
              v-if="
                styles.slidertype === 'CUSTOM' ||
                styles.slidertype === 'DEFAULT'
              ">
              <div class="componentslider">
                <v-lable>
                  <span class="sliderlable">Scale : {{ styles.scale }}</span>
                </v-lable>
                <v-slider
                  v-model="styles.scale"
                  step="0.1"
                  min="0"
                  max="1"
                  inverse-label
                  color="#000000"
                  track-color="rgb(0 0 0 / 12%)"></v-slider>
              </div>
            </div>
            <div
              class="col-sm-3"
              v-if="
                styles.slidertype === 'CUSTOM' ||
                styles.slidertype === 'DEFAULT'
              ">
              <div class="componentslider">
                <v-lable>
                  <span class="sliderlable"
                    >Viewport Faction : {{ styles.viewportFraction }}</span
                  >
                </v-lable>
                <v-slider
                  v-model="styles.viewportFraction"
                  step="0.1"
                  min="0.1"
                  max="1"
                  inverse-label
                  color="#000000"
                  track-color="rgb(0 0 0 / 12%)"></v-slider>
              </div>
            </div>

            <div class="col-sm-12">
              <SlideshowComponenet
                :slideshowid="data_id"
                :sliderdata="data"
                :categorieslist="categorieslist" />
            </div>

            <!-- <div class="col-sm-4">
              <v-select
                label="Slideshow"
                :items="slideshows"
                dense
                hide-details
                outlined
                v-model="data_id"
              ></v-select>
            </div> -->
          </div>

          <div
            class="row col-12 pad10"
            style="margin: 0px"
            v-if="
              type === 'imagebanner' ||
              type === 'product' ||
              type === 'story' ||
              type === 'imagecarousel' ||
              type === 'categorygridcarousel'
            ">
            <v-col md="12" sm="12" cols="12" v-if="type === 'story'">
              <span style="margin-bottom: 10px">Live Story Styles</span>
              <v-item-group mandatory v-model="styles.style_type" class="row">
                <div
                  v-for="(item, i) in storystyle"
                  :key="`${i}_app_template_layout_builder`"
                  class="col-md-6">
                  <img
                    :src="item.image"
                    :class="
                      styles.style_type === item.value
                        ? 'app_template active'
                        : 'app_template'
                    "
                    @click="styles.style_type = item.value"
                    style="width: 100%" />
                </div>
              </v-item-group>
            </v-col>

            <div
              :class="type === 'imagebanner' ? 'col-sm-4' : 'col-sm-4'"
              v-if="type === 'imagebanner'">
              <v-select
                label="Columns"
                :items="columns"
                dense
                hide-details
                outlined
                v-model="styles.column"></v-select>
            </div>

            <div
              class="col-sm-4"
              v-if="type === 'imagebanner'"
              style="display: none">
              <v-select
                label="Rows"
                :items="rows"
                dense
                hide-details
                outlined
                v-model="styles.row"></v-select>
            </div>

            <div class="col-sm-4" v-if="type === 'product'">
              <v-text-field
                label="No of Products"
                type="number"
                outlined
                color="primary"
                dense
                hide-details
                v-model="data_query.per_page"
                persistent-hint
                rounded
                height="1">
              </v-text-field>
            </div>

            <div
              class="col-sm-4"
              v-if="
                type === 'imagebanner' ||
                type === 'imagecarousel' ||
                type === 'categorygridcarousel'
              ">
              <v-select
                label="Image Fit"
                :items="boxfits"
                dense
                hide-details
                outlined
                v-model="styles.boxfit"></v-select>
            </div>
            <div
              :class="
                type === 'imagebanner' ||
                type === 'story' ||
                type === 'imagecarousel' ||
                type === 'categorygridcarousel'
                  ? 'col-sm-4'
                  : 'col-sm-6'
              "
              v-if="
                type === 'imagebanner' ||
                type === 'imagecarousel' ||
                type === 'categorygridcarousel'
              ">
              <div class="componentslider">
                <v-lable>
                  <span class="sliderlable"
                    >Border Radius : {{ styles.borderradius }}</span
                  >
                </v-lable>
                <v-slider
                  v-model="styles.borderradius"
                  step="0.1"
                  min="0"
                  max="100"
                  inverse-label
                  color="#000000"
                  track-color="rgb(0 0 0 / 12%)"></v-slider>
              </div>
            </div>
          </div>

          <div
            class="row col-12 pad10"
            style="margin: 0px"
            v-if="
              type === 'imagebanner' ||
              type === 'story' ||
              type === 'imagecarousel' ||
              type === 'categorygridcarousel'
            ">
            <div class="col-sm-4" style="display: none">
              <v-select
                label="Image Size"
                :items="sizetypes"
                dense
                hide-details
                outlined
                v-model="styles.size_type"></v-select>
            </div>
            <div
              class="col-sm-4"
              v-if="
                type === 'story' ||
                type === 'imagecarousel' ||
                type === 'categorygridcarousel'
              ">
              <v-text-field
                label="Image Width"
                type="number"
                outlined
                color="primary"
                dense
                hide-details
                v-model="styles.width"
                persistent-hint
                rounded
                height="1">
              </v-text-field>
            </div>
            <div
              class="col-sm-4"
              v-if="
                type === 'story' ||
                type === 'imagecarousel' ||
                type === 'categorygridcarousel'
              ">
              <v-text-field
                label="Image Height"
                type="number"
                outlined
                color="primary"
                dense
                hide-details
                v-model="styles.height"
                persistent-hint
                rounded
                height="1">
              </v-text-field>
            </div>

            <div class="col-sm-4" v-if="type === 'imagebanner'">
              <div class="componentslider">
                <v-lable>
                  <span class="sliderlable"
                    >Aspect Ratio : {{ styles.aspect_ratio }}</span
                  >
                </v-lable>
                <v-slider
                  v-model="styles.aspect_ratio"
                  step="0.1"
                  min="0.5"
                  max="4"
                  inverse-label
                  color="#000000"
                  track-color="rgb(0 0 0 / 12%)"></v-slider>
              </div>
            </div>
          </div>

          <div
            class="row col-12 pad10"
            style="margin: 0px; display: none"
            v-if="type === 'product'">
            <div class="col-sm-6">
              <v-checkbox
                label="Autoplay"
                dense
                hide-details
                outlined
                v-model="styles.autoPlay"></v-checkbox>
            </div>

            <div class="col-sm-6">
              <v-checkbox
                label="Loop"
                dense
                hide-details
                outlined
                v-model="styles.loop"></v-checkbox>
            </div>

            <div class="col-sm-6">
              <v-text-field
                label="Duration"
                type="number"
                outlined
                color="primary"
                dense
                hide-details
                v-model="styles.autoPlayAnimationDuration"
                persistent-hint
                rounded
                height="1">
              </v-text-field>
            </div>
          </div>

          <div
            class="row col-12 pad10"
            style="margin: 0px"
            v-if="type === 'productgrid' || type === 'productlist'">
            <div class="col-sm-6">
              <v-select
                label="Columns"
                :items="columns"
                dense
                hide-details
                outlined
                v-model="styles.column"></v-select>
            </div>

            <div class="col-sm-6">
              <v-text-field
                :label="
                  type === 'productgrid'
                    ? 'No of Products'
                    : 'No of Items that load one time'
                "
                type="number"
                outlined
                color="primary"
                dense
                hide-details
                v-model="data_query.per_page"
                persistent-hint
                rounded
                height="1">
              </v-text-field>
            </div>
          </div>

          <div
            class="row col-12"
            style="margin: 0px"
            v-if="type === 'countdown'">
            <v-col md="12" sm="12" cols="12">
              <span style="margin-bottom: 10px">Countdown Timer Styles</span>
              <v-item-group mandatory v-model="styles.style_type" class="row">
                <div
                  v-for="(item, i) in countdownstyle"
                  :key="`${i}_app_template_layout_builder`"
                  class="col-md-6">
                  <img
                    :src="item.image"
                    :class="
                      styles.style_type === item.value
                        ? 'app_template active'
                        : 'app_template'
                    "
                    @click="styles.style_type = item.value"
                    style="width: 100%" />
                </div>
              </v-item-group>
            </v-col>

            <div class="col-sm-12">
              <v-text-field
                label="Countdown Title"
                type="text"
                outlined
                color="primary"
                dense
                hide-details
                v-model="styles.countdown_title"
                persistent-hint
                rounded
                height="1">
              </v-text-field>
            </div>
            <div class="col-sm-12">
              <v-text-field
                label="Countdown Subtitle"
                type="text"
                outlined
                color="primary"
                dense
                hide-details
                v-model="styles.countdown_subtitle"
                persistent-hint
                rounded
                height="1">
              </v-text-field>
            </div>

            <div class="col-sm-12">
              <div>
                <!-- <lable>fef</lable> -->
                <input
                  class="countdowntime"
                  type="datetime-local"
                  v-model="styles.expire_time" />
              </div>
            </div>
          </div>

          <div
            class="row col-12"
            style="margin: 0px"
            v-if="type === 'searchbox'">
            <v-col md="12" sm="12" cols="12">
              <span style="margin-bottom: 5px">Search Bar Styles</span>
              <v-item-group mandatory v-model="styles.style_type" class="row">
                <div
                  v-for="(item, i) in searchstyles"
                  :key="`${i}_app_template_layout_builder`"
                  class="col-md-6">
                  <img
                    :src="item.image"
                    :class="
                      styles.style_type === item.value
                        ? 'app_template active'
                        : 'app_template'
                    "
                    @click="styles.style_type = item.value"
                    style="width: 100%" />
                </div>
              </v-item-group>
            </v-col>

            <div class="col-sm-6">
              <v-text-field
                label="Search Text"
                type="text"
                outlined
                color="primary"
                dense
                hide-details
                v-model="styles.text"
                persistent-hint
                rounded
                height="1">
              </v-text-field>
            </div>

            <div class="col-sm-6">
              <v-select
                label="Font Size"
                :items="fontsizesdata"
                dense
                hide-details
                outlined
                v-model="styles.fontsize"></v-select>
            </div>
          </div>

          <div
            class="row col-12 pad10"
            style="margin: 0px"
            v-if="type === 'corosel'">
            <v-col md="12" sm="12" cols="12">
              <span style="margin-bottom: 5px">Carousel Styles</span>
              <v-item-group mandatory v-model="styles.style_type" class="row">
                <div
                  v-for="(item, i) in categorystyle"
                  :key="`${i}_app_template_layout_builder`"
                  class="col-md-6">
                  <img
                    :src="item.image"
                    :class="
                      styles.style_type === item.value
                        ? 'app_template active'
                        : 'app_template'
                    "
                    @click="styles.style_type = item.value"
                    style="width: 100%" />
                </div>
              </v-item-group>
            </v-col>

            <div class="col-sm-4">
              <v-checkbox
                label="Autoplay"
                dense
                hide-details
                outlined
                v-model="styles.autoPlay"></v-checkbox>
            </div>
            <div class="col-sm-4">
              <v-checkbox
                label="Loop"
                dense
                hide-details
                outlined
                v-model="styles.loop"></v-checkbox>
            </div>
            <div class="col-sm-4">
              <v-checkbox
                label="Show Category Title"
                dense
                hide-details
                outlined
                v-model="styles.show_category_title"></v-checkbox>
            </div>

            <div class="col-sm-4">
              <v-text-field
                label="Image Width"
                type="number"
                outlined
                color="primary"
                dense
                hide-details
                v-model="styles.width"
                persistent-hint
                rounded
                height="1">
              </v-text-field>
            </div>
            <div class="col-sm-4">
              <v-text-field
                label="Image Height"
                type="number"
                outlined
                color="primary"
                dense
                hide-details
                v-model="styles.height"
                persistent-hint
                rounded
                height="1">
              </v-text-field>
            </div>

            <!-- <div class="col-sm-6"   >
                <v-select
                    label="Alignment"
                    :items="alignmenetdata"
                    dense
                    hide-details
                    outlined
                    v-model="styles.align"
                ></v-select>
            </div> -->

            <div class="col-sm-6" style="display: none">
              <v-text-field
                label="No of Categories"
                type="number"
                outlined
                color="primary"
                dense
                hide-details
                v-model="styles.catno"
                persistent-hint
                rounded
                height="1">
              </v-text-field>
            </div>

            <div class="col-sm-4">
              <v-select
                label="Title Font Size"
                :items="fontsizesdata"
                dense
                hide-details
                outlined
                v-model="styles.fontsize"></v-select>
            </div>

            <div class="col-sm-4">
              <v-select
                label="Text Align"
                :items="alignmenetdata"
                dense
                hide-details
                outlined
                v-model="styles.align"></v-select>
            </div>

            <div class="col-sm-4">
              <v-text-field
                label="Title Top Padding"
                type="number"
                outlined
                color="primary"
                dense
                hide-details
                v-model="styles.title_top_padding"
                persistent-hint
                rounded
                height="1">
              </v-text-field>
            </div>
            <div class="col-sm-4"></div>
            <div class="col-sm-4">
              <div class="componentslider">
                <v-lable>
                  <span class="sliderlable"
                    >Border Radius : {{ styles.borderradius }}</span
                  >
                </v-lable>
                <v-slider
                  v-model="styles.borderradius"
                  step="0.1"
                  min="0"
                  max="100"
                  inverse-label
                  color="#000000"
                  track-color="rgb(0 0 0 / 12%)"></v-slider>
              </div>
            </div>
          </div>

          <div
            class="row col-12 pad10"
            style="margin: 0px"
            v-if="type === 'youtubevideo'">
            <div class="col-sm-6">
              <v-select
                label="Select Video Type"
                :items="videotypes"
                dense
                hide-details
                outlined
                v-model="styles.videotypes"></v-select>
            </div>
            <div
              class="col-6"
              v-if="
                styles.videotypes === 'youtube' || styles.videotypes === 'vimeo'
              ">
              <v-text-field
                label="Enter Video ID"
                outlined
                color="primary"
                dense
                hide-details
                v-model="styles.videoId"
                persistent-hint
                rounded
                height="1">
              </v-text-field>
            </div>
            <div class="col-6" v-if="styles.videotypes === 'hosted'">
              <v-text-field
                label="Enter Video URL"
                outlined
                color="primary"
                dense
                hide-details
                v-model="styles.videoURL"
                persistent-hint
                rounded
                height="1">
              </v-text-field>
            </div>
          </div>

          <div
            class="col-sm-12"
            v-if="
              type === 'imagebanner' ||
              type === 'imagecarousel' ||
              type === 'categorygridcarousel'
            ">
            <SlideshowComponenet
              :slideshowid="data_id"
              :sliderdata="data"
              :categorieslist="categorieslist"
              :platform="platform" />
          </div>

          <div class="col-12" v-if="type == 'title' || type == 'countdown'">
            <RouteGenerator
              style="margin-top: 10px"
              :slide="componentitem"
              :dataquery="componentitem.data_query"
              :dataaction="componentitem.data_action"
              :data_action_type="componentitem.data_action_type"
              :categorieslist="categorieslist"
              :platform="platform" />
          </div>

          <div class="col-sm-12" v-if="type === 'story'">
            <StoryshowComponent
              :storyshowid="data_id"
              :storydata="data"
              :categorieslist="categorieslist"
              :platform="platform" />
          </div>

          <div class="col-12">
            <!-- spacing -->
            <v-expansion-panels>
              <v-expansion-panel v-if="false">
                <v-expansion-panel-header>
                  <span style="font-size: 14px; font-weight: bold"
                    >Border Radius</span
                  >
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <div class="row">
                    <div class="col-3">
                      <v-text-field
                        label="Left"
                        outlined
                        color="primary"
                        dense
                        hide-details
                        v-model="styles.borderLeft"></v-text-field>
                    </div>
                    <div class="col-3">
                      <v-text-field
                        label="Right"
                        outlined
                        color="primary"
                        dense
                        hide-details
                        v-model="styles.borderRight"></v-text-field>
                    </div>
                    <div class="col-3">
                      <v-text-field
                        label="Top"
                        outlined
                        type="number"
                        color="primary"
                        dense
                        hide-details
                        v-model="styles.borderTop"></v-text-field>
                    </div>
                    <div class="col-3">
                      <v-text-field
                        label="Bottom"
                        outlined
                        type="number"
                        color="primary"
                        dense
                        hide-details
                        v-model="styles.borderBottom"></v-text-field>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel
                v-if="type === 'product' || type === 'productgrid'">
                <v-expansion-panel-header>
                  <span style="font-size: 14px; font-weight: bold"
                    >Product Query Builder</span
                  >
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <div class="row">
                    <div class="col-4">
                      <v-switch
                        v-model="data_query.on_sale"
                        :label="
                          data_query.on_sale ? 'On Sale Active' : 'On Sale'
                        "
                        color="success"
                        inset></v-switch>
                    </div>

                    <div class="col-4">
                      <v-switch
                        v-model="data_query.featured"
                        :label="
                          data_query.featured ? 'Featured Active' : 'Featured'
                        "
                        color="success"
                        inset></v-switch>
                    </div>

                    <div class="col-4">
                      <v-switch
                        v-model="data_query.stock_status"
                        :label="
                          data_query.stock_status == 'instock'
                            ? 'In Stock Only'
                            : 'In Stock'
                        "
                        true-value="instock"
                        false-value="outofstock"
                        color="success"
                        inset></v-switch>
                    </div>

                    <div class="col-sm-4">
                      <v-select
                        label="Order By"
                        :items="productorderby"
                        dense
                        hide-details
                        outlined
                        v-model="data_query.orderby"></v-select>
                    </div>

                    <div class="col-sm-4">
                      <v-select
                        label="Order Sort"
                        :items="productorderbytype"
                        dense
                        hide-details
                        outlined
                        v-model="data_query.order"></v-select>
                    </div>

                    <div class="col-sm-4">
                      <v-select
                        label="Category"
                        :items="categorieslist"
                        dense
                        hide-details
                        outlined
                        v-model="data_query.category"></v-select>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span style="font-size: 14px; font-weight: bold"
                    >Spacing Options</span
                  >
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <div class="row">
                    <div class="col-3">
                      <v-text-field
                        label="Left"
                        outlined
                        type="number"
                        color="primary"
                        dense
                        hide-details
                        v-model="styles.paddingLeft"></v-text-field>
                    </div>
                    <div class="col-3">
                      <v-text-field
                        label="Right"
                        outlined
                        type="number"
                        color="primary"
                        dense
                        hide-details
                        v-model="styles.paddingRight"></v-text-field>
                    </div>
                    <div class="col-3">
                      <v-text-field
                        label="Top"
                        outlined
                        type="number"
                        color="primary"
                        dense
                        hide-details
                        v-model="styles.paddingTop"></v-text-field>
                    </div>
                    <div class="col-3">
                      <v-text-field
                        label="Bottom"
                        outlined
                        type="number"
                        color="primary"
                        dense
                        hide-details
                        v-model="styles.paddingBottom"></v-text-field>
                    </div>

                    <div class="col-3">
                      <v-text-field
                        label="Margin Left"
                        outlined
                        type="number"
                        color="primary"
                        dense
                        hide-details
                        v-model="styles.marginLeft"></v-text-field>
                    </div>
                    <div class="col-3">
                      <v-text-field
                        label="Margin Right"
                        outlined
                        type="number"
                        color="primary"
                        dense
                        hide-details
                        v-model="styles.marginRight"></v-text-field>
                    </div>
                    <div class="col-3">
                      <v-text-field
                        label="Margin Top"
                        outlined
                        type="number"
                        color="primary"
                        dense
                        hide-details
                        v-model="styles.marginTop"></v-text-field>
                    </div>
                    <div class="col-3">
                      <v-text-field
                        label="Margin Bottom"
                        outlined
                        type="number"
                        color="primary"
                        dense
                        hide-details
                        v-model="styles.marginBottom"></v-text-field>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span style="font-size: 14px; font-weight: bold"
                    >Color Options</span
                  >
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <div class="row">
                    <v-col md="6" sm="12" cols="12">
                      <v-text-field
                        label="Background Color"
                        outlined
                        readonly
                        color="primary"
                        dense
                        hide-details
                        v-model="styles.bgcolor">
                        <template v-slot:append>
                          <v-btn
                            icon
                            color="smoke"
                            @click="openBackgroundColorPicker()">
                            <v-avatar
                              :size="18"
                              :color="
                                styles.bgcolor == ''
                                  ? '#f0f0f0'
                                  : styles.bgcolor
                              " />
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col md="6" sm="12" cols="12">
                      <v-text-field
                        label="Text Color"
                        outlined
                        readonly
                        color="primary"
                        dense
                        hide-details
                        v-model="styles.color">
                        <template v-slot:append>
                          <v-btn
                            icon
                            color="smoke"
                            @click="openForegroundColorPicker()">
                            <v-avatar
                              :size="18"
                              :color="
                                styles.color == '' ? '#f0f0f0' : styles.color
                              " />
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col md="6" sm="12" cols="12" v-if="type === 'title'">
                      <v-text-field
                        label="Subtitle Color"
                        outlined
                        readonly
                        color="primary"
                        dense
                        hide-details
                        v-model="styles.subtitle_color">
                        <template v-slot:append>
                          <v-btn
                            icon
                            color="smoke"
                            @click="openSubtitleColorPicker()">
                            <v-avatar
                              :size="18"
                              :color="
                                styles.subtitle_color == ''
                                  ? '#f0f0f0'
                                  : styles.subtitle_color
                              " />
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col md="6" sm="12" cols="12" v-if="type === 'title'">
                      <v-text-field
                        label="Button Color"
                        outlined
                        readonly
                        color="primary"
                        dense
                        hide-details
                        v-model="styles.btn_color">
                        <template v-slot:append>
                          <v-btn
                            icon
                            color="smoke"
                            @click="openButtonColorPicker()">
                            <v-avatar
                              :size="18"
                              :color="
                                styles.btn_color == ''
                                  ? '#f0f0f0'
                                  : styles.btn_color
                              " />
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col md="6" sm="12" cols="12" v-if="type === 'title'">
                      <v-text-field
                        label="Button Text Color"
                        outlined
                        readonly
                        color="primary"
                        dense
                        hide-details
                        v-model="styles.btn_textcolor">
                        <template v-slot:append>
                          <v-btn
                            icon
                            color="smoke"
                            @click="openButtonTextColorPicker()">
                            <v-avatar
                              :size="18"
                              :color="
                                styles.btn_textcolor == ''
                                  ? '#f0f0f0'
                                  : styles.btn_textcolor
                              " />
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-col>
                  </div>

                  <div class="row" v-if="type === 'countdown'">
                    <div class="col-sm-4">
                      <v-text-field
                        label="Countdown Title Color"
                        outlined
                        readonly
                        color="primary"
                        dense
                        hide-details
                        v-model="styles.countdown_title_color">
                        <template v-slot:append>
                          <v-btn
                            icon
                            color="smoke"
                            @click="openCountdownTitleColorPicker()">
                            <v-avatar
                              :size="18"
                              :color="
                                styles.countdown_title_color == ''
                                  ? '#f0f0f0'
                                  : styles.countdown_title_color
                              " />
                          </v-btn>
                        </template>
                      </v-text-field>
                    </div>

                    <div class="col-sm-4">
                      <v-text-field
                        label="Countdown Timer Color"
                        outlined
                        readonly
                        color="primary"
                        dense
                        hide-details
                        v-model="styles.countdown_timer_color">
                        <template v-slot:append>
                          <v-btn
                            icon
                            color="smoke"
                            @click="openCountdownTimerColorPicker()">
                            <v-avatar
                              :size="18"
                              :color="
                                styles.countdown_timer_color == ''
                                  ? '#f0f0f0'
                                  : styles.countdown_timer_color
                              " />
                          </v-btn>
                        </template>
                      </v-text-field>
                    </div>

                    <div class="col-sm-4">
                      <v-text-field
                        label="Countdown Seconds Color"
                        outlined
                        readonly
                        color="primary"
                        dense
                        hide-details
                        v-model="styles.countdown_sec_color">
                        <template v-slot:append>
                          <v-btn
                            icon
                            color="smoke"
                            @click="openCountdownSecondColorPicker()">
                            <v-avatar
                              :size="18"
                              :color="
                                styles.countdown_sec_color == ''
                                  ? '#f0f0f0'
                                  : styles.countdown_sec_color
                              " />
                          </v-btn>
                        </template>
                      </v-text-field>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>

          <v-dialog scrollable max-width="500" v-model="picker.status">
            <SelectColor
              :color="picker.color"
              mode="hex"
              @close="picker.status = false"
              @save="saveColor" />
          </v-dialog>
        </div>
      </div>
    </v-dialog>

    <!-- </div> -->

    <!-- <v-dialog
        v-model="seen"
        hide-overlay
        transition="dialog-left-transition"
        width="200px"
        style="background-color: #fff;"
    >
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
          </v-toolbar>
        </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import componeneticon from "@/assets/images/builder/slidericon.png";
import DeleteMiniIcon from "@/components/icons/DeleteMiniIcon";
import EditMiniIcon from "@/components/icons/EditMiniIcon";
import SelectColor from "@/views/account/apps/builder/modals/SelectColor";
import SlideshowComponenet from "./SlideshowComponenet.vue";
import RouteGenerator from "./RouteGenerator.vue";
import StoryshowComponent from "./StoryshowComponent.vue";

import Textstyle01 from "@/assets/images/textstyles/style1.png";
import Textstyle02 from "@/assets/images/textstyles/style2.png";
import Textstyle03 from "@/assets/images/textstyles/style3.png";
import Textstyle04 from "@/assets/images/textstyles/style4.png";
import Textstyle05 from "@/assets/images/textstyles/style5.png";
import Textstyle06 from "@/assets/images/textstyles/style6.png";

import Sliderstyle1 from "@/assets/images/builder/slider_defalt.png";
import Sliderstyle2 from "@/assets/images/builder/slider_stack.png";
import Sliderstyle3 from "@/assets/images/builder/slider_tinder.png";
import Sliderstyle4 from "@/assets/images/builder/slider_custom.png";

import Search1 from "@/assets/images/builder/search_1_defalut.png";
import Search2 from "@/assets/images/builder/search_2.png";

import Categorystyle1 from "@/assets/images/builder/category_corousel_round.png";
import Categorystyle2 from "@/assets/images/builder/category_corousel_rectangletw.png";

import Countdown1 from "@/assets/images/builder/countdown_big.png";
import Countdown2 from "@/assets/images/builder/countdown_smallnew.png";

import Roundedstory from "@/assets/images/builder/story_roundsetting.png";
import Rectanglestory from "@/assets/images/builder/story_rectangle.png";

import buttonstyle01 from "@/assets/images/buttonstyles/bstyle1.png";
import buttonstyle02 from "@/assets/images/buttonstyles/bstyle2.png";
import buttonstyle03 from "@/assets/images/buttonstyles/bstyle3.png";

export default {
  name: "ActiveComponenetCard",
  props: {
    element: {
      type: Object,
      required: true,
    },
    status: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    vicon: {
      type: String,
      required: true,
    },
    styles: {
      type: Object,
      required: true,
    },
    data_id: {
      type: Number,
      required: true,
    },
    dataloadtype: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    query: {
      type: String,
      required: true,
    },
    activelist: {
      type: Object,
      required: true,
    },
    idx: {
      type: Number,
      required: true,
    },
    rowid: {
      type: Number,
      required: true,
    },
    componentitem: {
      type: Object,
      required: true,
    },
    data_query: {
      type: Object,
      required: true,
    },
    categorieslist: {
      type: Array,
      required: true,
    },
    platform: {
      type: String,
      required: true,
    },
  },
  components: {
    DeleteMiniIcon,
    SelectColor,
    EditMiniIcon,
    SlideshowComponenet,
    RouteGenerator,
    StoryshowComponent,
  },
  data: () => ({
    componeneticon: componeneticon,
    activeeditelement: false,
    seen: false,
    spacingview: false,
    customcolorsview: false,
    picker: {
      status: false,
      mode: "background",
      color: "#ffffff",
    },
    textstyletemplates: [
      {
        image: Textstyle01,
        name: "Subtitle Top",
        value: "style1",
      },
      {
        image: Textstyle02,
        name: "Title Top",
        value: "style2",
      },
      {
        image: Textstyle04,
        name: "Subtitle Top with Bottom Button",
        value: "style3",
      },
      {
        image: Textstyle03,
        name: "Title Top with Bottom Button",
        value: "style4",
      },
      {
        image: Textstyle05,
        name: "Subtitle Top With Button Right",
        value: "style5",
      },
      {
        image: Textstyle06,
        name: "Title Top With Button Right",
        value: "style6",
      },
    ],
    slidertypes: [
      {
        image: Sliderstyle1,
        name: "Default",
        value: "DEFAULT",
      },
      {
        image: Sliderstyle2,
        name: "Stack",
        value: "STACK",
      },
      {
        image: Sliderstyle3,
        name: "Tinder",
        value: "TINDER",
      },
      {
        image: Sliderstyle4,
        name: "Custom",
        value: "CUSTOM",
      },
    ],

    countdownstyle: [
      {
        image: Countdown2,
        name: "Small Countdown",
        value: "style2",
      },
      {
        image: Countdown1,
        name: "Large Countdown",
        value: "style1",
      },
    ],
    storystyle: [
      {
        image: Roundedstory,
        name: "Small Countdown",
        value: "style1",
      },
      {
        image: Rectanglestory,
        name: "Large Countdown",
        value: "style2",
      },
    ],
    searchstyles: [
      {
        image: Search1,
        name: "style 1",
        value: "style1",
      },
      {
        image: Search2,
        name: "style 2",
        value: "style2",
      },
    ],
    categorystyle: [
      {
        image: Categorystyle1,
        name: "style 1",
        value: "style_1",
      },
      {
        image: Categorystyle2,
        name: "style 2",
        value: "style_2",
      },
    ],
    buttontemplates: [
      {
        image: buttonstyle01,
        name: "Subtitle Top",
        value: "style1",
      },
      {
        image: buttonstyle02,
        name: "Title Top",
        value: "style2",
      },
      {
        image: buttonstyle03,
        name: "Subtitle Top with Bottom Button",
        value: "style3",
      },
    ],
  }),
  methods: {
    saveColor(color) {
      this.rowid = this.idx;
      if (this.picker.mode === "background") {
        this.styles.bgcolor = color;
      } else if (this.picker.mode === "foreground") {
        this.styles.color = color;
      } else if (this.picker.mode === "subtitle") {
        this.styles.subtitle_color = color;
      } else if (this.picker.mode === "btn") {
        this.styles.btn_color = color;
      } else if (this.picker.mode === "btntext") {
        this.styles.btn_textcolor = color;
      } else if (this.picker.mode === "countdowntitlecolor") {
        this.styles.countdown_title_color = color;
      } else if (this.picker.mode === "countdowntimercolor") {
        this.styles.countdown_timer_color = color;
      } else if (this.picker.mode === "countdownsecondcolor") {
        this.styles.countdown_sec_color = color;
      }
      this.picker.status = false;
    },
    openBackgroundColorPicker() {
      this.picker = {
        status: true,
        mode: "background",
        color: this.styles.bgcolor == "" ? "#ffffff" : this.styles.bgcolor,
      };
    },
    openForegroundColorPicker() {
      this.picker = {
        status: true,
        mode: "foreground",
        color: this.styles.color == "" ? "#000000" : this.styles.color,
      };
    },
    openSubtitleColorPicker() {
      this.picker = {
        status: true,
        mode: "subtitle",
        color:
          this.styles.subtitle_color == ""
            ? "#000000"
            : this.styles.subtitle_color,
      };
    },
    openButtonColorPicker() {
      this.picker = {
        status: true,
        mode: "btn",
        color: this.styles.btn_color == "" ? "#000000" : this.styles.btn_color,
      };
    },
    openButtonTextColorPicker() {
      this.picker = {
        status: true,
        mode: "btntext",
        color:
          this.styles.btn_textcolor == ""
            ? "#000000"
            : this.styles.btn_textcolor,
      };
    },
    openCountdownTitleColorPicker() {
      this.picker = {
        status: true,
        mode: "countdowntitlecolor",
        color:
          this.styles.countdown_title_color == ""
            ? "#000000"
            : this.styles.countdown_title_color,
      };
    },
    openCountdownTimerColorPicker() {
      this.picker = {
        status: true,
        mode: "countdowntimercolor",
        color:
          this.styles.countdown_timer_color == ""
            ? "#000000"
            : this.styles.countdown_timer_color,
      };
    },
    openCountdownSecondColorPicker() {
      this.picker = {
        status: true,
        mode: "countdownsecondcolor",
        color:
          this.styles.countdown_sec_color == ""
            ? "#000000"
            : this.styles.countdown_sec_color,
      };
    },
    deleteItem(idx) {
      console.log(idx);
      this.sliderdata.splice(idx, 1);
    },

    onFileUploadChanged(element, event) {
      element.loading = true;
      const selectedFile = event.target.files[0];

      if (!selectedFile) {
        element.loading = false;
        return;
      }

      let params = new FormData();
      params.set("screen", selectedFile);

      this.$http
        .post(
          `${this.$serverApiLink}api/account/assets/upload_gallery/${this.$route.params.uid}`,
          params,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((response) => {
          element.backgroundimage = response.data.uri;
          element.loading = false;
          console.log(element.backgroundimage);

          this.$emit("reload_preview", {
            uid: this.$route.params.uid,
            mode: "splash",
          });
        })
        .catch((error) => {
          element.loading = false;
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
        });
    },
  },

  mounted() {
    this.rowid = this.idx;
  },
  computed: {
    fontsizesdata: function () {
      return [
        { text: "XX-Large (24px)", value: "24" },
        { text: "X-Large (20px)", value: "20" },
        { text: "Large (18px)", value: "18" },
        { text: "Medium (16px)", value: "16" },
        { text: "Regular (14px)", value: "14" },
        { text: "Small (12px)", value: "12" },
        { text: "Tiny (10px)", value: "10" },
      ];
    },
    alignmenetdata: function () {
      return [
        { text: "Left", value: "left" },
        { text: "Right", value: "right" },
        { text: "Center", value: "center" },
      ];
    },
    videotypes: function () {
      return [
        { text: "Youtube", value: "youtube" },
        { text: "Vimeo", value: "vimeo" },
        { text: "Self Hosted", value: "hosted" },
      ];
    },
    fontweights: function () {
      return [
        { text: "Bold", value: "bold" },
        { text: "Normal", value: "normal" },
        { text: "Semi Bold", value: "semibold" },
        { text: "Light", value: "light" },
      ];
    },
    scrolldirectiondata: function () {
      return [
        { text: "Horizontal", value: "horizontal" },
        { text: "Verticle", value: "verticle" },
      ];
    },
    durationtime: function () {
      return [
        { text: "1s", value: "1000" },
        { text: "2s", value: "2000" },
        { text: "3s", value: "3000" },
        { text: "4s", value: "4000" },
        { text: "5s", value: "5000" },
      ];
    },
    imgslider: function () {
      return [
        { text: "DEFAULT", value: "DEFAULT" },
        { text: "STACK", value: "STACK" },
        { text: "TINDER", value: "TINDER" },
        { text: "CUSTOM", value: "CUSTOM" },
      ];
    },
    sliderstyle: function () {
      ``;
      return [
        { text: "Style 1", value: "style1" },
        { text: "Style 2", value: "style2" },
        { text: "Style 3", value: "style3" },
      ];
    },
    categorysliderstyle: function () {
      ``;
      return [
        { text: "Style 1", value: "style_1" },
        { text: "Style 2", value: "style_2" },
        { text: "Style 3", value: "style_3" },
      ];
    },
    slideshows: function () {
      ``;
      return [
        { text: "Slideshow 1", value: 1 },
        { text: "Slideshow 2", value: 2 },
        { text: "Slideshow 3", value: 3 },
      ];
    },
    rows: function () {
      return [
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" },
        { text: "6", value: "6" },
      ];
    },
    columns: function () {
      return [
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
      ];
    },
    boxfits: function () {
      return [
        { text: "Cover", value: "cover" },
        { text: "Contain", value: "contain" },
        { text: "Fill", value: "fill" },
        { text: "Fit Height", value: "fitHeight" },
        { text: "Fit Width", value: "fitWidth" },
      ];
    },
    sizetypes: function () {
      return [{ text: "Auto", value: "auto" }];
    },
    productorderby: function () {
      return [
        { text: "None", value: "" },
        { text: "Popularity", value: "popularity" },
        { text: "Price", value: "price" },
        { text: "Date", value: "date" },
      ];
    },
    productorderbytype: function () {
      return [
        { text: "Decending Order", value: "desc" },
        { text: "Ascending Order", value: "asc" },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field input {
  font-size: 0.5sem;
  padding: 0px;
}

.pad10 {
  padding: 0px 10px 0px 10px;
}
</style>
