<template>
  <div
    class="component-item card"
    style="width: 100%; border: 1px solid black"
    v-if="slideshowid != ''">
    <div class="card-body d-flex justify-content-center">
      <div class="row">
        <div class="col-6" style="float: right">Images</div>

        <div class="col-6">
          <v-btn
            style="float: right"
            small
            depressed
            color="dark"
            outlined
            class="mb-5"
            @click="add"
            >Add Images</v-btn
          >
        </div>

        <div
          class="col-md-6 gallerymediathumb"
          v-for="(element, idx) in sliderdata"
          :key="element.id">
          <div class="row">
            <div class="col-4">
              <img
                v-bind:src="element.image"
                style="
                  border-radius: 3px;
                  width: 100px;
                  height: 100px;
                  object-fit: cover;
                  background-color: bisque;
                " />
              <v-btn
                icon
                small
                color="danger"
                @click="sliderdata.splice(idx, 1)"
                style="
                  position: relative;
                  top: -35px;
                  left: 5px;
                  background-color: white;
                ">
                <DeleteMiniIcon :size="16" />
              </v-btn>
            </div>

            <div class="col-8">
              <div v-if="!element.loading">
                <input
                  ref="uploaderA"
                  type="file"
                  accept="image/*"
                  @change="onFileUploadChanged(element, $event)"
                  style="width: 100%; overflow: hidden; font-size: 14px" />
              </div>

              <div style="width: 32px; height: 32px" class="col-6" v-else>
                <v-progress-circular
                  indeterminate
                  color="blue"
                  size="32"
                  width="3"></v-progress-circular>
              </div>

              <hr style="margin-top: 10px" />

              <RouteGenerator
                style="margin-top: 10px"
                :slide="element"
                :dataquery="element.data_query"
                :dataaction="element.data_action"
                :data_action_type="element.data_action_type"
                :categorieslist="categorieslist"
                :platform="platform" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
// import CheckCircleIcon from "@/components/icons/CheckCircleIcon";
// import ClockIcon from "@/components/icons/ClockIcon";
import componeneticon from "@/assets/images/builder/slidericon.png";
import DeleteMiniIcon from "../icons/DeleteMiniIcon.vue";
import RouteGenerator from "./RouteGenerator.vue";

export default {
  name: "SlideshowComponenet",
  props: {
    data_id: {
      type: String,
      required: true,
    },
    sliderdata: {
      type: Object,
      required: true,
    },
    categorieslist: {
      type: Array,
      required: true,
    },
    platform: {
      type: String,
      required: true,
    },
  },
  components: {
    DeleteMiniIcon,
    RouteGenerator,
  },
  data: () => ({
    componeneticon: componeneticon,
    id: 3,
    edit: {
      status: true,
      loading: false,
      index: 1,
    },
    background_image: {
      isSelecting: false,
      selectedFile: null,
      upload: false,
      loading: false,
    },
  }),
  methods: {
    add: function () {
      this.id++;
      this.sliderdata.push({
        name: "Image " + this.slideshowid,
        image: "https://app.shopimint.com/backend/static/image-placeholder.jpg",
        text: "",
        random: Math.random().toString(36).slice(2),
        loading: false,
        data_query: {
          title: "Shop Page",
        },
        data_action: "",
        data_action_type: "",
      });
    },
    onFileUploadChanged(item, e) {
      item.loading = true;
      this.background_image.selectedFile = e.target.files[0];
      // this.$store.commit('setLoading', true);
      let params = new FormData();
      params.set("screen", this.background_image.selectedFile);
      this.$http
        .post(
          `${this.$serverApiLink}api/account/assets/upload_gallery/${this.$route.params.uid}`,
          params,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((response) => {
          console.log(item);
          item.image = response.data.uri;
          item.loading = false;
          this.$emit("reload_preview", {
            uid: this.$route.params.uid,
            mode: "splash",
          });
        })
        .catch((error) => {
          item.loading = false;
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.background_image.upload = false;
          // this.$store.commit('setLoading', false);
        });
    },
  },
};
</script>
