<template>
  <v-card flat tile>
    <div class="permissions_block" v-for="element in list" :key="element.id">
      <div class="d-flex justify-space-between align-center pa-4">
        <div class="d-flex justify-start align-center">
          <v-avatar color="whitesmoke" :size="60" class="mr-4">
            <div class="Apple--text" style="width: 32px; height: 32px">
              <img :src="require(`@/assets/images/socialmedia/${element.icon2}`)" :alt="element.socialmedia" style="width:32px; height:32px; color:Red;" class="mr-3"/>
            </div>
          </v-avatar>
        </div>
        <div class="col-md-10">
          <v-text-field
            :label="element.socialmedia + ' URL'"
            type="text"
            outlined
            color="primary"
            dense
            hide-details
            persistent-hint
            rounded
            height="1"
            v-model="element.value"
          >
          </v-text-field>
        </div>
        <v-switch
          v-model="element.enable"
          inset
        ></v-switch>
      </div>
    </div>

    <!-- {{ list }} -->
    <Footer
          :loading="btnLoad"
          ref="footer"
          @click="updateSocialMedia"
    />
  </v-card>
</template>

<script>
import Footer from '../../../../../components/blocks/Footer.vue';


export default {
  name: 'Socialmedialinks',
  components: {
    Footer
},
  props: {
  },
  data: () => ({
    loading:false,
    dialog: false,
    btnLoad: false,
    // list: [],
    list: [
      {
        "name" : 'facebook',
        "type": 'external',
        "value": 'https://www.facebook.com/', 
        "page": '',
        "socialmedia": 'Facebook',
        "icon": 'facebook', 
        "icon2": 'facebook.svg', 
        "enable": false, 
      },
      {
        "name" : 'youtube',
        "type": 'external',
        "value": 'www.youtube.com/channel/', 
        "page": '',
        "socialmedia": 'Youtube',
        "icon": 'youtube', 
        "icon2": 'youtube.svg', 
        "enable": false, 
      },
      {
        "name" : 'linkedin',
        "type": 'external',
        "value": 'https://www.linkedin.com/', 
        "page": '',
        "socialmedia": 'LinkedIn',
        "icon": 'linkedin', 
        "icon2": 'linkedin.svg', 
        "enable": false, 
      },
      {
        "name" : 'twitter',
        "type": 'external',
        "value": 'https://twitter.com/', 
        "page": '',
        "socialmedia": 'Twitter',
        "icon": 'twitter', 
        "icon2": 'twitter.svg',
        "enable": false, 
      },
      {
        "name" : 'instagram',
        "type": 'external',
        "value": 'https://www.instagram.com/', 
        "page": '',
        "socialmedia": 'Instagram',
        "icon": 'instagram', 
        "icon2": 'instagram.svg',
        "enable": false, 
      },
      {
        "name" : 'tiktok',
        "type": 'external',
        "value": 'https://www.tiktok.com/', 
        "page": '',
        "socialmedia": 'Tiktok',
        "icon": 'tiktok', 
        "icon2": 'tiktok.svg',
        "enable": false, 
      },
      {
        "name" : 'snapchat',
        "type": 'external',
        "value": 'https://www.snapchat.com', 
        "page": '',
        "socialmedia": 'Snapchat',
        "icon": 'snapchat', 
        "icon2": 'snapchat.svg', 
        "enable": false, 
      },
      {
        "name" : 'pinterest',
        "type": 'external',
        "value": 'https://www.pinterest.com', 
        "page": '',
        "socialmedia": 'Pinterest',
        "icon": 'pinterest', 
        "icon2": 'pinterest.svg', 
        "enable": false, 
      },
    ],
  }),
  methods: {
    updateSocialMedia() {
      this.btnLoad = true;
      let params = new URLSearchParams();
      params.append('socialMediaList', JSON.stringify(this.list) );
      console.log(JSON.stringify(this.list));
      this.$http.post(`${this.$serverApiLink}api/account/apps/updatesocialmedia/${this.$route.params.uid}`, params).
      then(
          // eslint-disable-next-line no-unused-vars
          response => {
            this.btnLoad = false;
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "socialmedialinks"
            });
            this.$refs.footer.showSuccessAlert();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.btnLoad = false;
          }
      );
    },
    getSocailMediaItems() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/apps/socialmediadetails/${this.$route.params.uid}`).
      then(
          response => {
            const data = response.data.app;
            if (data.socialMediaList.trim() != "[]"){
             this.list = JSON.parse(data.socialMediaList);
            } 
            this.loading = false;
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
          }
      );
    },
    isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
  },
  mounted() {
    this.getSocailMediaItems();
  }
}
</script>