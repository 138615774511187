<template>
    <v-card style="margin:5px; border-radius: 20px; padding: 10px;" :to="{name: item.route_name}" exact  >
        <v-img :src="item.photo"></v-img>
        <v-card-subtitle class="pt-1 pb-1 font-weight-normal text-center" style="font-size:18px;">
            {{ item.name }}
        </v-card-subtitle>
        <v-card-subtitle>
            <span style="font-size:14px;">
            {{ item.description}}
        </span>
        </v-card-subtitle>
        
        <v-card-actions class="text-end" style="width:100%;">
            <v-btn  color="blue" text small 
            :to="{name: item.route_name}" exact
            >
            Configure
            <v-icon style="font-size:18px;">{{ show ? 'mdi-chevron-up' : 'mdi-arrow-right' }}</v-icon></v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

export default {
    name: 'MarketingCard',
    props: {
        item: {
            type: Object,
            required: true
        },
    },
    data: () => ({
        show :"",
    }),
    methods:{
        navigatetopushpage(){
            this.$router.push('/home'); 
        }
    }
}
</script>