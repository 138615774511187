<template>
  <v-layout fill-height>
    <v-main>
      <PageBar
          :title="$tr('menu', 'key_e18')"
          
          :drawer="true"
          @onChange="updateTab"
      />
      <Loader v-if="loading"/>
      <template v-else>
        <template v-if="!tab">
          <Container>
            <div class="d-flex justify-space-between align-center">
              <Title :title="$tr('menu', 'key_e18')"/>
              <v-btn
                  small
                  depressed
                  color="primary"
                  outlined
                  class="mb-5"
                  @click="dialogProduct.status = true"
              >
                {{ $tr('project', 'key_53') }}
              </v-btn>
            </div>
            <EmptyList v-if="!gallery.list.length"/>
            <v-list dense two-line class="pa-0 background_block" v-else>
              <div v-for="(item, index) in gallery.list" :key="'menu_item_app_'+index" style="display: none;">
                <v-divider
                    v-if="index > 0"
                    :key="'menu_line_'+index"
                />
                <v-list-item
                    :key="'menu_select_item_'+index"
                    class="pl-0 pr-0 pt-0 pb-0"
                    
                >
                  <v-list-item-content >
                    <v-list-item-title style="font-weight:bold; font-size: 0.9em;" > 
                      {{ item.title }}                      
                    </v-list-item-title>
                    <v-list-item-subtitle class="smoke--text">
                      {{ item.message }}      
                    </v-list-item-subtitle >
                  </v-list-item-content>

                  <v-list-item-action>
                    <div>
                      <v-btn icon small color="orange" class="mr-3" @click="openAppUpdate(index)">
                        <EditMiniIcon :size="16"/>
                      </v-btn>
                      <v-btn icon small color="danger" @click="deleteProductItem(index)">
                        <DeleteMiniIcon :size="16"/>
                      </v-btn>
                    </div>
                  </v-list-item-action>
                  
                  
                </v-list-item>
              </div>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr >
                      <th class="text-left" style="color: black;">
                        Notification Type
                      </th>
                      <th class="text-left" style="color: black;">
                        Title
                      </th>
                      <th class="text-left" style="color: black;">
                        Message
                      </th>
                      <th class="text-left" style="color: black;">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in gallery.list"
                      :key="'menu_item_app_'+index"
                    >  
                      <td>{{ $tr('project', item.notification_type )  }}</td>
                      <td>{{ item.title }}</td>
                      <td>{{ item.message }}</td>
                      <td>
                        <div>
                          <v-btn icon small color="orange" class="mr-3" @click="openAppUpdate(index)">
                            <EditMiniIcon :size="16"/>
                          </v-btn>
                          <v-btn icon small color="danger" @click="deleteProductItem(index)">
                            <DeleteMiniIcon :size="16"/>
                          </v-btn>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-list>
          </Container>
          <Footer
              ref="footer"
          />
        </template>
        
        
      </template>
      <v-dialog
          scrollable
          max-width="680"
          v-model="dialogProduct.status"
      >
        <NewEventNotification
            :loading="dialogProduct.loading"
            ref="newApp"
            @close="dialogProduct.status = false"
            @save="createAppItem"
        />
      </v-dialog>
      <v-dialog
          scrollable
          max-width="680"
          v-model="editProduct.status"
      >
        <NewEventNotification
            v-if="editProduct.status"
            :item-title="gallery.list[editProduct.index].title"
            :item-message="gallery.list[editProduct.index].message"
            :item-notification_type="gallery.list[editProduct.index].notification_type"
            :item-duration="gallery.list[editProduct.index].duration"
            :item-actions="gallery.list[editProduct.index].actions"
            :item-route="gallery.list[editProduct.index].route"
            :item-sound="gallery.list[editProduct.index].sound"
            :loading="editProduct.loading"
            @close="editProduct.status = false"
            @save="updateAppItem"
        />
      </v-dialog>

    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Container from "@/components/blocks/Container";
import Title from "@/components/blocks/Title";
import Loader from "@/components/blocks/Loader";
import EmptyList from "@/views/account/apps/builder/components/EmptyList";
import NewEventNotification from "@/views/account/apps/builder/modals/NewEventNotification";
import DeleteMiniIcon from "@/components/icons/DeleteMiniIcon";
import EditMiniIcon from "@/components/icons/EditMiniIcon";
import Footer from "@/components/blocks/FooterWithoutButton";

export default {
  name: 'Slideshow',
  components: {
    PageBar,
    Container,
    Title,
    Loader,
    EmptyList,
    NewEventNotification,
    DeleteMiniIcon,
    EditMiniIcon,
    Footer
  },
  data: () => ({
    loading: true,
    tab: 0,
    gallery: {
      list: [],
      count: 0
    },
    dialogProduct: {
      status: false,
      loading: false
    },
    editProduct: {
      status: false,
      loading: false,
      index: 0
    },
    
  }),
  computed: {
    tabs: function () {
      return [
        this.$tr('project', 'key_118'),
        this.$tr('project', 'key_45'),
        this.$tr('project', 'key_327')
      ];
    },
    actions: function () {
      return [
        {
          text: this.$tr('project', 'key_57'),
          value: 0
        },
        {
          text: this.$tr('project', 'key_58'),
          value: 1
        },
        {
          text: this.$tr('project', 'key_59'),
          value: 2
        },
        {
          text: this.$tr('project', 'key_60'),
          value: 3
        },
        {
          text: this.$tr('project', 'key_298'),
          value: 4
        },
        {
          text: this.$tr('project', 'key_328'),
          value: 5
        },
      ];
    },
  },
  methods: {
    updateTab(value) {
      this.tab = value;
    },
    openAppUpdate(index) {
      this.editProduct = {
        status: true,
        loading: false,
        index: index
      }
    },
    /* product page navigtation area start */
    createAppItem(value) {
      this.dialogProduct.loading = true;
      let params = new URLSearchParams();
      params.append('message', value.message);
      params.append('title', value.title);
      params.append('notification_type', value.notification_type);
      params.append('duration', value.duration);
      params.append('route', value.route);
      params.append('action', value.action);
      params.append('sound', value.sound);
      params.append('icon', value.icon);
      this.$http.post(`${this.$serverApiLink}api/account/EventNotification/create/${this.$route.params.uid}`, params).then(
          response => {
            this.gallery.list.push({
              title:    value.title,
              message:  value.message,
              notification_type: value.notification_type,
              duration: value.duration,
              route:    value.route,
              action:   value.action,
              sound:    value.sound,
              icon:     value.icon,
              id:       response.data.id
            });
            this.dialogProduct = {
              loading: false,
              status: false
            };
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "app"
            });
            this.$refs.newProduct.clean();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.dialogProduct.loading = false;
          }
      );
    },
    deleteProductItem(index) {
      this.$store.commit('setLoading', true);
      this.$http.get(`${this.$serverApiLink}api/account/EventNotification/remove/${this.gallery.list[index].id}`).
      then(
          // eslint-disable-next-line no-unused-vars
          response => {
            this.gallery.list.splice(index, 1);
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "app"
            });
            this.$store.commit('setLoading', false);
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.$store.commit('setLoading', false);
          }
      );
    },
    updateAppItem(value) {
      this.editProduct.loading = true;
      let id = this.gallery.list[this.editProduct.index].id;
      let params = new URLSearchParams();
      params.append('message', value.message);
      params.append('title', value.title);
      params.append('notification_type', value.notification_type);
      params.append('duration', value.duration);
      params.append('route', value.route);
      params.append('action', value.action);
      params.append('sound', value.sound);
      params.append('icon', value.icon);
      this.$http.post(`${this.$serverApiLink}api/account/EventNotification/update/${id}`, params).
      then(
          () => {
            this.gallery.list[this.editProduct.index] = {
            title:      value.title,
            message:    value.message,
            action:     value.action,
            notification_type:  value.notification_type,
            duration:   value.duration,
            route:      value.route,
            sound:      value.sound,
            icon:       value.icon,
            id:         this.gallery.list[this.editProduct.index].id
            };
            
            this.editProduct = {
              status: false,
              loading: false,
              index: 0
            };
            this.getNotificationItems();
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "app"
            });
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.editProduct.loading = false;
          }
      );
    },
    getNotificationItems() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/EventNotification/list/${this.$route.params.uid}`).
      then(
          response => {
            this.gallery.list = response.data.list;
            this.gallery.count = response.data.count;
            this.loading = false;
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
          }
      );
    },
  },
  mounted() {
    this.getNotificationItems();
  }
}
</script>