<template>
    <v-layout fill-height>
    <v-main>
      <PageBar :title="$tr('menu', 'key_e6')" :tabs="tabs" @onChange="updateTab" :drawer="true" />
      <Loader v-if="loading" />
      <template>
        <!--  -->
          <v-row class="mb-6">
            <v-col md="12" sm="12" cols="12">
              <div>
                <v-parallax dark src="https://static.vecteezy.com/system/resources/previews/004/750/609/original/flat-design-abstract-background-soft-liquid-shapes-template-with-modern-gradient-background-colors-cool-aesthetic-background-design-suitable-for-social-media-post-mobile-app-banner-web-ads-free-vector.jpg" height="200">
                  <v-row align="center" justify="center">
                    <v-col class="text-center" cols="12">
                      <h2 class="text-h5 font-weight-medium mb-4" style="color:black;">
                        {{ $tr('project', 'key_e15') }}
                      </h2>
                      <h4 class="paragraph" style="color:grey; font-weight: 400;">
                        {{ $tr('project', 'key_e16') }}
                      </h4>
                    </v-col>
                  </v-row>
                </v-parallax>
              </div>
            </v-col>
          </v-row>

        <!-- All Categories -->
        <Container v-if="!tab">
          <Container class="row">
            <div v-for="(item, index) in intergration" :key="'blog_' + index" class="col-lg-3 col-md-4 col-sm-6 col-12">
                <IntergrationCard :item="item"/>
            </div>
          </Container>
        </Container>

        <!-- Active Intergrations -->
        <Container v-else-if="tab === 1">
          <Container class="row">
            <div class="col-lg-4 col-md-6 col-sm-6 col-12" v-for="item in intergration.filter(info => info.template_owner === 1)" :key="item.id" >
                <IntergrationCard :item="item"/>
              </div>
          </Container>
        </Container>

        <!-- push Notifications -->
        <Container v-else-if="tab === 2">
          <Container class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-12" v-for="item in intergration.filter(info => info.category === 'push-notifications')" :key="item.id" >
                <IntergrationCard :item="item"/>
              </div>
          </Container>
        </Container>

        <!-- analytics -->
        <Container v-else-if="tab === 3">
          <Container class="row">
            <template>
              <div class="col-lg-3 col-md-4 col-sm-6 col-12" v-for="item in intergration.filter(info => info.category === 'analytics')" :key="item.id" >
                <IntergrationCard :item="item"/>
              </div>
            </template>
          </Container>
        </Container>

        <!-- Firebase Integration -->
        <Container v-else-if="tab === 3">
          <Container class="row">
            <template>
              <div class="col-lg-3 col-md-4 col-sm-6 col-12" v-for="item in intergration.filter(info => info.category === 'analytics')" :key="item.id" >
                <IntergrationCard :item="item"/>
              </div>
            </template>
          </Container>
        </Container>

        <!-- Firebase Push Notifications Integration -->
        <Container v-else-if="tab === 3">
          <Container class="row">
            <template>
              <div class="col-lg-3 col-md-4 col-sm-6 col-12" v-for="item in intergration.filter(info => info.category === 'push-notifications')" :key="item.id" >
                <IntergrationCard :item="item"/>
              </div>
            </template>
          </Container>
        </Container>

        <!-- Chat -->
        <Container v-else-if="tab === 4">
          <Container class="row">
              <div class="col-lg-3 col-md-4 col-sm-6 col-12" v-for="item in intergration.filter(info => info.category === '')" :key="item.id" >
                <IntergrationCard :item="item"/>
              </div>
          </Container>
        </Container>

        <!--payments-->
        <Container v-else-if="tab === 5">
          <Container class="row">
              <div class="col-lg-3 col-md-4 col-sm-6 col-12" v-for="item in intergration.filter(info => info.category === 'payments')" :key="item.id" >
                <IntergrationCard :item="item"/>
              </div>
          </Container>
        </Container>

        <!--Rewards-->
        <Container v-else-if="tab === 6">
          <Container class="row">
              <div class="col-lg-3 col-md-4 col-sm-6 col-12" v-for="item in intergration.filter(info => info.category === '')" :key="item.id" >
                <IntergrationCard :item="item"/>
              </div>
          </Container>
        </Container>

        
        <!--Alerts-->
        <Container v-else-if="tab === 7">
          <Container class="row">
              <div class="col-lg-3 col-md-4 col-sm-6 col-12" v-for="item in template.filter(info => info.category === '')" :key="item.id" >
                <IntergrationCard :item="item"/>
              </div>
          </Container>
        </Container>

      </template>
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import IntergrationCard from "../../../../components/blocks/IntergrationCard.vue";
import Loader from "../../../../components/blocks/Loader.vue";
import Container from "../../../../components/blocks/Container.vue";
// import Title from "@/components/blocks/Title";
// import Container from "@/components/blocks/Container";
// import Loader from "@/components/blocks/Loader";
// import SelectColor from "@/views/account/apps/builder/modals/SelectColor";
// import DrawerTemplate from "@/assets/images/templates/drawer.png";
// import TabsTemplate from "@/assets/images/templates/tabs.png";
// import BarTemplate from "@/assets/images/templates/bar.png";
// import BlankTemplate from "@/assets/images/templates/blank.png";
// import Footer from "@/components/blocks/Footer";
// import DeleteMiniIcon from "@/components/icons/DeleteMiniIcon";
// import CancelIcon from "@/components/icons/CancelIcon";

export default {
  name: 'Design',
  components: {
    PageBar,
    IntergrationCard,
    Loader,
    Container
},
  data: () => ({
    loading: true,
    intergration: [],
    picker: {
      status: false,
      mode: "theme",
      color: "#ffffff"
    },
    tab: 0,
    app: {
      color_theme: "#F44336",
      color_title: 0,
      loader: 0,
      pull_to_refresh: 0,
      loader_color: "#F44336",
      template: 0,
      btn_color: "#F44336",
      display_title: 0,
      icon_color: "#F44336",
      active_color: "#F44336",
    }
  }),
  computed: {
    tabs: function () {
      return [
        this.$tr('project', 'key_e20'),
        this.$tr('project', 'key_e24'),
        this.$tr('project', 'key_e25'),
        this.$tr('project', 'key_e26'),
        this.$tr('project', 'key_e27'),
        this.$tr('project', 'key_e28'),
        this.$tr('project', 'key_e29'),
        this.$tr('project', 'key_e30'),
      ];
    },
  },
  methods: {
    getIntergrations() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/intergrations/getintergrations/${this.$route.params.id}`).
        then(
          response => {
            this.intergration = response.data.intergration;
            this.loading = false;
          }
        ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
        );
    },
    startUpdate() {
      if (!this.tab) {
        this.updateMain();
      } else if (this.tab === 1) {
        this.updateDrawer();
      }
    },
    updateTab(value) {
      this.tab = value;
    }
  },
  mounted() {
    this.getIntergrations();
  }
}
</script>