<template>
  <v-layout fill-height>
    <v-main>
      <PageBar title="Productcard" :drawer="true" pagetype="pages" />

      <Loader v-if="loading" />
      <Container v-else>
        <!-- <PageSettingNavBar :name="currentpage" /> -->
        <div class="row">
          <h3 class="col">Customise Product Card</h3>
          <HelpArticle2 id="63242954b78f9a796bdea96e" />
        </div>

        <v-row>
          <v-col md="12" sm="12" cols="12">
            <h5>Select Product card Design</h5>
            <v-item-group mandatory v-model="page.productCardStyle" class="row">
              <div
                v-for="(item, i) in productCardStyles"
                :key="`${i}_app_template_layout_builder`"
                class="col-md-2 col-6"
              >
                <img
                  :src="item.image"
                  :class="page.productCardStyle === i ? 'app_template active' : 'app_template'"
                  @click="page.productCardStyle = i"
                  style="width: 100%"
                />
                <div class="caption text-uppercase">
                  {{ item.name }}
                </div>
              </div>
            </v-item-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" sm="4" cols="4">
            <div class="componentslider">
              <v-lable>
                <span class="sliderlable"
                  >Product Card ImageRatio :
                  {{page.productCardImageRation}}</span
                >
              </v-lable>
              <v-slider
                v-model="page.productCardImageRation"
                step="0.25"
                min="0.75"
                max="2"
                inverse-label
                color="#000000"
                track-color="rgb(0 0 0 / 12%)"
              ></v-slider>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" sm="4" cols="4">
            <v-select
              :items="enable"
              dense
              hide-details
              label="Show Product Card Sales Badge"
              outlined
              v-model="page.productCardShowSaleBadge"
            ></v-select>
          </v-col>

          <v-col md="4" sm="4" cols="4">
            <v-select
              :items="enable"
              dense
              hide-details
              label="Show Product Card Sales New Badge"
              outlined
              v-model="page.productCardShowSNewBadge"
            ></v-select>
          </v-col>

          <v-col md="4" sm="4" cols="4">
            <v-select
              :items="enable"
              dense
              hide-details
              label="Show Product Card Rating"
              outlined
              v-model="page.productCardShowRatings"
            ></v-select>
          </v-col>
          <div class="col-md-6">
            <div
              class="permissions_block mb-3 d-flex justify-space-between align-center pa-4"
            >
              <div class="d-flex justify-start align-center">
                <div style="padding: 10px">
                  <div
                    v-if="!page.productCardPlaceholderImage"
                    class="smoke--text"
                    style="width: 32px; height: 32px"
                  >
                    <ImageIcon :size="32" />
                  </div>
                  <div style="width: 120px; height: 120px" v-else>
                    <v-img
                      :src="page.productCardPlaceholderImage"
                      style="width: 120px; max-height: 120px"
                    >
                      <template v-slot:placeholder>
                        <v-progress-circular
                          indeterminate
                          color="smoke"
                          size="32"
                          width="3"
                        ></v-progress-circular>
                      </template>
                    </v-img>
                  </div>
                </div>

                <div style="margin-left: 5px">
                  <div class="body-1 font-weight-medium">
                    {{ $tr('project', 'key_447') }}
                  </div>
                  <div class="body-2 smoke--text">
                    {{ $tr('project', 'key_389') }}
                  </div>
                  <div>
                    <v-btn
                      :loading="logo_image.isSelecting"
                      small
                      depressed
                      class="mt-3"
                      @click="onButtonLogoClick"
                      style="float: left"
                    >
                      {{ $tr('project', 'key_86') }}
                    </v-btn>

                    <input
                      ref="uploaderB"
                      class="d-none"
                      type="file"
                      accept="image/*"
                      @change="onFileLogoChanged"
                    />
                  </div>

                  <v-btn
                    small
                    depressed
                    class="mt-3"
                    @click="openCreativePortal(page.productCardPlaceholderImage)"
                    style="float: left; margin-left: 10px"
                  >
                    <!-- <EditIcon />  -->
                    Open Designer
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </v-row>
      </Container>
      <Footer
        v-if="!loading"
        :loading="btnLoad"
        ref="footer"
        @click="updateSplash"
      />
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Container from "@/components/blocks/Container";
// import Title from "@/components/blocks/Title";
import Loader from "@/components/blocks/Loader";
// import ImageIcon from "@/components/icons/ImageIcon";
import Footer from "@/components/blocks/Footer";

import Productcardstyle01 from "@/assets/images/productcards/productcard.png";
import HelpArticle2 from "../../../../components/blocks/HelpArticle2.vue";
import ImageIcon from "../../../../components/icons/ImageIcon.vue";

export default {

  components: {
    PageBar,
    Container,
    // Title,
    Loader,
    Footer,
    HelpArticle2,
    ImageIcon
},
  data: () => ({
    loading: false,
    page: {
      productCardStyle          :""  ,
      productCardImageRation    :""  ,
      productCardShowSaleBadge  :""  ,
      productCardShowSNewBadge  :""  ,
      productCardShowRatings    :""  ,
      productCardPlaceholderImage :"",
    },
    logo_image: {
      isSelecting: false,
      selectedFile: null,
      upload: false,
      loading: false
    },
    btnLoad: false,
    creative: false,
    iframe: {
        src: "https://app.shopimint.com/backend/creative/generate",
    },
    productCardStyles: [
      {
        image: Productcardstyle01,
        name: "Product Card Design 01"
      }
    ],
  }),
  computed: {
    enable: function () {
      return [
        {
          text: 'Enable',
          value: '1'
        },
        {
          text: 'Disable',
          value: '0'
        },
      ];
    }
  },
  methods: {
    updateSplash() {
      this.btnLoad = true;
      let params = new URLSearchParams();
      params.append('productCardStyle', this.page.productCardStyle);
      params.append('productCardImageRation', this.page.productCardImageRation);
      params.append('productCardShowSaleBadge', this.page.productCardShowSaleBadge);
      params.append('productCardShowSNewBadge', this.page.productCardShowSNewBadge);
      params.append('productCardShowRatings', this.page.productCardShowRatings);
      this.$http.post(`${this.$serverApiLink}api/account/pagesettings/update_productcard/${this.$route.params.uid}`, params).
      then(
          // eslint-disable-next-line no-unused-vars
          response => {
            this.btnLoad = false;
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "productcard"
            });
            this.$refs.footer.showSuccessAlert();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.btnLoad = false;
          }
      );
    },
    onButtonLogoClick() {
      this.logo_image.isSelecting = true;
      window.addEventListener('focus', () => {
        this.logo_image.isSelecting = false
      }, {once: true});
      this.$refs.uploaderB.click();
    },
    onFileLogoChanged(e) {
      this.logo_image.selectedFile = e.target.files[0];
      this.$store.commit('setLoading', true);
      this.logo_image.upload = true;
      let params = new FormData();
      params.set('productCardPlaceholderImage', this.logo_image.selectedFile );
      this.$http.post(
          `${this.$serverApiLink}api/account/assets/upload_productcardplaceholder/${this.$route.params.uid}`, params, {headers: {'Content-Type': 'multipart/form-data' }}).
      then(
          response => {
            this.page.productCardPlaceholderImage = response.data.uri;
            this.logo_image.upload = false;
            this.logo_image.isSelecting = false;
            this.$store.commit('setLoading', false);
            this.$emit("reload_preview", {
              uid: this.$route.params.uid,
              mode: "categorypagebanner"
            });
            this.$refs.footer.showSuccessAlert();
            this.getBannerDetail();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.logo_image.upload = false;
            this.logo_image.isSelecting = false;
            this.$store.commit('setLoading', false);
            this.getBannerDetail();
          }
      );
    },
    getPageSettings() {
      this.$http.get(`${this.$serverApiLink}api/account/pagesettings/detail/${this.$route.params.uid}`).
      then(
          response => {
            this.page = response.data.detail;
            this.loading = false;
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
          }
      );
    },

  },
  mounted() {
    this.getPageSettings();
  }
}
</script>

<style>
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.card {
  flex-direction: row;
  background: #fff;
  padding: 10px;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border-radius: 10px;
}

.theme--dark .card {
  background-color: rgb(29, 29, 44);
  color: white;
}

.card img {
  width: 30%;
}

.v-text-field input {
  font-size: 0.9em;
  color: black;
  font-weight: bold;
  padding: 0px;
}

.v-select {
  font-size: 0.9em;
  color: black;
  font-weight: bold;
  padding: 0px;
}

::v-deep .v-label {
  font-size: 0.9em;
}

::v-deep .v-label--active {
  font-size: 0.9em;
  font-weight: bold;
}

.pagesmenubar {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.pagesmenubar li {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}
</style>
