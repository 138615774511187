<template>
  <v-layout fill-height>
    <v-main>
      <PageBar :title="$tr('menu', 'key_e4')" :preview="false" drawer />
      <Loader v-if="loading" />
      <template v-else>
        <EmptyState v-if="list.length === 0" :title="$tr('menu', 'key_e4')"
          :subtitle="$tr('project', 'key_234') + '- We are working on here'">
          <template v-slot:icon>
            <div style="width: 45px; height: 45px">
              <MailStackIcon :size="45" />
            </div>
          </template>
        </EmptyState>
        <div v-else>

          <!-- <v-parallax dark src="https://cdn.vuetifyjs.com/images/parallax/material.jpg" height="300">
            <v-row align="center" justify="center">
              <v-col class="text-center" cols="12">
                <h1 class="text-h4 font-weight-medium mb-4">
                  {{ $tr('menu', 'key_e4') }}
                </h1>
                <h4 class="subheading">
                  Your article here
                </h4>
              </v-col>
            </v-row>
          </v-parallax> -->

          <Container class="row" >

            <div v-for="(item, index) in list" :key="'blog_' + index" class="col-lg-8">

              <div style="margin:10px;">

                <v-img :src="item.featured_image" style="border-radius:25px;" height="300px" v-if="item.post_type == 'post'" ></v-img>

                <div class="plyr__video-embed" v-else >

                    <iframe 
                        v-if="item.video_link.includes('youtube')"
                        width="100%" 
                        height="380" 
                        :src="item.video_link" 
                        :title="item.title" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen>
                    </iframe>

                    <!-- <iframe v-if="item.video_link.includes('youtube')" 
                      :src='item.video_link'
                      allowfullscreen allowtransparency allow="autoplay" width="100%" height="400">
                    </iframe> -->

                    <iframe v-else-if="item.video_link.includes('vimeo')" 
                      src="https://www.youtube.com/embed/bTqVqk7FSmY?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1"
                      allowfullscreen allowtransparency allow="autoplay" width="100%" height="400">
                    </iframe>

                </div>

                <v-card-title>
                  {{ item.title }}
                </v-card-title>
                <v-card-subtitle>
                  {{ item.category }}
                  <v-divider></v-divider>
                </v-card-subtitle>
                
              </div>

              <div :v-html="item.content" style="padding-left:5%; padding-right:5%;" >{{ item.content }}</div>
              
            </div>
            <div class="col-lg-4">
              <h3>Similar Articles</h3>
              <v-divider style="margin:10px;"></v-divider>
              <div v-for="(post, index) in all" :key="'blog_' + index" class="col-lg-12" style="padding:1px;">

                <v-card 
                  style="border-radius:15px;margin-bottom: 10px;" 
                  :to="{name: 'helpcenterarticle' , params: {id: post.id} }" exact 
                  @click="getBlogList"
                >
                  <v-row no-gutters>
                    <v-col cols="6">
                      <!-- <v-img :src="item.featured_image" style="border-radius:25px;" height="100px"></v-img> -->
                      <v-card-title>
                        <v-img :src="post.featured_image" style="border-radius:10px;" height="100px"></v-img>
                        <v-spacer></v-spacer>
                      </v-card-title>
                    </v-col>
                    <v-col cols="6">
                      <v-card-subtitle>
                        {{ post.title }}
                      </v-card-subtitle>
                      <v-btn 
                        color="blue lighten-2" 
                        text
                      >Read More
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </div>
          </Container>
        </div>
      </template>
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Loader from "@/components/blocks/Loader";
import EmptyState from "@/components/blocks/EmptyState";
import Container from "@/components/blocks/Container";
import MailStackIcon from "@/components/icons/MailStackIcon";

export default {
  name: 'Tickets',
  components: {
    PageBar,
    Loader,
    EmptyState,
    // Title,
    Container,
    MailStackIcon,
  },
  data: () => ({
    video: true,
    loading: false,
    list: [],
    all: [],
    offset: 0,
    count: 0,
    loadBtn: {
      status: true,
      loading: false
    },
  }),
  methods: {
    getBlogList() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/blog/getblogpost/${this.$route.params.id}`).
        then(
          response => {
            this.list = response.data.post;
            this.video = true;
            this.loading = false;
            this.getSimilarBlog();
          }
        ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
            this.getSimilarBlog();
          }
        );
    },
    getSimilarBlog() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/blog/getsimilarposts/${this.$route.params.id}`).
        then(
          response => {
            this.all = response.data.allpost;
            this.loading = false;
          }
        ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
        );
    },
    loadMore() {
      this.loadBtn.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/blog/list/1/${this.offset}`).
        then(
          response => {
            if (response.data.list.length > 0) {
              for (let i = 0; i < response.data.list.length; i++) {
                this.list.push(response.data.list[i]);
              }
            } else {
              this.loadBtn.status = false;
            }
            this.offset += 20;
            this.loadBtn.loading = false;
          }
        ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loadBtn.loading = false;
          }
        );
    }
  },
  mounted() {
    this.getBlogList();
  }
}
</script>