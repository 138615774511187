var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"fill-height":""}},[_c('v-main',[_c('PageBar',{attrs:{"title":_vm.$tr('menu', 'key_e1'),"drawer":true,"pagetype":"pages"}}),(_vm.loading)?_c('Loader'):_c('Container',[_c('Title',{staticStyle:{"display":"none"},attrs:{"title":_vm.$tr('project', 'key_e4')}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"row"}),_c('div',{staticClass:"row",staticStyle:{"margin-top":"0px"}},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row",staticStyle:{"margin-top":"5px"}},[_c('div',[_c('div',{class:_vm.selected == 'popular'
                        ? 'activecategory card pagesidecard'
                        : 'card pagesidecard',on:{"click":function($event){_vm.selected = 'popular'}}},[_c('div',{staticClass:"card-body justify-content-center"},[_c('h5',{staticClass:"card-title text-center",staticStyle:{"font-size":"12px"}},[_c('v-icon',{staticStyle:{"height":"0px","color":"orange"},attrs:{"size":20}},[_vm._v("mdi-star ")]),_vm._v(" Popular ")],1)])])]),_c('div',[_c('div',{class:_vm.selected == 'title'
                        ? 'activecategory card pagesidecard'
                        : 'card pagesidecard',on:{"click":function($event){_vm.selected = 'title'}}},[_c('div',{staticClass:"card-body justify-content-center"},[_c('h5',{staticClass:"card-title text-center",staticStyle:{"font-size":"12px"}},[_vm._v(" Text ")])])])]),_c('div',[_c('div',{class:_vm.selected == 'image'
                        ? 'activecategory card pagesidecard'
                        : 'card pagesidecard',on:{"click":function($event){_vm.selected = 'image'}}},[_c('div',{staticClass:"card-body justify-content-center"},[_c('h5',{staticClass:"card-title text-center",staticStyle:{"font-size":"12px"}},[_vm._v(" Image ")])])])]),_c('div',[_c('div',{class:_vm.selected == 'slider'
                        ? 'activecategory card pagesidecard'
                        : 'card pagesidecard',on:{"click":function($event){_vm.selected = 'slider'}}},[_c('div',{staticClass:"card-body justify-content-center"},[_c('h5',{staticClass:"card-title text-center",staticStyle:{"font-size":"12px"}},[_vm._v(" Slider ")])])])]),_c('div',[_c('div',{class:_vm.selected == 'product'
                        ? 'activecategory card pagesidecard'
                        : 'card pagesidecard',on:{"click":function($event){_vm.selected = 'product'}}},[_c('div',{staticClass:"card-body justify-content-center"},[_c('h5',{staticClass:"card-title text-center",staticStyle:{"font-size":"12px"}},[_vm._v(" Product ")])])])]),_c('div',[_c('div',{class:_vm.selected == 'corosel'
                        ? 'activecategory card pagesidecard'
                        : 'card pagesidecard',on:{"click":function($event){_vm.selected = 'corosel'}}},[_c('div',{staticClass:"card-body justify-content-center"},[_c('h5',{staticClass:"card-title text-center",staticStyle:{"font-size":"12px"}},[_vm._v(" Collection ")])])])]),_c('div',[_c('div',{class:_vm.selected == 'searchbox'
                        ? 'activecategory card pagesidecard'
                        : 'card pagesidecard',on:{"click":function($event){_vm.selected = 'searchbox'}}},[_c('div',{staticClass:"card-body justify-content-center"},[_c('h5',{staticClass:"card-title text-center",staticStyle:{"font-size":"12px"}},[_vm._v(" Search ")])])])]),_c('div',[_c('div',{class:_vm.selected == 'countdown'
                        ? 'activecategory card pagesidecard'
                        : 'card pagesidecard',on:{"click":function($event){_vm.selected = 'countdown'}}},[_c('div',{staticClass:"card-body justify-content-center"},[_c('h5',{staticClass:"card-title text-center",staticStyle:{"font-size":"12px"}},[_vm._v(" Countdown Timer ")])])])]),_c('div',[_c('div',{class:_vm.selected == 'story'
                        ? 'activecategory card pagesidecard'
                        : 'card pagesidecard',on:{"click":function($event){_vm.selected = 'story'}}},[_c('div',{staticClass:"card-body justify-content-center"},[_c('h5',{staticClass:"card-title text-center",staticStyle:{"font-size":"12px"}},[_vm._v(" Live Story ")])])])]),_c('div',[_c('div',{class:_vm.selected == 'youtubevideo'
                        ? 'activecategory card pagesidecard'
                        : 'card pagesidecard',on:{"click":function($event){_vm.selected = 'youtubevideo'}}},[_c('div',{staticClass:"card-body justify-content-center"},[_c('h5',{staticClass:"card-title text-center",staticStyle:{"font-size":"12px"}},[_vm._v(" Video ")])])])])])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"wrappercomponent"},[_c('draggable',{staticClass:"ch-masonry",staticStyle:{"padding-top":"20px"},attrs:{"list":_vm.list1,"group":{ name: 'people', pull: 'clone', put: false },"clone":_vm.clone},on:{"start":_vm.start}},_vm._l((_vm.list1),function(element){return _c('div',{key:element.id,staticClass:"ch-masonry__item"},[_c('ComponenetItemCard',{attrs:{"status":true,"name":element.name,"displayname":element.displayname,"image":element.vicon,"selectedcategory":_vm.selected,"type":element.type}})],1)}),0)],1)])])]),_c('div',{staticClass:"col-sm-6",staticStyle:{"align-items":"center","justify-content":"center","width":"100%"}},[_c('div',{staticClass:"dragArea2",style:(Object.keys(_vm.list2).length == 0
                ? "background-image: url('https://app.shopimint.com/backend/static/dragdropbackground.png'); background-size: 367px 98px; margin-top:25px"
                : 'margin-top:25px')},[_c('draggable',_vm._b({staticClass:"list-group",staticStyle:{"margin-top":"20px","width":"100%"},attrs:{"list":_vm.list2,"group":"people","handle":".handle"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":!_vm.drag ? 'flip-list' : null}},_vm._l((_vm.list2),function(element,idx){return _c('div',{key:element.id,staticClass:"col-sm-12",staticStyle:{"padding":"0px","margin":"0px"}},[_c('ActiveComponenetCard',{attrs:{"element":element,"status":true,"componentitem":element,"name":element.name,"vicon":element.vicon,"styles":element.styles,"idx":idx,"rowid":element.id,"type":element.type,"data":element.data,"data_id":element.data_id,"dataloadtype":element.dataloadtype,"data_query":element.data_query,"query":element.query,"activelist":_vm.list2,"categorieslist":_vm.categorieslist,"platform":_vm.platform}})],1)}),0)],1)],1)])])],1),(!_vm.loading)?_c('Footer',{ref:"footer",attrs:{"loading":_vm.btnLoad},on:{"click":_vm.updatePage}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }