<template>
  <v-card flat tile>
    <v-card-title class="pa-0">
      <v-toolbar
          flat
          class="modal_bar"
      >
        <v-toolbar-title>
          {{ $tr('project', !itemTitle.length ? 'key_408' : 'key_407') }}
        </v-toolbar-title>
        <v-spacer/>
        <v-btn
            icon
            @click="$emit('close')"
        >
          <CancelIcon/>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-divider/>
    <v-card-text class="pa-0">
      <v-container fluid>
        <Title :title="$tr('project', 'key_409')"/>
        <v-row>
          <v-col md="12" sm="12" cols="12" class="pb-0">
            <v-text-field
                :label="$tr('project', 'key_112')"
                outlined
                color="primary"
                dense
                autofocus
                v-model="title"
            ></v-text-field>
          </v-col>
          <v-col md="12" sm="12" cols="12" class="pb-0">
            <v-textarea
                :label="$tr('project', 'key_411')"
                outlined
                color="primary"
                dense
                autofocus
                v-model="message"
            ></v-textarea>
          </v-col>
          <v-col md="6" sm="12" cols="12" class="pb-0">
            <v-select
                :label="$tr('project', 'key_410')"
                outlined
                color="primary"
                dense
                :items="notification_types"
                v-model="notification_type"
            ></v-select>
          </v-col>
          <v-col md="6" sm="12" cols="12" class="pb-0">
            <v-select
                :label="$tr('project', 'key_412')"
                outlined
                color="primary"
                dense
                :items="durations"
                v-model="duration"
            ></v-select>
          </v-col>
          <v-col md="6" sm="12" cols="12" class="pb-0">
            <v-select
                :label="$tr('project', 'key_56')"
                outlined
                color="primary"
                dense
                :items="actions"
                v-model="action"
            ></v-select>
          </v-col>
          <v-col md="6" sm="12" cols="12" class="pb-0"  >
            <v-select
                label="Select Page"
                outlined
                color="primary"
                dense
                :items="routes"
                v-model="route"
            ></v-select>
          </v-col>
          <v-col md="12" sm="12" cols="12" class="pb-0"  >
            <v-select
                label="Select Sound"
                outlined
                color="primary"
                dense
                :items="sounds"
                v-model="sound"
            ></v-select>
          </v-col>
        </v-row>
        <Title :title="$tr('project', 'key_69')"/>
        <div class="permissions_block  mb-3">
          <div class="d-flex justify-space-between align-center pa-4">
            <div class="d-flex justify-start align-center">
              <v-avatar color="whitesmoke" :size="60" class="mr-4">
                <div class="smoke--text" style="width: 32px; height: 32px; font-size: 30px">
                  <ion-icon :name="icon"></ion-icon>
                </div>
              </v-avatar>
              <div>
                <div class="body-1 font-weight-medium black--text">
                  {{ $tr('project', 'key_62') }}
                </div>
                <div class="body-2 smoke--text smoke--text">
                  {{ $tr('project', 'key_302') }}
                </div>
              </div>
            </div>
            <v-btn small depressed @click="dialog = true">
              {{ $tr('project', 'key_303') }}
            </v-btn>
          </div>
        </div>
      </v-container>
      <v-divider/>
    </v-card-text>
    <v-container fluid class="text-right">
      <v-btn color="primary" :loading="loading" depressed @click="save">
        {{ $tr('project', 'key_173') }}
      </v-btn>
    </v-container>
    <v-dialog
        scrollable
        max-width="680"
        v-model="dialog"
    >
      <SelectIcon
          @save="updateIcon"
          @close="dialog = false"
          :icon="icon"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import CancelIcon from "@/components/icons/CancelIcon";
import Title from "@/components/blocks/Title";
import SelectIcon from "@/views/account/apps/builder/modals/SelectIcon";

export default {
  name: 'NewEventNotification',
  components: {
    CancelIcon,
    Title,
    SelectIcon
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    itemTitle: {
      type: String,
      default: ""
    },
    itemMessage: {
      type: String,
      default: ""
    },
    itemNotification_type: {
      type: String,
      default: "abandoned_cart_first"
    },
    itemDuration: {
      type: Number,
      default: 10
    },
    itemRoute: {
      type: String,
      default: "HomeScreen"
    },
    itemAction: {
      type: String,
      default: "open_app"
    },
    itemSound: {
      type: String,
      default: "Tri-tone"
    },
    itemIcon: {
      type: String,
      default: "albums-outline"
    },
  },
  data: () => ({
    title: "",
    message:"",
    notification_type: "",
    route: "HomeScreen",
    duration:"",
    sound:"",
    action: 6,
    icon: "albums-outline",
    search: "",
    selectedIcon: "",
    dialog: false,
  }),
  computed: {
    actions: function () {
      return [
        {
          text: this.$tr('project', 'key_e33'),
          value: "open_app"
        },
      ];
    },
    notification_types: function () {
      return [
        {
          text: "Abandoned Cart One",
          value: "abandoned_cart_first"
        },
        {
          text: "Abandoned Cart Two",
          value: "abandoned_cart_second"
        },
        {
          text: "Abandoned Cart Three",
          value: "abandoned_cart_third"
        },
        {
          text: "Welcome Message",
          value: "welcome_message"
        },
        {
          text: "Order Success",
          value: "order_success"
        },
        {
          text: "Order Delivery",
          value: "order_delivery"
        },
        {
          text: "Rate Order",
          value: "rate_order"
        },
        {
          text: "Wishlist",
          value: "wishlist"
        }
      ];
    },
    durations: function () {
      return [
        {
          text: "10s",
          value: 10
        },
        {
          text: "20s",
          value: 20
        },
        {
          text: "30s",
          value: 30
        },
        {
          text: "40s",
          value: 40
        },
        {
          text: "50s",
          value: 50
        },
        {
          text: "60s",
          value: 60
        }
      ];
    },
    routes: function () {
      return [
        {
          text: "Home Page",
          value: "HomeScreen"
        },
        {
          text: "Cart Page",
          value: "Cart"
        },
        {
          text: "Profile",
          value: "ProfilePage"
        },
        {
          text: "Categories",
          value: "Categories"
        }
      ];
    },
    sounds: function () {
      return [
        {
          text: "Tri-tone",
          value: "Tri-tone"
        }
      ];
    },
    // label: function () {
    //   if (this.action === 0 || this.action === 1) {
    //     return this.$tr('project', 'key_61')
    //   } else if (this.action === 2 || this.action === 5) {
    //     return this.$tr('project', 'key_48')
    //   } else if (this.action === 3) {
    //     return this.$tr('auth', 'key_5')
    //   } else {
    //     return this.$tr('project', 'key_301')
    //   }
    // }
  },
  methods: {
    updateIcon(icon) {
      this.icon = icon;
      this.dialog = false;
    },
    save() {
      this.$emit("save", {
        title: this.title,
        message: this.message,
        notification_type: this.notification_type,
        duration: this.duration,
        action: this.action,
        route : this.route,
        sound : this.sound,
        icon: this.icon,
      });
    },
    clean() {
      this.title   = "";
      this.message   = "";
      this.notification_type   = "";
      this.duration   = "";
      this.actions   = "";
      this.route   = "";
      this.sound   = "";
      this.action = "";
      this.icon   = "albums-outline";
      this.search = "";
    }
  }, 
  beforeMount() {
    this.title   = this.itemTitle;
    this.message = this.itemMessage;
    this.notification_type = this.itemNotification_type;
    this.duration = this.itemDuration;
    this.route = this.itemRoute;
    this.sound = this.itemSound;
    this.action = this.itemAction;
    this.icon   = this.itemIcon;
  },
  mounted() {

  }
}
</script>