<template>
  <v-layout fill-height>
    <v-main>
      <PageBar
          :title="$tr('menu', 'key_e16')"
          :drawer="true"
      />
      <IntergrationView  v-for="item in intergration.filter(info => info.route_name === 'sociallogin')" :key="item.id"  :item="item" />
      <Loader v-if="loading"/>
      <Container v-else>
                  
          <Title :title="$tr('project', 'key_390')"/>
          <div class="row">
            <div class="col-md-4">
              
              <div class="permissions_block" style="margin-top: 20px;">
                <div class="d-flex justify-space-between align-center " style="padding: 16px 16px 0px 16px ;">
                  <v-img
                    src="https://app.shopimint.com/backend/static/intergration/googlelogin.png"
                  ></v-img>
                </div>
                
                <div class="d-flex justify-space-between align-center pa-4">
                  
                  <div class="d-flex justify-start align-center">
                    <div>
                      <div class="body-1 font-weight-medium">
                        {{ $tr('project', 'key_392') }}
                      </div>
                      <div class="body-2 smoke--text">
                        {{ $tr('project', 'key_394') }}
                      </div>
                    </div>
                  </div>
                  <v-switch
                      :true-value="1"
                      :false-value="0"
                      v-model="settings.googlelogin_enabled"
                      inset
                  ></v-switch>
                  <HelpArticle2 id="632987a9b78f9a796bdeaeb8"/>
                </div>
                <div class="d-flex justify-space-between align-center " style="padding: 0px 16px 16px 16px ;">
                  <v-text-field
                    :label="$tr('project', 'key_400')"
                    outlined
                    color="primary"
                    dense
                    hide-details
                    v-model="settings.googlelogin_code"
                  ></v-text-field>
                </div>
                <!-- <div class="d-flex justify-space-between align-center " style="padding: 0px 16px 16px 16px ;">
                  <v-text-field
                    :label="$tr('project', 'key_429')"
                    outlined
                    color="primary"
                    dense
                    hide-details
                    v-model="settings.googlelogin_secret"
                  ></v-text-field>
                </div> -->
              </div>

            </div>
            <div class="col-md-4">
              
              <div class="permissions_block" style="margin-top: 20px;">
                <div class="d-flex justify-space-between align-center " style="padding: 16px 16px 0px 16px ;">
                  <v-img
                    src="https://app.shopimint.com/backend/static/intergration/applelogin.png"
                  ></v-img>
                </div>
                
                <div class="d-flex justify-space-between align-center pa-4">
                  
                  <div class="d-flex justify-start align-center">
                    <div>
                      <div class="body-1 font-weight-medium">
                        {{ $tr('project', 'key_395') }}
                      </div>
                      <div class="body-2 smoke--text">
                        {{ $tr('project', 'key_396') }}
                      </div>
                    </div>
                  </div>
                  <v-switch
                      :true-value="1"
                      :false-value="0"
                      v-model="settings.apple_enabled"
                      inset
                  ></v-switch>
                  <HelpArticle2 id="6329885ab78f9a796bdeaed7"/>
                </div>
                <!-- <div class="d-flex justify-space-between align-center " style="padding: 0px 16px 16px 16px ;">
                  
                  <v-text-field
                    :label="$tr('project', 'key_401')"
                    outlined
                    color="primary"
                    dense
                    hide-details
                    v-model="settings.applelogin_code"
                ></v-text-field>
                
                </div>
                <div class="d-flex justify-space-between align-center " style="padding: 0px 16px 16px 16px ;">
                  
                  <v-text-field
                    :label="$tr('project', 'key_431')"
                    outlined
                    color="primary"
                    dense
                    hide-details
                    v-model="settings.applelogin_secret"
                ></v-text-field>
                
                </div> -->
              </div>
            </div>
            <div class="col-md-4">
              <div class="permissions_block" style="margin-top: 20px;">
                <div class="d-flex justify-space-between align-center " style="padding: 16px 16px 0px 16px ;">
                  <v-img
                    src="https://app.shopimint.com/backend/static/intergration/facebooklogin.png"
                  ></v-img>
                </div>
                
                <div class="d-flex justify-space-between align-center pa-4">
                  
                  <div class="d-flex justify-start align-center">
                    <div>
                      <div class="body-1 font-weight-medium">
                        {{ $tr('project', 'key_391') }}
                      </div>
                      <div class="body-2 smoke--text">
                        {{ $tr('project', 'key_393') }}
                      </div>
                    </div>
                  </div>
                  <v-switch
                      :true-value="1"
                      :false-value="0"
                      v-model="settings.facebooklogin_enabled"
                      inset
                  ></v-switch>
                  <HelpArticle2 id="63297f6eb78f9a796bdeae65"/> 
                </div>
                <div class="d-flex justify-space-between align-center " style="padding: 0px 16px 16px 16px ;">
                  
                  <v-text-field
                    :label="$tr('project', 'key_399')"
                    outlined
                    color="primary"
                    dense
                    hide-details
                    v-model="settings.facebooklogin_code"
                ></v-text-field>
                
                </div>
                <div class="d-flex justify-space-between align-center " style="padding: 0px 16px 16px 16px ;">
                  
                  <v-text-field
                    :label="$tr('project', 'key_430')"
                    outlined
                    color="primary"
                    dense
                    hide-details
                    v-model="settings.facebooklogin_secret"
                ></v-text-field>
                
                </div>
                
              </div>
            </div>
            
          </div>
          
      </Container>

      <Footer
          v-if="!loading"
          :loading="btnLoad"
          ref="footer"
          @click="updatePushDetail"
      />
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Container from "@/components/blocks/Container";
import Title from "@/components/blocks/Title";
import Loader from "@/components/blocks/Loader";
import Footer from "@/components/blocks/Footer";
import IntergrationView from "../../../../../components/blocks/IntergrationView.vue";
import HelpArticle2 from "../../../../../components/blocks/HelpArticle2.vue";
export default {
  name: 'Push',
  components: {
    PageBar,
    Container,
    Title,
    Loader,
    Footer,
    IntergrationView,
    HelpArticle2
},
  data: () => ({
    loading: true,
    btnLoad: false,
    intergration: [],
    settings: {
      googlelogin_enabled : "",
      googlelogin_code:"",
      googlelogin_secret:"",
      facebooklogin_enabled: "",
      facebooklogin_code:"",
      facebooklogin_secret: "",
      apple_enabled: "",
      applelogin_code: "",
      applelogin_secret: "",
    },
    reissue: false,
    btn_load: false,
  }),
  methods: {
    getPushSettings() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/appintergration/social/${this.$route.params.uid}`).
      then(
          response => {
            this.settings = response.data.detail;
            this.getIntergrations();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
      );
    },
    getIntergrations() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/intergrations/getintergrations/${this.$route.params.id}`).
        then(
          response => {
            this.intergration = response.data.intergration;
            this.loading = false;
          }
        ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
        );
    },
    updatePushDetail() {
      this.btnLoad = true;
      let params = new URLSearchParams();
      params.append('googlelogin_enabled', this.settings.googlelogin_enabled);
      params.append('googlelogin_code', this.settings.googlelogin_code);
      params.append('googlelogin_secret', this.settings.googlelogin_secret);
      params.append('facebooklogin_enabled', this.settings.facebooklogin_enabled);
      params.append('facebooklogin_code', this.settings.facebooklogin_code);
      params.append('facebooklogin_secret', this.settings.facebooklogin_secret);
      params.append('apple_enabled', this.settings.apple_enabled);
      params.append('applelogin_code', this.settings.applelogin_code);
      params.append('applelogin_secret', this.settings.applelogin_secret);
      this.$http.post(`${this.$serverApiLink}api/account/appintergration/updatesocial/${this.$route.params.uid}`, params ).
      then(
          // eslint-disable-next-line no-unused-vars
          response => {
            this.btnLoad = false;
            this.$refs.footer.showSuccessAlert();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
              });
            console.log("gehe")
            this.btnLoad = false;
          }
      );
    },
  },
  mounted() {
    this.getPushSettings();
  }
}
</script>