<template>
  <v-layout fill-height>
    <v-main>
      <PageBar :title="$tr('menu', 'key_1')" :preview="false" drawer />
      <Loader v-if="loading" />
      <template v-else>
        <div class="bg2" v-if="list.length === 0">
          <v-container fluid>
            <div class="row">
              <div class="col-md-9">
                <HomeTitle
                  :title="$tr('project', 'key_20')"
                  :subtitle="$tr('project', 'key_21')">
                  <template v-slot:icon>
                    <FragmentIcon />
                  </template>
                  <template v-slot:action>
                    <img src="" />
                    <v-btn
                      depressed
                      outlined
                      color="primary"
                      large
                      @click="$emit('open_launcher')">
                      {{ $tr("project", "key_2") }}
                    </v-btn>
                    <!-- <div class="row" style="padding-top: 20px;">
              <img src="https://app.shopimint.com/backend/static/marketing/arrow.png" width="50%">
            </div> -->
                    <div
                      class="row"
                      style="padding-top: 20px; justify-content: center">
                      <div class="lg-6 md-12" style="margin: 10px">
                        <a
                          href="https://help.shopimint.com/tag/app-builder-basic-design-guidelines/"
                          target="_blank">
                          <v-btn
                            color="secondary"
                            outlined
                            rounded
                            large
                            small
                            x-small>
                            See Documentation
                          </v-btn>
                        </a>
                      </div>
                      <div class="lg-6 md-12" style="margin: 10px">
                        <a
                          href="https://help.shopimint.com/app-builder-basic-design-guidelines/"
                          target="_blank">
                          <v-btn
                            color="secondary"
                            outlined
                            rounded
                            large
                            small
                            x-small>
                            Watch 30 Min Video
                          </v-btn>
                        </a>
                      </div>
                    </div>
                  </template>
                </HomeTitle>
              </div>
            </div>
            <div class="row"></div>
          </v-container>
          <div style="bottom: 0; margin-top: 5%; margin-right: 50px">
            <GuidArea />
          </div>
        </div>
        <div class="bg" v-else>
          <v-container fluid>
            <div class="row">
              <div class="col-md-9">
                <div class="app_grid">
                  <div v-for="(app, index) in list" :key="'app_' + index">
                    <router-link
                      :to="{ name: 'Main', params: { uid: app.uid } }"
                      style="color: inherit">
                      <div class="app_card">
                        <div style="margin-right: 20px">
                          <AppIcon :image="!app.icon ? null : app.icon" />
                        </div>
                        <div>
                          <div
                            class="font-weight-bold d-inline-block text-truncate notranslate"
                            style="max-width: 280px; margin-bottom: 0">
                            {{ app.name }}
                          </div>
                          <div
                            class="caption text-truncate"
                            style="max-width: 280px; margin-bottom: 6px">
                            {{ app.link }}
                          </div>
                          <div>
                            <StatusBadge :status="!app.status ? false : true" />
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="loadBtn.status && count > 20"
              class="d-flex justify-center mt-8 mb-5">
              <v-btn
                rounded
                outlined
                :loading="loadBtn.loading"
                @click="loadMore">
                {{ $tr("project", "key_26") }}
              </v-btn>
            </div>
          </v-container>
          <div style="bottom: 0; margin-top: 5%; margin-right: 50px">
            <GuidArea />
          </div>
        </div>
      </template>
    </v-main>

    <v-dialog v-model="dialog" max-width="1000px">
      <v-row style="margin: 0px">
        <v-col md="12" sm="12" cols="12" style="padding: 0px">
          <div class="videoWrapper">
            <iframe
              width="560"
              height="349"
              src="https://www.youtube.com/embed/videoseries?list=PLeb_yDpam3dzKkjojodV9A1Ieqcw8BUEs"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen>
            </iframe>
          </div>
        </v-col>
      </v-row>
    </v-dialog>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Loader from "@/components/blocks/Loader";
// import EmptyState from "@/components/blocks/EmptyState";
import HomeTitle from "@/components/blocks/HomeTitle";
import GuidArea from "@/components/blocks/GuidArea";
import FragmentIcon from "@/components/icons/LogoIcon";
import AppIcon from "@/components/blocks/AppIcon";
import StatusBadge from "@/components/blocks/StatusBadge";
import homebackground from "@/assets/images/homebackground.png";

export default {
  name: "Apps",
  components: {
    PageBar,
    Loader,
    // EmptyState,
    HomeTitle,
    GuidArea,
    FragmentIcon,
    AppIcon,
    StatusBadge,
  },
  data: () => ({
    homebackground: homebackground,
    sort: 0,
    loading: true,
    list: [],
    offset: 0,
    dialog: false,
    count: 0,
    loadBtn: {
      status: true,
      loading: false,
    },
  }),
  methods: {
    getAppsList() {
      this.loading = true;
      this.$http
        .get(
          `${this.$serverApiLink}api/account/apps/list/${this.sort}/${this.offset}`
        )
        .then((response) => {
          this.list = response.data.apps;
          this.loading = false;
          this.offset += 20;
          this.count = response.data.count;
        })
        .catch((error) => {
          console.log(error), console.error(error.response);
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.loading = false;
        });
    },
    loadMore() {
      this.loadBtn.loading = true;
      this.$http
        .get(
          `${this.$serverApiLink}api/account/apps/list/${this.sort}/${this.offset}`
        )
        .then((response) => {
          if (response.data.apps.length > 0) {
            for (let i = 0; i < response.data.apps.length; i++) {
              this.list.push(response.data.apps[i]);
            }
          } else {
            this.loadBtn.status = false;
          }
          this.offset += 20;
          this.loadBtn.loading = false;
        })
        .catch((error) => {
          this.$store.commit("setSnackBar", {
            code: !error.response ? 408 : error.response.status,
            message: error.response.data.message,
          });
          this.loadBtn.loading = false;
        });
    },
  },
  mounted() {
    this.getAppsList();
  },
};
</script>

<style scoped>
.bg {
  background-image: url("https://app.shopimint.com/backend/static/homebackground.png");
  background-size: contain;
  height: calc(100% - 72px);
  margin-bottom: 0px;
}

.bg2 {
  background-image: url("https://app.shopimint.com/backend/static/homebackground2.png");
  background-size: contain;
  height: calc(100% - 72px);
  margin-bottom: 0px;
}
</style>
